import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

export class SearchRoute {
  constructor(public route: string, public view_option: string) {}
}

@Component({
  selector: 'pla-search-variant-bar',
  templateUrl: './search-variant-bar.component.html',
  styleUrls: ['./search-variant-bar.component.scss'],
})
export class SearchVariantBarComponent implements OnInit {
  @Input('searchOptions') public options: SearchRoute[] = [];

  constructor() {}

  ngOnInit(): void {}

  public isActiveRoute(route: SearchRoute): boolean {
    return window.location.href.endsWith(route.route);
  }
}
