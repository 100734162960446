<div *ngIf="placement && CanShow()">
    <div class="rounded-lg d-block d-md-flex align-items-center justify-content-start w-100 p-3"
        [style.backgroundColor]="BackgroundFromPlacement()" [style.color]="ForegroundFromPlacement()">

        <!-- ACTIONS -->
        <div *ngIf="placement.type == 'IMAGE_CONTENT'" class="me-2 flex-shrink-0">
            <ng-template [ngTemplateOutlet]="placement_action"
                [ngTemplateOutletContext]="{ placement: placement }"></ng-template>
        </div>

        <!-- CONTENT -->
        <div class="d-flex align-items-start justify-content-center flex-column gap-1 w-100 flex-fill">
            <p class="fs-md-16 mb-1 lh-base">{{ placement.info.header }}</p>
            <p class="fs-md-12 mb-0 lh-base">{{ Replacements(placement.info.body) }}</p>
        </div>

        <!-- ACTIONS -->
        <div *ngIf="placement.type == 'CONTENT_IMAGE'" class="ms-2 flex-shrink-0">
            <ng-template [ngTemplateOutlet]="placement_action"
                [ngTemplateOutletContext]="{ placement: placement }"></ng-template>
        </div>
    </div>
</div>

<ng-template #placement_action let-placement="placement">
    <ng-template *ngIf="placement.action == 'SIGN_UP'" [ngTemplateOutlet]="sign_up"
        [ngTemplateOutletContext]="{ data: placement.info }"></ng-template>
    <ng-template *ngIf="placement.action == 'RAW_IMAGE'" [ngTemplateOutlet]="raw_image"
        [ngTemplateOutletContext]="{ data: placement.info }"></ng-template>
    <ng-template *ngIf="placement.action == 'IMAGE_LINK'" [ngTemplateOutlet]="image_link"
        [ngTemplateOutletContext]="{ data: placement.info }"></ng-template>
    <ng-template *ngIf="placement.action == 'BUTTON_LINK'" [ngTemplateOutlet]="button_link"
        [ngTemplateOutletContext]="{ data: placement.info }"></ng-template>
</ng-template>

<ng-template #sign_up let-data="data">
    <div class="mx-md-0 mx-auto mt-md-0 mt-3 d-block w-100" style="max-width: 250px;">
        <button mat-raised-button class="{{data.actionClass}}" (click)="actionClick()">{{ data.actionText }}</button>
    </div>
</ng-template>

<ng-template #button_link let-data="data">
    <div class="mx-md-0 mx-auto mt-md-0 mt-3 d-block w-100" style="max-width: 250px;">
        <a href="{{data.actionLink}}" mat-raised-button class="text-decoration-none {{data.actionClass}}">{{ data.actionText }}</a>
    </div>
</ng-template>

<ng-template #raw_image let-data="data">
    <div class="d-block text-decoration-none {{ data.actionClass }}">
        <img src="{{data.actionImage}}" class="mx-md-0 mx-auto mt-md-0 mt-3 d-block w-100 {{ data.actionImageClass }}" style="max-width: 250px; object-fit: contain;" />
    </div>
</ng-template>

<ng-template #image_link let-data="data">
    <a href="{{ data.actionLink }}" class="d-block text-decoration-none {{ data.actionClass }}">
        <img src="{{data.actionImage}}" class="mx-md-0 mx-auto mt-md-0 mt-3 d-block w-100" style="max-width: 250px;" />
    </a>
</ng-template>