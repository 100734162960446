<div style="background-color: #214953;">
  <div class="container-fluid w-100 mx-auto pt-3" style="max-width: 1500px;">

    <div class="row px-3 py-2 mb-3 mb-lg-0">
      <div class="col-12 col-md-6 px-0">
        <div class="h-100 d-flex align-items-center justify-content-start justify-content-lg-start mb-3 mb-lg-0">
          <div>
            <pla-platex-direct class="platex-logo" [colour]="'white'" [direct]="false"
              [platePortal]="true"></pla-platex-direct>
            <p class="fs-md-12 my-0 text-white text-start text-md-center">Version {{ versionNo }}</p>
          </div>
        </div>
      </div>
      <div class="col-12 order-2 order-md-1"></div>
      <div class="col-12 col-md-6 px-0">
        <div class="text-lg-end text-start">
          <p class="lh-base fs-md-12 text-white my-0">{{ plateCount | number }}</p>
          <p class="lh-base fs-md-12 text-white my-0">Registrations available now</p>
        </div>
      </div>
    </div>

    <div class="row px-3 mb-3">
      <div class="ps-0 col-xl-3 col-lg-3 col-sm-6 col-12 pe-0 text-start text-lg-start mb-3 mb-lg-0">
        <p class="fw-bold mt-0 mb-0" style="color: #FAB82B;">BUY</p>
        <a href="/buy" class="mb-1 text-decoration-none d-block text-white">Search personal plates for sale</a>
        <a href="/initials-search" class="mb-1 text-decoration-none d-block text-white">Search for your Initial
          plate</a>
        <a href="/byo-search" class="mb-1 text-decoration-none d-block text-white">Build-Your-Own private plate</a>
        <a href="/new-release/25" class="mb-1 text-decoration-none d-block text-white">Search new released plates</a>
        <a href="/under-200" class="mb-0 text-decoration-none d-block text-white">Plates for less than £200</a>
      </div>
      <div
        class="d-none border-start border-light col-xl-3 col-lg-3 col-sm-6 col-12 pe-0 ps-0 ps-md-3 text-start text-lg-start mb-3 mb-lg-0 no-border"
        style="border-color: #707070 !important;">
        <p class="fw-bold mt-0 mb-0" style="color: #FAB82B;">SELL</p>
        <a href="" class="mb-1 text-decoration-none d-block text-danger">How to value a number plate</a>
        <a href="" class="mb-1 text-decoration-none d-block text-danger">How much is my plate worth</a>
        <a href="" class="mb-1 text-decoration-none d-block text-danger">Sell on PlateX</a>
        <a href="" class="mb-1 text-decoration-none d-block text-danger">How to sell safely online</a>
        <a href="" class="mb-0 text-decoration-none d-block text-danger">Sell my plate</a>
      </div>
      <div
        class="border-start border-light col-xl-3 col-lg-3 col-sm-6 col-12 pe-0 ps-0 ps-md-3 text-start text-lg-start mb-3 mb-lg-0 no-border"
        style="border-color: #707070 !important;">
        <a href="/articles/number-plate-types" class="text-decoration-none d-block fw-bold mt-0 mb-0"
          style="color: #FAB82B;">Number Plate Types</a>
        <a href="/articles/private-number-plates" class="mb-1 text-decoration-none d-block text-white">Private number
          plates</a>
        <a href="/articles/cherished-number-plates" class="mb-1 text-decoration-none d-block text-white">Cherished
          number plates </a>
        <a href="/articles/dateless-number-plates" class="mb-1 text-decoration-none d-block text-white">Dateless number
          plates</a>
        <a href="/articles/prefix-number-plates" class="mb-1 text-decoration-none d-block text-white">Prefix number
          plates</a>
        <a href="/articles/suffix-number-plates" class="mb-0 text-decoration-none d-block text-white">Suffix number
          plates</a>
      </div>
      <div
        class="border-start border-light col-xl-3 col-lg-3 col-sm-6 col-12 pe-0 ps-0 ps-md-3 text-start text-lg-start mb-3 mb-lg-0 no-border"
        style="border-color: #707070 !important;">
        <p class="fw-bold mt-0 mb-0" style="color: #FAB82B;">Topics</p>
        <a href="/articles/how-to-transfer-a-registration" class="mb-1 text-decoration-none d-block text-white">How to
          transfer a registration</a>
        <a href="/articles/how-to-retain-a-registration" class="mb-1 text-decoration-none d-block text-white">How to
          retain a registraion</a>
        <a class="mb-1 text-decoration-none d-block text-white" style="cursor: pointer;"
          id="open_preferences_center">Update cookies
          preferences</a>
        <a class="mb-1 text-decoration-none d-block text-white" style="cursor: pointer; height: 20px;"></a>
        <a class="mb-1 text-decoration-none d-block text-white" href="mailto:info@platex.co.uk">Contact us</a>
      </div>
      <!-- WHILE SELL IS HIDDEN -->
      <div class="col-xl-3 col-lg-3 col-sm-6 col-12 pe-0 ps-0 ps-md-3 text-start text-lg-start mb-3 mb-lg-0"></div>
      <!-- END -->
      <div class="col-xl-3 col-lg-3 col-sm-6 col-12 pe-0"></div>
    </div>

    <div class="border-top border-light row px-3 py-3" style="border-color: #707070 !important;">
      <div class="col-md-5 col-12 ps-0">
        <p class="w-100 d-block fs-md-12 my-0 text-white text-center text-lg-start lh-base">Site Design & Build by
          PlateX</p>
      </div>
      <div class="col-md-2 col-12 d-flex align-items-center justify-content-center gap-4 my-3 my-lg-0">
        <a href="https://www.facebook.com/profile.php?id=100093000570664" style="max-width: 25px;" class="d-block bg"
          target="_blank"><object class="d-block mx-auto w-100" style="pointer-events: none"
            data="./../../../assets/facebook.svg"></object>
        </a>

        <a href="https://www.instagram.com/plates" style="max-width: 25px;" class="d-block" target="_blank"><object
            class="d-block mx-auto w-100" style="pointer-events: none" data="./../../../assets/instagram.svg"></object>
        </a>

        <a href="https://www.twitter.com/platex_uk" style="max-width: 25px;" class="d-block" target="_blank"><object
            class="d-block mx-auto w-100" style="pointer-events: none" data="./../../../assets/twitter.svg"></object>
        </a>
      </div>
      <div class="col-md-5 col-12 pe-0">
        <p class="w-100 d-block fs-md-12 my-0 text-white lh-base text-center text-lg-end">Copyright 2020 - {{ currYear
          }} PlateX</p>
      </div>
    </div>
  </div>
</div>