import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin-service';
import { NotificationService } from 'src/app/services/notification-service';
import { SessionService } from 'src/app/services/session-service';

export class AccountPage {
  constructor(
    public id: string,
    public name: string,
    public enabled: boolean = true
  ) { }
}

@Component({
  selector: 'pla-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
  public accountPages: AccountPage[] = [
    new AccountPage('favourites', 'Favourites'),
    new AccountPage('notify-me', 'Notify Me'),
    // new AccountPage('alerts', 'Alerts'),
    new AccountPage('messages', 'Messages'),
    new AccountPage('details', 'Details'),
    new AccountPage('selling', 'Selling'),
  ];

  public selectedPage: AccountPage = new AccountPage(
    'favourites',
    'Favourites'
  );

  constructor(
    private sessionService: SessionService,
    private router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private adminService: AdminService,
  ) {
    if (!this.sessionService.isLoggedIn()) {
      this.router.navigate(['/login']);
      return;
    } else {
      this.adminService.verify().subscribe((data: { admin: boolean }) => {
        if (!data.admin) {
          return;
        }
        this.accountPages.push(new AccountPage('admin', 'Admin'))

        this.loadPage();
      });
    }
  }

  private loadPage(): void {
    this.notificationService.notificationEmitter.subscribe(() => {
      this.decidePage();
    });

    var pageSection = this.activatedRoute.snapshot.paramMap.get('pageSection');
    if (pageSection == null || pageSection == '') {
      this.decidePage();
      return;
    }
    this.selectedPage = this.accountPages.filter(
      (ap: AccountPage) => ap.id == pageSection
    )[0];
  }

  private decidePage(): void {
    var pageSection = this.activatedRoute.snapshot.paramMap.get('pageSection');
    if (pageSection != null && pageSection != '') return;
    var events = this.notificationService.eventfeed();
    events.forEach((e) => {
      if (e.notificationTitle === 'new-message')
        this.changePageHandler(this.accountPages[3]);
    });
  }

  ngOnInit(): void { }

  public changePageHandler(accountPage: AccountPage): void {
    this.selectedPage = accountPage;
    this.location.go(`/account/${accountPage.id}`);
  }
}
