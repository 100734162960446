import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Registration } from 'src/app/models/registration';

@Component({
  selector: 'pla-inline-plate-viewer-link',
  templateUrl: './inline-plate-viewer-link.component.html',
  styleUrls: ['./inline-plate-viewer-link.component.scss']
})
export class InlinePlateViewerLinkComponent implements OnInit {

  @Input() public plate: Registration;
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  public OpenPlateViewerWithPlate(): void {
    if (this.plate == undefined) return;
    this.router.navigate([`/plate-viewer/plate/${this.plate.formattedRegistration}`]);
  }

}
