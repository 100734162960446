<div *ngIf="!loading; else skeletonRow">
  <div class="container-fluid w-100 py-3">
    <div class="row">
      <!-- PLATE AND RANK -->
      <div
        class="col-5 col-sm-3 col-md-3 col-lg-4 col-xl-3 px-0 d-flex align-items-center justify-content-start gap-1 gap-sm-2">
        <pla-plate [plate]="plate" [forcedTerm]="forcedTerm" [showPrice]="false" [mobileSmall]="true"
          [smallPlate]="false" [showPercentage]="false" [customClass]="'flat shaded'" [plateSize]="'smaller'"
          [noMargin]="true" [disabled]="!plate.available" [openModal]="true" [white]="whitePlate"></pla-plate>

        <div *ngIf="canRank" class="d-block"><pla-inline-plate-rank [registration]="plate"></pla-inline-plate-rank>
        </div>
        <div *ngIf="!canRank" class="d-block"><mat-icon data-nosnippet class="mat-icon i-center fs-mb-30 fs-md-35 opacity-0"
            style="visibility: hidden; color: transparent;">bug_report</mat-icon></div>
      </div>

      <!-- FAVOURITE -->
      <div
        class="col-2 col-sm-2 col-md-2 d-flex d-sm-flex align-items-center justify-content-end justify-content-sm-center px-0">
        <div class="pe-2 pe-sm-0">
          <pla-inline-favourite [plate]="plate"></pla-inline-favourite>
        </div>
      </div>

      <!-- PLATE VIEWER -->
      <div class="col-0 col-sm-1 col-md-2 d-none d-sm-flex align-items-center justify-content-end px-0">
        <pla-inline-plate-viewer-link [plate]="plate"></pla-inline-plate-viewer-link>
      </div>

      <!-- PRICE -->
      <div
        class="col-4 col-sm-4 col-md-3 col-xl-4 d-flex flex-column align-items-center justify-content-center text-center ps-0 pe-0">
        <span *ngIf="plate.price == -1" class="fs-md-16 lh-sm d-block fw-normal">Not Available</span>
        <span *ngIf="plate.price > -1 && plate.price < 10000000" class="fs-md-16 fs-mb-16 lh-sm d-block fw-normal">{{
          plate.price / 100 | currency:
          'GBP' }}</span>
        <span *ngIf="plate.price > 10000000" class="fs-md-16 fs-mb-16 lh-sm d-block fw-normal">{{ plate.price / 100 |
          currency : "GBP" : "symbol" :
          "1.0-0" }}</span>
        <span *ngIf="plate.seller && plate.price > -1" class="fs-md-12 fs-mb-12 text-truncate">{{ plate.seller }}</span>
      </div>

      <!-- ACTION -->
      <div class="col-1 col-sm-2 col-lg-1 d-flex align-items-center justify-content-start ps-0 ps-md-1">
        <button (click)="openPlateDetail(plate)" style="min-height: 28px;"
          class="platex-button w-100 d-none d-sm-flex align-items-center justify-content-center gap-1 rounded-3 py-1 px-2">
          <span class="fs-md-14 fw-normal lh-1">View</span>
        </button>
        <mat-icon data-nosnippet (click)="openPlateDetail(plate)" class="d-block d-sm-none" data-nosnippet>chevron_right</mat-icon>
      </div>
    </div>
  </div>
  <div *ngIf="!lastRow" class="border-bottom border-1"></div>
</div>

<!-- SKELETON ROW -->
<ng-template #skeletonRow>
  <div class="container-fluid w-100 py-3 skeleton">
    <div class="row">
      <!-- PLATE AND RANK -->
      <div
        class="col-5 col-sm-3 col-md-3 col-lg-4 col-xl-3 px-0 d-flex align-items-center justify-content-start gap-1 gap-sm-2">
        <div class="d-none d-sm-block"><pla-inline-plate-rank></pla-inline-plate-rank></div>
        <div class="plate-size skeleton-item"></div>
      </div>

      <!-- FAVOURITE -->
      <div
        class="col-2 col-sm-2 col-md-2 d-flex d-sm-flex align-items-center justify-content-end justify-content-sm-center px-0">
        <div class="pe-2 pe-sm-0">
          <div class="icon-button skeleton-item"></div>
        </div>
      </div>

      <!-- PLATE VIEWER -->
      <div class="col-0 col-sm-1 col-md-2 d-none d-sm-flex align-items-center justify-content-end px-0">
        <div class="icon-button skeleton-item"></div>
      </div>

      <!-- PRICE -->
      <div
        class="col-4 col-sm-4 col-md-3 col-xl-4 d-flex flex-column align-items-center justify-content-center text-center">
        <span class="price-size skeleton-item fs-md-16 fs-mb-16 lh-sm d-block fw-normal">£000,000</span>
        <span class="price-size skeleton-item fs-md-12 fs-mb-12 text-truncate mt-1">PLATEX</span>
      </div>

      <!-- ACTION -->
      <div class="col-1 col-sm-2 col-lg-1 d-flex align-items-center justify-content-start px-0">
        <div class="d-none d-sm-block skeleton-item py-1 px-2 w-100"><span class="fs-md-14 fw-normal lh-1">View</span>
        </div>
        <div class="d-block d-sm-none icon-button skeleton-item"></div>
      </div>
    </div>
  </div>
  <div *ngIf="!lastRow" class="border-bottom border-1"></div>
</ng-template>

<!-- SKELETON ROW -->
<ng-template #oldSkeletonRow>
  <div class="result-row skeleton">
    <td>
      <div class="plate-size skeleton-item"></div>
    </td>
    <div class="flex-space desktop-only"></div>
    <td class="no-padding flex-space">
      <div class="price-container n-a-price">
        <p class="price-size skeleton-item"></p>
        <p class="price-size skeleton-item"></p>
      </div>
    </td>
    <div class="desktop-only flex-space"></div>
    <td class="actions-field flex-field">
      <div class="action-button-width desktop-only">
        <div class="btn-container-full-width">
          <div class="action-button-size skeleton-item"></div>
        </div>
      </div>

      <div class="action-button-width btn-container-full-width desktop-only">
        <div class="action-button-size skeleton-item"></div>
      </div>

      <div class="dynamic action-button-width mobile-only">
        <div class="btn-container-full-width">
          <div class="icon-button skeleton-item"></div>
        </div>
      </div>

      <div class="action-button-width btn-container-no-right-padding">
        <div class="btn-container-full-width">
          <div class="action-button-size skeleton-item"></div>
        </div>
      </div>
    </td>
  </div>
</ng-template>