import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { Registration } from 'src/app/models/registration';
import { PlatopediaPlate } from 'src/app/models/platopediaPlate';
import { RegistrationService } from 'src/app/services/registration-service';
import {
  SearchReqResponse,
  SearchService,
} from 'src/app/services/search-service';
import { ResultType } from '../search-result-table/search-result-table.component';
import { PlatopediaSearchRequest } from 'src/app/pages/platopedia-page/platopedia-page.component';

@Component({
  selector: 'pla-platopedia-list',
  templateUrl: './platopedia-list.component.html',
  styleUrls: ['./platopedia-list.component.css'],
})
export class PlatopediaListComponent implements OnInit {
  public searchHeader: string;
  public searched: PlatopediaSearchRequest = null;
  public searchResults: PlatopediaPlate[];
  public results: SearchReqResponse;
  public resultType: ResultType = ResultType.Term;
  public loading: boolean = true;
  public deleteItem: EventEmitter<any> = new EventEmitter<any>();

  public resultChange: EventEmitter<SearchReqResponse> =
    new EventEmitter<SearchReqResponse>();
  @Input() public customHeader: TemplateRef<any>;
  @Input() public scrollToTopTrigger: EventEmitter<void>;
  @Input() public searchChange: EventEmitter<PlatopediaSearchRequest>;

  constructor(
    private searchService: SearchService,
    private registrationService: RegistrationService
  ) {
    this.deleteItem.subscribe((res: Registration) => {
      this.results.registrations.splice(
        this.results.registrations.findIndex(
          (r) => r.registration == res.registration
        )
      );
      this.searchService.deletePlateopediaWord(
        res.term,
        res.registration.replace(' ', '')
      );
    });
  }

  ngOnInit(): void {
    this.setupListener();
  }

  private setupListener(): void {
    this.searchChange.subscribe((req: PlatopediaSearchRequest) => {
      this.search(req);
    });
  }

  private applyFilters(
    req: PlatopediaSearchRequest,
    results: PlatopediaPlate[]
  ): void {
    var res = [...results];
    var filteringRequired = true;
    // lets filter

    if (req.indiLetter === 'ALL') {
      // only support name full filtering for now
      if (req.queryString.indexOf('name') > -1) {
        filteringRequired = false;
      }
    }

    if (filteringRequired) {
      res = res.filter((r) => {
        var firstLetter = r.word[0];
        return (
          firstLetter >= req.letterGroup[0] && firstLetter <= req.letterGroup[1]
        );
      });

      if (req.indiLetter) {
        res = res.filter((r) => {
          var firstLetter = r.word[0];
          return firstLetter == req.indiLetter;
        });
      }
    }

    var _ = this.registrationService.formatPlatopediaRegistrations(res);

    _.forEach((r, i) => {
      r.registration['term'] = r.word;
      r.registration['meta_data'] = r.meaning;
      r.registration['filters'] = r.filters;
      r.registration.id = i;
    });
    var registrations = _.map((r) => r.registration);
    this.results = new SearchReqResponse(
      'plateopedia_id',
      registrations.sort((a, b) => a.term.localeCompare(b.term))
    );
    this.resultChange.emit(this.results);
  }

  private makeNewRequest(req: PlatopediaSearchRequest): boolean {
    if (this.searched == null) return true;
    if (this.searched.queryString != req.queryString) return true;
    return false;
  }

  private search(request: PlatopediaSearchRequest): void {
    this.searchHeader = request.safeHeader;
    if (!this.makeNewRequest(request)) {
      this.applyFilters(request, this.searchResults);
      return;
    }
    this.loading = true;
    this.searchService.searchplatopedia(
      request.queryString,
      (filters: string, res: PlatopediaPlate[]) => {
        this.loading = false;
        if (res == null) {
          this.resultChange.emit(new SearchReqResponse('plateopedia_id', []));
          return;
        }
        this.searched = JSON.parse(JSON.stringify({ ...request }));
        this.searchResults = res;
        this.applyFilters(request, res);
      }
    );
  }
}
