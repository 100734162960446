import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserDetails } from 'src/app/models/userDetails';
import { SessionService } from 'src/app/services/session-service';
import { UserService } from 'src/app/services/user-service';

@Component({
  selector: 'pla-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
})
export class AccountDetailsComponent implements OnInit {
  private userDetails: UserDetails;
  public accountDetailsForm: FormGroup;
  public loading: boolean = true;

  constructor(
    private sessionService: SessionService,
    private router: Router,
    private userService: UserService,
    private snackbar: MatSnackBar
  ) {
    if (!this.sessionService.isLoggedIn()) {
      this.router.navigate(['/login']);
    } else {
      this.loading = true;
      this.userService.getUserDetails((u: UserDetails) => {
        this.userDetails = u;
        this.createFormGroup();
        this.loading = false;
      });
    }
  }

  ngOnInit(): void {}

  public saveDetails(): void {
    if (this.accountDetailsForm.invalid) {
      this.snackbar.open(
        'Some of the details you entered are incorrect',
        'Close',
        { duration: 5000 }
      );
    } else {
      var updatedDetails = this.accountDetailsForm.getRawValue() as UserDetails;
      this.userService.updateUserDetails(updatedDetails).subscribe((res) => {
        this.snackbar.open('Details updated successfully!', 'Close', {
          duration: 5000,
        });
        this.userDetails = updatedDetails;
        this.accountDetailsForm.disable();
      });
    }
  }

  public signOut(): void {
    this.sessionService.signOut(() => {
      this.router.navigate(['/login']).then(() => {
        this.snackbar.open('Signed out successfully!', 'Close', {
          duration: 3000,
        });
      });
    });
  }

  public cancelUpdate(): void {
    this.accountDetailsForm.disable();
  }

  public updateDetails(): void {
    this.accountDetailsForm.enable();
  }

  private createFormGroup(): void {
    this.accountDetailsForm = new FormGroup({
      title: new FormControl(this.userDetails.title, [Validators.required]),
      firstName: new FormControl(this.userDetails.firstName, [
        Validators.required,
      ]),
      lastName: new FormControl(this.userDetails.lastName, [
        Validators.required,
      ]),
      mobileNumber: new FormControl(this.userDetails.mobileNumber, [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
      ]),
      addressLine1: new FormControl(this.userDetails.addressLine1, [
        Validators.required,
      ]),
      addressLine2: new FormControl(this.userDetails.addressLine2, []),
      addressLine3: new FormControl(this.userDetails.addressLine3, []),
      postTown: new FormControl(this.userDetails.postTown, [
        Validators.required,
      ]),
      postCode: new FormControl(this.userDetails.postCode, [
        Validators.required,
        Validators.pattern(
          '^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$'
        ),
      ]),
    });

    this.accountDetailsForm.disable();
  }
}
