import { Component, Input, OnInit } from '@angular/core';
import { QuickSignupService } from 'src/app/services/quick-signup-service';
import { RegistrationService } from 'src/app/services/registration-service';
import { SessionService } from 'src/app/services/session-service';

export enum PlacementType {
  IMAGE_CONTENT = 'IMAGE_CONTENT',
  CONTENT_IMAGE = 'CONTENT_IMAGE'
}

export enum PlacementAction {
  SIGN_UP = 'SIGN_UP',
  RAW_IMAGE = 'RAW_IMAGE',
  IMAGE_LINK = 'IMAGE_LINK',
  BUTTON_LINK = 'BUTTON_LINK',
}

export enum PlacementColour {
  INFO,
  WARNING,
  SUCCESS,
  DANGER
}

export class PlacementInfo {
  public header: string;
  public body: string;

  // for the actions
  public actionImage?: string;
  public actionClass?: string;
  public actionText?: string;
  public actionLink?: string;
  public actionImageClass?: string;
  public actionMethod?: () => void;

  // visibility
  public hideWhenLoggedOut?: boolean = false;
  public hideWhenLoggedIn?: boolean = false;
  public swapWith?: PagePlacement;
}

export class PagePlacement {
  constructor(public type: PlacementType, public colour: PlacementColour, public action: PlacementAction, public info: PlacementInfo) { }
}


export const PAGE_PLACEMENTS = {
  //
  SUPERCHARGE_YOUR_SEARCH: (quickSignupService: QuickSignupService, swapWith?: PagePlacement) => new PagePlacement(PlacementType.CONTENT_IMAGE, PlacementColour.SUCCESS, PlacementAction.SIGN_UP, {
    header: 'SUPERCHARGE YOUR SEARCH',
    body: 'Favourite Plates - Setup notify when your ideal plates for sale - Unlock Premium Features',
    actionText: 'Sign In',
    actionClass: 'dark-green-button mx-auto d-block w-100',
    actionMethod: () => {
      quickSignupService.signupCapture(true);
    },
    hideWhenLoggedIn: true,
    swapWith: swapWith,
  }),
  DISCOVER_NAME_PLAGES: (swapWith?: PagePlacement) => new PagePlacement(PlacementType.CONTENT_IMAGE, PlacementColour.DANGER, PlacementAction.IMAGE_LINK, {
    header: 'DISCOVER THE ULTIMAGE NAME PLATE COLLECTION',
    body: 'A comprehensive collection of PERFECT NAME PLATES from around the globe',
    actionImage: 'https://cdn.platex.co.uk/pages/new-release/name-plate-collection.png',
    actionLink: '/names',
    swapWith: swapWith,
  }),
  OWN_SIMILAR_FREE_3_MONTHS: (swapWith?: PagePlacement) => new PagePlacement(PlacementType.CONTENT_IMAGE, PlacementColour.INFO, PlacementAction.BUTTON_LINK, {
    header: 'Own Similar?  Advertise your plate here for just £17.50 for a 3 months listing!',
    body: '0% Commission - Deal Direct with buyers - No hidden costs, buyers/sellers fees means more money in your pocket!',
    actionText: 'List on PlateX',
    actionLink: '/sell',
    actionClass: 'dark-green-button mx-auto d-block w-100',
    swapWith: swapWith,
  }),
  HOW_DID_WE_DO: (swapWith?: PagePlacement) => new PagePlacement(PlacementType.IMAGE_CONTENT, PlacementColour.WARNING, PlacementAction.RAW_IMAGE, {
    header: 'HOW DID WE DO?',
    body: 'Our search algorithm "Plato" searched |*PLATE_M*|M number plates from multiple dealers to find these result for you.',
    actionImage: 'https://cdn.platex.co.uk/pages/search-results/how_did_we_do.png',
    actionClass: 'pe-md-2 mb-3 mb-md-0',
    actionImageClass: 'mh-50px',
    swapWith: swapWith,
  })
  //
}

@Component({
  selector: 'pla-page-placement',
  templateUrl: './page-placement.component.html',
  styleUrls: ['./page-placement.component.scss']
})
export class PagePlacementComponent implements OnInit {

  @Input() public placement: PagePlacement;
  public loggedIn: boolean = this.sessionService.isLoggedIn();
  public plateCount: number = Math.floor(
    Math.random() * (6820000 - 6810000 + 1) + 68000000
  );
  public plateMillions: string;

  constructor(private sessionService: SessionService, private registrationService: RegistrationService) {
    this.registrationService
      .getPlateCount()
      .subscribe((_: { totalCount: number }) => {
        this.plateCount = _.totalCount;
        this.plateMillions = (_.totalCount/1000000).toFixed(0);
      });
  }

  ngOnInit(): void {
  }

  public Replacements(str: string): string {
    return str.replace('|*PLATE_M*|', this.plateMillions);
  }

  public CanShow(): boolean {
    if (this.placement == null) return false;
    if (this.loggedIn && this.placement.info.hideWhenLoggedIn) {
      if (this.placement.info.swapWith != null) this.placement = this.placement.info.swapWith;
      return false;
    }
    if (!this.loggedIn && this.placement.info.hideWhenLoggedOut) {
      if (this.placement.info.swapWith != null) this.placement = this.placement.info.swapWith;
      return false;
    }
    return true;
  }

  public BackgroundFromPlacement(): string {
    if (this.placement == undefined) return ''; // wont render
    switch (this.placement.colour) {
      case PlacementColour.INFO: return '#F7F7F7';
      case PlacementColour.WARNING: return '#FEECD3';
      case PlacementColour.SUCCESS: return '#E5F1EE';
      case PlacementColour.DANGER: return '#FAF1ED';
      default: return '#F7F7F7';
    }
  }

  public ForegroundFromPlacement(): string {
    if (this.placement == undefined) return ''; // wont render
    switch (this.placement.colour) {
      case PlacementColour.INFO: return '#043946';
      case PlacementColour.WARNING: return '#043946';
      case PlacementColour.SUCCESS: return '#043946';
      case PlacementColour.DANGER: return '#043946';
      default: return '#043946';
    }
  }

  public actionClick(): void {
    this.placement.info.actionMethod();
  }
}


// if (this.sessionService.isLoggedIn()) {}