<div class="page">
  <div class="page-description-container"></div>
  <div class="main-content-container content-container">
    <div class="center-content-container">
      <!-- SEARCH CRITERIA -->
      <div class="pla_card mobile spacing no-top">
        <!-- <pla-banner-image [taller]="true"></pla-banner-image> -->

        <!-- PAGE OPTIONS -->
        <div class="tag-option-container">
          <ng-container *ngFor="let tagOption of tagOptions">
            <div
              class="tag-option"
              [ngStyle]="{
                'background-image': 'url(' + tagOption.backgroundImage + ')'
              }"
              (click)="changeTag(tagOption)"
            >
              <div class="detail-container">
                <span>{{ tagOption.name }}</span>
              </div>
            </div>
          </ng-container>
        </div>

        <!-- TERM OPTIONS  -->
        <div style="text-align: center">
          <mat-form-field appearance="fill">
            <mat-label>{{ tag }}</mat-label>
            <mat-select
              *ngIf="options"
              [(value)]="searchTerm"
              (selectionChange)="searchTermSelectionChanged($event)"
            >
              <mat-option *ngFor="let option of options" [value]="option.term">
                {{ option.term }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- RESULTS -->
      <div *ngIf="searchTerm && (loading || results); else infoSection">
        <div class="pla_card mobile spacing">
          <pla-search-result-table
            [headerText]="searchTerm"
            [results]="results"
            [resultChange]="resultChange"
            [searching]="loading"
            [showFilter]="false"
            [showSort]="true"
            [showToggle]="false"
            [paginationPageSize]="20"
            [customButton]="hideEvent"
            [resultRowType]="resultType"
            [allowShowMore]="true"
            [showTerm]="false"
          ></pla-search-result-table>
        </div>
      </div>

      <ng-template #infoSection>
        <div class="pla_card mobile spacing padding">
          <p>We promise, the process could not be any easier!</p>

          <p>
            Create your own plate using either of the two plate design formats
            above. Millions of combinations available in both formats, starting
            at just &pound;155 (excluding VAT and transfer fee). The official
            DVLA certificate will be sent to you within 4-5 days (or we can
            transfer for you, just add the &lsquo;DVLA transfer service&rsquo;
            to your order on the secure purchase page before checkout. We can
            arrange the actual number plates and deliver to you (just add
            &lsquo;Perspex number plates&rsquo; when placing your order). Make
            Your DVLA Number Plates In Four Easy Steps:
          </p>

          <p>
            There are over 55 MILLION unissued government stock (DVLA
            registrations) available now.
          </p>

          <p>
            Choose a format suitable for your vehicle (Prefix or Current).
            Select a &ldquo;prefix&rdquo; letter or a pair of
            &ldquo;current&rdquo; letters (I, O, Q, Z not available for Prefix).
            Select a number from 1 to 31 or a higher number or multiple of ten
            (40,50,60 etc +,33,44, 55, 100, 200 etc). Select the letters you
            require and hit the search button. Many people will pick their
            initials but you may be able to abbreviate your name like CRG for
            Craig, JAS for Jason or RUS for Russell. Simply enter your choice of
            letters and numbers into the search boxes and scan the huge database
            of available number plates. Whether you choose from the DVLA&rsquo;s
            &lsquo;current plate&rsquo; format (CR68 REG), or the older
            &lsquo;PREFIX plate&rsquo; format (X6 JDW), you can be sure of
            getting an original new number plate never before used in the UK.
          </p>

          <p>
            All personalised number plates purchased from here will be issued on
            a valid DVLA registrations certificate of entitlement in
            approximately 4 days, OR you can hold the personalised plate on the
            registration certificate provided for up to 10 years. We have sold
            tens of thousands of DVLA number plates over the years. They make
            perfect gifts for any occasion with prices starting from only
            &pound;155 plus VAT and DVLA Fee.
          </p>

          <p>
            Transferring to a vehicle is very easy, similar to taxing a vehicle
            online. This can be done up to 10 years after purchase if you do not
            want to assign straight away, are in the process of purchasing a new
            vehicle, or perhaps buying as a gift.
          </p>

          <p>Which registration is suitable for my car?</p>

          <p>
            Please Note: It is not possible to use a personalised registration
            to make a vehicle appear newer, e.g. to use a 2008 &ldquo;08&rdquo;
            plate on a 2006 vehicle. However, there are no restrictions on using
            an older number plate or the same age as the vehicle for which it is
            registered &ndash; You can use a historic (dateless) 1903 number
            plate on a brand new vehicle, fresh out of the showroom or on a
            1960&rsquo;s, 70&rsquo;s or 80&rsquo;s classic!
          </p>

          <p>
            The rule is simple, If the vehicle was first registered from
            September 2001 (51 reg) onwards, you can choose from both of the
            above PREFIX or CURRENT plate styles but If your vehicle is older
            than Sept 2001, then just select PREFIX or search our cherished
            numbers and look at dateless, or suffix as both will also be
            suitable. Dateless number plates are usually much more costly. If
            you are unsure , give us a call!
          </p>
          >
        </div>
      </ng-template>
    </div>
  </div>
</div>
