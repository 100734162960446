import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { SearchService } from 'src/app/services/search-service';

export class NamePopularity {
  public name: string;
  public registration: string;
  public popularity: number;
  public resalePrice: number;
  public resaleYear: string;
}

@Component({
  selector: 'pla-name-popularity-data',
  templateUrl: './name-popularity-data.component.html',
  styleUrls: ['./name-popularity-data.component.scss']
})
export class NamePopularityDataComponent implements OnInit {

  public paginationPageSize: number = 10;
  private currentPageIndex = 0;
  public paginationChange: EventEmitter<any>;
  public viewResults: NamePopularity[] = [];
  public displayResults: NamePopularity[] = [];

  public sorts: { name: string, sort: 'asc' | 'desc' | 'none' }[] = [
    { name: 'registration', sort: 'none' },
    { name: 'popularity', sort: 'asc' },
    { name: 'resalePrice', sort: 'none' }
  ]

  constructor(private router: Router, private searchService: SearchService) { }

  ngOnInit(): void {
    this.searchService.getNamePopularity((results: NamePopularity[]) => {
      this.viewResults = results;
      this.sortData('registration', 0);
      this.gotoPage(this.currentPageIndex);
    });
  }

  public GotoPlate(registration: string): void {
    this.router.navigate([`/plate-detail/${registration.replace(' ', '')}`])
  }

  public sortChange(sort: string) {
    var index = this.sorts.findIndex(s => s.name == sort);
    if (index == -1) return;
    this.sorts[index].sort = this.sorts[index].sort == 'asc' ? 'desc' : 'asc';
    this.sorts = this.sorts.map(s => { if (s.name == sort) return s; s.sort = 'none'; return s; });
    // sort
    this.sortData(sort, index);
    this.gotoPage(0);
  }

  private sortData(sort: string, index: number): void {
    if (sort === 'registration') {
      if (this.sorts[index].sort === 'asc') {
        this.viewResults = this.viewResults.sort((a, b) => a.name.localeCompare(b.name));
      } else {
        this.viewResults = this.viewResults.sort((a, b) => b.name.localeCompare(a.name));
      }
    } else {
      if (this.sorts[index].sort === 'asc') {
        this.viewResults = this.viewResults.sort((a, b) => a[sort] - b[sort])
      } else {
        this.viewResults = this.viewResults.sort((a, b) => b[sort] - a[sort])
      }
    }
  }

  public isSorting(sort: string): 'none' | 'asc' | 'desc' {
    var index = this.sorts.findIndex(s => s.name == sort);
    if (index == -1) return 'none';
    return this.sorts[index].sort;
  }

  public sortingIcon(sort: string): string {
    const sortType = this.isSorting(sort);
    if (sortType === 'none') return 'unfold_more';
    if (sortType === 'asc') return 'north';
    if (sortType === 'desc') return 'south';
  }

  public paginate(page: PageEvent): void {
    this.gotoPage(page.pageIndex);
    if (this.paginationChange != null) this.paginationChange.emit(page);
  }

  private gotoPage(pageIndex: number) {
    this.currentPageIndex = pageIndex;
    this.displayResults = this.get_page_range(
      pageIndex,
      this.paginationPageSize
    );
  }

  public get_page_range(page_index, page_size): NamePopularity[] {
    var min_data_range = page_index * page_size;
    var max_data_range = (page_index + 1) * page_size;
    if (this.viewResults == undefined) return [];
    max_data_range =
      this.viewResults.length > max_data_range
        ? max_data_range
        : this.viewResults.length;
    const visibleResults = this.viewResults.slice(
      min_data_range,
      max_data_range
    );

    return visibleResults;
  }

}
