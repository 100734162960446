<div>
  <pla-wildcard-character-select [padLeft]="padLeft" [style]="style" [any_character]="any_character"
    [no_character]="no_character" [regenerateWithBreakdowns]="regenerateWithBreakdowns" [regenerate]="regenerateInput"
    (inlineSearch)="doInlineSearch($event)" [prefix]="prefix" [numbers]="numbers" [letters]="letters"
    [withPadding]="withPadding"></pla-wildcard-character-select>
</div>
<div *ngIf="showSwitch" class="mt-3 mx-auto tabbed-button-section secondary search-input-sizing">
  <pla-switch-toggle [selectedTabId]="activeSwitch" [tabs]="tabs" [chipColour]="'#F4F4F4'" [pillRadius]="'20px'"
    [chipTextColour]="'#2F2F2F'" [selectedChipColour]="'#3A5B64'" [selectedChipTextColour]="'#fff'"
    (switchChange)="SwitchChange($event)"></pla-switch-toggle>
</div>