import { Component, EventEmitter, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DropdownConfig, DropdownOption } from 'src/app/components/custom-dropdown/custom-dropdown.component';
import {
  FaqBuilder,
  InfoDropdown,
  InfoDropdownContent,
} from 'src/app/components/faq/faq.component';
import { FeatureType } from 'src/app/components/featured-plates/featured-plates.component';
import { SidebarFilterConfig } from 'src/app/components/side-bar-filters/side-bar-filters.component';
import {
  StickyConfig,
  StickyLink,
  StickyLinkGroup,
} from 'src/app/components/sticky-tabs/sticky-tabs.component';
import { CompareFilters } from 'src/app/components/tp-sellers/tp-sellers.component';
import { DVLAVehicleEnquiryResult } from 'src/app/models/dvlaVehicleEnquiryResult';
import { FormattedPlateResponse } from 'src/app/models/formattedPlateResponse';
import { HttpLoadingResp } from 'src/app/models/httpLoadingResp';
import { MarketShare } from 'src/app/models/market-data';
import { PlateDetail } from 'src/app/models/plateDetail';
import { Registration } from 'src/app/models/registration';
import { ImageKeyPair, ImageService } from 'src/app/services/image-service';
import { RegistrationService } from 'src/app/services/registration-service';
import {
  SearchReqResponse,
  SearchService,
} from 'src/app/services/search-service';
import { UserService } from 'src/app/services/user-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'pla-compare-page',
  templateUrl: './compare-page.component.html',
  styleUrls: ['./compare-page.component.scss'],
})
export class ComparePageComponent implements OnInit {
  public filterTrigger: EventEmitter<void> = new EventEmitter<void>();
  public searchTrigger: EventEmitter<void> = new EventEmitter<void>();
  public sortTrigger: EventEmitter<void> = new EventEmitter<void>();
  public sortChange: EventEmitter<string> = new EventEmitter<string>();
  public sortConfig = new DropdownConfig('Sort', 'sort', [
    new DropdownOption('price-ascending', 'Lowest Price'),
    new DropdownOption('price-descending', 'Highest Price'),
  ]);
  public plateStyle: string;
  public favouritePlate: Registration;
  public plateToCompare: string = '';
  public newPage: boolean = true;
  public marketShareData: MarketShare;
  public cards: any[] = [
    {
      number: 'https://cdn.platex.co.uk/pages/compare/point_01.png',
      image:
        'https://cdn.platex.co.uk/pages/compare/_platex_compare_imac.png?t=123',
      title: 'ENTER THE REGISTRATION',
      body: `To compare prices for a private plate you've already chosen, just enter the exact registration number take care to correctly enter the letters 'O' and 'I' and the numbers '0' and '1'.`,
    },
    {
      number: 'https://cdn.platex.co.uk/pages/compare/point_02.png',
      image: 'https://cdn.platex.co.uk/pages/compare/Compare Table.png',
      title: 'SEE HOW MUCH YOU COULD SAVE',
      body: 'Review your comparison results to view prices from over 40 dealers. Find the best deals quickly and discover how much you could save on your plate.',
    },
    {
      number: 'https://cdn.platex.co.uk/pages/compare/point_03.png',
      image: 'https://cdn.platex.co.uk/pages/compare/Compare Filter.png',
      title: 'TAILOR YOUR RESULTS ',
      body: 'You can filter dealer prices to include  acrylic plates or managed transfer service. Prices of options vary greatly so filter to find the best overall deal.',
    },
    {
      number: 'https://cdn.platex.co.uk/pages/compare/point_04.png',
      image: 'https://cdn.platex.co.uk/pages/compare/compare_mac_vert.png',
      title: 'PURCHASE DIRECTLY FROM THE DEALER',
      body: 'Once you\'ve found your ideal plate, click through to the dealer\'s site to complete your purchase. Choose any additional features, arrange payment, and finish the transaction all in one place.',
    },
  ];

  public stickyLinkConfig: StickyConfig = new StickyConfig(
    [
      new StickyLinkGroup([
        new StickyLink('Compare Deals', 'compare-deals', true),
        // new StickyLink(
        //   'Alternative Registrations',
        //   'alternative-registrations',
        //   false
        // ),
        new StickyLink('History', 'history', false),
        // new StickyLink('Market Insights', 'market-insight', false),
        new StickyLink('Q&As', 'faq', false),
      ]),
    ],
    new EventEmitter<void>()
  );
  public rsFeatured = new FeatureType('Recently Sold', 'recently-sold');
  public raFeatured = new FeatureType('Recently Added', 'recently-added');
  public compareFilters = new EventEmitter<
    SidebarFilterConfig<CompareFilters>
  >();

  public registrationLoaded: boolean = false;
  public registration: string;
  public validRegistration: boolean = false;
  public selectedImage: ImageKeyPair = undefined;

  public faq: InfoDropdown[] = [
    FaqBuilder.build('faq_1', 'Why Use PlateX?', [`Almost all registrations on the market are listed with multiple dealers at varying prices, sometimes with over a 30% difference. This makes it crucial to check PlateX before making a purchase to ensure you get the best deal.`,
      `Examples of Savings</p><ul>
      <li><strong>New Issue Private Plates around £250:</strong> By using PlateX, you could save between £14 and £80 compared to other listings.</li>
      <li><strong>New Issue Private Plates around £600:</strong> Potential savings range from £28 to an impressive £192 when using our comparison tool.</li>
      <li><strong>New Issue Private Plates around £1000:</strong> As the price rises so do the potential savings and using pour price comparison could save you £45 to a whopping £272</li>
      <li><strong>Preowned Registrations:</strong> For preowned registrations, the savings can be substantial, sometimes reaching over £10,000 just by comparing prices and choosing the right dealer.</li>
      <li><strong>Record-Breaking Savings:</strong> The most astonishing saving found through PlateX was £20 million on the prestigious registration "F1", which was listed by different dealers with prices ranging from £15 million to £35 million. While this extreme price might be aimed at attracting media attention, it highlights the immense value of PlateX price comparison.</li>
      </ul>`]),
    FaqBuilder.build('faq_2', 'Why should I use PlateX price comparison service? ', [`The answer is simple: our price comparison service helps you save money. Just as you compare car and home insurance quotes on price comparison sites such as GoCompare and Compare the Market, you can compare prices of a particular private plate with PlateX to find the best quote.`, `By using our free service, you can avoid overpaying and find the best price for your perfect private plate combined with extras such as a pair of acrylic plates or a fully managed transfer service.   PlateX plate compare even allow you to filter result to include options for payment by finance or Klarna.`]),
    FaqBuilder.build('faq_3', 'How Do You Show So Many Registration Prices?', [`As a private plate portal and price comparison site, we strive to display as many private registrations as possible, regardless of whether the dealer is a customer of PlateX.`, `Most cherished registration prices are updated automatically, while others are refreshed by our team at least once a day, and some several times. However, since private plate dealers may update their prices more frequently, PlateX does not guarantee that prices are always correct. Always double-check the price on the private plate dealer’s website before completing a purchase to ensure accuracy.`]),
    FaqBuilder.build('faq_4', 'How Do You Sort the Prices for Private Plates?', [`PlateX price comparison service displays results starting for the lowest priced dealer supplying just the private registration number on a V750 certificate of entitlement to the most expensive.`, `However, PlateX users can toggle options to show the lowest price with a set of standard physical number plates (when offered by the dealer) and/or the popular transfer service, where a private plate dealer handles all the required documentation to transfer your new personal registration to your car.`]),
    FaqBuilder.build('faq_5', 'Does PlateX Also Sell Private Plates?', [`No, PlateX does not own or sell any registrations directly. PlateX is an information service, price comparison site, and listing service. We act as a portal to the entire personal registration market, and our sophisticated search algorithm and tools help users find their perfect private plate.`])
  ];

  public detail: {
    min: number;
    max: number;
    totalResultCount: number;
    lastUpdate: Date;
    plateDetail: PlateDetail;
    loaded: boolean;
  } = {
      min: 0,
      max: 0,
      totalResultCount: 0,
      lastUpdate: new Date(),
      plateDetail: undefined,
      loaded: false,
    };
  public registeredVehicleInfo: HttpLoadingResp<DVLAVehicleEnquiryResult> =
    new HttpLoadingResp();
  public isRegisteredToVehicle: boolean = false;

  public altResultChange: EventEmitter<SearchReqResponse> =
    new EventEmitter<SearchReqResponse>();
  public altSearching: boolean = true;
  public altSearchResults: SearchReqResponse;

  public visibleChartData: { showingChart: boolean; showingTable: boolean } =
    undefined;

  constructor(
    private router: Router,
    private imageService: ImageService,
    private activatedroute: ActivatedRoute,
    private registrationService: RegistrationService,
    private userService: UserService,
    private searchService: SearchService,
    private sanitize: DomSanitizer
  ) {
    // check for input
    var reg = this.activatedroute.snapshot.paramMap.get('registration');
    if (reg == null) return;
    this.registration = reg;
    this.formatInput();
  }

  public marketShareDataUpdate(event: MarketShare): void {
    this.marketShareData = event;
  }

  public chartDataUpdate(event: any): void {
    console.log('chart data', event);
    this.visibleChartData = event;
  }

  public sortChanged(dropdown: DropdownOption): void {
    this.sortChange.emit(dropdown.value);
  }

  public hasCompareHistory(): boolean {
    if (this.userService.getHistory('compare').length > 0) return true;
    return false;
  }

  public EnterSubmit(event: KeyboardEvent): void {
    if (event.code === 'Enter') {
      event.stopImmediatePropagation();
      this.StartCompare();
    }
  }

  public StartCompare(): void {
    this.router.navigate([`/compare/${this.plateToCompare}`]);
  }

  public SetCompareFilters(
    compareFilters: SidebarFilterConfig<CompareFilters>
  ): void {
    this.compareFilters.emit(compareFilters);
  }

  public CompareResultsChange(firstLoad: boolean): void {
    this.registrationService.formatRegistrationFromString(
      this.registration,
      this.detail.min,
      this.detail.min > -1,
      (_r) => {
        this.favouritePlate = _r;
      }
    );
    if (firstLoad) return;
    setTimeout(() => {
      this.stickyLinkConfig.reScroll.emit();
    }, 100);
  }

  public DetailLoaded(): boolean {
    return this.detail && this.detail.loaded;
  }

  public PriceRangeChange(data: { min: number, max: number, totalResultCount: number, }): void {
    this.detail = {
      ...this.detail,
      totalResultCount: data.totalResultCount,
      min: data.min,
      max: data.max,
      loaded: true,
    };
    console.log(this.detail);
    if (data.max <= 0) {
      this.FetchAlternateResults();
    }
  }

  private FetchAlternateResults(): void {
    this.altSearching = true;
    this.searchService.searchRaw(
      this.registration.replace(' ', ''),
      (res: SearchReqResponse) => {
        try {
          console.log('super search complete');
          res.registrations = this.registrationService.formatRegistrations(
            res.registrations
          );
          this.altResultChange.emit(res);
          this.altSearchResults = res;
          this.altSearching = false;
        } catch (ex: any) {
          console.warn(ex);
          this.altSearching = false;
        }
      }
    );
  }

  private formatInput(): void {
    this.registrationLoaded = false;
    this.registrationService
      .FormatRegistration(this.registration)
      .subscribe((formattedResp: FormattedPlateResponse) => {
        if (
          formattedResp.formattedRegistration == null ||
          formattedResp.formattedRegistration == ''
        )
          return;
        this.plateStyle = formattedResp.registrationStyle;
        this.validRegistration = formattedResp.valid;
        this.registration = formattedResp.formattedRegistration;
        this.registrationLoaded = true;
        this.generateQuickImage();
      });
  }

  ngOnInit(): void {
    this.GetDetail();
  }

  private GetDetail(): void {
    if (this.registration == '') return;
    this.registrationService
      .getPlateDetail(this.registration)
      .subscribe((d) => {
        this.detail = { ...this.detail, plateDetail: d };
        this.fetchRegisteredVehicleInfo();
      });
  }

  private fetchRegisteredVehicleInfo(): void {
    if (!this.registration || this.registration == '' || this.registration == null) return;
    this.registrationService
      .getVehicleInfo(this.registration.replace(' ', ''))
      .subscribe(
        (_: DVLAVehicleEnquiryResult) => {
          this.registeredVehicleInfo.Update(false, _);
          this.isRegisteredToVehicle = _ != null;
        },
        () => {
          this.registeredVehicleInfo.Update(false, null);
        }
      );
  }

  private generateQuickImage(): void {
    if (this.registration == '') this.registration = 'PLA73X';
    this.imageService.INIT();
    this.imageService.quickBuild(
      this.imageService.getQuickConfig(),
      this.registration,
      (image: ImageKeyPair) => {
        this.selectedImage = image;
      }
    );
  }
}
