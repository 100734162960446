<div *ngIf="!raw" class="position-relative text-dark w-100 pointer {{ containerClass }}">
  <div
    class="w-100 overflow-hidden d-flex align-items-center {{ justify }} gap-2 px-3 py-2 {{ baseClass }} {{ (config && open) ? openedClass : closedClass }}"
    style="cursor: pointer;" (click)="ToggleOpen()">

    <div *ngIf="showBullet" class="p-1 rounded-circle border border-2 border-dark">
      <div class="rounded-circle border-dark bg-dark" style="width: 7px; height: 7px"></div>
    </div>

    <small class="fs-6 text-decoration-none text-dark text-center d-block my-0">{{ config.meta.showSelected &&
      selectedValue ? selectedValue.view : config.title }}</small>
    <mat-icon data-nosnippet *ngIf="config.icon" class="small">{{config.icon}}</mat-icon>
  </div>

  <!-- OPTIONS -->
  <div *ngIf="config && open"
    class="{{ optionClass }} w-100 position-absolute start-0 end-0 bottom-0 translate-bottom bg-light shadow-sm"
    style="z-index: 1001;">
    <div style="overflow-y: auto; max-height: 300px;">
      <ng-container *ngFor="let option of VisibleOptions(config.options)">
        <div class="border-top px-3 py-3 d-flex align-items-center justify-content-start gap-2 bg-white"
          style="cursor: pointer" (click)="SelectValue(option)">
          <div class="p-1 rounded-circle border border-2" [ngClass]="{
          'border-dark': selectedValue && option.value == selectedValue.value
        }">
            <div [ngClass]="{
            'border-dark': selectedValue && option.value == selectedValue.value,
            'bg-dark': selectedValue && option.value == selectedValue.value,
            'bg-transparent': !selectedValue || option.value != selectedValue.value
          }" class="rounded-circle" style="width: 7px; height: 7px"></div>
          </div>
          <p class="mb-0 fs-6 text-start" [ngClass]="{
          'fw-normal': selectedValue && option.value == selectedValue.value,
          'fw-light': !selectedValue || option.value != selectedValue.value,
          'text-muted': !selectedValue || option.value != selectedValue.value,
          'text-dark': selectedValue && option.value == selectedValue.value
        }">
            {{ option.view }}
          </p>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<!--RAW DROPDOWN-->
<div *ngIf="raw && config && open"
  class="position-absolute text-dark w-100 pointer top-100 {{ end ? 'end-0' : 'start-0' }} {{ containerClass }}"
  style="z-index: 1001; min-width: fit-content; max-width: 250px; width: 100vw !important; height: fit-content">
  <div class="{{ optionClass }} w-100">
    <div style="overflow-y: auto; max-height: 300px;">
      <ng-container *ngFor="let option of VisibleOptions(config.options); let index = index;">
        <div class="px-3 py-3 d-flex align-items-center justify-content-start gap-2 bg-white" [ngClass]="{
          'border-top': index > 0
        }" style="cursor: pointer" (click)="SelectValue(option)">
          <div class="p-1 rounded-circle border border-2" [ngClass]="{
          'border-dark': selectedValue && option.value == selectedValue.value
        }">
            <div [ngClass]="{
              'border-dark': selectedValue && option.value == selectedValue.value,
              'bg-dark': selectedValue && option.value == selectedValue.value,
              'bg-transparent': !selectedValue || option.value != selectedValue.value
              }" class="rounded-circle" style="width: 7px; height: 7px"></div>
          </div>
          <p class="mb-0 fs-6 text-start" [ngClass]="{
          'fw-normal': selectedValue && option.value == selectedValue.value,
          'fw-light': !selectedValue || option.value != selectedValue.value,
          'text-muted': !selectedValue || option.value != selectedValue.value,
          'text-dark': selectedValue && option.value == selectedValue.value
        }">
            {{ option.view }}
          </p>
        </div>
      </ng-container>
    </div>
  </div>
</div>