import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Registration } from '../../../app/models/registration';
import { PlateModalComponent } from '../plate-modal/plate-modal.component';
import * as htmlToImage from 'html-to-image';
import { SessionService } from 'src/app/services/session-service';

@Component({
  selector: 'pla-admin-plate',
  template: `<div><img [src]="image_source" /></div>`,
})
class AdminPlateImageComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public image_source: string) { }
}

export class PlateConfigOptions {
  constructor(
    public size: string,
    public font: string,
    public border: string,
    public flag: string
  ) { }
}

@Component({
  selector: 'pla-plate',
  templateUrl: './plate.component.html',
  styleUrls: ['./plate.component.scss'],
})
export class PlateComponent implements OnInit {
  @Input() public center: boolean = true;
  @Input() public loading: boolean = false;
  @Input() public rawPlate: string;
  @Input() public rawPrice: number;
  @Input() public plate: Registration;
  @Input() public white: boolean = false;
  @Input() public showPrice: boolean;
  @Input() public showPence: boolean = true;
  @Input() public penceLimit: number = 0;
  @Input() public showPercentage: boolean = false;
  @Input() public openModal: boolean = true;
  @Input() public xSmallPlate: boolean = false;
  @Input() public smallPlate: boolean = false;
  @Input() public xLargePlate: boolean = false;
  @Input() public mobileSmall: boolean = true;
  @Input() public mobileTiny: boolean = false;
  @Input() public disabled: boolean = false;
  @Input() public noMargin: boolean = true;
  @Input() public plateSize: string = '';
  @Input() public inlineText: boolean = false;
  @Input() public useCustom: boolean = false;
  @Input() public customSize: number = 10; // ch unit
  @Input() public customClass: string;
  @Input() public customFontSize: number = 26;
  @Input() public priceFontSize: number = 20;
  @Input() public priceClass: string = '';
  @Input() public changeHover: boolean = false;
  @Input() public blank: boolean = false;
  @Input() public hoverText: string = '';
  @Input() public termFirst: boolean = false;
  @Input() public secondaryText: string;
  @Input() public showIndex: boolean = false;
  @Input() public showSoldRibbon: boolean = false;
  @Input() public forceSold: boolean = false;
  @Input() public forcedTerm: string | undefined = undefined;

  // CUSTOMISATION OPTIONS
  @Input() public redraw: EventEmitter<PlateConfigOptions>;

  public borderClass: string = '';
  public sizeClass: string = '';
  public fontClass: string = '';
  public flagClass: string = '';

  public noShadow: boolean = false;

  constructor(
    public router: Router,
    public dialog: MatDialog,
    private sessionService: SessionService,
    private changeDetection: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    if (this.redraw != null) {
      this.redraw.subscribe((_: PlateConfigOptions) => {
        this.drawConfig(_);
      });
    }
    this.drawOptions();

    //
    if (this.plate == undefined || this.plate == null) return; // whoops
    if (this.plate.price <= 0 || this.plate.plateOwner == -1) {
      this.priceClass += ' text-muted fst-italic';
    }
  }

  public shouldShowPence(): boolean {
    return this.showPence;
  }

  public contextMenu(e: PointerEvent): void {
    return;
    if (!this.sessionService.isAdmin()) return;
    e.preventDefault();
    this.noShadow = true;

    this.changeDetection.detectChanges();

    var x = (e.target as any).parentNode;
    htmlToImage
      .toPng(x)
      .then((data) => {
        this.dialog.open(AdminPlateImageComponent, { data: data });
        this.noShadow = false;
      })
      .catch(function (error) {
        console.error('oops, something went wrong!');
        console.log(error);
      });
  }

  private drawOptions(): void {
    if (this.xSmallPlate || this.inlineText) this.plateSize = 'x-small';
    if (this.smallPlate) this.plateSize = 'small';
    if (this.xLargePlate) this.plateSize = 'x-large';
  }

  private drawConfig(config: PlateConfigOptions) {
    if (config.border != null) this.borderClass = `border-${config.border}`;
    if (config.size != null) this.sizeClass = `size-${config.size}`;
    if (config.font != null) this.fontClass = `font-${config.font}`;
    if (config.flag != null) this.flagClass = `flag-${config.flag}`;

    if (config.border == 'none') this.borderClass = '';
    if (config.size == 'standard') this.sizeClass = '';
    if (config.font == 'standard') this.fontClass = '';
    if (config.flag == 'no-flag') this.flagClass = '';
  }

  public plateDetail(): void {
    if (!this.openModal) return;
    var registration = this.rawPlate || this.plate.registration;

    if (this.forcedTerm) {
      this.router.navigate([`/plate-detail/${registration.replace(' ', '')}/${this.forcedTerm}`]);
      return;
    }

    this.router.navigate([`/plate-detail/${registration.replace(' ', '')}`]);
  }
}
