import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FaqBuilder, InfoDropdown } from 'src/app/components/faq/faq.component';
import { FeatureType } from 'src/app/components/featured-plates/featured-plates.component';
import {
  BYOTermBreakdown,
  BYOTermResponse,
} from 'src/app/models/byoTermResponse';
import { Registration } from 'src/app/models/registration';
import { RegistrationService } from 'src/app/services/registration-service';
import {
  SearchReqResponse,
  SearchService,
} from 'src/app/services/search-service';
import {
  TrackingService,
  TRACKING_SEARCH_TYPE,
} from 'src/app/services/tracking-service';

@Component({
  selector: 'pla-byo-search-page',
  templateUrl: './byo-search-page.component.html',
  styleUrls: ['./byo-search-page.component.scss'],
})
export class BYOSearchPageComponent implements OnInit {
  public styleBreakdowns: BYOTermResponse = null;
  public raFeatured = new FeatureType(
    'Recently Added',
    'recently-added'
  );

  @Input() public style: string = 'current';
  @Input('breakdowns') public inputBreakdowns: BYOTermResponse = null; // FROM API ####TGE / ####TGE / TGE####
  @Input('searchDefault') public searchDefault: boolean = true;
  @Input('inlineSearch') public inlineSearch: boolean = false;
  @Input() public gotoResults: boolean = false;
  @Input() public showSwitch: boolean = true;

  public featuredNames: { name: string, plate: string, style: string, link: string }[] = [
    { name: 'JOHN', plate: 'JO11 N??', style: 'current', link: 'JO/11/N--' },
    { name: 'LOREN', plate: 'LO23 N??', style: 'current', link: 'LO/23/N--' },
    { name: 'CHIARA', plate: 'CH14 RA?', style: 'current', link: 'CH/14/RA-' },
    { name: 'LUKE', plate: '???1 UKE?', style: 'current', link: '--/-1/UKE' },
    { name: 'PETER', plate: 'PE73 R??', style: 'current', link: 'PE/73/R--' },
    { name: 'SARAH', plate: 'SA24 H??', style: 'current', link: 'SA/24/H--' },
    { name: 'STEVEN', plate: '?S73 VEN', style: 'current', link: 'ST/73/VEN' },
    { name: 'GEORGE', plate: 'GE02 GE?', style: 'current', link: 'GE/02/GE-' },
    { name: 'PAUL', plate: 'PA11 L??', style: 'current', link: 'PA/11/L--' },
    { name: 'KATIE', plate: 'KA71 E??', style: 'current', link: 'KA/71/E--' },
    { name: 'HUGO', plate: 'HU60 ???', style: 'current', link: 'HU/60/---' },
    { name: 'SIMON', plate: '??51 MON', style: 'current', link: '--/51/MON' },
  ]

  public faq: InfoDropdown[] = [
    FaqBuilder.build('faq_1', 'What personalised number plates are available?', [
      'Our Build-Your-Own Plate page lets you explore a wide range of personalised private plates, offering endless possibilities to suit your style and preferences. Whether you’re looking for a plate that resembles "GE02 GE?" for George, "PE73 R??" for Peter, or even “FA57 ??? for those with a sports car, you can create endless combinations tailored to your needs.',
      'PlateX provides access to plates from the DVLA and over 40 dealers, allowing you to find the perfect private plate quickly and easily. With our advanced search features, you can filter by price, style, and specific characters to design a plate that’s both meaningful and unique.'
    ]),
    FaqBuilder.build('faq_2', 'Can I customise a private plate with my initials or name?', [
      'Absolutely! With the Build-Your-Own Plate feature, you can customise a private plate with initials, names, or any combination of letters and numbers. For instance, you can search for "MR?? TOM" to find options resembling TOM and refine the results further by choosing the initial characters or numbers.',
      'This tool is perfect for creating a plate that feels personal and unique to you.'
    ]),
    FaqBuilder.build('faq_3', 'What are the rules for creating personalised plates in the UK?', [
      '<p>When creating a personalised private plate, it must comply with DVLA regulations, which include:</p>' +
      '<ul>' +
      '<li>Following the correct UK format for prefix, suffix, or current style registrations (e.g., "GE02 GE?" or "PE73 R??").</li>' +
      '<li>Avoiding offensive or misleading combinations.</li>' +
      '<li>Displaying the plate in the standard UK font and with correct spacing.</li>' +
      '</ul>' +
      '<p>Our Build-Your-Own Plate tool ensures all displayed options meet legal requirements, so you can confidently design a plate within the rules.</p>'
    ]),
    FaqBuilder.build('faq_4', 'How do I check if my custom plate idea is available?', [
      '<p>Using the Build-Your-Own Plate tool, you can instantly check if your custom private plate idea is available. For example, enter "???1 UKE" to see options for a plate resembling "LUKE" with personalised characters. This tool searches across plates offered by the DVLA and over 40 dealers, giving you the most comprehensive view of available personalised plates.</p>' +
      '<p>If you already know the exact registration you want, use our price comparison page to find the best deals across the market. If the plate isn’t currently available, simply choose the "Notify" option, and we’ll message you as soon as it becomes available again. PlateX makes it easy to find and secure the perfect private plate.</p>'
    ]),
    FaqBuilder.build('faq_5', 'Are there restrictions on the letters and numbers I can use?', [
      '<p>Yes, there are restrictions when creating a personalised plate:</p>' +
      '<ul>' +
      '<li>You cannot use offensive or inappropriate combinations.</li>' +
      '<li>The plate must comply with UK registration formats, such as current style (e.g., "GE02 GE?"), suffix, or prefix styles.</li>' +
      '<li>Some specific combinations may be reserved or unavailable.</li>' +
      '</ul>' +
      '<p>Our tool only displays plates that meet these rules, ensuring you won’t encounter any issues with your personalised selection.</p>'
    ]),
    FaqBuilder.build('faq_6', 'How much does it cost to create a personalised plate?', [
      '<p>The cost of creating a personalised private plate varies depending on the combination\'s uniqueness and availability. Registrations that are being resold—meaning they’ve already been issued by the DVLA—tend to be more expensive than those that have never been issued before. Resale plates often carry a premium due to their rarity, popularity, or demand.</p>' +
      '<p>For example, plates with shorter or highly sought-after combinations like "PE73 R??" for Peter or "AM07 TOM" for a Tom with an Aston Martin are likely to cost more.</p>' +
      '<p>Using our Build-Your-Own Plate tool, you can see real-time pricing across over 40 dealers and the DVLA. This ensures you get the best deal on a plate that’s both meaningful and budget-friendly.</p>'
    ]),
    FaqBuilder.build('faq_7', 'Can I include a specific date or number in my custom plate?', [
      '<p>Yes, you can include meaningful numbers or dates in your personalised private plate. For example, if you’re a Tom who owns an Aston Martin, you might create a plate like "AM07 TOM," blending your initials, a personal connection, and the make of your vehicle.</p>' +
      '<p>With our Build-Your-Own Plate tool, you can easily combine specific numbers, dates, or letters to craft a plate that’s uniquely yours. Enter your desired characters, and we’ll display all available options across the DVLA and dealer databases.</p>'
    ]),
    FaqBuilder.build('faq_8', 'Why are some personalised plates more expensive than others?', [
      '<p>The price of a personalised private plate often depends on its uniqueness, popularity, and how closely it resembles a specific name or word. In the case of plates created in Build-Your-Own styles—such as current, prefix, and suffix—the most expensive tend to be those that spell out full names or interesting, funny, or common words without any additional letters or numbers.</p>' +
      '<p>For example:</p>' +
      '<ul>' +
      '<li>AS11 LEY (current style), which spells "ASHLEY," was marketed for £750,160.</li>' +
      '<li>J4 MES (prefix style), which resembles "JAMES," was marketed for £275,160.</li>' +
      '<li>NAD IR (suffix style), which spells "NADIR," was marketed at £640,720.</li>' +
      '</ul>' +
      '<p>These plates are highly sought after due to their rarity and immediate recognisability. However, more affordable personalised plates can still be created by incorporating extra letters or numbers. With PlateX\'s Build-Your-Own Plate tool, you can find options that balance personalisation and budget while searching for the best prices across the market.</p>'
    ]),
    FaqBuilder.build('faq_9', 'Can I finance a custom private plate?', [
      '<p>Yes, financing is available for many custom private plates, making it easier to spread the cost of your perfect plate. After using the Build-Your-Own Plate tool to design and select the registration you want, you can compare prices for that specific plate across the market.</p>' +
      '<p>With PlateX, you can also filter results to include only dealers that offer bespoke finance options or payment plans through Klarna. This ensures you can choose a personalised plate that suits both your style and your budget, with flexible payment options to make it more accessible.</p>'
    ]),
    FaqBuilder.build('faq_10', 'What happens if my desired combination isn’t available?', [
      '<p>If your desired private plate combination isn’t available, don’t worry! Enter the combination into our price comparison search tool to perform a thorough market-wide search. If no results appear, simply select the "Notify" option, and we’ll message you if that specific registration comes to market.</p>' +
      '<p>Alternatively, you can:</p>' +
      '<ul>' +
      '<li>Use the Build-Your-Own Plate feature to try alternate combinations, such as adjusting letters or numbers (e.g., switching "GE02 GE?" to "GE02 GEX").</li>' +
      '<li>Use our super-search tool to search for related words or names, helping you discover other great options that might suit your needs.</li>' +
      '</ul>' +
      '<p>PlateX ensures you have every possible option to find the perfect plate!</p>'
    ])
  ]

  public results: SearchReqResponse;
  public resultChange: EventEmitter<SearchReqResponse> =
    new EventEmitter<SearchReqResponse>();

  public searchTerm: string = '';
  public loading: boolean = false;
  public hasSearchCriteria: boolean = false;

  public no_character: string = '﹣';
  public any_character: string = '?';
  public wc_character: string = '*';

  constructor(
    private router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private searchService: SearchService,
    private registrationService: RegistrationService,
    private trackingService: TrackingService
  ) { }

  ngOnInit(): void {
    // inline = inside search page
    if (this.inlineSearch) {
      this.styleBreakdowns = this.inputBreakdowns;
      if (this.searchDefault) this.search();
      return;
    }

    this.styleBreakdowns = new BYOTermResponse().construct();
    this.style = this.activatedRoute.snapshot.paramMap.get('style');
    if (this.style == null) {
      // blank slate
      this.style = 'current';
    } else {
      // existing values from route
      var _routeActive = this.styleBreakdowns[this.style] as BYOTermBreakdown;
      _routeActive.construct(
        this.style,
        this.activatedRoute.snapshot.paramMap.get('prefix'),
        this.activatedRoute.snapshot.paramMap.get('numbers'),
        this.activatedRoute.snapshot.paramMap.get('letters')
      );

      this.trackingService.TrackSearch(
        TRACKING_SEARCH_TYPE.BYO,
        `${_routeActive.prefix}${_routeActive.numbers}${_routeActive.letters}`,
        { style: this.style }
      );
    }

    if (!this.searchDefault) return;
    this.search();
  }

  public tableHeaderSearchTerm(): string {
    return this.searchTerm.replace('$', this.wc_character).replace('$', this.wc_character).replace('$', this.wc_character);
  }

  public activeBreakdown(): BYOTermBreakdown {
    if (this.style == null) return new BYOTermBreakdown().construct('current', '', '', '');
    if (this.styleBreakdowns == null) return new BYOTermBreakdown().construct(this.style, '', '', '');
    var _ = this.styleBreakdowns[this.style] as BYOTermBreakdown;
    _.style = this.style; // force style
    return _;
  }

  public doInlineSearch(term: BYOTermBreakdown): void {
    // update model behind UI
    this.style = term.style;
    this.styleBreakdowns[this.style] = term;

    if (this.inlineSearch && !this.gotoResults) {
      this.search();
    } else {
      if (this.gotoResults) {
        this.router.navigate([`/byo-search/${this.style}/${term.prefix}/${term.numbers}/${term.letters}`])
      } else {
        // update route
        this.location.go(
          `/byo-search/${this.style}/${term.prefix}/${term.numbers}/${term.letters}`
        );
        // search without refreshing
        this.search();
      }
    }
  }

  private search(): void {
    if (
      this.activeBreakdown().prefix == '' &&
      this.activeBreakdown().numbers == '' &&
      this.activeBreakdown().letters == ''
    ) {
      this.hasSearchCriteria = false;
      return;
    }

    this.hasSearchCriteria = true;

    this.searchTerm = `${this.activeBreakdown().prefix}${this.activeBreakdown().numbers
      }${this.activeBreakdown().letters}`.replace(/-/gi, '?');

    this.loading = true;
    this.searchService.searchBYO(
      this.style,
      this.activeBreakdown().prefix,
      this.activeBreakdown().numbers,
      this.activeBreakdown().letters,
      (_: Registration[]) => {
        // search exact
        this.searchService.searchExact(
          encodeURIComponent(this.searchTerm),
          (exact_results: Registration[]) => {
            this.loading = false;
            this.handleResults([...exact_results, ..._]);
          }
        );
      }
    );
  }

  public handleResults(registrations: Registration[]): void {
    registrations = this.registrationService.formatRegistrations(registrations);
    this.results = new SearchReqResponse('byo_id', registrations);
    this.resultChange.emit(this.results);
  }
}
