<div class="page">
  <pla-main-nav-chips></pla-main-nav-chips>
  <div class="content-container extended">
    <div class="ps-5 side-container mb-3">
      <div class="pt-0 position-relative">
        <pla-side-nav></pla-side-nav>
      </div>

      <div *ngIf="false" class="bg-light py-3">
        <pla-featured-plates [featureType]="npFeatured" [plateSize]="'smaller'" [priceFontSize]="16" [maxCount]="10"
          [sortBy]="'price'" [plateClass]="'flat shaded'" [showIndex]="true"></pla-featured-plates>
      </div>
    </div>
    <div class="center-content-container position-relative">
      <div class="pla_card mobile spacing no-top no-card no-left no-right position-relative">
        <div *ngIf="false">
          <!-- BREADCRUMBS -->
          <div class="w-100">
            <div
              class="border-bottom d-flex align-items-center justify-content-start gap-2 w-100 px-3 px-lg-0 pb-2 pt-0">
              <a style="color: #327b8d" class="d-block text-decoration-none" [routerLink]="['/']">Home</a>
              <span>/</span>
              <a style="color: #939393; white-space: pre" class="text-decoration-none d-block"
                [routerLink]="['/dateless-search']">Dateless</a>
            </div>
          </div>
        </div>

        <pla-banner-image [carBanner]="'FERRARI_275'" [mobileCarBanner]="'FERRARI_275_MB'" [rawImage]="true"
          [backgroundImage]="true" [backgroundClass]="'compare-mobile-background'">
          <div class="compare-banner-height">
            <div class="d-flex d-md-flex align-items-start justify-content-between flex-column">
              <div class="p-4 p-md-4">
                <h1 class="lh-sm fw-bold fs-md-28 fs-mb-24 text-white">Dateless Search</h1>
              </div>
            </div>
          </div>
        </pla-banner-image>
      </div>

      <div class="pla_card mobile spacing no-top no-card no-left no-right position-relative py-0 me-0 me-md-2">

        <div class="mt-4">
          <!--TEXT CONTENT-->
          <p class="px-4 text-start fw-bolder fs-md-20 fs-mb-18 mb-0 mx-auto lh-sm hs-mb">Dateless Number Plates</p>
          <p class="px-4 text-start fs-md-16 fs-mb-14 mb-2 mb-md-3 mx-auto lh-sm">Our Dateless Number Plates tool makes
            finding your ideal personalised registration simple. With a system designed to sort by the number of
            characters and refine results using an embedded letter and number search, locating the perfect plate has
            never been easier. Whether you’re after a prestigious single-digit plate or a unique combination, this tool
            streamlines the process.</p>
        </div>

        <div class="px-2">
          <div class="rounded-lg border border-1 py-4 px-3 w-100 mx-auto mt-4"
            style="background-color: #F1F4F2; border-color: #969696; max-width: 600px">
            <div class="px-0 px-md-2 mt-0 w-100 mx-auto">
              <div class="px-4 px-md-3">
                <div class="mx-auto" style="max-width: 350px">
                  <p class="mb-1 lh-sm fw-normal fs-md-14 fs-mb-13">Dateless Plate Type</p>
                  <pla-custom-dropdown [justify]="'justify-content-start'" [baseClass]="'bg-white border border-1'"
                    [closedClass]="'rounded-3 border'" [showBullet]="true" [openedClass]="
                    'rounded-top-3 border-start border-top border-end border-bottom-transparent'
                  " [optionClass]="
                    'border-1 border border-dark border-start border-end border-bottom rounded-bottom-3 overflow-hidden'
                  " (change)="OptionChange($event)" [forceSelect]="changeDropdown"
                    [config]="datelessOptionsDropdownConfig"></pla-custom-dropdown>
                </div>
              </div>
            </div>
            <div>
              <div class="px-0 px-md-2 mt-4 w-100 mx-auto">
                <div class="px-4 px-md-3">
                  <div class="w-100 mb-1 mx-auto search-input" style="max-width: 350px">
                    <div class="mx-auto rounded-2 overflow-hidden position-relative bg-white border">
                      <div class="mx-auto d-flex align-items-stretch justify-content-between py-2">
                        <div class="bg-transparent w-100">
                          <pla-new-search-input [disabled]="searching" [emitOnInput]="true" [placeholder]="'Prefered Letters / Numbers'"
                            [searchTexts]="[]" [internalRouting]="false" [searchTrigger]="searchTrigger"
                            [weight]="'normal'" [fixedHeight]="false"
                            (searchEvent)="filterSearchResults($event)"></pla-new-search-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div *ngIf="canSearch; else infoSection">
            <div class="pla_card no-card mobile spacing px-2 px-md-0">

              <div *ngIf="stickyLinkConfig"
                class="d-flex justify-content-between align-items-stretch mt-5 bg-white position-sticky start-0 end-0"
                style="top: 54px; z-index: 1000;">
                <pla-sticky-tabs class="flex-fill w-100" [config]="stickyLinkConfig" [classes]="'pt-3 pb-3'">
                  <div extraChips1
                    class="flex-shrink-0 d-flex d-lg-none align-items-center justify-content-center px-3 px-md-3 py-2 rounded-pill hover-interact pointer"
                    [style.background]="'#F1F4F2'" [style.borderColor]="'#F1F4F2'"
                    [style.borderWidth]="stickyLinkConfig.metaData.defaultStyle.borderWidth + 'px'"
                    style="cursor: pointer; border-style: solid;" (click)="mobileFilterToggle.emit(true)">
                    <mat-icon data-nosnippet style="max-height: 14px; line-height: 14px !important;"
                      class="my-0 d-block lh-sm text-center text-dark fs-md-20 fs-mb-18"
                      [style.color]="stickyLinkConfig.metaData.defaultStyle.textColour">tune</mat-icon>
                  </div>
                </pla-sticky-tabs>
                <div class="dd-raw my-3 position-relative me-3 d-none d-lg-block">
                  <div class="flex-shrink-0 px-3 px-md-3 py-2 rounded-pill hover-interact pointer position-relative"
                    [style.background]="'#F1F4F2'" [style.borderColor]="'#F1F4F2'"
                    [style.borderWidth]="stickyLinkConfig.metaData.defaultStyle.borderWidth + 'px'"
                    style="cursor: pointer; border-style: solid;" (click)="sortTrigger.emit()">
                    <div class="d-flex align-items-center justify-content-center gap-2">
                      <mat-icon data-nosnippet style="max-height: 14px; line-height: 14px !important; max-width: 15px;"
                        class="my-0 d-block lh-sm text-center text-dark fs-md-20 fs-mb-18"
                        [style.color]="stickyLinkConfig.metaData.defaultStyle.textColour">swap_vert</mat-icon>
                      Sort
                    </div>
                  </div>
                  <pla-custom-dropdown [containerClass]="'border border-1 shadow rounded-3 overflow-hidden mt-1'"
                    [end]="true" [config]="sortDropdownConfig" [remoteOpen]="sortTrigger"
                    (change)="TermSortChange($event, 'sortBy')" [raw]="true"></pla-custom-dropdown>
                </div>
              </div>

              <div class="pt-2 pb-5 w-100 px-1 px-md-1 position-relative" id="results-content">
                <div class="position-absolute" style="top: -92px" id="section-results"></div>
                <!-- SEARCH RESULTS -->
                <pla-search-result-table [searching]="searching || selecting" [searchType]="''"
                  [searchTitle]="searchTitle('dateless combined')" [resultType]="'MATCHES'"
                  [pluralResultType]="'MATCHES'" [results]="allResults" [resultChange]="all_resultChange"
                  [paginationPageSize]="100"></pla-search-result-table>
              </div>

              <div *ngIf="faq != undefined && faq.length > 0;"
                class="pt-5 pb-5 w-100 px-3 px-md-1 position-relative bg-white border-top" id="faq-content">
                <div class="position-absolute" style="top: -92px" id="section-faq"></div>
                <div class="px-0 px-md-2">
                  <div class="pb-5 pt-5">
                    <p class="text-center text-md-start fs-md-26 fs-mb-22">
                      Frequently Asked Questions
                    </p>
                  </div>
                  <div class="w-100 px-3 px-md-0 pb-5">
                    <pla-faq [faq]="faq"></pla-faq>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ng-template #loadingSpinner>
          <div class="full spinner-loader">
            <div>
              <mat-progress-spinner class="spinner cyan" mode="indeterminate" [color]="'#327b8d'"
                [diameter]="60"></mat-progress-spinner>
            </div>
          </div>
        </ng-template>

        <ng-template #infoSection>
          <div class="pla_card no-card mobile spacing padding">
            <div class="px-0">
              <div>
                <div class="text-title heading">
                  <h2 class="border-bottom mb-1">
                    <b class="strong">Dateless-By-Size</b>
                  </h2>
                </div>
                <div class="text-section">
                  <div class="text-paragraph">
                    <p>
                      This useful tool searches all the
                      <pla-glossary-term-word [term]="'Dateless Registration'"
                        [text]="'Dateless'"></pla-glossary-term-word>
                      number plates specifically by the number of characters.
                      Enter letters or numbers into the plate search box to
                      refine the results further or leave blank to review all
                      plates available in that series.
                    </p>
                  </div>
                </div>

                <div class="text-title heading mt-3">
                  <h2 class="border-bottom mb-1">
                    <b class="strong">Did you know...</b>
                  </h2>
                </div>
                <div class="text-section">
                  <div class="text-paragraph">
                    <p class="indent">
                      Single/Single or 1/1 registrations are the most highly
                      sought-after registrations and command incredible prices.
                      The pinnacle of these are the number ‘1’ registration of
                      which there are only 46 combinations available. The most
                      expensive 1/1 registration currently available is
                      <pla-plate class="d-inline-block" [customClass]="'flat shaded'" [rawPlate]="'F 1'"
                        [useCustom]="true" [customSize]="9" [customFontSize]="17" [inlineText]="true"></pla-plate>
                    </p>
                  </div>
                </div>

                <div class="text-section mt-3">
                  <div class="text-paragraph">
                    <div class="faq-item">
                      <mat-icon data-nosnippet>help_outline</mat-icon>
                      <h4>Can I put a Dateless registration on my vehicle?</h4>
                    </div>
                    <p class="indent">
                      You are not allowed to make a vehicle appear younger by
                      transferring a more recent registration. However,
                      <pla-glossary-term-word [term]="'Dateless Registration'"
                        [text]="'Dateless'"></pla-glossary-term-word>
                      registrations have no date indicator so they can be
                      transferred to any age vehicle.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!-- FEATURED LISTING -->
            <div class="featured-container mt-5">
              <div class="px-0">
                <h2 class="border-bottom mb-1">Featured Dateless</h2>
              </div>
              <div class="mt-2">
                <pla-featured-plates [featureType]="datelessFeatured" [showTitle]="false" [plateSize]="'smaller'"
                  [priceFontSize]="16" [sortBy]="'price'" [plateClass]="'flat shaded'"></pla-featured-plates>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<!-- OVERLAY PANEL -->
<pla-footer-popout [name]="'Search page mobile filters'" [opened]="false" [externalToggle]="mobileFilterToggle"
  [showBorder]="true" [actionText]="'Apply'">
  <div title class="d-flex align-items-center justify-content-start gap-2">
    <h4 class="lh-sm fs-md-20">FILTER</h4>
    <mat-icon data-nosnippet>tune</mat-icon>
  </div>
  <pla-side-bar-filters *ngIf="filterUpdate" [showBorders]="true" [location]="'mobile-popup'"
    [filters]="filterUpdate"></pla-side-bar-filters>
</pla-footer-popout>