import { Component, EventEmitter, OnInit } from '@angular/core';
import { DropdownOption, DropdownConfig } from 'src/app/components/custom-dropdown/custom-dropdown.component';
import { FaqBuilder, InfoDropdown } from 'src/app/components/faq/faq.component';
import { FeatureType } from 'src/app/components/featured-plates/featured-plates.component';
import { SidebarFilterConfig, SidebarFilterGroup, SidebarFilter } from 'src/app/components/side-bar-filters/side-bar-filters.component';
import { StickyConfig, StickyLinkGroup, StickyLink } from 'src/app/components/sticky-tabs/sticky-tabs.component';
import { HttpLoadingResp } from 'src/app/models/httpLoadingResp';
import { Registration } from 'src/app/models/registration';
import { RegistrationService } from 'src/app/services/registration-service';
import { SearchReqResponse, SearchService } from 'src/app/services/search-service';
import { SearchFilters } from '../search-results-page/search-results-page.component';

export class NumberOneResults {
  public one_by_one: Registration[];
  public two_by_one: Registration[];
  public three_by_one: Registration[];
}

@Component({
  selector: 'pla-number-one-page',
  templateUrl: './number-one-page.component.html',
  styleUrls: ['./number-one-page.component.scss']
})
export class NumberOnePageComponent implements OnInit {

  public stickyLinkConfig: StickyConfig = new StickyConfig(
    [
      new StickyLinkGroup([
        new StickyLink('Single Letter Results', 'single-letter-results', true),
        new StickyLink('Two Letter Results', 'two-letter-results', false),
        new StickyLink('Three Letter Results', 'three-letter-results', false),
        new StickyLink('Q&As', 'faq', false),
      ].filter(x => x != null)),
    ],
    new EventEmitter<void>()
  );
  public termSorting: DropdownOption = new DropdownOption('HIGH-TO-LOW', 'Price High to Low');
  public mobileFilterToggle: EventEmitter<boolean> = new EventEmitter<boolean>();;
  public sortTrigger: EventEmitter<void> = new EventEmitter<void>();
  public filterChange: EventEmitter<SearchFilters> = new EventEmitter<SearchFilters>();
  public filterUpdate: EventEmitter<SidebarFilterConfig<any>> =
    new EventEmitter<SidebarFilterConfig<any>>();
  public sortDropdownConfig: DropdownConfig = new DropdownConfig('Sort By', 'swap_vert', [
    new DropdownOption('A-Z', 'A-Z'),
    new DropdownOption('Z-A', 'Z-A'),
    new DropdownOption('LOW-TO-HIGH', 'Price Low to High'),
    new DropdownOption('HIGH-TO-LOW', 'Price High to Low')
  ], {
    initialValue: this.termSorting
  });

  public searchFilters: SidebarFilterConfig<SearchFilters> = new SidebarFilterConfig<SearchFilters>(
    [
      new SidebarFilterGroup('Sort By', [
        new SidebarFilter('sort', 'Sort By', 'sortBy', {
          selectConfig: this.sortDropdownConfig
        })
      ], { visible: true, customClass: 'd-block d-lg-none' }),
    ], this.filterChange);

  public raFeatured = new FeatureType('Recently Added', 'recently-added');
  public one_results: HttpLoadingResp<SearchReqResponse> = new HttpLoadingResp(true, undefined);
  public two_results: HttpLoadingResp<SearchReqResponse> = new HttpLoadingResp(true, undefined);
  public three_results: HttpLoadingResp<SearchReqResponse> = new HttpLoadingResp(true, undefined);
  public faq: InfoDropdown[] = [
    FaqBuilder.build('faq_1', 'Are Number 1 registrations expensive?', `Yes, 'Number 1' registrations are highly valuable due to their rarity and historical significance. These unique dateless registrations, are number plates with a 1 paired with one to three letters, are some of the most sought-after private plates on the market. With prestigious examples like S 1, which sold for £397,500, F 1 at £440,625, and 1 D at £352,411, it’s clear that 'Number 1' plates carry significant investment potential. Private collectors and enthusiasts see these plates as not only iconic but also as rare assets that continue to appreciate in value. For those looking to make a statement, 'Number 1' private plates are a premium choice that highlights exclusivity and style, reflecting a long-standing tradition of personal distinction.`),
    FaqBuilder.build('faq_2', 'What are the cheapest Number 1 registrations?', `If you results do not show in the collection on this page it likely means they are not currently offered for sale.  The best way to find your initials number 1 plate is to search for the exact registration in either our price comparison page or by entering it directly into our search bar. If the private number plate you want is currently unavailable, simply select “Notify,” and you’ll be alerted as soon as it comes on the market. Alternatively, explore a variety of available private number plates on our initials page.`),
    FaqBuilder.build('faq_3', 'How can I find my initials on a number plate with a 1?', `The best starting place is right here on the PlateX Number 1 page, which is updated daily with rare 1, 2, and 3-letter dateless Number 1 registrations offered for sale by various dealers across the market. These exclusive registrations often command a lofty price, so if you’d like to explore other options, try our initials page for a range of private plate combinations that might also suit your initials.`),
    FaqBuilder.build('faq_4', 'What is the most expensive private Number 1 plate?', `The value of a private number plate, especially the prestigious Number 1 registrations, is subjective, but two plates stand out: "F1" and "A1." The "F1" plate was purchased by entrepreneur Afzal Kahn in 2008 for £440,625, setting a record for a private number plate. Since then, Kahn has reportedly turned down offers as high as £10 million, highlighting its appeal and investment potential.</p><p>The "A1" plate, recognized as the first UK registration issued in 1903, holds unmatched historic value. Originally owned by Earl Russell, it was later transferred to Dunlop Holdings in 1970 for £2,500, with proceeds going to charity. Used for corporate promotions, it was sold in 2000 to Prince Jefri Bolkiah, the younger brother of the Sultan of Brunei, who placed it on a Bentley Azure. With its unique history and provenance, this iconic private number plate is unlikely to return to the market.`),
    FaqBuilder.build('faq_5', 'What are the cheapest Number 1 private registrations?', 'In terms of registrations featuring just the number "1" and initials, one way to achieve a similar look to the highly valued dateless registrations is by using current-style plates. For example, plates starting with "OO" followed by the year code "11" create the impression of a series number "0011," followed by three letters, such as "OO11 APD." As of this writing, "OO11 APD" is available as a new release from £799, whereas the dateless registration "11 APD" is priced at £20,030. To see these current-style plates and other great private registration options, check out our initials page.</p><p>If, however, you’re specifically looking for a traditional dateless Number 1 registration, you can find the most affordable options by sorting the listings by price, starting with the lowest. This allows you to see the current cheapest offerings on the market, and our price comparison service ensures you get the best deal on your ideal Number 1 registration.'),
  ]

  constructor(
    private searchService: SearchService,
    private registrationService: RegistrationService
  ) {
    this.filterChange.subscribe((_: SearchFilters) => {
      this.TermSortChange(this.sortDropdownConfig.options.find(o => o.value === _.sortBy), "");
    });
  }

  ngOnInit(): void {
    this.Search();
  }


  private Search(): void {
    this.searchService.getNumber1((_: NumberOneResults) => {
      this.one_results.Update(false, new SearchReqResponse('1-number-1', this.registrationService.formatRegistrations(_.one_by_one)));
      this.two_results.Update(false, new SearchReqResponse('2-number-1', this.registrationService.formatRegistrations(_.two_by_one)));
      this.three_results.Update(false, new SearchReqResponse('3-number-1', this.registrationService.formatRegistrations(_.three_by_one)));

      this.filterUpdate.emit(this.searchFilters);
      this.TermSortChange(this.termSorting, "");
    })
  }

  public TermSortChange(e: DropdownOption, key: string): void {
    this.termSorting = e;
    if (this.termSorting.value === 'A-Z') {
      this.one_results.Update(false, new SearchReqResponse('1-number-1', this.one_results.data.registrations.sort((a, b) => a.registration.localeCompare(b.registration))));
      this.two_results.Update(false, new SearchReqResponse('2-number-1', this.two_results.data.registrations.sort((a, b) => a.registration.localeCompare(b.registration))));
      this.three_results.Update(false, new SearchReqResponse('3-number-1', this.three_results.data.registrations.sort((a, b) => a.registration.localeCompare(b.registration))));
    }
    else if (this.termSorting.value === 'Z-A') {
      this.one_results.Update(false, new SearchReqResponse('1-number-1', this.one_results.data.registrations.sort((a, b) => b.registration.localeCompare(a.registration))));
      this.two_results.Update(false, new SearchReqResponse('2-number-1', this.two_results.data.registrations.sort((a, b) => b.registration.localeCompare(a.registration))));
      this.three_results.Update(false, new SearchReqResponse('3-number-1', this.three_results.data.registrations.sort((a, b) => b.registration.localeCompare(a.registration))));
    }
    else if (this.termSorting.value === 'HIGH-TO-LOW') {
      this.one_results.Update(false, new SearchReqResponse('1-number-1', this.one_results.data.registrations.sort((a, b) => b.price - a.price)));
      this.two_results.Update(false, new SearchReqResponse('2-number-1', this.two_results.data.registrations.sort((a, b) => b.price - a.price)));
      this.three_results.Update(false, new SearchReqResponse('3-number-1', this.three_results.data.registrations.sort((a, b) => b.price - a.price)));
    }
    else if (this.termSorting.value === 'LOW-TO-HIGH') {
      this.one_results.Update(false, new SearchReqResponse('1-number-1', this.one_results.data.registrations.sort((a, b) => a.price - b.price)));
      this.two_results.Update(false, new SearchReqResponse('2-number-1', this.two_results.data.registrations.sort((a, b) => a.price - b.price)));
      this.three_results.Update(false, new SearchReqResponse('3-number-1', this.three_results.data.registrations.sort((a, b) => a.price - b.price)));
    }
  }
}
