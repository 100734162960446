<div class="page">
    <pla-main-nav-chips></pla-main-nav-chips>
    <div class="content-container extended">
        <div class="ps-5 side-container mb-3">
            <div class="pt-0 position-relative">
                <pla-side-nav></pla-side-nav>
            </div>

            <div *ngIf="false" class="bg-light py-3">
                <pla-featured-plates [featureType]="raFeatured" [plateSize]="'smaller'" [priceFontSize]="16"
                    [maxCount]="10" [sortBy]="'price'" [plateClass]="'flat shaded'"
                    [showIndex]="false"></pla-featured-plates>
            </div>
        </div>
        <div class="center-content-container position-relative">
            <div class="pla_card mobile spacing no-top no-card no-left no-right position-relative">
                <pla-banner-image [carBanner]="'NUM_1_DT'" [mobileCarBanner]="'NUM_1_MB'" [rawImage]="true"
                    [backgroundImage]="true" [backgroundClass]="'compare-mobile-background'">
                    <div class="compare-banner-height">
                        <div class="d-flex d-md-flex align-items-start justify-content-between flex-column">
                            <div class="p-4 p-md-4">
                                <p class="lh-sm fw-bold fs-md-28 fs-mb-24 text-white">NUMBER 1s</p>
                            </div>
                        </div>
                    </div>
                </pla-banner-image>

                <div class="mt-4">
                    <p class="px-4 text-start fw-bolder fs-md-20 fs-mb-18 mb-0 mx-auto lh-sm hs-mb">Premier
                        Numbers </p>
                    <p class="px-4 text-start fs-md-16 fs-mb-14 mb-2 mb-md-3 mx-auto lh-sm">Number 1 registrations
                        are a symbol of scarcity and distinction, pairing the singular number “1” with up to 3 initial
                        letters for a refined, monogrammed look that evokes an old-money appeal.</p>

                    <p class="px-4 text-start fs-md-16 fs-mb-14 mb-2 mb-md-3 mx-auto lh-sm">These rare dateless plates
                        are the
                        preferred choice for those seeking understated elegance and
                        refinement, offering a classic aesthetic that resonates with those who value heritage over
                        trend. With their timeless appearance, Number 1 plates convey sophistication and exclusivity.
                    </p>
                </div>

                <div class="s-spacing"></div>
            </div>

            <div
                class="pla_card mobile spacing no-top no-card no-left no-right position-relative px-2 px-md-1 py-0 me-0 me-md-2">

                <div *ngIf="stickyLinkConfig"
                    class="d-flex justify-content-between align-items-stretch mt-2 bg-white position-sticky start-0 end-0"
                    style="top: 54px; z-index: 1000;">
                    <pla-sticky-tabs class="flex-fill w-100" [config]="stickyLinkConfig" [classes]="'pt-3 pb-3'">
                        <div extraChips1
                            class="flex-shrink-0 d-flex d-lg-none align-items-center justify-content-center px-3 px-md-3 py-2 rounded-pill hover-interact pointer"
                            [style.background]="'#F1F4F2'" [style.borderColor]="'#F1F4F2'"
                            [style.borderWidth]="stickyLinkConfig.metaData.defaultStyle.borderWidth + 'px'"
                            style="cursor: pointer; border-style: solid;" (click)="mobileFilterToggle.emit(true)">
                            <mat-icon data-nosnippet style="max-height: 14px; line-height: 14px !important;"
                                class="my-0 d-block lh-sm text-center text-dark fs-md-20 fs-mb-18"
                                [style.color]="stickyLinkConfig.metaData.defaultStyle.textColour">tune</mat-icon>
                        </div>
                    </pla-sticky-tabs>
                    <div class="dd-raw my-3 position-relative me-3 d-none d-lg-block">
                        <div class="flex-shrink-0 px-3 px-md-3 py-2 rounded-pill hover-interact pointer position-relative"
                            [style.background]="'#F1F4F2'" [style.borderColor]="'#F1F4F2'"
                            [style.borderWidth]="stickyLinkConfig.metaData.defaultStyle.borderWidth + 'px'"
                            style="cursor: pointer; border-style: solid;" (click)="sortTrigger.emit()">
                            <div class="d-flex align-items-center justify-content-center gap-2">
                                <mat-icon data-nosnippet
                                    style="max-height: 14px; line-height: 14px !important; max-width: 15px;"
                                    class="my-0 d-block lh-sm text-center text-dark fs-md-20 fs-mb-18"
                                    [style.color]="stickyLinkConfig.metaData.defaultStyle.textColour">swap_vert</mat-icon>
                                Sort
                            </div>
                        </div>
                        <pla-custom-dropdown [containerClass]="'border border-1 shadow rounded-3 overflow-hidden mt-1'"
                            [end]="true" [config]="sortDropdownConfig" [remoteOpen]="sortTrigger"
                            (change)="TermSortChange($event, 'sortBy')" [raw]="true"></pla-custom-dropdown>
                    </div>
                </div>

                <!-- RESULTS -->
                <div class="pla_card no-card mobile spacing px-2">
                    <div>
                        <div class="pt-4 pb-5 w-100 px-1 px-md-1 position-relative" id="single-letter-results-content">
                            <div class="position-absolute" style="top: -92px" id="section-single-letter-results"></div>
                            <pla-search-result-table [showCount]="true" [headerText]="'Single Letter'"
                                [results]="one_results.data" [resultChange]="one_results.updated"
                                [searching]="one_results.loading" [showFilter]="false" [showSort]="false"
                                [showToggle]="false" [showFullSeries]="true"
                                [paginationPageSize]="10"></pla-search-result-table>
                        </div>

                        <div class="pt-4 pb-5 w-100 px-1 px-md-1 position-relative" id="two-letter-results-content">
                            <div class="position-absolute" style="top: -92px" id="section-two-letter-results"></div>
                            <pla-search-result-table [showCount]="true" [headerText]="'Two Letter'"
                                [results]="two_results.data" [resultChange]="two_results.updated"
                                [searching]="two_results.loading" [showFilter]="false" [showSort]="false"
                                [showToggle]="false" [showFullSeries]="true"
                                [paginationPageSize]="10"></pla-search-result-table>
                        </div>

                        <div class="pt-4 pb-5 w-100 px-1 px-md-1 position-relative" id="three-letter-results-content">
                            <div class="position-absolute" style="top: -92px" id="section-three-letter-results"></div>
                            <pla-search-result-table [showCount]="true" [headerText]="'Three Letter'"
                                [results]="three_results.data" [resultChange]="three_results.updated"
                                [searching]="three_results.loading" [showFilter]="false" [showSort]="false"
                                [showToggle]="false" [showFullSeries]="true"
                                [paginationPageSize]="10"></pla-search-result-table>
                        </div>
                    </div>
                </div>


                <div *ngIf="faq != undefined && faq.length > 0;"
                    class="pb-5 w-100 px-3 px-md-1 position-relative bg-white" id="faq-content">
                    <div class="position-absolute" style="top: -92px" id="section-faq"></div>
                    <div class="px-0 px-md-2">
                        <div class="pb-5 pt-5">
                            <p class="text-center text-md-start fs-md-26 fs-mb-22">
                                Frequently Asked Questions
                            </p>
                        </div>
                        <div class="w-100 px-3 px-md-0 pb-5">
                            <pla-faq [faq]="faq"></pla-faq>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- OVERLAY PANEL -->
<pla-footer-popout [name]="'Search page mobile filters'" [opened]="false" [externalToggle]="mobileFilterToggle"
    [showBorder]="true" [actionText]="'Apply'">
    <div title class="d-flex align-items-center justify-content-start gap-2">
        <h4 class="lh-sm fs-md-20">FILTER</h4>
        <mat-icon data-nosnippet>tune</mat-icon>
    </div>
    <pla-side-bar-filters *ngIf="filterUpdate" [showBorders]="true" [location]="'mobile-popup'"
        [filters]="filterUpdate"></pla-side-bar-filters>
</pla-footer-popout>