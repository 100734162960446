<div *ngIf="filtersConfig; else loading">
  <ng-container *ngFor="let filterGroup of visibleFilters(); last as isLast">
    <div class="{{ isLast ? '' : 'mb-4' }} {{ filterGroup.config.customClass }}">
      <p class="fw-bolder fs-md-17">{{ filterGroup.viewName }}</p>
      <ng-container *ngFor="let filter of filterGroup.filters; last as lastFilter">

        <ng-template *ngIf="filter.filterType == 'checkbox'" [ngTemplateOutlet]="checkbox"
          [ngTemplateOutletContext]="{ filter: filter, last: lastFilter }"></ng-template>
        <ng-template *ngIf="filter.filterType == 'select'" [ngTemplateOutlet]="select"
          [ngTemplateOutletContext]="{ filter: filter, last: lastFilter }"></ng-template>
        <ng-template *ngIf="filter.filterType == 'sort'" [ngTemplateOutlet]="sort"
          [ngTemplateOutletContext]="{ filter: filter, last: lastFilter }"></ng-template>

      </ng-container>

      <div *ngIf="!isLast && showBorders" class="border-top border-1 mt-4 w-100 d-block"></div>

    </div>
  </ng-container>
</div>

<ng-template #loading>
  <div class="full spinner-loader">
    <div>
      <mat-progress-spinner class="spinner cyan" mode="indeterminate" [color]="'#327b8d'"
        [diameter]="60"></mat-progress-spinner>
    </div>
    <span>Loading Filters...</span>
  </div>
</ng-template>

<ng-template #checkbox let-filter="filter" let-last="last">
  <div>
    <mat-checkbox [color]="'primary'" class="w-100 overflow-hidden" (change)="ValChange($event, filter.key)">
      <span *ngIf="!filter.config || !filter.config.htmlReplacement"
        class="fs-md-15 fs-mb-15 text-decoration-none text-dark d-block my-0 lh-sm" data-test="filter-view-name">{{
        filter.viewName
        }}</span>
      <div *ngIf="filter.config && filter.config.htmlReplacement" [innerHtml]="filter.config.htmlReplacement | safeHtml"
        class="ms-2" data-test="filter-custom-html"></div>
    </mat-checkbox>
    <!-- SUB TEXT -->
    <div class="d-flex align-items-start justify-content-start">
      <div class="d-block" style="width: 100%; max-width: 16px; min-width: 16px; margin-right: 8px;"></div>
      <small class="d-block mt-0 fs-md-12 fs-mb-13 lh-sm  {{ last ? 'mb-0' : 'mb-3' }}"
        *ngIf="filter.config && filter.config.subText">{{filter.config.subText}}</small>
    </div>
  </div>
</ng-template>

<ng-template #sort let-filter="filter" let-last="last">
  <!-- {{filterObj | json}} -->
  <ng-container *ngIf="filterObj">
    <mat-radio-group [name]="filter.key">
      <ng-container *ngFor="let option of filter.config.selectConfig.options">
        <mat-radio-button (change)="SortValChange($event, filter.key)" [value]="option.value" [color]="'primary'"
          class="mrb w-100 overflow-hidden">
          <span *ngIf="!filter.config || !filter.config.htmlReplacement"
            class="fs-md-15 fs-mb-15 text-decoration-none text-dark d-block my-0 lh-sm">{{
            option.view }}</span>
          <div *ngIf="filter.config && filter.config.htmlReplacement"
            [innerHtml]="filter.config.htmlReplacement | safeHtml" class="ms-2" data-test="filter-custom-html"></div>
        </mat-radio-button>
      </ng-container>
    </mat-radio-group>
    <!-- SUB TEXT -->
    <div class="d-flex align-items-start justify-content-start">
      <div class="d-block" style="width: 100%; max-width: 16px; min-width: 16px; margin-right: 8px;"></div>
      <small class="d-block mt-0 fs-md-12 fs-mb-13 lh-sm {{ last ? 'mb-0' : 'mb-3' }}"
        *ngIf="filter.config && filter.config.subText">{{filter.config.subText}}</small>
    </div>
  </ng-container>
</ng-template>

<ng-template #select let-filter="filter" let-last="last">
  <pla-custom-dropdown [baseClass]="'bg-white border-1 border-dark'" [closedClass]="'rounded-3 border'"
    [openedClass]="'rounded-top-3 border-start border-top border-end'"
    [optionClass]="'border-start border-end border-bottom rounded-bottom-3'" (change)="SelectChange($event, filter.key)"
    [config]="filter.config.selectConfig"></pla-custom-dropdown>
</ng-template>