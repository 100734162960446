import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'noCache',
})
export class ImageCachePipe implements PipeTransform {

  private cacheNumber(): string {
    if (!environment.production) return environment.buildNo;
    return environment.versionNo;
  }

  transform(value: string): string {
    if (!value) {
      return '';
    }
    return `${value}?v=${this.cacheNumber()}`;
  }
}
