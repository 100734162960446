import { Component, OnInit } from '@angular/core';
import { RawSoldPlate } from 'src/app/models/registration';
import { AdminService } from 'src/app/services/admin-service';

@Component({
  selector: 'pla-admin-gpt',
  templateUrl: './admin-gpt.component.html',
  styleUrls: ['./admin-gpt.component.scss']
})
export class AdminGptComponent implements OnInit {

  public aiSearchFeature: {
    request: string,
    loading: boolean,
    req: string,
    res: { query: string, res: RawSoldPlate[] }
  } = { loading: false, request: '', req: 'Whats the most expensive plate sold ignoring price changes', res: undefined }

  constructor(private adminService: AdminService) { }

  ngOnInit(): void {
  }

  public getJson(): void {
    navigator.clipboard.writeText(JSON.stringify(this.aiSearchFeature.res.res, null, 4));
    alert('copied to clipboard')
  }

  public SearchAI(): void {
    this.aiSearchFeature.loading = true;
    this.adminService.searchAI(this.aiSearchFeature.req, (res: { request: string, query: string, res: RawSoldPlate[] }) => {
      this.aiSearchFeature.loading = false;
      if (res == null) {
        alert('failed to complete');
      } else {
        console.log(res);
        this.aiSearchFeature.res = res;
      }
    });
  }
}
