import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export class SwitchTab {
  constructor(public name: string, public id: string) { }
}

@Component({
  selector: 'pla-switch-toggle',
  templateUrl: './switch-toggle.component.html',
  styleUrls: ['./switch-toggle.component.scss'],
})
export class SwitchToggleComponent implements OnInit {
  public comp_id: number = Math.floor(Math.random() * 1000);
  public selectedTabIndex: number = 0;
  @Input() public chipColour: string;
  @Input() public chipTextColour: string;
  @Input() public selectedChipColour: string;
  @Input() public selectedChipTextColour: string;
  @Input() public pillRadius: string;
  @Input() public selectedTabId: string;
  @Input() public tabs: SwitchTab[] = [];
  @Input() public forceSwitch: EventEmitter<string>;
  @Output() public switchChange: EventEmitter<SwitchTab> =
    new EventEmitter<SwitchTab>();
  private emitTimeout: number = -1;

  constructor() { }

  ngOnChanges(): void {
    if (this.forceSwitch === undefined) return;
    this.forceSwitch.subscribe((id: string) => {
      var switchTo = this.tabs.findIndex(t => t.id === id);
      if (switchTo === -1) return;
      this.onSelectionChange(this.tabs[switchTo], switchTo);
    })
  }

  ngOnInit(): void {
    if (this.tabs == null || this.tabs.length == 0) return;
    if (this.selectedTabId == null || this.selectedTabId == '')
      this.selectedTabId = this.tabs[0].id;
    else {
      this.selectedTabIndex = this.tabs.findIndex(t => t.id === this.selectedTabId);
      this.onSelectionChange(this.tabs[this.selectedTabIndex], this.selectedTabIndex)
    }
  }

  onSelectionChange(tab: SwitchTab, index: number) {
    if (tab == null || tab.id == this.selectedTabId) return;
    this.selectedTabIndex = index;
    this.selectedTabId = tab.id;

    window.clearTimeout(this.emitTimeout);
    this.emitTimeout = window.setTimeout(async () => this.emitChange(tab), 250);
  }

  private async emitChange(tab: SwitchTab): Promise<void> {
    this.switchChange.emit(tab);
  }
}
