<div>
    <!-- <div *ngIf="upcoming">
        <p><strong>Upcoming:</strong> {{upcoming.soldPrice / 100 | currency: 'GBP' }} {{upcoming.registration}} - {{upcoming.soldDate | date:
            'medium'}}</p>
    </div>
    <button (click)="addPoint()">Add Point</button> -->
    <div class="position-relative">
        <div id="chartdiv" style="width: 100%;"></div>
        <div class="position-absolute top-0 bottom-0 start-0" style="right: 33%; background-color: rgba(0,0,0,0.0);">
        </div>
    </div>
</div>