import { Component, Input, OnInit } from '@angular/core';
import { MarketPriceData } from 'src/app/models/market-data';
import { CompareService } from 'src/app/services/compare-service';

@Component({
  selector: 'pla-market-insight',
  templateUrl: './market-insight.component.html',
  styleUrls: ['./market-insight.component.scss'],
})
export class MarketInsightComponent implements OnInit {
  @Input() public registration: string;
  @Input() public cheapestRegPrice: number;

  public priceData: any;

  constructor(private compareService: CompareService) { }

  ngOnInit(): void {
    this.GetChartData();
  }

  private GetChartData(): void {
    this.GetPriceData();
  }

  private GetPriceData(): void {
    this.compareService
      .GetMarketPriceData(this.registration.replace(' ', ''))
      .subscribe((data: MarketPriceData) => {
        this.priceData = {
          meta: {
            series: data.req.series,
            style: data.req.style,
            comparePrice: this.cheapestRegPrice,
          },
          data: [
            [data.lowest.series, data.mean.series, data.highest.series],
            [data.lowest.style, data.mean.style, data.highest.style],
          ],
        };
      });
  }
}
