import { Component, OnInit } from '@angular/core';
import { InfoDropdown } from 'src/app/components/faq/faq.component';
import { FeatureType } from 'src/app/components/featured-plates/featured-plates.component';

@Component({
  selector: 'pla-buy-page',
  templateUrl: './buy-page.component.html',
  styleUrls: ['./buy-page.component.scss']
})
export class BuyPageComponent implements OnInit {

  public npFeatured = new FeatureType(
    'Most Expensive Plates',
    'most-expensive'
  );
  public faq: InfoDropdown[] = []

  constructor() { }

  ngOnInit(): void {
  }

}
