import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { DropdownConfig, DropdownOption } from 'src/app/components/custom-dropdown/custom-dropdown.component';
import { FaqBuilder, InfoDropdown } from 'src/app/components/faq/faq.component';
import { FeatureType } from 'src/app/components/featured-plates/featured-plates.component';
import { SidebarFilter, SidebarFilterConfig, SidebarFilterGroup } from 'src/app/components/side-bar-filters/side-bar-filters.component';
import { SECONDARY_STICKY_CONFIG, StickyConfig, StickyLink, StickyLinkGroup } from 'src/app/components/sticky-tabs/sticky-tabs.component';
import { SwitchTab } from 'src/app/components/switch-toggle/switch-toggle.component';
import { BYOTermBreakdown } from 'src/app/models/byoTermResponse';
import { Registration } from 'src/app/models/registration';
import { RegistrationService } from 'src/app/services/registration-service';
import {
  SearchReqResponse,
  SearchService,
} from 'src/app/services/search-service';
import {
  TrackingService,
  TRACKING_SEARCH_TYPE,
} from 'src/app/services/tracking-service';
import { SearchFilters } from '../search-results-page/search-results-page.component';

export class DatelessSearchResponse {
  constructor(
    public style: string,
    public layout: string,
    public main: Registration[]
  ) { }
}

export class DatelessStyleOptionGroup {
  constructor(
    public name: string,
    public viewName: string,
    public reverseViewName: string,
    public options: DatelessLengthOption[]
  ) { }
}

export class DatelessLengthOption {
  constructor(public viewValue: string, public value: string) { }
}

export class DatelessSearchRequestItem {
  constructor(
    public size: string,
    public search: string,
    public callback: (res: DatelessSearchResponse) => void,
    public canReturn: boolean = true
  ) { }

  public pop(): void {
    this.canReturn = false;
  }
}

@Component({
  selector: 'pla-dateless-search-page',
  templateUrl: './dateless-search-page.component.html',
  styleUrls: ['./dateless-search-page.component.scss'],
})
export class DatelessSearchPageComponent implements OnInit, AfterViewInit {
  public npFeatured = new FeatureType(
    'Recently Added',
    'recently-added'
  );
  public datelessFeatured = new FeatureType('Featured Dateless', 'dateless');
  public results: DatelessSearchResponse = new DatelessSearchResponse(
    '',
    '',
    [],
  );
  public allResults: SearchReqResponse;
  public canSearch: boolean = false;
  public searching: boolean = false;
  public tableTitle: string;
  public all_resultChange: EventEmitter<SearchReqResponse> =
    new EventEmitter<SearchReqResponse>();

  private filter: string = '';
  public lastSearchTime: number = 0;
  public selectionCooldown: number = 1500;
  public queuedDebounce: boolean = false;

  public sortTrigger: EventEmitter<void> = new EventEmitter<void>();
  public mobileFilterToggle: EventEmitter<boolean> = new EventEmitter<boolean>();;
  public searchTrigger: EventEmitter<void> = new EventEmitter<void>();
  public changeDropdown: EventEmitter<DropdownOption> = new EventEmitter<DropdownOption>();
  public selectedDropdownOption: DropdownOption;
  public selectedOption: DatelessLengthOption;
  public datelessOptionsDropdownConfig: DropdownConfig = new DropdownConfig('Select a format', 'swap_vert', [
    new DropdownOption('*x-', 'One Letter and One Number', { styleName: '1x1', length: 2 }),
    new DropdownOption('*x--', 'One Letter and Two Numbers', { styleName: '1x2', length: 3 }),
    new DropdownOption('*x---', 'One Letter and Three Numbers', { styleName: '1x3', length: 4 }),
    new DropdownOption('*x----', 'One Letter and Four Numbers', { styleName: '1x4', length: 5 }),
    new DropdownOption('**x-', 'Two Letters and One Number', { styleName: '2x1', length: 3 }),
    new DropdownOption('**x--', 'Two Letters and Two Numbers', { styleName: '2x2', length: 4 }),
    new DropdownOption('**x---', 'Two Letters and Three Numbers', { styleName: '2x3', length: 5 }),
    new DropdownOption('**x----', 'Two Letters and Four Numbers', { styleName: '2x4', length: 6 }),
    new DropdownOption('***x-', 'Three Letters and One Number', { styleName: '3x1', length: 4 }),
    new DropdownOption('***x--', 'Three Letters and Two Numbers', { styleName: '3x2', length: 5 }),
    new DropdownOption('***x---', 'Three Letters and Three Numbers', { styleName: '3x3', length: 6 }),
    new DropdownOption('***x----', 'Three Letters and Four Numbers', { styleName: '3x4', length: 7 }),
  ], { showSelected: true, selectOnStart: true });

  public stickyLinkConfig: StickyConfig;
  private pageStyle: string;
  private searchCriteria: string;
  public termSorting: DropdownOption;
  public filterChange: EventEmitter<SearchFilters> = new EventEmitter<SearchFilters>();
  public filterUpdate: EventEmitter<SidebarFilterConfig<any>> =
    new EventEmitter<SidebarFilterConfig<any>>();
  public sortDropdownConfig: DropdownConfig = new DropdownConfig('Sort By', 'swap_vert', [
    new DropdownOption('A-Z', 'A-Z'),
    new DropdownOption('Z-A', 'Z-A'),
    new DropdownOption('LOW-TO-HIGH', 'Price Low to High'),
    new DropdownOption('HIGH-TO-LOW', 'Price High to Low')
  ]);
  public searchFilters: SidebarFilterConfig<SearchFilters> = new SidebarFilterConfig<SearchFilters>(
    [
      new SidebarFilterGroup('Sort By', [
        new SidebarFilter('sort', 'Sort By', 'sortBy', {
          selectConfig: this.sortDropdownConfig
        })
      ], { visible: true, customClass: 'd-block d-lg-none' }),
    ], this.filterChange);

  public faq: InfoDropdown[] = [
    FaqBuilder.build('faq_1', 'What are dateless number plates?', [`Dateless number plates, also known as cherished plates, are UK vehicle registrations issued before 1963 that lack an age identifier. They typically feature formats such as <a href="/plate-detail/ABC123">ABC 123</a> or <a href="/plate-detail/123ABC">123 ABC</a>, making them timeless and suitable for vehicles of any age. The absence of a date marker allows owners to conceal the age of their vehicle, adding a touch of classic elegance.`, `Dateless registrations are highly sought after due to their rarity, historical significance, and exclusivity. In fact, the vast majority of the most expensive private plates ever sold or currently available on the market are dateless, including iconic examples like A 1 and F 1, which have fetched record-breaking prices. These plates are considered the ultimate statement of prestige among collectors and enthusiasts.`]),
    FaqBuilder.build('faq_2', 'Why are dateless plates called cherished registrations?', [`The term "cherished number plates" has been used in the UK to describe vehicle registrations that hold personal or historical significance. While the exact origin of the phrase is not well-documented, it has been commonly used by the Driver and Vehicle Licensing Agency (DVLA) and private registration dealers to refer to plates that are particularly valued by their owners. These plates often feature unique combinations, such as initials or short numbers, and are prized for their rarity and the ability to conceal a vehicle's age. The term "cherished" reflects the personal attachment and pride that owners have for these distinctive registrations.`]),
    FaqBuilder.build('faq_3', 'Are dateless number plates a good investment?', [`Dateless number plates are often considered a solid investment due to their rarity and timeless appeal. Unlike standard plates, they lack an age identifier, allowing them to be assigned to vehicles of any age, which adds to their versatility and desirability. The market for these plates has shown consistent growth, with many appreciating in value over time. For example, the registration '<a href="/plate-detail/25O">25 O</a>' sold for £518,000, highlighting the potential for significant returns. Investors are drawn to dateless plates not only for their aesthetic appeal but also for their potential to yield substantial profits, making them a popular choice among collectors and enthusiasts.`]),
    FaqBuilder.build('faq_4', 'What is the difference between a dateless plate and a personalised plate?', [`Dateless plates, such as <a href="/plate-detail/999AB">999 AB</a>, are historic registrations issued before 1963 that lack age identifiers. Their timeless design makes them suitable for any vehicle, regardless of its age. These plates often feature shorter combinations of letters and numbers, adding to their exclusivity and prestige.`, `Personalised plates, on the other hand, are modern creations designed to reflect personal interests, names, or initials, using formats like <a href="/plate-detail/G302RGE">G302 RGE</a> or <a href="/plate-detail/PE73RRR">PE73 RRR</a>. These plates incorporate more recent registration styles, such as prefix, suffix, or current formats, and often include age identifiers.`, `While both dateless and personalised plates allow vehicle owners to showcase individuality, dateless registrations hold additional appeal due to their rarity and historical significance. Their enduring demand among collectors and enthusiasts often makes them a valuable long-term investment.`]),
    FaqBuilder.build('faq_5', 'How do I find dateless number plates for sale?', [`On our dedicated Dateless Registrations page, finding your perfect plate is simple. Start by selecting the format you prefer, such as <a href="/dateless-search/*x-">1 letter + 1 number</a> or <a href="/dateless-search/***x---">3 letters + 3 numbers</a>, from our dropdown menu. You can then browse all available dateless private plates in that format from dealers across the market.`, `If you’re looking for something more specific, customise the search by entering your preferred letters or numbers. Alternatively, try our Initials Search tool, which shows all matching dateless registration options featuring your chosen initials. PlateX ensures a seamless search experience to help you find the ideal cherished plate.`]),
    FaqBuilder.build('faq_6', 'Why are dateless number plates so expensive', [`Dateless number plates command higher prices due to several key factors: </p><ul>
      <li><strong>Rarity: </strong>Issued before 1963, these plates are limited in number. Many have been lost or retired, making the remaining ones scarce and highly sought after.</li>
      <li><strong>Timeless Appeal:</strong>Lacking age identifiers, dateless plates can be assigned to vehicles of any age without revealing the vehicle's year of manufacture. This versatility enhances their desirability among collectors and enthusiasts.</li>
      <li><strong>Prestige and Personalisation:</strong>Short, memorable combinations like 'AB 1' are not only highly individual but also exude a sense of prestige. Plates featuring initials or significant numbers often hold personal value, further driving demand.</li>
      <li><strong>Investment Potential:</strong>The limited supply and consistent demand have led to significant appreciation in value over time. For instance, the registration '25 O' sold for £518,000, highlighting the strong investment potential of dateless plates.</li>
    </ul>`]),
    FaqBuilder.build('faq_7', 'Can I transfer a dateless plate to any car?', [`Yes, dateless registrations can be transferred to any car, regardless of its age. Since they lack an age identifier, they can legally be assigned to both modern and classic vehicles. The DVLA oversees the transfer process, ensuring that your cherished plate is officially linked to your car. PlateX connects you with dealers who can guide you through this seamless transfer process.`]),
    FaqBuilder.build('faq_8', 'What is the oldest dateless plate ever issued?', [`The UK's first vehicle registration plate, <a href="/plate-detail/A1">A 1</a>, was issued in 1903 by the London County Council. However, it was not the earliest plate assigned nationwide. Records suggest that <a href="/plate-detail/DY1">DY 1</a> was issued in Hastings on 23 November 1903, predating <a href="/plate-detail/A1">A 1</a>. These early dateless plates, lacking age identifiers, are highly prized for their historical significance and rarity.`]),
  ]
  private debounceTimeout: any;  // Variable to store the timeout ID


  constructor(
    private searchService: SearchService,
    private registrationService: RegistrationService,
    private route: ActivatedRoute,
    private location: Location,
    private trackingService: TrackingService
  ) {
    this.pageStyle = this.route.snapshot.params.length;
    this.searchCriteria = this.route.snapshot.params.searchCriteria;

    this.filterChange.subscribe((_: SearchFilters) => {
      this.TermSortChange(this.sortDropdownConfig.options.find(o => o.value === _.sortBy), "");
    });
    this.stickyLinkConfig = new StickyConfig(
      [
        new StickyLinkGroup([
          new StickyLink('Results', 'results', true),
          new StickyLink('Q&As', 'faq', false),
        ].filter(x => x != null)),
      ],
      new EventEmitter<void>()
    );

    if (this.searchCriteria == null && this.searchCriteria == undefined) {
      this.canSearch = false;
      return; // nothing selected
    }

    this.trackingService.TrackSearch(
      TRACKING_SEARCH_TYPE.DATELESS,
      this.searchCriteria || '',
      {
        size: this.pageStyle,
      }
    );
    this.canSearch = true;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.selectedOption = new DatelessLengthOption('FROM START', this.pageStyle);
    const toSelect = [...this.datelessOptionsDropdownConfig.options];
    const option = toSelect.find(x => x.value === this.pageStyle)
    if (option === undefined) return;
    this.changeDropdown.emit(option);
  }

  public OptionChange(e: DropdownOption): void {
    this.selectedDropdownOption = e;
    this.canSearch = true;
    this.selectedOption = new DatelessLengthOption(e.view, e.value)
    this.tableTitle = e.metaData['styleName'];
    this.location.go(this.createUrl());
    this.makeSearchRequest();
  }

  public TermSortChange(e: DropdownOption, key: string): void {
    this.termSorting = e;
    if (this.allResults == undefined) return;
    if (e.value === 'A-Z') this.allResults.registrations = this.allResults.registrations.sort((a, b) => a.registration.localeCompare(b.registration));
    else if (e.value === 'Z-A') this.allResults.registrations = this.allResults.registrations.sort((a, b) => b.registration.localeCompare(a.registration));
    else if (e.value === 'HIGH-TO-LOW') this.allResults.registrations = this.allResults.registrations.sort((a, b) => b.price - a.price);
    else if (e.value === 'LOW-TO-HIGH') this.allResults.registrations = this.allResults.registrations.sort((a, b) => a.price - b.price);
    this.all_resultChange.emit({ ...this.allResults });
  }

  private createUrl(): string {
    return `dateless-search/${this.selectedOption.value}`;
  }

  public selectedOptionLength(): number {
    return this.selectedDropdownOption.metaData['length'];
  }

  public filterSearchResults(input: string): void {
    this.searchCriteria = input;

    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(() => {
      this.makeSearchRequest();
    }, 500);
  }

  private makeSearchRequest(): void {
    this.searching = true;
    this.searchService.searchDatelessLength(
      this.selectedOption.value,
      this.searchCriteria || '',
      (res: DatelessSearchResponse) => {
        this.filterUpdate.emit(this.searchFilters);
        this.allResults = { id: 'dateless', registrations: this.registrationService.formatRegistrations(res.main) };
        this.TermSortChange(this.termSorting, "");
        setTimeout(() => {
          this.searching = false;
        }, 500)
      }
    );
  }

  public searchTitle(header: string): string {
    return this.tableTitle;//`${this.results.style}  (${header})`;
  }
}
