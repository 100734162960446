<div>
  <div *ngIf="!loading; else loadingSpinner">
    <div *ngIf="accountDetailsForm" class="user-details-container">
      <h3>Personal Details</h3>
      <form class="form-container" [formGroup]="accountDetailsForm">
        <div class="form-input-container">
          <mat-form-field appearance="legacy">
            <mat-label>Title</mat-label>
            <mat-select formControlName="title">
              <mat-option></mat-option>
              <mat-option [value]="'Mr'">Mr</mat-option>
              <mat-option [value]="'Miss'">Miss</mat-option>
              <mat-option [value]="'Mrs'">Mrs</mat-option>
              <mat-option [value]="'Dr'">Dr</mat-option>
            </mat-select>
            <mat-error
              *ngIf="accountDetailsForm.controls.title.hasError('required')"
            >
              Title is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <input
              matInput
              formControlName="firstName"
              placeholder="First Name"
              name="firstName"
              type="text"
            />
            <mat-error
              *ngIf="accountDetailsForm.controls.firstName.hasError('required')"
            >
              First name is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <input
              matInput
              formControlName="lastName"
              placeholder="Last Name"
              name="lastName"
              type="text"
            />
            <mat-error
              *ngIf="accountDetailsForm.controls.lastName.hasError('required')"
            >
              Last name is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <input
              matInput
              formControlName="mobileNumber"
              placeholder="Mobile number"
              name="mobile"
              type="text"
            />
            <mat-error
              *ngIf="
                accountDetailsForm.controls.mobileNumber.hasError('pattern') &&
                !accountDetailsForm.controls.mobileNumber.hasError('required')
              "
            >
              Please enter a valid mobile number
            </mat-error>
            <mat-error
              *ngIf="
                accountDetailsForm.controls.mobileNumber.hasError('required')
              "
            >
              Mobile number is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <input
              matInput
              formControlName="addressLine1"
              placeholder="Address line 1"
              name="addressLine1"
              type="text"
            />
            <mat-error
              *ngIf="
                accountDetailsForm.controls.addressLine1.hasError('required')
              "
            >
              Addresss is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <input
              matInput
              formControlName="addressLine2"
              placeholder="Address line 2"
              name="addressLine2"
              type="text"
            />
            <mat-error
              *ngIf="
                accountDetailsForm.controls.addressLine2.hasError('required')
              "
            >
              Addresss is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <input
              matInput
              formControlName="addressLine3"
              placeholder="Address line 3"
              name="addressLine3"
              type="text"
            />
            <mat-error
              *ngIf="
                accountDetailsForm.controls.addressLine3.hasError('required')
              "
            >
              Addresss is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <input
              matInput
              formControlName="postTown"
              placeholder="Town"
              name="postTown"
              type="text"
            />
            <mat-error
              *ngIf="accountDetailsForm.controls.postTown.hasError('required')"
            >
              Town is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <input
              matInput
              formControlName="postCode"
              placeholder="Post code"
              name="postCode"
              type="text"
            />
            <mat-error
              *ngIf="
                accountDetailsForm.controls.postCode.hasError('pattern') &&
                !accountDetailsForm.controls.postCode.hasError('required')
              "
            >
              Please enter a valid post code
            </mat-error>
            <mat-error
              *ngIf="accountDetailsForm.controls.postCode.hasError('required')"
            >
              Post code is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="action-button" *ngIf="accountDetailsForm.enabled">
          <button mat-raised-button class="cyan-button" (click)="saveDetails()">
            Save Details
          </button>
        </div>

        <div class="action-button" *ngIf="accountDetailsForm.enabled">
          <button
            mat-raised-button
            class="turquoise-border-button"
            (click)="cancelUpdate()"
          >
            Cancel
          </button>
        </div>

        <div class="action-button" *ngIf="!accountDetailsForm.enabled">
          <button
            mat-raised-button
            class="cyan-button"
            (click)="updateDetails()"
          >
            Update Details
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #loadingSpinner>
  <div class="full spinner-loader">
    <div class="py-3">
      <mat-progress-spinner
        class="spinner cyan"
        mode="indeterminate"
        [color]="'#327b8d'"
        [diameter]="60"
      ></mat-progress-spinner>
    </div>
    <span>Loading Details...</span>
  </div>
</ng-template>
