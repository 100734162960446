import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Registration } from 'src/app/models/registration';
import { SessionService } from 'src/app/services/session-service';
import { UserService } from 'src/app/services/user-service';

@Component({
  selector: 'pla-term-list-result-row',
  templateUrl: './term-list-result-row.component.html',
  styleUrls: ['./term-list-result-row.component.scss'],
})
export class TermListResultRowComponent implements OnInit {
  @Input('loading') public loading: boolean = false;
  @Input('plate') public plate: Registration;
  @Input('lastRow') public lastRow: boolean = false;
  @Input('term') public term: string;
  @Input('customButton') public customButton: EventEmitter<any>;
  @Input('showTerm') public showTerm: boolean = true;
  @Input('allowShowMore') public allowShowMore: boolean = false;
  @Input('whitePlate') public whitePlate: boolean = false;

  public isAdmin: boolean = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private sessionService: SessionService
  ) {
    this.isAdmin = this.sessionService.isAdmin();
  }

  ngOnInit(): void {}

  public gotoCompareLink(reg: Registration): void {
    var url = `/compare/${reg.registration}`;
    // return url;
    this.router.navigate([url]);
  }

  public favouriteRegistration(reg: Registration): void {
    this.userService.favouriteRegistration(reg, (favs: Registration[]) => {
      var _filteredFavourites = favs.filter(
        (f) =>
          f.registration.replace(' ', '') == reg.registration.replace(' ', '')
      );
      reg.favourite = _filteredFavourites && _filteredFavourites.length > 0;
    });
  }

  public openPlateDetail(registration: Registration) {
    this.router.navigate([`/plate-detail/${registration.registration}`]);
  }

  public isPOA(registration: Registration) {
    return registration.price == 0 && registration.plateOwner != 0;
  }
}
