import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpLoadingResp } from 'src/app/models/httpLoadingResp';
import { SoldPlate } from 'src/app/models/soldPlate';
import { RegistrationService } from 'src/app/services/registration-service';
import { DataPoint } from '../chart/chart.component';

@Component({
  selector: 'pla-price-history',
  templateUrl: './price-history.component.html',
  styleUrls: ['./price-history.component.scss'],
})
export class PriceHistoryComponent implements OnInit {
  @Input() public registration: string;
  @Output() public showingData: EventEmitter<{ showingChart: boolean, showingTable: boolean }> = new EventEmitter<{ showingChart: boolean, showingTable: boolean }>();
  public registrationHistory: HttpLoadingResp<SoldPlate[]> =
    new HttpLoadingResp<SoldPlate[]>();
  public registrationHistoryPoints: DataPoint[] = [];

  constructor(private registrationService: RegistrationService) { }

  ngOnInit(): void {
    this.fetchRegistrationHistory();
  }

  private fetchRegistrationHistory(): void {
    this.registrationService.getPlateHistory(
      this.registration.replace(' ', ''),
      (history: SoldPlate[]) => {
        if (history != null) {
          history.map((p) => (p.price = Number.parseInt(p.sold_price) / 100));
          this.registrationHistory.Update(false, history);

          // map data to chart points
          this.registrationHistory.data.map((soldPlate: SoldPlate) => {
            var dataPoint = new DataPoint(
              soldPlate.sold_date,
              Number.parseInt(soldPlate.sold_price) / 100
            );
            this.registrationHistoryPoints.push(dataPoint);
            return soldPlate;
          });
        }
        this.showingData.emit({ showingChart: this.hasSoldData(2, true), showingTable: this.hasSoldData(1, true) })
      },
      (error: any) => {
        this.registrationHistory.Update(false, null);
        this.showingData.emit({ showingChart: this.hasSoldData(2, true), showingTable: this.hasSoldData(1, true) })
      }
    );
  }

  public hasSoldData(
    len: number = 1,
    require_different_prices: boolean = false
  ): boolean {
    var show = false;
    show =
      this.registrationHistoryPoints &&
      this.registrationHistoryPoints.length >= len;
    if (!show) return show;

    if (require_different_prices) {
      var samePoints = true;
      var lastDataPoint: DataPoint = null;
      this.registrationHistoryPoints.forEach(
        (element: DataPoint, i: number) => {
          if (lastDataPoint == null) lastDataPoint = element;
          if (lastDataPoint.y != element.y) samePoints = false;
        }
      );

      if (samePoints) return false;
    }

    return show;
  }
}
