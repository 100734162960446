import { DatePipe } from '@angular/common';
import { not } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotifyRegistration } from 'src/app/models/notifyRegistration';
import { Registration } from 'src/app/models/registration';
import { RegistrationService } from 'src/app/services/registration-service';
import { SessionService } from 'src/app/services/session-service';
import { UserService } from 'src/app/services/user-service';

@Component({
  selector: 'pla-account-notify',
  templateUrl: './account-notify.component.html',
  styleUrls: ['./account-notify.component.scss'],
})
export class AccountNotifyComponent implements OnInit {
  public notifies: Registration[] = [];
  public loading: boolean = true;

  constructor(
    private sessionService: SessionService,
    private userService: UserService,
    private router: Router,
    private registrationService: RegistrationService,
    private datePipe: DatePipe
  ) {
    this.loading = true;
    this.userService.notifiesUpdated.subscribe((_: Registration[]) => {
      this.notifies = this.registrationService.formatRegistrations(_);
      this.loading = false;
    });
    if (!this.sessionService.isLoggedIn()) return;
    this.getNotifies();
  }

  ngOnInit(): void {}

  public openPlateDetail(registration: Registration): void {
    this.router.navigate([`/plate-detail/${registration.registration}`]);
  }

  private getNotifies(): void {
    if (this.notifies.length > 0) return;
    this.loading = true;
    this.userService.getUserNotifies((_: Registration[]) => {
      this.notifies = this.registrationService.formatRegistrations(_);
      this.loading = false;
    });
  }

  public notifySafeDate(notify: NotifyRegistration): string {
    return `Notify requested ${this.datePipe.transform(
      notify.dateAdded,
      'shortDate'
    )}`;
  }
}
