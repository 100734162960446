import { Component, OnInit } from '@angular/core';
import { RegistrationService } from 'src/app/services/registration-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'pla-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public currYear = new Date(Date.now()).getFullYear();
  public versionNo = environment.versionNo;
  public plateCount: number = Math.floor(
    Math.random() * (6820000 - 6810000 + 1) + 68000000
  );

  constructor(private registrationService: RegistrationService) {
    this.registrationService
      .getPlateCount()
      .subscribe((_: { totalCount: number }) => {
        this.plateCount = _.totalCount;
      });
  }

  ngOnInit(): void { }
}
