import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pla-how-to-retain-a-registration',
  templateUrl: './how-to-retain-a-registration.component.html',
  styleUrls: ['./how-to-retain-a-registration.component.scss']
})
export class HowToRetainARegistrationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
