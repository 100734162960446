import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormattedPlateResponse } from 'src/app/models/formattedPlateResponse';
import { Listing } from 'src/app/models/listing';
import { CreateListingResponse } from 'src/app/models/createListingResponse';
import { ListingService } from 'src/app/services/listing-service';
import { HttpErrorResponse } from '@angular/common/http';
import { IProduct } from 'src/app/models/payment/IProduct';
import { OrderType } from 'src/app/services/payment/order-type.enum';
import {
  GetStripePaymentLink,
  ListingType,
  ListingTypeMapper,
} from 'src/app/models/enums/listingType';
import { ListingPricePoint } from 'src/app/models/listingPricePoint';
import { RegistrationService } from 'src/app/services/registration-service';
import { SearchService } from 'src/app/services/search-service';
import { Registration } from 'src/app/models/registration';
import { HttpLoadingResp } from 'src/app/models/httpLoadingResp';
import { PlateDetail } from 'src/app/models/plateDetail';
import { ImageKeyPair, ImageService } from 'src/app/services/image-service';
import { PlateOwner } from 'src/app/models/enums/plateOwner';
import { PageStep } from 'src/app/components/page-steps/page-steps.component';
import { UserDetails } from 'src/app/models/userDetails';
import { UserService } from 'src/app/services/user-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationService } from 'src/app/services/notification-service';

export class CompsResponse {
  constructor(
    public wildcard: { breakdown: string; results: Registration[] }[],
    public main: Registration[],
    public matched_percentage: number,
    public calculations: {
      combined: number;
      available: number;
      sold: number;
      calculation_range: number[];
    }
  ) {}
}

@Component({
  selector: 'pla-create-auction',
  templateUrl: './create-auction.component.html',
  styleUrls: ['./create-auction.component.scss'],
})
export class CreateAuctionComponent implements OnInit {
  // new
  private DEFAULT_DETAIL: PlateDetail = {
    formattedRegistration: '-',
    registrationString: '-',
    owner: PlateOwner.NONE,
    style: '-',
    issueMonth: '',
    issueYear: '-',
    issueRegion: '-',
    platexPrice: -1,
  };
  public imageOptionEmitter: EventEmitter<ImageKeyPair[]> = new EventEmitter<
    ImageKeyPair[]
  >();
  public registrationMetaData: HttpLoadingResp<PlateDetail> =
    new HttpLoadingResp(false, this.DEFAULT_DETAIL);
  public formattedRegistration: string = null;
  public quickImage: ImageKeyPair = null;

  public listing: Listing = new Listing();
  public userDetails: UserDetails;
  public results: Registration[];

  public pageSteps: PageStep[] = [
    new PageStep().AddBody(
      '<b>Enter</b> the registration you wish to list for same'
    ),
    new PageStep().AddBody(
      '<b>Select</b> a keyword to associate with your registration'
    ),
    new PageStep().AddBody(
      '<b>Compare</b> prices of similar registrations that have been sold and for sale to help choose an asking price'
    ),
    new PageStep().AddBody(
      '<b>List</b> your registration and see your advert live on the site.'
    ),
  ];

  constructor(
    private registrationService: RegistrationService,
    private imageService: ImageService,
    private userService: UserService,
    private snackbar: MatSnackBar,
    private notificationService: NotificationService,

    // OLD
    private listingService: ListingService,
    private searchService: SearchService
  ) {
    this.userService.getUserDetails((u: UserDetails) => {
      this.userDetails = u;

      this.listing.firstName = u.firstName;
      this.listing.lastName = u.lastName;
    });
  }

  ngOnInit(): void {}

  private fetchRegistrationDetails(callback: () => void): void {
    if (this.listing.registration == null || this.listing.registration == '')
      return;
    this.registrationService
      .getPlateDetail(this.listing.registration)
      .subscribe((metaData: PlateDetail) => {
        this.registrationMetaData.Update(false, metaData);
        callback();
      });
  }

  public formattingRegistration: boolean = false;
  public plateValid: boolean = true;
  public lastValuationDetails: { reg: string; term: string } = {
    reg: '',
    term: '',
  };

  public fetchingValuations: boolean = false;
  public valuationPlates: Registration[] = [];
  public availableValuationPlates: Registration[] = [];
  public soldValuationPlates: Registration[] = [];

  public compResp: CompsResponse = null;

  public listingTypes = [
    { view: 'Platex Commision', value: ListingType.Platex_Commision },
    { view: 'Monthly Plan', value: ListingType.Standard_Monthly },
    { view: '3 Month Plan', value: ListingType.Standard_3_Monthly },
    { view: 'Annual Plan', value: ListingType.Standard_Annually },
  ];
  public listingPriceDescription: ListingPricePoint;

  // HANDLERS
  public HandleRegistrationBlur(event: Event) {
    this.FormatRegistration();
    this.fetchRegistrationDetails(() => {});
  }

  public HandleTermInputBlur(event: Event) {}

  public mapValuationToColour(valuation: Registration): string {
    if (valuation.plateOwner == 999) return 'negative';
    return 'positive';
  }

  public mapValuationToState(valuation: Registration): string {
    if (valuation.plateOwner == 999) return 'Sold';
    return 'For Sale';
  }

  public listPlateClickHandler(): void {
    this.listing.price = Number.parseInt(this.listing.price.toFixed(2));
    this.listingService.CreateListing(this.listing).subscribe(
      () => {
        console.log('listing created');
      },
      () => {
        console.warn('listing failed');
      }
    );
  }

  // LOGIC

  public listingPrice(): string {
    const price = ListingTypeMapper(this.listing.listingType);
    return `£${price.price.toFixed(2)} ${price.period}`;
  }

  private FormatRegistration() {
    if (!this.listing.registration || this.listing.registration == '') return;
    this.formattingRegistration = true;
    this.registrationService
      .FormatRegistration(this.listing.registration)
      .subscribe(
        (resp: FormattedPlateResponse) => {
          if (resp == null) return;
          this.formattingRegistration = false;
          this.plateValid = resp.valid;
          this.listing.registration = resp.formattedRegistration;
        },
        () => {
          this.formattingRegistration = false;
          this.plateValid = false;
        }
      );
  }

  public FetchValuations(): void {
    this.resetValuationReulsts();
    if (this.formattingRegistration) {
      this.plateValid = false;
      this.snackbar.open('Registration not formatted correctly', 'Close', {
        duration: 10000,
      });
      return;
    }
    if (this.listing.registration == '' || this.listing.registration == null) {
      this.plateValid = false;
      this.snackbar.open('Registration must be provided', 'Close', {
        duration: 10000,
      });
      return;
    }
    if (this.listing.searchTerm == '' || this.listing.searchTerm == null) {
      this.snackbar.open('A search term must be provided', 'Close', {
        duration: 10000,
      });
      return;
    }
    if (!this.plateValid) {
      this.snackbar.open('Invalid registration provided', 'Close', {
        duration: 10000,
      });
      return; // is plate valid
    }

    // update search details for checks
    this.lastValuationDetails.reg = this.listing.registration;
    this.lastValuationDetails.term = this.listing.searchTerm;
    // end update

    // search
    this.fetchingValuations = true;
    this.searchService.getValuationPlates(
      this.listing.registration,
      this.listing.searchTerm,
      (_: Registration[]) => {
        console.log('fetched valuations', _);
        this.fetchingValuations = false;
        if (_ == null) return;
        this.results = this.registrationService.formatRegistrations(_);
        console.log(this.results);

        // _.main = this.registrationService.formatRegistrations(_.main);
        // _.wildcard = _.wildcard.map((wc) => {
        //   if (wc.results == null || wc.results.length == 0) {
        //     wc.results = [];
        //     return wc;
        //   }
        //   wc.results = this.registrationService.formatRegistrations(wc.results);
        //   return wc;
        // });
        // this.compResp = _;

        // var wildcardResults = [];
        // _.wildcard.forEach((wc) => {
        //   wildcardResults.push(...wc.results);
        // });

        // this.valuationPlates = this.registrationService.formatRegistrations([
        //   ..._.main,
        //   ...wildcardResults,
        // ]);
        // this.availableValuationPlates = this.valuationPlates.filter(
        //   (r) => r.seller != null
        // );
        // this.soldValuationPlates = this.valuationPlates.filter(
        //   (r) => r.seller == null
        // );
      }
    );
  }

  private resetValuationReulsts(): void {
    this.valuationPlates = [];
    this.availableValuationPlates = [];
    this.soldValuationPlates = [];
  }

  public canFetchValue(): boolean {
    if (this.fetchingValuations) return false;
    if (!this.plateValid) return false;
    if (this.listing.registration == '') return false;
    if (this.listing.registration == null) return false;
    if (this.listing.searchTerm == '') return false;
    if (this.listing.searchTerm == null) return false;
    return true;
  }

  public canList(): boolean {
    if (!this.plateValid) return false;
    if (this.listing.searchTerm == '' || this.listing.searchTerm == null)
      return false;
    if (!this.listing.legalOwner) return false;
    if (!this.listing.taxed_or_sorn && !this.listing.on_retention) return false;
    if (this.listing.price == null) return false;
    if (this.listing.price.toString() == '') return false;
    if (this.listing.price <= 0) return false;

    return true;
  }

  public CreateListing(): void {
    const postListing = JSON.parse(JSON.stringify(this.listing)) as Listing;
    postListing.price = Number.parseInt((postListing.price * 100).toFixed(0)); // minor currency
    this.listingService.CreateListing(postListing).subscribe(
      (resp: CreateListingResponse) => {
        this.CreateListingPayment(resp);
      },
      (resp: HttpErrorResponse) => {
        this.plateValid = false;
        this.snackbar.open(
          (resp.error as { errorMessage: string }).errorMessage,
          'Close',
          {
            duration: 10000,
          }
        );
        console.error(resp);
      }
    );
  }

  public ListingTypeChanged(): void {
    this.listingPriceDescription = null;
    this.listingService
      .GetListingPrice(this.listing.listingType)
      .subscribe((resp: ListingPricePoint) => {
        this.listingPriceDescription = resp;
      });
  }

  // TODO
  private CreateListingPayment(listingResp: CreateListingResponse) {
    if (!listingResp.success) return;
    if (listingResp.listing.listingType === ListingType.Platex_Commision) {
      var paymentLink = `${GetStripePaymentLink(
        listingResp.listing.listingType
      )}${listingResp.listing.advertId}`;
    } else {
      var paymentLink = `${GetStripePaymentLink(
        listingResp.listing.listingType
      )}?client_reference_id=${listingResp.listing.advertId}`;
    }

    location.href = paymentLink;
  }
}
