import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  InfoDropdown,
  InfoDropdownContent,
} from 'src/app/components/faq/faq.component';

@Component({
  selector: 'pla-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css'],
})
export class InfoComponent implements OnInit {
  public glossaryUrl: string = '';

  public infoContent: InfoDropdown[] = [
    new InfoDropdown('about-us', 'About Us', true, [
      new InfoDropdownContent(
        null,
        '<p>PlateX is a UK technology company whose sole endeavour is to simplify the cherished registration market.</p><p>Cutting edge search technologies help our users find their perfect registration and our price comparison service locates the best possible deal.</p><p>For sellers we provide a cost-effective, commission-free advertising service to an unrivalled marketplace designed specifically to promote liquidity.</p>'
      ),
    ]),
    new InfoDropdown('our-strategy', 'Our Strategy', false, [
      new InfoDropdownContent(
        null,
        '<p>To simplify the cherished registration market in the UK, our strategy is to:</p><ul><li>be the place consumers turn to first and engage with most</li><li>provide unrivalled exposure and leads for our customers</li><li>innovate to create a simpler and more liquid market</li></ul>'
      ),
    ]),
    new InfoDropdown('our-technology', 'Our Technology', false, [
      new InfoDropdownContent(
        null,
        '<p>At the core of all features on the PlateX platform is our proprietary search algorithm PLATO, named after another famous deep thinker. PLATO allows users to search the entire marketplace of registrations including those offered for resale by dealers, private owners, and millions of unissued government stock, all in a single search.</p>'
      ),
      new InfoDropdownContent(
        null,
        '<p>Within just a few seconds PLATO retrieves and ranks search results by numerous datapoints including pattern match, recognisability and phonetics while minimising blight caused by additional characters.</p>'
      ),
      new InfoDropdownContent(
        null,
        '<p>As an autonomous algorithm PLATO continues to learn and adapt with every search it undertakes. In addition our users help PLATO learn to improve the quality of ranking through the Vote Up / Down icons beneath each search result.   To ensure that users are guaranteed to be shown the best result we safeguard the integrity of the PLATO search by not offering any service to improve the ranking of a registration for fees or affiliation.</p>'
      ),
    ]),
  ];

  constructor(private activatedRoute: ActivatedRoute) {
    this.glossaryUrl = this.activatedRoute.snapshot.paramMap.get('term') || '';
  }

  ngOnInit(): void {}
}
