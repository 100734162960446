import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { QuickSignupService } from '../../services/quick-signup-service';

@Component({
  selector: 'pla-signup-capture-modal',
  templateUrl: './signup-capture-modal.component.html',
  styleUrls: ['./signup-capture-modal.component.scss'],
})
export class SignupCaptureModalComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<SignupCaptureModalComponent>,
    private quickSignUpService: QuickSignupService
  ) { }

  ngOnInit(): void { }

  public OpenSignUpModal(): void {
    this.dialogRef.close();
    this.quickSignUpService.quickSignup(
      'search-popup',
      undefined,
      (_: boolean) => { },
      () => { }
    );
  }

  public Close(): void {
    this.dialogRef.close();
  }
}
