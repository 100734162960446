import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DropdownConfig, DropdownOption } from '../custom-dropdown/custom-dropdown.component';
import { MatRadioChange } from '@angular/material/radio';

export class SidebarFilterConfig<T> {
  constructor(
    public filterGroups: SidebarFilterGroup[],
    public onChange: EventEmitter<T>
  ) { }
}

export class SidebarFilterGroup {
  constructor(public viewName: string, public filters: SidebarFilter[] = [], public config: {
    visible?: boolean,
    customClass?: string
  } = {
      visible: true,
      customClass: ''
    }) { }
}

export class SidebarFilter {
  constructor(public filterType: 'checkbox' | 'select' | 'sort', public viewName: string, public key: string, public config?: {
    selectConfig?: DropdownConfig,
    sortConfig?: DropdownConfig,
    subText?: string,
    htmlReplacement?: string,
    customClass?: string,
  }) { }
}

@Component({
  selector: 'pla-side-bar-filters',
  templateUrl: './side-bar-filters.component.html',
  styleUrls: ['./side-bar-filters.component.scss'],
})
export class SideBarFiltersComponent implements OnInit {
  @Input() public filters: EventEmitter<SidebarFilterConfig<any>> =
    new EventEmitter<SidebarFilterConfig<any>>();
  @Input() public location = 'sidebar';
  @Input() public showBorders: boolean = false;
  @Input() public filtersConfig: SidebarFilterConfig<any>;
  private filterObj = {};
  constructor() { }

  ngOnInit(): void {
    this.filters.subscribe((fc) => {
      this.filtersConfig = fc;
      this.SetupFilters();
    });
  }

  private SetupFilters(): void {
    var filterObjString = this.filtersConfig.filterGroups.map((fg) =>
      fg.filters.map((f) => f.key)
    );
    ([] as string[]).concat(...filterObjString).forEach((f) => {
      this.filterObj[f] = false;
    });
  }

  public SelectChange(e: DropdownOption, key: string): void {
    this.filterObj[key] = e.value;
    this.filtersConfig.onChange.emit(this.filterObj);
  }

  public ValChange(e: MatCheckboxChange, key: string): void {
    this.filterObj[key] = e.checked;
    this.filtersConfig.onChange.emit(this.filterObj);
  }

  public SortValChange(e: MatRadioChange, key: string): void {
    console.log(e);
    this.filterObj[key] = e.value;
    this.filtersConfig.onChange.emit(this.filterObj);
  }

  public visibleFilters(): SidebarFilterGroup[] {
    return this.filtersConfig.filterGroups.filter(fg => fg.config.visible);
  }
}
