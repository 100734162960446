<div class="w-100">
    <div class="w-100">
        <div style="height: 40px;"></div>

        <div class="content-container extended">
            <div class="w-100 px-0">
                <div class="px-4 px-md-3">
                    <p class="fs-md-26 fs-mb-24 lh-sm fw-bolder text-start h-mb">Sell Direct: No Middlemen, No
                        Commission</p>
                    <p class="lh-sm fw-light fs-md-18 fs-mb-16 text-start mb-0">Thousands of buyers use our market-wide
                        search
                        and price comparison to find their perfect private number plate. List on PlateX to get your
                        registration in front of the right buyers more often.<br />
                        Take control—sell your plate like you would a car, with no middlemen and no commission.</p>
                </div>
            </div>
        </div>

        <div class="s-spacing"></div>

        <div class="content-container extended">
            <div class="w-100 px-0">
                <div class="px-4">
                    <p class="fs-md-26 fs-mb-24 lh-sm fw-bolder text-start h-mb">Why List On PlateX?</p>
                </div>

                <div class="container-fluid px-4">
                    <div class="row">
                        <div class="col-12 col-md-7">
                            <ng-container *ngFor="let wlw of whyListWith; let i = index">
                                <div class="d-flex flex-row align-items-center justify-content-start gap-3 rounded-1 {{ i == whyListWith.length - 1 ? 'mb-0' : 'mb-4'}}"
                                    style="background-color: #F7F7F7; padding: 18px 16px; max-width: 750px">
                                    <div class="flex-shrink-0">
                                        <img src="{{wlw.icon}}" class="d-block w-100 mx-auto"
                                            style="max-width: 45px; max-height: 35px; object-fit: contain;" />
                                    </div>
                                    <div class="flex-fill d-flex flex-column align-items-center justify-content-start">
                                        <p
                                            class="fw-bolder h-100 lh-1 flex-fill w-100 mb-2 fs-mb-16 fs-md-18 text-start">
                                            {{wlw.title}}
                                        </p>
                                        <p class="fs-md-16 fs-mb-16 lh-sm fw-light my-0 text-dark text-start">
                                            {{wlw.body}}</p>
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                        <!-- REVUELTO -->
                        <div class="col-12 col-md-5">
                            <div class="w-100 h-100 d-none d-md-flex align-items-stretch justify-content-center">
                                <div class="w-100 h-100 p-4 d-flex align-items-end justify-content-end"
                                    style="background-image: url('https://cdn.platex.co.uk/pages/landing/REVUELTO_PLATEX_V2.png'); background-position-x: center; background-position-y: bottom; background-size: cover; background-repeat: no-repeat;">
                                    <div class="w-100 mx-auto" style="max-width: 250px;">
                                        <button
                                            class="d-block w-100 rounded-3 white-border-button fs-md-20 fs-mb-18 py-3 px-3">
                                            Value My Registration
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-block d-md-none row px-4 mt-4">
                    <div>
                        <button
                            class="d-block d-md-none w-100 rounded-3 platex-border-button fs-md-20 fs-mb-18 py-3 px-3">
                            Value My Registration
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="s-spacing"></div>

    <div style="background-color: #F1F4F2;">
        <div class="content-container extended">
            <div class="w-100 px-0 py-4">
                <div class="px-4">
                    <p class="fs-md-26 fs-mb-24 lh-sm fw-bolder text-start my-0">New Listings</p>
                </div>
                <div>
                    <pla-featured-listings [showSellSlide]="true"></pla-featured-listings>
                </div>
                <div class="px-4">
                    <p class="fs-md-16 fs-mb-16 fw-light my-0 text-dark text-start"><strong class="fw-bolder">Sell With
                            Style -</strong> Along
                        with appearing in search results your plate will be showcased in photorealistic quality in the
                        New Listing feature reaching thousands of buyers.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="s-spacing"></div>

    <div class="" style="background: #fff;">
        <div class="content-container extended">
            <div class="container-fluid w-100 px-4">
                <div class="row px-0">
                    <p class="fs-md-26 fs-mb-24 lh-sm fw-bolder text-start h-mb">Selling made simple</p>
                </div>

                <!-- DESKTOP -->
                <div class="px-0 row d-none d-md-block">
                    <div class="d-flex justify-content-between align-content-center">
                        <ng-container *ngFor="let card of cards; let i = index;">
                            <div class="vs-{{i}}-content w-100">
                                <div class="w-100">
                                    <div class="w-100 text-center mb-3 d-flex align-items-center justify-content-center"
                                        style="height: 120px;">
                                        <img [src]="card.image" style="max-height: 120px; max-width: 160px"
                                            class="mx-auto d-block card-image" />
                                    </div>
                                    <div class="w-100 mx-auto">
                                        <!-- NUMBER -->
                                        <div class="d-flex align-items-center justify-content-center mb-3">
                                            <div *ngIf="i > 0" class="w-100 flex-fill"
                                                style="height: 1px; background-color: #0A6077;"></div>
                                            <div *ngIf="i == 0" class="w-100 flex-fill" style="height: 1px;"></div>
                                            <span style="color: #0A6077;"
                                                class="d-block fw-normal lh-1 fs-md-28 text-center px-3">{{ i + 1
                                                }}</span>
                                            <div *ngIf="i < cards.length - 1" class="w-100 flex-fill"
                                                style="height: 1px; background-color: #0A6077;">
                                            </div>
                                            <div *ngIf="i == cards.length - 1" class="w-100 flex-fill"
                                                style="height: 1px;">
                                            </div>
                                        </div>
                                        <!-- CONTENT -->
                                        <div class="px-2">
                                            <p class="lh-sm flex-fill w-100 my-0 fs-mb-20 fs-md-18 mb-2 text-center fw-normal mx-auto"
                                                style="max-width: 270px;">
                                                {{ card.title }}</p>
                                            <p class="lh-base pb-0 pt-0 fs-md-16 fs-mb-15 lh-1 mb-0 text-center fw-light mx-auto"
                                                style="max-width: 270px;">
                                                {{ card.body }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <!-- MOBILE -->
                <div class="px-0 row d-block d-md-none">
                    <ng-container *ngFor="let card of cards; let i = index;">
                        <div class="d-flex d-md-none align-items-stretch justify-content-center gap-3">
                            <!-- number -->
                            <div class="d-flex align-items-center justify-content-center flex-fill flex-column">
                                <div *ngIf="i > 0" class="flex-fill" style="width: 1px; background-color: #0A6077;">
                                </div>
                                <div *ngIf="i == 0" class="flex-fill" style="width: 1px;"></div>
                                <div style="border: 1px solid #0A6077; width: 25px; height: 25px;"
                                    class="d-flex align-items-center justify-content-center rounded-circle my-2">
                                    <span style="color: #0A6077;"
                                        class="d-block fw-normal lh-1 fs-mb-16 w-100 mx-auto text-center">{{ i +
                                        1
                                        }}</span>
                                </div>
                                <div *ngIf="i < cards.length - 1" class="flex-fill"
                                    style="width: 1px; background-color: #0A6077;">
                                </div>
                                <div *ngIf="i == cards.length - 1" class="flex-fill" style="width: 1px;"></div>
                            </div>
                            <!-- content -->
                            <div class="vs-{{i}}-content w-100">
                                <div class="w-100">
                                    <div class="w-100 text-center mb-3">
                                        <img [src]="card.image" style="max-width: 100px;"
                                            class="mx-auto w-100 d-block card-image" />
                                    </div>
                                    <div style="max-width: 270px;" class="mx-auto">
                                        <p
                                            class="lh-sm flex-fill w-100 my-0 fs-mb-20 fs-md-18 mb-2 text-center fw-normal">
                                            {{ card.title }}</p>
                                        <p class="lh-base pb-0 pt-0 fs-md-16 fs-mb-15 lh-1 mb-0 text-center fw-light">
                                            {{ card.body }}</p>
                                    </div>
                                </div>
                            </div>

                            <div style="width: 25px; height: 1px;"></div>
                        </div>
                        <div *ngIf="i < cards.length - 1" class="pb-5 d-block d-md-none"
                            style="width: 25px; border-right-style: solid; border-right-width: 1px; border-color: #0A6077 !important;">
                        </div>
                    </ng-container>

                    <div class="pt-4">
                        <button class="d-block w-100 rounded-3 platex-border-button fs-md-20 fs-mb-18 py-3 px-3">
                            Try PlateX for free
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="s-spacing"></div>

    <div>
        <div class="content-container extended">
            <div class="container-fluid w-100">
                <div class="row px-0">
                    <p class="px-4 fs-md-26 fs-mb-24 lh-sm fw-bolder text-start h-mb">Every Great Success
                        Starts With A Plan</p>
                </div>
            </div>
        </div>
    </div>
    <div style="background-color: #F1F4F2;">
        <div class="content-container extended">
            <div class="container-fluid w-100">
                <div class="px-0 row py-4">
                    <p class="d-block d-md-none px-4 fs-md-26 fs-mb-15 lh-1 fw-normal text-start h-mb">All of our plans
                        come
                        with a no obligation 2 week free trial.</p>

                    <swiper class="sp swiper px-2 px-md-4" [config]="sp_options">
                        <ng-template swiperSlide>
                            <!-- MONTHLY -->
                            <div class="mb-4 mb-md-0 mx-auto" style="max-width: 310px; min-width: 300px;">
                                <div class="h-100 bg-white rounded-lg overflow-hidden d-flex flex-column justify-content-between shadow-sm mb-md-0 mb-0 border border-1"
                                    style="border-color: transparent !important;">
                                    <div class="py-4 px-3 pb-3" style="background-color: #D8E4E3;">
                                        <div class="d-flex justify-content-start align-items-start gap-1">
                                            <div>
                                                <img src="https://cdn.platex.co.uk/pages/landing/calendar_1_month.png"
                                                    class="d-block w-100 ms-0 me-auto" style="max-width: 45px" />
                                            </div>

                                            <div>
                                                <p class="lh-1 flex-fill mb-1 fs-mb-18 fs-md-22">Monthly</p>
                                                <small class="text-muted py-1">No commitment, cancel
                                                    anytime</small>

                                                <p class="lh-1 mt-2 my-0 fs-mb-22 fs-md-22">£7.50 <small
                                                        class="text-muted fs-mb-15">/
                                                        month</small></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="py-4 px-3 pt-3">
                                        <div class="d-flex align-items-center justify-content-start gap-2">
                                            <img src="https://cdn.platex.co.uk/assets/checkmark.png"
                                                class="d-block w-100" style="max-width: 20px" />
                                            <p class="my-0">Flexible monthly plan</p>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-start gap-2">
                                            <img src="https://cdn.platex.co.uk/assets/checkmark.png"
                                                class="d-block w-100" style="max-width: 20px" />
                                            <p class="my-0">No commission or buyers premium</p>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-start gap-2">
                                            <img src="https://cdn.platex.co.uk/assets/checkmark.png"
                                                class="d-block w-100" style="max-width: 20px" />
                                            <p class="my-0">Seen first</p>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-start gap-2">
                                            <img src="https://cdn.platex.co.uk/assets/checkmark.png"
                                                class="d-block w-100" style="max-width: 20px" />
                                            <p class="my-0">Mailed out to members</p>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-start gap-2">
                                            <img src="https://cdn.platex.co.uk/assets/checkmark.png"
                                                class="d-block w-100" style="max-width: 20px" />
                                            <p class="my-0">Cancel at any time</p>
                                        </div>

                                        <a [routerLink]="'/sell/1-month'"
                                            class="mt-3 btn d-flex align-items-center justify-content-center px-3 rounded-pill gap-1 mx-auto"
                                            style="background-color: #D8E4E3; max-width: 200px;">
                                            <span class="fs-md-18 fsm-mb-16 p-0">Choose Monthly</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <!-- END MONTHLY -->
                        </ng-template>

                        <ng-template swiperSlide>
                            <!-- 3 MONTHS -->
                            <div class="mb-4 mb-md-0 mx-auto" style="max-width: 310px; min-width: 300px;">
                                <div class="h-100 bg-white rounded-lg overflow-hidden d-flex flex-column justify-content-between shadow-sm mb-md-0 mb-0 border border-1"
                                    style="border-color: #3791A8 !important;">
                                    <div class="py-2 px-2" style="background-color: #3791A8;">
                                        <p class="lh-1 flex-fill my-0 fs-mb-18 fs-md-22 text-white text-center">MOST
                                            POPULAR
                                        </p>
                                    </div>
                                    <div class="py-4 px-3 pb-3" style="background-color: #D8E4E3;">
                                        <div class="d-flex justify-content-start align-items-start gap-1">
                                            <div>
                                                <img src="https://cdn.platex.co.uk/pages/landing/calendar_3_month.png"
                                                    class="d-block w-100 ms-0 me-auto" style="max-width: 45px" />
                                            </div>

                                            <div>
                                                <p class="lh-1 flex-fill mb-1 fs-mb-18 fs-md-22">3 Months</p>
                                                <p class="lh-1 py-1 my-0 fs-mb-22 fs-md-22">
                                                    <span class="text-decoration-line-through">£22.50</span> <small
                                                        class="text-danger ms-2 fs-mb-15">Save 20%</small>
                                                </p>

                                                <p class="lh-1 mt-2 my-0 fs-mb-22 fs-md-22">Only £17.50</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="py-4 px-3 pt-3">
                                        <div class="d-flex align-items-center justify-content-start gap-2">
                                            <img src="https://cdn.platex.co.uk/assets/checkmark.png"
                                                class="d-block w-100" style="max-width: 20px" />
                                            <p class="my-0">Flexible monthly plan</p>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-start gap-2">
                                            <img src="https://cdn.platex.co.uk/assets/checkmark.png"
                                                class="d-block w-100" style="max-width: 20px" />
                                            <p class="my-0">No commission or buyers premium</p>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-start gap-2">
                                            <img src="https://cdn.platex.co.uk/assets/checkmark.png"
                                                class="d-block w-100" style="max-width: 20px" />
                                            <p class="my-0">Seen first</p>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-start gap-2">
                                            <img src="https://cdn.platex.co.uk/assets/checkmark.png"
                                                class="d-block w-100" style="max-width: 20px" />
                                            <p class="my-0">Mailed out to members</p>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-start gap-2">
                                            <img src="https://cdn.platex.co.uk/assets/checkmark.png"
                                                class="d-block w-100" style="max-width: 20px" />
                                            <p class="my-0">Cancel at any time</p>
                                        </div>

                                        <a [routerLink]="'/sell/1-month'"
                                            class="mt-3 btn d-flex align-items-center justify-content-center px-3 rounded-pill gap-1 mx-auto"
                                            style="background-color: #3791A8; max-width: 200px;">
                                            <span class="fs-md-18 fsm-mb-16 text-white p-0">Choose 3 Months</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <!-- END 3 MONTHS -->
                        </ng-template>
                        <ng-template swiperSlide>
                            <!-- 6 MONTHS -->
                            <div class="mb-4 mb-md-0 mx-auto" style="max-width: 310px; min-width: 300px;">
                                <div class="h-100 bg-white rounded-lg overflow-hidden d-flex flex-column justify-content-between shadow-sm mb-md-0 mb-0 border border-1"
                                    style="border-color: transparent !important;">
                                    <div class="py-4 px-3 pb-3" style="background-color: #D8E4E3;">
                                        <div class="d-flex justify-content-start align-items-start gap-1">
                                            <div>
                                                <img src="https://cdn.platex.co.uk/pages/landing/calendar_world.png"
                                                    class="d-block w-100 ms-0 me-auto" style="max-width: 45px" />
                                            </div>

                                            <div>
                                                <p class="lh-1 flex-fill mb-1 fs-mb-18 fs-md-22">6 Months</p>
                                                <p class="lh-1 py-1 my-0 fs-mb-22 fs-md-22">
                                                    <span class="text-decoration-line-through">£00.00</span> <small
                                                        class="text-danger ms-2 fs-mb-15">Save 30%</small>
                                                </p>

                                                <p class="lh-1 mt-2 my-0 fs-mb-22 fs-md-22">Only £31.50</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="py-4 px-3 pt-3">
                                        <div class="d-flex align-items-center justify-content-start gap-2">
                                            <img src="https://cdn.platex.co.uk/assets/checkmark.png"
                                                class="d-block w-100" style="max-width: 20px" />
                                            <p class="my-0">Flexible monthly plan</p>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-start gap-2">
                                            <img src="https://cdn.platex.co.uk/assets/checkmark.png"
                                                class="d-block w-100" style="max-width: 20px" />
                                            <p class="my-0">No commission or buyers premium</p>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-start gap-2">
                                            <img src="https://cdn.platex.co.uk/assets/checkmark.png"
                                                class="d-block w-100" style="max-width: 20px" />
                                            <p class="my-0">Seen first</p>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-start gap-2">
                                            <img src="https://cdn.platex.co.uk/assets/checkmark.png"
                                                class="d-block w-100" style="max-width: 20px" />
                                            <p class="my-0">Mailed out to members</p>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-start gap-2">
                                            <img src="https://cdn.platex.co.uk/assets/checkmark.png"
                                                class="d-block w-100" style="max-width: 20px" />
                                            <p class="my-0">Cancel at any time</p>
                                        </div>

                                        <a [routerLink]="'/sell/12-months'"
                                            class="mt-3 btn d-flex align-items-center justify-content-center px-3 rounded-pill gap-1 mx-auto"
                                            style="background-color: #D8E4E3; max-width: 200px;">
                                            <span class="fs-md-18 fsm-mb-16 p-0">Choose Annual</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <!-- END 6 MONTHS -->
                        </ng-template>
                        <ng-template swiperSlide>
                            <!-- YEARLY -->
                            <div class="mb-4 mb-md-0 mx-auto" style="max-width: 310px; min-width: 300px;">
                                <div class="h-100 bg-white rounded-lg overflow-hidden d-flex flex-column justify-content-between shadow-sm mb-md-0 mb-0 border border-1"
                                    style="border-color: transparent !important;">
                                    <div class="py-4 px-3 pb-3" style="background-color: #D8E4E3;">
                                        <div class="d-flex justify-content-start align-items-start gap-1">
                                            <div>
                                                <img src="https://cdn.platex.co.uk/pages/landing/calendar_world.png"
                                                    class="d-block w-100 ms-0 me-auto" style="max-width: 45px" />
                                            </div>

                                            <div>
                                                <p class="lh-1 flex-fill mb-1 fs-mb-18 fs-md-22">12 Months</p>
                                                <p class="lh-1 py-1 my-0 fs-mb-22 fs-md-22">
                                                    <span class="text-decoration-line-through">£90.00</span> <small
                                                        class="text-danger ms-2 fs-mb-15">Save 40%</small>
                                                </p>

                                                <p class="lh-1 mt-2 my-0 fs-mb-22 fs-md-22">Only £54.00</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="py-4 px-3 pt-3">
                                        <div class="d-flex align-items-center justify-content-start gap-2">
                                            <img src="https://cdn.platex.co.uk/assets/checkmark.png"
                                                class="d-block w-100" style="max-width: 20px" />
                                            <p class="my-0">Flexible monthly plan</p>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-start gap-2">
                                            <img src="https://cdn.platex.co.uk/assets/checkmark.png"
                                                class="d-block w-100" style="max-width: 20px" />
                                            <p class="my-0">No commission or buyers premium</p>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-start gap-2">
                                            <img src="https://cdn.platex.co.uk/assets/checkmark.png"
                                                class="d-block w-100" style="max-width: 20px" />
                                            <p class="my-0">Seen first</p>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-start gap-2">
                                            <img src="https://cdn.platex.co.uk/assets/checkmark.png"
                                                class="d-block w-100" style="max-width: 20px" />
                                            <p class="my-0">Mailed out to members</p>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-start gap-2">
                                            <img src="https://cdn.platex.co.uk/assets/checkmark.png"
                                                class="d-block w-100" style="max-width: 20px" />
                                            <p class="my-0">Cancel at any time</p>
                                        </div>

                                        <a [routerLink]="'/sell/12-months'"
                                            class="mt-3 btn d-flex align-items-center justify-content-center px-3 rounded-pill gap-1 mx-auto"
                                            style="background-color: #D8E4E3; max-width: 200px;">
                                            <span class="fs-md-18 fsm-mb-16 p-0">Choose Annual</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <!-- END YEARLY -->
                        </ng-template>
                    </swiper>

                    <div class="sp-dots mt-4 text-center">
                        <div class="sp-pagination-swiper-dots"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="s-spacing"></div>

    <!-- FEATURED LISTING -->

    <div class="content-container extended">
        <div class="container-fluid w-100 px-4">
            <div class="row px-0">
                <p class="px-4 fs-md-26 fs-mb-24 lh-sm fw-bolder text-start h-mb">Recently Sold</p>
            </div>
            <div class="px-0 row">
                <div style="max-width: 1180px;" class="mx-auto">
                    <pla-featured-plates [featureType]="rsFeatured" [showTitle]="false" [priceFontSize]="16"
                        [maxCount]="10" [sortBy]="'price'" [plateClass]="'flat shaded'" [mobileTiny]="false"
                        [plateSize]="'nr-s'" [showIndex]="false"></pla-featured-plates>
                </div>
            </div>
        </div>
    </div>

    <div class="s-spacing"></div>

    <!-- FAQ's -->

</div>