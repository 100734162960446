export enum PlateOwner {
  NONE = -1,
  DVLA = 0,
  PRIVATE = 1,
  PLATEX = 2,
  RESELLER = 3,
  DVLA_PENDING = 4,
  CURRENT_DVLA_AUCTION = 5,
  DVLA_UNSOLD = 6
}
