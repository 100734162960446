<!-- LOCKED CONTENT -->
<ng-container *ngIf="contentLocked; else contentUnlocked">
  <!-- SHOW BUTTON -->
  <ng-container *ngIf="showButton; else customContent">
    <ng-container [ngSwitch]="buttonType">
      <button *ngSwitchCase="'button'" (click)="requestSignupModal(undefined)" mat-flat-button>
        {{ loginRequiredMessage }}
      </button>
      <span class="label-button" (click)="requestSignupModal(undefined)" *ngSwitchDefault>{{ loginRequiredMessage }}</span>
    </ng-container>
  </ng-container>

  <!-- Pass from parent -->
  <ng-template #customContent>
    <div>
      <div (click)="clickedContent()" class="{{customClasses}}">
        <ng-content select="[lockedContent]"></ng-content>
      </div>
    </div>

    <ng-content select="[lockedNonInteract]"></ng-content>
  </ng-template>
</ng-container>

<!-- unlocked content -->
<ng-template #contentUnlocked>
  <ng-content select="[unlockedContent]"></ng-content>
</ng-template>