import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompareResult } from '../compare-result';
import { HTTPCONSTANTS } from '../http/http-constants';
import { MarketPriceData, MarketShare, MarketValueData } from '../models/market-data';
import { PlateSeller } from '../models/thirdPartySeller';

@Injectable({ providedIn: 'root' })
export class CompareService {
  constructor(private httpClient: HttpClient) {}

  public GetMarketValueData(registration: string): Observable<MarketValueData> {
    return this.httpClient.get<MarketValueData>(
      `${HTTPCONSTANTS.plato_search_protocol}${HTTPCONSTANTS.plato_search_base}?process=market-value-data&registration=${registration}`
    );
  }

  public GetMarketShare(): Observable<MarketShare> {
    return this.httpClient.get<MarketShare>(
      `${HTTPCONSTANTS.plato_search_protocol}${HTTPCONSTANTS.plato_search_base}?process=market-share`
    );
  }

  public GetMarketPriceData(registration: string): Observable<MarketPriceData> {
    return this.httpClient.get<MarketPriceData>(
      `${HTTPCONSTANTS.plato_search_protocol}${HTTPCONSTANTS.plato_search_base}?process=market-price-data&registration=${registration}`
    );
  }

  public getPlateComparison(search: string): Observable<CompareResult[]> {
    return this.httpClient.get<CompareResult[]>(
      `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/compare/${search}`
    );
  }

  public mapSellerToCompare(sellers: PlateSeller[]): CompareResult[] {
    var results: CompareResult[] = sellers.map((s) => {
      var _ = new CompareResult();
      _.site = s.site;
      _.siteLogo = s.siteLogo;
      _.status = s.status;
      _.status_class = s.status_class;
      _.url = s.url;
      _.price = s.price;
      _.finance = s.finance;
      _.finance_pretty = s.finance_pretty;
      _.transferService = s.transferService;
      _.physicalPlatePrice = s.physicalPlatePrice;
      _.trustPilotScore = s.trustPilotScore;
      _.trustPilotLink = s.trustPilotLink;
      _.trustPilotRatingCount = s.trustPilotRatingCount;
      _.message = s.message;
      _.viewName = s.viewName;
      _.domainSuffix = s.domainSuffix;
      _.calculated = s.calculated;
      _.total = s.total;
      _.platexListing = s.platexListing;
      return _;
    });
    return results;
  }

  public calculateFoundRows(
    results: CompareResult[],
    includeTransfer: boolean,
    includePhysical: boolean
  ): CompareResult[] {
    if (results == null) return [];
    results.map((r) => {
      r.calculated = false;
      r.total = r.price; // CERTIFICATE PRICE
      if (r.transferService >= 0)
        r.total += includeTransfer ? r.transferService / 100 : 0;
      if (r.physicalPlatePrice >= 0)
        r.total += includePhysical ? r.physicalPlatePrice / 100 : 0;
      r.calculated = true;

      // if (includeTransfer && r.transferService < 0) r.calculated = false;
      // if (includePhysical && r.physicalPlatePrice < 0) r.calculated = false;

      if (r.status != 'FOUND') r.calculated = false; // NOT FOUND NOTHING TO ADD, WILL SHOW N/A
      if (r.price == 0) r.calculated = false; // DONT DO POA?

      if (r.price <= 0) {
        r.total = 0; // lock it down
      }
      return r;
    });
    return results;
  }

  public calculateSellerRows(
    results: PlateSeller[],
    includeTransfer: boolean,
    includePhysical: boolean
  ): PlateSeller[] {
    var x: PlateSeller[] = JSON.parse(JSON.stringify(results));
    x.map((r: PlateSeller) => {
      r.registrationPrice = r.totalPrice; // CERTIFICATE PRICE
      r.totalPrice = r.registrationPrice;
      if (r.transferService >= 0)
        r.totalPrice += includeTransfer ? r.transferService : 0;
      if (r.physicalPlatePrice >= 0)
        r.totalPrice += includePhysical ? r.physicalPlatePrice : 0;
      return r;
    });

    x = x.filter((r: PlateSeller) => {
      if (includeTransfer && r.transferService <= 0) return false;
      if (includePhysical && r.physicalPlatePrice <= 0) return false;
      return true;
    });

    return x;
  }
}
