import { Component, OnInit } from '@angular/core';
import { FeatureType } from 'src/app/components/featured-plates/featured-plates.component';
import BlogPost from 'src/app/models/blogPost';
import { BlogService } from 'src/app/services/blog-service';

@Component({
  selector: 'pla-blog-landing-page',
  templateUrl: './blog-landing-page.component.html',
  styleUrls: ['./blog-landing-page.component.scss'],
})
export class BlogLandingPageComponent implements OnInit {
  public rsFeatured = new FeatureType('Recently Sold', 'recently-sold');
  public raFeatured = new FeatureType('Recently Added', 'recently-added');

  public blogPosts: BlogPost[] = [];

  constructor(private blogService: BlogService) {
    this.blogService.GetArticles().subscribe((posts: BlogPost[]) => {
      this.blogPosts = posts;
    });
  }

  ngOnInit(): void {}
}
