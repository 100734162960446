import { Component, EventEmitter, OnInit } from '@angular/core';
import { DropdownOption, DropdownConfig } from 'src/app/components/custom-dropdown/custom-dropdown.component';
import { FaqBuilder, InfoDropdown } from 'src/app/components/faq/faq.component';
import { FeatureType } from 'src/app/components/featured-plates/featured-plates.component';
import { SidebarFilterConfig, SidebarFilterGroup, SidebarFilter } from 'src/app/components/side-bar-filters/side-bar-filters.component';
import { StickyConfig, StickyLinkGroup, StickyLink } from 'src/app/components/sticky-tabs/sticky-tabs.component';
import { HttpLoadingResp } from 'src/app/models/httpLoadingResp';
import { Registration } from 'src/app/models/registration';
import { RegistrationService } from 'src/app/services/registration-service';
import { SearchReqResponse, SearchService } from 'src/app/services/search-service';
import { SearchFilters } from '../search-results-page/search-results-page.component';

@Component({
  selector: 'pla-under-one-hundred',
  templateUrl: './under-one-hundred.component.html',
  styleUrls: ['./under-one-hundred.component.scss']
})
export class UnderOneHundredComponent implements OnInit {

  public searchTrigger: EventEmitter<void> = new EventEmitter<void>();
  public stickyLinkConfig: StickyConfig = new StickyConfig(
    [
      new StickyLinkGroup([
        new StickyLink('Results', 'results', true),
        new StickyLink('Q&As', 'faq', false),
      ].filter(x => x != null)),
    ],
    new EventEmitter<void>()
  );
  public termSorting: DropdownOption = new DropdownOption('HIGH-TO-LOW', 'Price High to Low');
  public mobileFilterToggle: EventEmitter<boolean> = new EventEmitter<boolean>();;
  public sortTrigger: EventEmitter<void> = new EventEmitter<void>();
  public filterChange: EventEmitter<SearchFilters> = new EventEmitter<SearchFilters>();
  public filterUpdate: EventEmitter<SidebarFilterConfig<any>> =
    new EventEmitter<SidebarFilterConfig<any>>();
  public sortDropdownConfig: DropdownConfig = new DropdownConfig('Sort By', 'swap_vert', [
    new DropdownOption('A-Z', 'A-Z'),
    new DropdownOption('Z-A', 'Z-A'),
    new DropdownOption('LOW-TO-HIGH', 'Price Low to High'),
    new DropdownOption('HIGH-TO-LOW', 'Price High to Low')
  ], {
    initialValue: this.termSorting
  });

  public searchFilters: SidebarFilterConfig<SearchFilters> = new SidebarFilterConfig<SearchFilters>(
    [
      new SidebarFilterGroup('Sort By', [
        new SidebarFilter('sort', 'Sort By', 'sortBy', {
          selectConfig: this.sortDropdownConfig
        })
      ], { visible: true, customClass: 'd-block d-lg-none' }),
    ], this.filterChange);

  public raFeatured = new FeatureType('Recently Added', 'recently-added');
  private allResults: Registration[] = [];
  public results: HttpLoadingResp<SearchReqResponse> = new HttpLoadingResp(true, undefined);
  public faq: InfoDropdown[] = [
    FaqBuilder.build('faq_1', 'What are the cheapest options for private number plates?', [
      '<p>The most affordable private number plates often include dateless registrations from Northern Ireland, which are identifiable by the inclusion of an "I" or "Z" in the first three letters (e.g.,"AIZ 1234" or "GHI 5678"). These plates are especially popular due to their low cost and lack of age identifiers, making them suitable for all vehicles regardless of age.</p>'
    ]),

    FaqBuilder.build('faq_2', 'Can I get a private plate for under £200?', [
      '<p>Yes, you absolutely can! That’s why we created this page—to help you find the most affordable private plates from across the market. With PlateX, you can easily filter private plate options by price, ensuring you stay within budget, and even refine the results to include meaningful letters or numbers. Whether you’re searching for a personal plate with your initials, a special date, or lucky numbers, PlateX makes it easy to find a budget-friendly plate that suits your needs.</p>'
    ]),

    FaqBuilder.build('faq_3', 'Do cheaper private plates have any restrictions or drawbacks?', [
      '<p>If you choose a suffix, prefix, or current style private plate, you’ll be restricted by the year identifier included in the registration. This means you cannot display these plates on a vehicle that is older than the registration itself.</p>' +
      '<p>However, with dateless registrations or Northern Irish private plates, this restriction does not apply. These plates can be assigned to any vehicle, regardless of its age. The only drawback is that dateless and Northern Irish plates are often longer, typically featuring seven characters and occasionally six.</p>' +
      '<p>To find a private plate that suits your needs, you can use our filters to enter meaningful letters and numbers, making it easy to personalise even these more affordable options.</p>'
    ]),

    FaqBuilder.build('faq_4', 'Are there additional costs beyond the purchase price?', [
      '<p>At PlateX, we endeavour to display prices from dealers that include the £80 DVLA transfer charge. However, it’s always a good idea to confirm this when you are redirected to the dealer’s page to complete your checkout. Additionally, some dealers may charge for extras, such as administration fees or the cost of manufacturing the physical plates, so be sure to review the final details before purchasing your private plate.</p>'
    ]),

    FaqBuilder.build('faq_5', 'How do I transfer a cheap private plate to my vehicle?', [
      '<p>After purchasing your private plate, you’ll receive either a certificate of entitlement (V750) or a retention document (V778). You can assign the plate to your vehicle through the DVLA’s online service or by post, following the instructions provided. The process is straightforward and usually takes a few days to complete.</p>' +
      '<p>Many dealers also offer a fully managed transfer service, where they handle all the paperwork on your behalf for an additional fee. They can also supply you with the physical plates displaying your new registration, making the process even more convenient.</p>'
    ]),

    FaqBuilder.build('faq_6', 'Can I sell my affordable private plate in the future?', [
      '<p>Yes, you can sell your private plate at any time in the future. However, it’s important to note that affordable private plates—especially those purchased for under £200—are less likely to significantly appreciate in value. While some plates with unique or desirable combinations may fetch a higher price, lower-cost plates are typically better suited for personalisation rather than as an investment.</p>'
    ]),

    FaqBuilder.build('faq_7', 'Will a cheap private plate affect my insurance premiums?', [
      '<p>A private plate, whether cheap or expensive, typically does not impact your insurance premiums. However, it’s crucial to inform your insurer about the new registration to ensure your policy details are accurate and up to date. Failure to do so could lead to issues with claims or renewals.</p>' +
      '<p>As a friendly reminder, don’t forget to update other services linked to your vehicle\'s registration, such as residents\' parking permits, congestion charge Auto Pay, and any subscriptions or accounts that use your old registration. Keeping these details current will save you time and avoid potential fines or service interruptions.</p>'
    ]),

    FaqBuilder.build('faq_8', 'Are there financing options available for cheap private plates?', [
      '<p>While financing is typically offered for higher-priced private plates, there are dealers who provide flexible payment options for affordable plates. When you find your ideal cheap private plate, you can use PlateX\'s free price comparison service to ensure you\'re getting the best deal. Additionally, our platform allows you to filter for dealers that offer finance or payment options like Klarna, enabling you to split the cost into manageable payments. This makes securing your perfect plate even easier and more affordable.</p>'
    ]),

    FaqBuilder.build('faq_9', 'How can I ensure the private plate I choose is legal?', [
      '<p>To ensure your private plate complies with the law, it must adhere to DVLA regulations regarding formatting, spacing, and character style. Legal plates must use the standard UK font, with correctly spaced letters and numbers, and should not include decorative symbols or modifications that make the plate difficult to read or misleading.</p>' +
      '<p>For example:</p>' +
      '<ul>' +
      '<li>Spacing: "AB12 CDE" is legal, but "AB12CDE" or "A B12CDE" is not.</li>' +
      '<li>Font: Plates must use the prescribed font style; stylised or italic fonts are prohibited.</li>' +
      '</ul>' +
      '<p>Additionally, plates must display the BS AU 145e standard mark, confirming they meet reflective and durability requirements. When purchasing your personal plate, always ensure the dealer follows these guidelines and supplies plates that meet legal standards.</p>' +
      '<p>If you\'re unsure, PlateX helps you find reputable dealers to ensure your private plate is fully compliant with UK law.</p>'
    ]),

    FaqBuilder.build('faq_10', 'Where can I find cheap private plates for sale?', [
      '<p>You can find cheap private plates through several sources:</p>' +
      '<ul>' +
      '<li>The DVLA, which offers affordable plates starting from just £250 (including the £80 assignment fee).</li>' +
      '<li>Over 40 private dealers, who list a wide range of plates with various styles and combinations.</li>' +
      '<li>eBay, where some private plates are available for sale or auction.</li>' +
      '</ul>' +
      '<p>However, the best place to search for cheap private plates is PlateX, where all registrations offered by the DVLA and over 40 dealers are displayed in one place. Our platform provides the best search facility, allowing you to filter by price, style, and meaningful combinations to find your ideal plate quickly and easily.</p>' +
      '<p>While eBay may have plates for sale, these are often listed at a premium to cover commission and listing fees. To ensure you’re getting the best deal, always use the PlateX comparison service to check if the same plate is available at a lower price elsewhere.</p>'
    ])
  ];

  constructor(
    private searchService: SearchService,
    private registrationService: RegistrationService
  ) {
    this.filterChange.subscribe((_: SearchFilters) => {
      this.TermSortChange(this.sortDropdownConfig.options.find(o => o.value === _.sortBy), "");
    });
  }

  ngOnInit(): void {
    this.Search();
  }

  public filterSearchResults(input: string): void {
    const filteredResults = [...this.allResults].filter(r => r.registration.indexOf(input) > -1);
    this.results.Update(false, new SearchReqResponse('sub-200', filteredResults));
    this.TermSortChange(this.termSorting, "");
  }

  private Search(): void {
    this.searchService.getSub200((_: Registration[]) => {
      this.allResults = this.registrationService.formatRegistrations(_);
      this.results.Update(false, new SearchReqResponse('sub-200', this.allResults));

      this.filterUpdate.emit(this.searchFilters);
      this.TermSortChange(this.termSorting, "");
    })
  }

  public TermSortChange(e: DropdownOption, key: string): void {
    this.termSorting = e;
    var sortedData = [];
    if (this.termSorting.value === 'A-Z') sortedData = this.results.data.registrations.sort((a, b) => a.registration.localeCompare(b.registration));
    else if (this.termSorting.value === 'Z-A') sortedData = this.results.data.registrations.sort((a, b) => b.registration.localeCompare(a.registration));
    else if (this.termSorting.value === 'HIGH-TO-LOW') sortedData = this.results.data.registrations.sort((a, b) => b.price - a.price);
    else if (this.termSorting.value === 'LOW-TO-HIGH') sortedData = this.results.data.registrations.sort((a, b) => a.price - b.price);

    this.results.Update(false, new SearchReqResponse('sub-200', this.registrationService.formatRegistrations(sortedData)));
  }
}
