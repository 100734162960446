import { Component, OnInit } from '@angular/core';
import SwiperCore, {
  Navigation,
  Pagination,
  Swiper,
  SwiperOptions,
  Thumbs,
} from 'swiper';
import { Router } from '@angular/router';
import { PageStep } from 'src/app/components/page-steps/page-steps.component';
import { TabbedSection } from 'src/app/components/tabbed-section/tabbed-section.component';
import {
  InfoDropdown,
  InfoDropdownContent,
} from 'src/app/components/faq/faq.component';
import { FeatureType } from 'src/app/components/featured-plates/featured-plates.component';
SwiperCore.use([Navigation, Thumbs, Pagination]);

export class ListingPlanDescriptionItem {
  constructor(public header: string, public description: string) {}
}

export class ListingPlanButtonOptions {
  constructor(public text: string, public colour: string) {}
}

export class ListingPlanPriceOption {
  constructor(public price: number, public term: string) {}
}

export class ListingPlanOption {
  constructor(
    public title: string,
    public priceOptions: ListingPlanPriceOption[],
    public priceDescription: string,
    public button: ListingPlanButtonOptions,
    public descriptionPoints: ListingPlanDescriptionItem[],
    public visible: boolean = true
  ) {}
}

export class ListingPlanPriceBreakdown {
  constructor(public title: string, public saving: number) {}
}

export class ListingPlanBreakdown {
  constructor(
    public title: string,
    public breakdowns: ListingPlanPriceBreakdown[]
  ) {}
}

@Component({
  selector: 'pla-selling-landing-page',
  templateUrl: './selling-landing-page.component.html',
  styleUrls: ['./selling-landing-page.component.scss'],
})
export class SellingLandingPageComponent implements OnInit {
  public rsFeatured = new FeatureType('Recently Sold', 'recently-sold');
  public fFeatured = new FeatureType('Your Favourites', 'favourite', true);
  public raFeatured = new FeatureType('Recently Added', 'recently-added');

  public faq: InfoDropdown[] = [
    new InfoDropdown('plate-worth-1', 'How much is my plate worth?', true, [
      new InfoDropdownContent(
        null,
        `<p>To get the best idea of the value of a plate a comparison should be made with similar registrations that have sold or that are currently for sale and then adjust the price according to whether the registration would be more or less desirable.</p>
        <p>When you enter your registration number in the sell form we will show you similar registrations sold and for sale to help you choose the perfect price.</p>`
      ),
    ]),
    new InfoDropdown('plate-worth-2', 'How much is my plate worth?', false, [
      new InfoDropdownContent(
        null,
        `<p>To get the best idea of the value of a plate a comparison should be made with similar registrations that have sold or that are currently for sale and then adjust the price according to whether the registration would be more or less desirable.</p>
        <p>When you enter your registration number in the sell form we will show you similar registrations sold and for sale to help you choose the perfect price.</p>`
      ),
    ]),
    new InfoDropdown('plate-worth-3', 'How much is my plate worth?', false, [
      new InfoDropdownContent(
        null,
        `<p>To get the best idea of the value of a plate a comparison should be made with similar registrations that have sold or that are currently for sale and then adjust the price according to whether the registration would be more or less desirable.</p>
        <p>When you enter your registration number in the sell form we will show you similar registrations sold and for sale to help you choose the perfect price.</p>`
      ),
    ]),
  ];

  public pageSteps: PageStep[] = [
    new PageStep()
      .AddHeader('Create Your Advert!')
      .AddBody(
        'We help you pick a price with help from our market data of similar registrations for sale and recently sold.'
      ),
    new PageStep()
      .AddHeader('Respond to Enquiries')
      .AddBody(
        'Your live advert includes a contact form to allow buyers to message you directly.'
      ),
    new PageStep()
      .AddHeader('Arrange Your Sale')
      .AddBody(
        'Finalise the sale with your buyer and keep 100% of the selling price with no commission charged!'
      ),
  ];

  public pageSlides: { icon: string; header: string; body: string }[] = [
    {
      icon: 'search',
      header: 'Be Found',
      body: 'With over 68 million registrations on the market today our industry leading Smart-Search ensures your registration will be seen by the right buyers more often.',
    },
    {
      icon: 'notifications_active',
      header: 'Alerts',
      body: 'Our powerful Alert features messages buyers each time a new plate is added that matches their search giving our sellers an excellent chance to secure a quick sale!',
    },
    {
      icon: 'handshake',
      header: 'Deal Direct',
      body: 'Sell your registration as simply as you would sell your car.  Create your listing, respond to enquiries, and arrange a sale!',
    },
    {
      icon: 'balance',
      header: 'Impartial',
      body: 'Being the best personalised registration site is our business not dealing in them! We don’t own any plates you never need worry your plate will never be giving second class treatment!`',
    },
    {
      icon: 'calculate',
      header: 'Guided Valuation',
      body: 'You choose the marketing price that suits you assisted by the comparable evidence of similar plates sold and being marketed.',
    },
    {
      icon: 'percent',
      header: 'Commision Free',
      body: 'Unlike the industry standard of charging a commission of anything up to 30% of the sales price of your registration you only pay a small listing fee for your advert.',
    },
  ];

  public listingPlanOptions: ListingPlanOption[] =
    this.CreateListingPlanOptions();

  public listingPlanBreakdowns: ListingPlanBreakdown[] =
    this.CreateListingPlanBreakdowns();

  public tabSections: TabbedSection[] = [
    new TabbedSection('Personal'),
    new TabbedSection('Business'),
  ];

  constructor(private router: Router) {}

  ngOnInit(): void {}

  public tabChange(tab: TabbedSection) {
    console.log(tab);
  }

  public ViewPlanDetail(plan: ListingPlanOption): void {
    this.router.navigate(['/create-auction']);
    console.log(plan);
  }

  public visibleSlides(): number {
    return window.innerWidth <= 768 ? 1 : 3;
  }

  private CreateListingPlanOptions(): ListingPlanOption[] {
    var options: ListingPlanOption[] = [];
    options.push(
      new ListingPlanOption(
        'Basic',
        [
          new ListingPlanPriceOption(450, '/Mo'),
          new ListingPlanPriceOption(5000, '/Yr'),
        ],
        '',
        new ListingPlanButtonOptions('Begin free trial', 'turquoise'),
        [
          new ListingPlanDescriptionItem(
            'Unique class leading search algorithm',
            'ensures your plate is seen by the right buyers more often.'
          ),
          new ListingPlanDescriptionItem(
            'Seen First -',
            'Choose a keyword / initial and your plate will be displayed in the first section ensuring maximum exposure'
          ),
          new ListingPlanDescriptionItem(
            'Sellers Hub -',
            'Track listing views, respond to offers and reply to enquires. You deal directly with the buyer to end of sale.'
          ),
          new ListingPlanDescriptionItem(
            'Guided valuation -',
            'We give special comparable plates, sold and for sale, to guide you to choose the optimum price.'
          ),
          new ListingPlanDescriptionItem(
            'Flexible -',
            'Change plan or cancel at any time'
          ),
        ]
      )
    );

    options.push(
      new ListingPlanOption(
        'Sold for you',
        [new ListingPlanPriceOption(0, 'Free to List')],
        'Buyer pays the service charge fee* at point of sale which is added to the listing price',
        new ListingPlanButtonOptions('List my plate', 'cyan'),
        [
          new ListingPlanDescriptionItem(
            'Unique class leading search algorithm',
            'ensures your plate is seen by the right buyers more often.'
          ),
          new ListingPlanDescriptionItem(
            'Seen First -',
            'Choose a keyword / initial and your plate will be displayed in the first section ensuring maximum exposure'
          ),
          new ListingPlanDescriptionItem(
            'Sellers Hub -',
            'Track listing views, respond to offers and reply to enquires. You deal directly with the buyer to end of sale.'
          ),
          new ListingPlanDescriptionItem(
            'Guided valuation -',
            'We give special comparable plates, sold and for sale, to guide you to choose the optimum price.'
          ),
          new ListingPlanDescriptionItem(
            'Flexible -',
            'Change plan or cancel at any time'
          ),
        ],
        false
      )
    );
    return options;
  }

  private CreateListingPlanBreakdowns(): ListingPlanBreakdown[] {
    var options: ListingPlanBreakdown[] = [];
    options.push(
      new ListingPlanBreakdown(
        'A plate sold for £500 after 6 months marketing',
        [
          new ListingPlanPriceBreakdown(
            'BASIC plan at £4.50 / month will return to you',
            47300
          ),
          new ListingPlanPriceBreakdown(
            'SOLD FOR YOU plan will return to you',
            38500
          ),
        ]
      )
    );

    options.push(
      new ListingPlanBreakdown(
        'A plate sold for £1,000 after 1 year marketing',
        [
          new ListingPlanPriceBreakdown(
            'BASIC plan at £45 / year will return to you',
            95000
          ),
          new ListingPlanPriceBreakdown(
            'SOLD FOR YOU plan will return to you',
            79500
          ),
        ]
      )
    );

    options.push(
      new ListingPlanBreakdown(
        'A plate sold for £8,000 after 6 months marketing',
        [
          new ListingPlanPriceBreakdown(
            'BASIC plan at £4.50 x 2 years will return to you',
            791000
          ),
          new ListingPlanPriceBreakdown(
            'SOLD FOR YOU plan will return to you',
            770500
          ),
        ]
      )
    );
    return options;
  }
}
