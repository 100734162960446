import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pla-how-to-transfer-a-registration',
  templateUrl: './how-to-transfer-a-registration.component.html',
  styleUrls: ['./how-to-transfer-a-registration.component.scss']
})
export class HowToTransferARegistrationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
