<div *ngIf="!loading; else loadingSpinner">
  <ng-container *ngFor="let notifyPlate of notifies">
    <div class="notify-row">
      <pla-search-result-row
        [plate]="notifyPlate"
        [lastRow]="false"
        [showRating]="false"
        [allowShowMore]="false"
        [whitePlate]="false"
        [showPrice]="false"
        [showButton1]="false"
        [showButton2]="false"
        [showButton3]="true"
        [customText]="notifySafeDate(notifyPlate)"
      ></pla-search-result-row>
    </div>
  </ng-container>
</div>

<ng-template #loadingSpinner>
  <div class="full spinner-loader">
    <div class="py-3">
      <mat-progress-spinner
        class="spinner cyan"
        mode="indeterminate"
        [color]="'#327b8d'"
        [diameter]="60"
      ></mat-progress-spinner>
    </div>
    <span>Loading Notifies...</span>
  </div>
</ng-template>
