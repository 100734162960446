<div class="page">
  <div class="page-description-container"></div>
  <div class="main-content-container content-container" id="results-section">
    <div class="center-content-container">
      <div class="pla_card mobile spacing no-card no-left no-right no-bottom">
        <div class="header-container">
          <div class="page-selector">
            <ng-container *ngFor="let accountPage of accountPages">
              <div class="selector" *ngIf="selectedPage && accountPage">
                <button mat-flat-button [ngClass]="{
                    'platex-button': selectedPage.id === accountPage.id,
                    'platex-border-button greyed':
                      selectedPage.id !== accountPage.id
                  }" [disabled]="!accountPage.enabled" (click)="changePageHandler(accountPage)">
                  <span style="font-weight: bold">{{ accountPage.name }}</span>
                </button>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="page-content" *ngIf="selectedPage">
          <ng-container [ngSwitch]="selectedPage.id">
            <pla-account-favourites *ngSwitchCase="'favourites'"></pla-account-favourites>
            <pla-account-notify *ngSwitchCase="'notify-me'"></pla-account-notify>
            <pla-account-alerts *ngSwitchCase="'alerts'"></pla-account-alerts>
            <pla-account-messages *ngSwitchCase="'messages'"></pla-account-messages>
            <pla-account-selling *ngSwitchCase="'selling'"></pla-account-selling>
            <pla-admin-page *ngSwitchCase="'admin'"></pla-admin-page>
            <pla-account-details *ngSwitchDefault></pla-account-details>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>