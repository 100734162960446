import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin-service';

export class AdminWordbankWord {
  public id: number;
  public term: string;
  public plate: string;
  public sold: boolean;
  public price: number;
  public filter: string;
  public owner: number;
}

@Component({
  selector: 'pla-admin-wordbank',
  templateUrl: './admin-wordbank.component.html',
  styleUrls: ['./admin-wordbank.component.scss']
})
export class AdminWordbankComponent implements OnInit {

  public showAll: boolean = false;
  public wordbankFeature: {
    loading: boolean,
    filter: string,
    res: AdminWordbankWord[]
  } = { loading: false, filter: '51', res: [] }

  constructor(private adminService: AdminService) { }

  ngOnInit(): void {
    this.SearchWordbank();
  }

  public SearchWordbank(): void {
    this.wordbankFeature.loading = true;
    this.adminService.getWordbank(this.wordbankFeature.filter, (res: AdminWordbankWord[]) => {
      this.wordbankFeature.loading = false;
      if (res == null) {
        alert('failed to complete');
      } else {
        this.wordbankFeature.res = res.sort((a, b) => a.term.localeCompare(b.term));
      }
    });
  }


  public DeleteItem(term: AdminWordbankWord): void {
    this.adminService.DeleteWordbank(term.id, () => {
      var i = this.wordbankFeature.res.findIndex(t => t.id === term.id);
      this.wordbankFeature.res.splice(i, 1);
    })
  }
}
