import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { SwiperOptions } from 'swiper';

export class PageStep {
  public header: string;
  public step: string;

  constructor() {}

  public AddHeader(header: string): PageStep {
    this.header = header;
    return this;
  }

  public AddBody(body: string): PageStep {
    this.step = body;
    return this;
  }
}

@Component({
  selector: 'pla-page-steps',
  templateUrl: './page-steps.component.html',
  styleUrls: ['./page-steps.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PageStepsComponent implements OnInit {
  @Input() public title: string = null;
  @Input() public steps: PageStep[] = [];
  @Input() public hideOnMobile: boolean = true;
  @Input() public swiperOnMobile: boolean = false;

  @Input() public backgroundColor: string = '#f4f4f4';
  @Input() public textColor: string = '#000';
  @Input() public headerColor: string = '#fff';
  @Input() public stepRoundColor: string = '#054758';
  @Input() public stepColor: string = '#fff';
  @Input() public stepLineColor: string = '#fff';
  @Input() public stepBorderColor: string = '#fff';

  public swiperNavigation = {
    nextEl: '.swiper-nav.swiper-next',
    prevEl: '.swiper-nav.swiper-prev',
  };

  public swiperConfig: SwiperOptions = {
    slidesPerView: 1,
    pagination: true,
    loop: true,
    // navigation: this.swiperNavigation,
    enabled: true,
    centeredSlides: true,
    // spaceBetween: 10,
    setWrapperSize: true,
  };

  constructor() {}

  ngOnInit(): void {}
}
