import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Registration } from '../models/registration';
import { Observable, of } from 'rxjs';
import { SynonymResult } from '../models/synonymResult';
import { HTTPCONSTANTS } from '../http/http-constants';
import { FeaturedInitials, InitialSearchResults } from '../models/initialsSearchResults';
import { PlatopediaPlate } from '../models/platopediaPlate';
import { MultiTermOption } from '../models/multiTermOption';
import { MultiTermPlate } from '../models/multiTermPlate';
import { NamePlateResponse } from '../models/namePlateResponse';
import { Country } from '../models/country';
import { BYOTermResponse } from '../models/byoTermResponse';
import { AltSearchOption } from '../models/altSearchOption';
import { environment } from 'src/environments/environment';
import { DatelessSearchResponse } from '../pages/dateless-search-page/dateless-search-page.component';
import { CompsResponse } from '../pages/create-auction/create-auction.component';
import { DVLAAuctionResp } from '../pages/dvla-auction-page/dvla-auction-page.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NewReleaseResult } from '../models/newReleaseResult';
import { RoiRegistration } from '../components/new-release-market-data/new-release-market-data.component';
import { UserService } from './user-service';
import { NamePopularity } from '../components/name-popularity-data/name-popularity-data.component';
import { RecentlySoldPlate } from '../price-history-chart/price-history-chart.component';
import { NumberOneResults } from '../pages/number-one-page/number-one-page.component';
import { SessionService } from './session-service';

export class PredictiveSearch {
  constructor(public registration: string, public price: string) { }
}

export class SearchReqResponse {
  constructor(public id: string, public registrations: Registration[]) { }
}

export class ExtendedSearchReqResponse {
  constructor(public version: string, public results: Registration[]) { }
}

@Injectable({ providedIn: 'root' })
export class SearchService {
  private userLoaded: boolean = false;
  private waitingCallbacks: any[] = [];
  // Raw search
  private lastResults: SearchReqResponse;
  private lastSearch: string;

  // Synonym search
  private lastSynonymResults: SynonymResult[];
  private lastSynonymSearch: string;

  private useLocalStorage: boolean = false;//true && !environment.production;

  constructor(private httpClient: HttpClient, private snackbar: MatSnackBar, private userService: UserService, private sessionService: SessionService) {
    if (!this.sessionService.isLoggedIn()) {
      this.userLoaded = true;
      this.waitingCallbacks.forEach(c => c());

      return;
    }
    this.userService.fetchAllUserData(() => {
      this.userLoaded = true;
      this.waitingCallbacks.forEach(c => c());
    });
  }

  private UserCheckCallback(callback: any): void {
    if (!this.sessionService.isLoggedIn()) { callback(); } else {
      if (this.userLoaded) callback();
      else this.waitingCallbacks.push(callback);
    }
  }

  public getYesterdaySoldResults(
    callback: (results: RecentlySoldPlate[]) => void
  ): void {
    this.httpClient
      .get<RecentlySoldPlate[]>(
        `${HTTPCONSTANTS.plato_search_protocol}${HTTPCONSTANTS.plato_search_base}?process=yesterday-sold`
      )
      .subscribe(
        (_: RecentlySoldPlate[]) => {
          callback(_);
        },
        () => {
          callback([]);
        }
      );
  }

  public getSub200(
    callback: (results: Registration[]) => void
  ): void {
    this.httpClient
      .get<Registration[]>(
        `${HTTPCONSTANTS.plato_search_protocol}${HTTPCONSTANTS.plato_search_base}?process=sub-200`
      )
      .subscribe(
        (_: Registration[]) => {
          callback(_);
        },
        () => {
          callback([]);
        }
      );
  }

  public getNumber1(
    callback: (results: NumberOneResults) => void
  ): void {
    this.httpClient
      .get<NumberOneResults>(
        `${HTTPCONSTANTS.plato_search_protocol}${HTTPCONSTANTS.plato_search_base}?process=number-1`
      )
      .subscribe(
        (_: NumberOneResults) => {
          callback(_);
        },
        () => {
          callback(undefined);
        }
      );
  }

  public getPredictiveSearch(
    search: string,
    callback: (results: PredictiveSearch[]) => void
  ): void {
    this.httpClient
      .get<PredictiveSearch[]>(
        `${HTTPCONSTANTS.plato_search_protocol}${HTTPCONSTANTS.plato_search_base}?process=predictive-search&term=${search}`
      )
      .subscribe(
        (_: PredictiveSearch[]) => {
          callback(_);
        },
        () => {
          callback([]);
        }
      );
  }

  public getAltSearchTerms(
    search: string,
    callback: (results: AltSearchOption[]) => void
  ): void {
    this.httpClient
      .get<AltSearchOption[]>(
        `${HTTPCONSTANTS.plato_search_protocol}${HTTPCONSTANTS.plato_search_base}?process=alt-search-options&term=${search}`
      )
      .subscribe(
        (_) => {
          callback(_);
        },
        () => {
          callback(null);
        }
      );
  }

  public getValuationPlates(
    registration: string,
    search: string,
    callback: (results: Registration[]) => void
  ): void {
    this.httpClient
      .get(
        `${HTTPCONSTANTS.plato_search_protocol}${HTTPCONSTANTS.plato_search_base}?process=valuation&registration=${registration}&term=${search}`
      )
      .subscribe(
        (_: Registration[]) => {
          callback(_);
        },
        () => {
          callback(null);
        }
      );
  }

  public extendedSearch(
    term: string,
    style: string,
    callback: (results: ExtendedSearchReqResponse[]) => void
  ): void {
    this.httpClient
      .get(
        `${HTTPCONSTANTS.plato_search_protocol}${HTTPCONSTANTS.plato_search_base}?process=extended-search&term=${term}&style=${style}`
      )
      .subscribe(
        (_: ExtendedSearchReqResponse[]) => {
          this.UserCheckCallback(() => callback(_));
        },
        () => {
          callback(null);
        }
      );
  }

  public searchRaw(
    search: string,
    callback: (results: SearchReqResponse) => void
  ): void {
    if (
      this.lastSearch != null &&
      search.toUpperCase() == this.lastSearch.toUpperCase()
    ) {
      callback(this.lastResults);
      return;
    }

    if (
      this.useLocalStorage &&
      localStorage.getItem(search.toUpperCase()) != null
    ) {
      console.log('use local results');
      var _storedResults = localStorage.getItem(search.toUpperCase());
      this.UserCheckCallback(() => callback(JSON.parse(_storedResults)))
      return;
    }

    this.httpClient
      .get<SearchReqResponse>(
        `${HTTPCONSTANTS.plato_search_protocol}${HTTPCONSTANTS.plato_search_base}?process=search_v2&term=${search}`
      )
      .subscribe(
        (res: SearchReqResponse) => {
          this.lastSearch = search;
          this.lastResults = res;
          if (this.useLocalStorage)
            localStorage.setItem(search.toUpperCase(), JSON.stringify(res)); // only in dev
          this.UserCheckCallback(() => callback(res));
        },
        () => callback(null)
      );
  }

  public searchAuction(
    search_criteria: string,
    callback: (results: DVLAAuctionResp) => void
  ): void {
    var url = `${HTTPCONSTANTS.platoProtocol}${HTTPCONSTANTS.platoBaseAddress}/search-dvla-auction/${search_criteria}`;
    if (search_criteria == null)
      url = `${HTTPCONSTANTS.platoProtocol}${HTTPCONSTANTS.platoBaseAddress}/search-dvla-auction`;
    this.httpClient.get<DVLAAuctionResp>(url).subscribe(
      (res: DVLAAuctionResp) => {
        callback(res);
      },
      () => callback(null)
    );
  }

  public searchInitials(
    initials: string,
    style: string,
    callback: (results: InitialSearchResults) => void
  ): void {
    this.httpClient
      .get<InitialSearchResults>(
        `${HTTPCONSTANTS.plato_search_protocol}${HTTPCONSTANTS.plato_search_base}?process=initials&style=${style}&term=${initials}`
      )
      .subscribe(
        (res: InitialSearchResults) => {
          this.UserCheckCallback(() => callback(res));
        },
        () => callback(null)
      );
  }

  public featuredInitials(
    callback: (results: FeaturedInitials) => void
  ): void {
    this.httpClient
      .get<FeaturedInitials>(
        `${HTTPCONSTANTS.plato_search_protocol}${HTTPCONSTANTS.plato_search_base}?process=featured-initials`
      )
      .subscribe(
        (res: FeaturedInitials) => {
          callback(res);
        },
        () => callback(null)
      );
  }

  public searchPerfect(
    term: string,
    callback: (results: Registration[]) => void
  ): void {
    this.httpClient
      .get<Registration[]>(
        `${HTTPCONSTANTS.plato_search_protocol}${HTTPCONSTANTS.plato_search_base}?process=perfect&term=${term}`
      )
      .subscribe(
        (res: Registration[]) => {
          this.UserCheckCallback(() => callback(res));
        },
        () => callback(null)
      );
  }

  public searchExact(
    term: string,
    callback: (results: Registration[]) => void
  ): void {
    this.httpClient
      .get<Registration[]>(
        `${HTTPCONSTANTS.plato_search_protocol}${HTTPCONSTANTS.plato_search_base}?process=exact&term=${term}`
      )
      .subscribe(
        (res: Registration[]) => {
          this.UserCheckCallback(() => callback(res));
        },
        () => callback([])
      );
  }

  public searchDatelessLength(
    length: string,
    search_criteria: string,
    callback: (results: DatelessSearchResponse) => void
  ): void {
    this.httpClient
      .get<DatelessSearchResponse>(
        `${HTTPCONSTANTS.plato_search_protocol}${HTTPCONSTANTS.plato_search_base}?process=dateless${search_criteria == '' ? '' : `&term=${search_criteria}`}&layout=${length}`
      )
      .subscribe(
        (res: DatelessSearchResponse) => {
          callback(res);
        },
        () => callback(null)
      );
  }

  public getNewReleaseName(
    year: string,
    callback: (results: NewReleaseResult[]) => void
  ): void {
    this.httpClient
      .get<NewReleaseResult[]>(
        `${HTTPCONSTANTS.plato_search_protocol}${HTTPCONSTANTS.plato_search_base}?process=new-release-name&filters=${year}`
      )
      .subscribe(
        (res: NewReleaseResult[]) => {
          this.UserCheckCallback(() => callback(res))
        },
        () => callback(null)
      );
  }

  public getNewRelease(
    year: string,
    filter: string,
    callback: (results: NewReleaseResult[]) => void
  ): void {
    this.httpClient
      .get<NewReleaseResult[]>(
        `${HTTPCONSTANTS.plato_search_protocol}${HTTPCONSTANTS.plato_search_base}?process=new-release&filters=new-${year}-release`
      )
      .subscribe(
        (res: NewReleaseResult[]) => {
          this.UserCheckCallback(() => callback(res))
        },
        () => callback(null)
      );
  }

  public getNamePopularity(
    callback: (results: NamePopularity[]) => void
  ): void {
    this.httpClient
      .get<NamePopularity[]>(
        `${HTTPCONSTANTS.plato_search_protocol}${HTTPCONSTANTS.plato_search_base}?process=name-popularity`
      )
      .subscribe(
        (res: NamePopularity[]) => {
          callback(res);
        },
        () => callback(null)
      );
  }

  public getInitialPopularity(
    callback: (results: { letterFirst: NamePopularity[], numberFirst: NamePopularity[] }) => void
  ): void {
    this.httpClient
      .get<{ letterFirst: NamePopularity[], numberFirst: NamePopularity[] }>(
        `${HTTPCONSTANTS.plato_search_protocol}${HTTPCONSTANTS.plato_search_base}?process=initials-popularity`
      )
      .subscribe(
        (res: { letterFirst: NamePopularity[], numberFirst: NamePopularity[] }) => {
          callback(res);
        },
        () => callback(null)
      );
  }

  public getNewReleaseROI(
    year: string,
    callback: (results: RoiRegistration[]) => void
  ): void {
    this.httpClient
      .get<RoiRegistration[]>(
        `${HTTPCONSTANTS.plato_search_protocol}${HTTPCONSTANTS.plato_search_base}?process=full-roi&filters=${year}`
        //`${HTTPCONSTANTS.plato_search_protocol}${HTTPCONSTANTS.plato_search_base}?process=new-release-roi&filters=${year}`
      )
      .subscribe(
        (res: RoiRegistration[]) => {
          callback(res);
        },
        () => callback(null)
      );
  }

  public searchplatopedia(
    queryString: string,
    callback: (filters: string, results: PlatopediaPlate[]) => void
  ): void {
    this.httpClient
      .get<PlatopediaPlate[]>(
        `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/platopedia/words${queryString}`
      )
      .subscribe(
        (res: PlatopediaPlate[]) => {
          this.UserCheckCallback(() => callback(queryString, res))
        },
        () => callback(queryString, null)
      );
  }

  public deletePlateopediaWord(word: string, registration: string): void {
    this.httpClient
      .delete(
        `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/platopedia/word/${word}/${registration}`
      )
      .subscribe(
        () => {
          this.snackbar.open(`Deleted ${word} from word list`);
        },
        () => {
          alert('failed to delete term');
        }
      );
  }

  public fetchMultiTermOptions(
    tag: string,
    callback: (results: MultiTermOption[]) => void
  ): void {
    this.httpClient
      .get<MultiTermOption[]>(
        `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/search/multi-term/options/${tag}`
      )
      .subscribe(
        (res: MultiTermOption[]) => {
          callback(res);
        },
        () => callback(null)
      );
  }

  public fetchMultiTermResults(
    term: string,
    callback: (results: MultiTermPlate[]) => void
  ): void {
    this.httpClient
      .get<MultiTermPlate[]>(
        `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/search/multi-term/${term}/results`
      )
      .subscribe(
        (res: MultiTermPlate[]) => {
          callback(res);
        },
        () => callback(null)
      );
  }

  public getNameCountries(callback: (results: Country[]) => void): void {
    this.httpClient
      .get<Country[]>(
        `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/search/name/countries`
      )
      .subscribe(
        (res: Country[]) => {
          callback(res);
        },
        () => callback(null)
      );
  }

  public fetchNamePlates(
    pageIndex: number,
    countryCode: string,
    firstCharacter: string,
    callback: (results: NamePlateResponse) => void
  ): void {
    this.httpClient
      .get<NamePlateResponse>(
        `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/search/name/results/${countryCode}/${firstCharacter}`,
        {
          headers: new HttpHeaders({
            pageIndex: pageIndex.toString(),
          }),
        }
      )
      .subscribe(
        (res: NamePlateResponse) => {
          callback(res);
        },
        () => callback(null)
      );
  }

  public hideNamePlate(id: string): void {
    this.httpClient
      .get<Registration[]>(
        `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/search/name/${id}/hide`
      )
      .subscribe(
        () => { },
        () => alert('error')
      );
  }

  public hideMTPlate(id: string): void {
    this.httpClient
      .get<Registration[]>(
        `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/search/multi-term/${id}/hide`
      )
      .subscribe(
        () => { },
        () => alert('error')
      );
  }

  public searchSynonym(
    search: string,
    callback: (results: SynonymResult[]) => void
  ): void {
    if (search == this.lastSynonymSearch) {
      callback(this.lastSynonymResults);
      return;
    }
    this.httpClient
      .get<SynonymResult[]>(
        `${HTTPCONSTANTS.plato_search_protocol}${HTTPCONSTANTS.plato_search_base}?process=synonym&term=${search}`
      )
      .subscribe(
        (res: SynonymResult[]) => {
          this.lastSynonymSearch = search;
          this.lastSynonymResults = res;
          callback(res);
        },
        () => callback(null)
      );
  }

  public searchBYO(
    style: string,
    prefix: string,
    numbers: string,
    letters: string,
    callback: (results: Registration[]) => void
  ): void {
    this.httpClient
      .get<Registration[]>(
        `${HTTPCONSTANTS.plato_search_protocol}${HTTPCONSTANTS.plato_search_base}?process=byo&style=${style}&term=${prefix}/${numbers}/${letters}`
      )
      .subscribe(
        (res: Registration[]) => {
          callback(res);
        },
        () => callback([])
      );
  }

  public getBYOTerm(term: string, callback: (result: BYOTermResponse) => void) {
    this.httpClient
      .get<BYOTermResponse>(
        `${HTTPCONSTANTS.platoProtocol}${HTTPCONSTANTS.platoBaseAddress}/byo-term/${term}`
      )
      .subscribe(
        (res: BYOTermResponse) => {
          callback(res);
        },
        () => callback(null)
      );
  }

  public scorePlate(plate: string, term: string): Observable<any> {
    return this.httpClient.get<any>(
      `${HTTPCONSTANTS.platoProtocol}${HTTPCONSTANTS.platoBaseAddress}/score/${plate}/${term}`
    );
  }
}
