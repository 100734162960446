<div id="sticky-bby" [ngClass]="{'position-sticky': config.metaData.sticky }" class="{{classes}}"
  [ngStyle]="{ 'background': config.metaData.backgroundColour }" style="top: 54px; z-index: 90; overflow-x: auto;">
  <div id="{{ elid }}-chips-container"
    class="d-flex align-items-stretch justify-content-start gap-3 gap-md-3 pe-2 ps-3 ps-md-1 pe-md-1">
    <ng-content select="[extraChips1]"></ng-content>
    <ng-content select="[extraChips2]"></ng-content>
    <ng-container *ngFor="let group of groups; let last = last">
      <ng-container *ngFor="let link of group.links; let lastInGroup = last">
        <div class="flex-shrink-0" [ngClass]="{'pe-3': last && lastInGroup }">
          <div *ngIf="!link.hidden" [id]="link.anchor.replace('/', '').replace('/', '-')"
            class="px-3 px-md-3 py-2 rounded-pill hover-interact pointer" [ngClass]="{'selected': link.active }"
            [style.background]="link.active ? config.metaData.selectedStyle.backgroundColour : config.metaData.defaultStyle.backgroundColour"
            [style.borderColor]="link.active ? config.metaData.selectedStyle.borderColour : config.metaData.defaultStyle.borderColour"
            [style.borderWidth]="(link.active ? config.metaData.selectedStyle.borderWidth : config.metaData.defaultStyle.borderWidth) + 'px'"
            style="cursor: pointer; border-style: solid;" (click)="ChangeLink(link)">
            <small class="my-0 d-block lh-sm {{
          link.active ? 'fs-md-14' : 'fs-md-14'
        }}" [style.color]="link.active ? config.metaData.selectedStyle.textColour : config.metaData.defaultStyle.textColour">{{
              link.viewName }}</small>
          </div>
        </div>
      </ng-container>
      <div *ngIf="!last" style="border-color: #dee2e6 !important;" class="border-start border-1"></div>
    </ng-container>
  </div>
</div>