<div *ngIf="viewResults">
    <div>
        <pla-initials-popularity-table [results]="viewResults.numberFirst">
            <p class="fs-md-18 text-white mb-3">Numbers First Initials</p>
            <p class="fs-md-14 text-white mt-0 mb-3">
                Investing in new issue number plates can yield impressive financial returns, as illustrated in the
                table
                below. These plates were purchased at or shortly after their DVLA release and later resold at
                substantial profits.</p>
            <p class="fs-md-14 text-white mt-0 mb-3">
                ROI (Return on Investment), shown in the final column, indicates the percentage increase in value
                from
                initial purchase to resale. For example, the registration GU11TAR, bought for £399 in 2011, was
                resold
                for £9,219 in 2023, delivering a staggering ROI of 2211%. Likewise, SP19YDR, purchased for £399 in
                2019
                and sold for £6,551 in 2020, achieved an astounding 1542% ROI in just one year.</p>
            <p class="fs-md-14 text-white my-0">
                These examples highlight the potential financial gains from speculative purchases of new issue
                private
                number plates, especially those resembling popular names or words. This market research shows that,
                with
                careful selection, private plates can be a highly attractive alternative investment.</p>
        </pla-initials-popularity-table>
    </div>

    <div class="mt-5">
        <pla-initials-popularity-table [results]="viewResults.letterFirst">
            <p class="fs-md-18 text-white mb-3">Letters First Initials</p>
            <p class="fs-md-14 text-white mt-0 mb-3">
                Investing in new issue number plates can yield impressive financial returns, as illustrated in the
                table
                below. These plates were purchased at or shortly after their DVLA release and later resold at
                substantial profits.</p>
            <p class="fs-md-14 text-white mt-0 mb-3">
                ROI (Return on Investment), shown in the final column, indicates the percentage increase in value
                from
                initial purchase to resale. For example, the registration GU11TAR, bought for £399 in 2011, was
                resold
                for £9,219 in 2023, delivering a staggering ROI of 2211%. Likewise, SP19YDR, purchased for £399 in
                2019
                and sold for £6,551 in 2020, achieved an astounding 1542% ROI in just one year.</p>
            <p class="fs-md-14 text-white my-0">
                These examples highlight the potential financial gains from speculative purchases of new issue
                private
                number plates, especially those resembling popular names or words. This market research shows that,
                with
                careful selection, private plates can be a highly attractive alternative investment.</p>
        </pla-initials-popularity-table>
    </div>
</div>