import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PlatexUserAlert } from 'src/app/models/platexUserAlert';
import { SessionService } from 'src/app/services/session-service';
import { UserService } from 'src/app/services/user-service';

@Component({
  selector: 'pla-account-alerts',
  templateUrl: './account-alerts.component.html',
  styleUrls: ['./account-alerts.component.scss'],
})
export class AccountAlertsComponent implements OnInit {
  public newAlert: PlatexUserAlert = new PlatexUserAlert();
  public regStyleControl: FormControl = new FormControl();
  public budgetControl: FormControl = new FormControl();
  public saleTypeControl: FormControl = new FormControl();

  public alerts: PlatexUserAlert[] = [];
  public loading: boolean = true;

  constructor(
    private sessionService: SessionService,
    private userService: UserService
  ) {
    this.loading = true;
    this.userService.alertsUpdated.subscribe((_: PlatexUserAlert[]) => {
      this.alerts = _;
      this.loading = false;
    });
    if (!this.sessionService.isLoggedIn()) return;
    this.getAlerts();
  }

  ngOnInit(): void {}

  private getAlerts(): void {
    if (this.alerts.length > 0) return;
    this.loading = true;
    this.userService.getPlatexUserAlerts((_: PlatexUserAlert[]) => {
      this.alerts = _.map((a) => {
        if (a.registrationStyle == null || a.registrationStyle == '')
          a.registrationStyle = 'All Styles';
        else
          a.registrationStyle = a.registrationStyle
            .replace('current', 'Current')
            .replace('prefix', 'Prefix')
            .replace('suffix', 'Suffix')
            .replace('dateless', 'Dateless')
            .split(',')
            .join(', ');
        return a;
      });
      this.loading = false;
    });
  }

  public addNewAlertHandler(): void {
    this.newAlert.registrationStyle = (this.regStyleControl.value as []).join(
      ','
    );
    // this.newAlert.budget = (this.budgetControl.value as []).join(',');
    // this.newAlert.saleType = this.saleTypeControl.value;

    this.userService.addAlert(this.newAlert, (_alerts: PlatexUserAlert[]) => {
      this.newAlert = new PlatexUserAlert();
      this.regStyleControl.reset();
      this.budgetControl.reset();
      this.saleTypeControl.reset();
    });
  }

  public deleteAlert(alert: PlatexUserAlert): void {
    this.userService.addAlert(alert, (_: PlatexUserAlert[]) => {
      console.log('alert removed');
    });
  }
}
