import { Component, Input, OnInit } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

@Component({
  selector: 'pla-chart-price-data',
  templateUrl: './chart-price-data.component.html',
  styleUrls: ['./chart-price-data.component.scss']
})
export class ChartPriceDataComponent implements OnInit {

  @Input() public data: {
    meta: {
      series: string,
      style: string,
      registration: string,
      comparePrice: number,
    },
    data: number[][]
  };

  constructor() { }

  ngOnInit(): void {
    // Create chart instance
    var chart = am4core.create("chart-price-data", am4charts.XYChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    // Add data
    chart.data = [{
      "category": "Lowest",
      "series": this.data.data[0][0],
      "style": this.data.data[1][0]
    }, {
      "category": "Mean",
      "series": this.data.data[0][1],
      "style": this.data.data[1][1]
    }, {
      "category": "Highest",
      "series": this.data.data[0][2],
      "style": this.data.data[1][2]
    }];

    var min = this.data.data[0][1] > this.data.data[1][1] ? this.data.data[0][1] : this.data.data[0][1];
    var max = this.data.data[1][2] < this.data.data[1][2] ? this.data.data[1][2] : this.data.data[1][2];
    var offset = min + (min * 0.01)
    var unitMax = max;//this.GetNextUnit(max + offset);


    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.minGridDistance = 40;
    categoryAxis.fontSize = 11;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = unitMax;
    valueAxis.strictMinMax = true;
    valueAxis.renderer.minGridDistance = 30;
    valueAxis.fontSize = 11;

    var series_series = chart.series.push(new am4charts.ColumnSeries());
    series_series.dataFields.valueY = "series";
    series_series.dataFields.categoryX = "category";
    series_series.name = `${this.data.meta.series}`;

    var style_series = chart.series.push(new am4charts.ColumnSeries());
    style_series.dataFields.valueY = "style";
    style_series.dataFields.categoryX = "category";
    style_series.name = `All ${this.data.meta.style} style`;

    var axisBreak = valueAxis.axisBreaks.create();
    axisBreak.startValue = min + offset;
    axisBreak.endValue = max - offset;
    let d = (axisBreak.endValue - axisBreak.startValue) / (valueAxis.max - valueAxis.min);
    axisBreak.breakSize = 0.05 * (1 - d) / d;

    let hoverState = axisBreak.states.create("hover");
    hoverState.properties.breakSize = 1;
    hoverState.properties.opacity = 0.1;
    hoverState.transitionDuration = 1500;
    axisBreak.defaultState.transitionDuration = 1000;

    let trend = chart.series.push(new am4charts.LineSeries());
    trend.dataFields.valueY = "plate";
    trend.dataFields.categoryX = "category";
    trend.strokeWidth = 1;
    trend.stroke = trend.fill = am4core.color("#FF0000");
    trend.data = [{
      "category": "Lowest",
      "plate": this.data.meta.comparePrice
    }, {
      "category": "Mean",
      "plate": this.data.meta.comparePrice
    }, {
      "category": "Highest",
      "plate": this.data.meta.comparePrice
    }];
    console.log(trend.data);
  }

  private GetNextUnit(ori: number): number {
    var __ = ori.toFixed(0).toString();
    var _ = Number.parseInt(__[0]) + 1;
    var ___ = _.toString().padEnd(__.length, '0');
    return Number.parseInt(___);
  }

}
