<!-- OVERLAY PANEL -->
<div [style.display]="opened ? 'flex' : 'none'"
    class="position-fixed bottom-0 start-0 end-0 top-0 bg-overlay align-items-stretch justify-content-between flex-column"
    style="z-index: 1000">
    <div (click)="Toggle(false)" class="flex-fill"></div>
    <!-- PANEL -->
    <div class="bg-white above-shadow-sm position-fixed bottom-0 start-0 end-0 d-flex flex-column align-items-stretch justify-content-between"
        style="
      max-height: 80vh;
      border-top-left-radius: 20px !important;
      border-top-right-radius: 20px !important;
    ">
        <!-- TITLE -->
        <div class="pt-3 px-4">
            <div class="d-flex align-items-center justify-content-end text-dark pb-3" [ngClass]="{
                'border-bottom border-1': showBorder
            }">
                <div class="flex-fill">
                    <ng-content select="[title]"></ng-content>
                </div>
                <mat-icon data-nosnippet style="color: #0A6077; fill: #0A6077;" (click)="Toggle(false)">close</mat-icon>
            </div>
        </div>

        <!-- CONTENT -->
        <div class="h-100 pt-3 pb-3 px-4" style="overflow-y: auto;">
            <ng-content></ng-content>
        </div>

        <!-- ACTIONS -->
        <div class="align-self-end flex-shrink-0 w-100">
            <div style="background-color: #3a5b64; cursor: pointer" class="w-100 py-4" (click)="Toggle(false)">
                <p class="w-100 mb-0 text-white text-center fs-md-17">{{actionText}}</p>
            </div>
        </div>
    </div>
</div>