import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pla-dateless-number-plates',
  templateUrl: './dateless-number-plates.component.html',
  styleUrls: ['./dateless-number-plates.component.scss']
})
export class DatelessNumberPlatesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
