<div class="page">
  <pla-main-nav-chips></pla-main-nav-chips>
  <div class="content-container extended">
    <div class="ps-5 side-container mb-3">
      <div class="pt-0 position-relative">
        <pla-side-nav></pla-side-nav>
      </div>

      <div *ngIf="false" class="bg-light py-3">
        <pla-featured-plates [featureType]="initialFeatured" [plateSize]="'smaller'" [priceFontSize]="16"
          [maxCount]="10" [sortBy]="'price'" [plateClass]="'flat shaded'" [showIndex]="false"></pla-featured-plates>
      </div>
    </div>
    <div class="center-content-container position-relative">
      <div class="pla_card mobile spacing no-top no-card no-left no-right position-relative">
        <div *ngIf="false">
          <!-- BREADCRUMBS -->
          <div class="w-100">
            <div
              class="border-bottom d-flex align-items-center justify-content-start gap-2 w-100 px-3 px-lg-0 pb-2 pt-0">
              <a style="color: #327b8d" class="d-block text-decoration-none" [routerLink]="['/']">Home</a>
              <span>/</span>
              <a style="color: #939393; white-space: pre" class="text-decoration-none d-block"
                [routerLink]="['/initials-search']">Initials</a>
            </div>
          </div>
        </div>

        <pla-banner-image [carBanner]="'LAMBORGHINI_DIABLO'" [mobileCarBanner]="'LAMBORGHINI_DIABLO_MB'"
          [rawImage]="true" [backgroundImage]="true" [backgroundClass]="'compare-mobile-background'">
          <div class="compare-banner-height">
            <div class="d-flex d-md-flex align-items-start justify-content-between flex-column">
              <div class="p-4 p-md-4">
                <p class="lh-sm fw-bold fs-md-28 fs-mb-24 text-white">Initials Finder</p>
              </div>
            </div>
          </div>
        </pla-banner-image>

        <div class="mt-4">
          <!--TEXT CONTENT-->
          <p class="px-4 text-start fw-bolder fs-md-20 fs-mb-18 mb-0 mx-auto lh-sm hs-mb">Make Your Monogram</p>
          <p class="px-4 text-start fs-md-16 fs-mb-14 mb-2 mb-md-3 mx-auto lh-sm">Personalising your vehicle with your
            initials on private plates is incredibly popular, and our search tool makes it even easier to find the
            perfect cherished registration within your budget. Simply enter up to three letters as your initials, and
            let us present the best matches from each style of UK registration, including current, prefix, suffix, and
            dateless formats.</p>
        </div>

        <div class="mt-4">
          <div>
            <div class="search-area inner">
              <div class="w-100 pt-0">
                <div class="px-4 px-md-3">
                  <div
                    class="d-flex align-items-center justify-content-center mx-auto rounded-2 overflow-hidden plate-background flat shaded position-relative py-2 px-2"
                    style="max-width: 300px;">

                    <div class=""><button style="max-width: 56px; color: transparent;"
                        class="w-100 fs-md-12 fs-mb-12 btn d-flex align-items-center justify-content-center px-2 rounded-0 gap-1">
                        <mat-icon data-nosnippet class="">bug_report</mat-icon>
                      </button></div>

                    <div class="w-100 mx-auto plate-code-input" style="max-width: 220px;">
                      <code-input [isCharsCode]="true" [code]="initials" [codeLength]="3" [inputType]="'text'"
                        (codeChanged)="UpdateInitialsInput($event)"></code-input>
                    </div>

                    <div class=""><button (click)="doInlineSearch()" style="max-width: 56px"
                        class="w-100 fs-md-12 fs-mb-12 btn text-dark d-flex align-items-center justify-content-center px-2 rounded-0 gap-1">
                        <mat-icon data-nosnippet class="">search</mat-icon>
                      </button></div>
                  </div>
                </div>
              </div>
            </div>
            <span class="header mx-auto d-block fw-light text-center w-100 fs-md-14 fs-mb-12 lh-sm h-mb mt-1"
              style="max-width: 600px">Enter 1-3
              letters to find your ideal initials registration.</span>
          </div>
        </div>

      </div>

      <div
        class="pla_card mobile spacing no-top no-card no-left no-right position-relative px-2 px-md-1 py-0 me-0 me-md-2">

        <div *ngIf="stickyLinkConfig && initialResults"
          class="d-flex justify-content-between align-items-stretch mt-2 bg-white position-sticky start-0 end-0"
          style="top: 54px; z-index: 1000;">
          <pla-sticky-tabs class="flex-fill w-100" [config]="stickyLinkConfig" [classes]="'pt-3 pb-3'">
            <div extraChips1
              class="flex-shrink-0 d-flex d-lg-none align-items-center justify-content-center px-3 px-md-3 py-2 rounded-pill hover-interact pointer"
              [style.background]="'#F1F4F2'" [style.borderColor]="'#F1F4F2'"
              [style.borderWidth]="stickyLinkConfig.metaData.defaultStyle.borderWidth + 'px'"
              style="cursor: pointer; border-style: solid;" (click)="mobileFilterToggle.emit(true)">
              <mat-icon data-nosnippet style="max-height: 14px; line-height: 14px !important;"
                class="my-0 d-block lh-sm text-center text-dark fs-md-20 fs-mb-18"
                [style.color]="stickyLinkConfig.metaData.defaultStyle.textColour">tune</mat-icon>
            </div>
          </pla-sticky-tabs>
          <div class="dd-raw my-3 position-relative me-3 d-none d-lg-block">
            <div class="flex-shrink-0 px-3 px-md-3 py-2 rounded-pill hover-interact pointer position-relative"
              [style.background]="'#F1F4F2'" [style.borderColor]="'#F1F4F2'"
              [style.borderWidth]="stickyLinkConfig.metaData.defaultStyle.borderWidth + 'px'"
              style="cursor: pointer; border-style: solid;" (click)="sortTrigger.emit()">
              <div class="d-flex align-items-center justify-content-center gap-2">
                <mat-icon data-nosnippet style="max-height: 14px; line-height: 14px !important; max-width: 15px;"
                  class="my-0 d-block lh-sm text-center text-dark fs-md-20 fs-mb-18"
                  [style.color]="stickyLinkConfig.metaData.defaultStyle.textColour">swap_vert</mat-icon>
                Sort
              </div>
            </div>
            <pla-custom-dropdown [containerClass]="'border border-1 shadow rounded-3 overflow-hidden mt-1'" [end]="true"
              [config]="sortDropdownConfig" [remoteOpen]="sortTrigger" (change)="SortChange($event, 'sortBy')"
              [raw]="true"></pla-custom-dropdown>
          </div>
        </div>

        <!-- RESULTS -->
        <div *ngIf="
    initialResults ||
      dlf_searching ||
      dnf_searching ||
      current_searching ||
      prefix_searching ||
      suffix_searching;
    else infoSection
  ">
          <div class="pla_card no-card mobile spacing">
            <div class="pt-2 pb-5 w-100 px-1 px-md-1 position-relative" id="dateless-letter-first-results-content">
              <div class="position-absolute" style="top: -92px" id="section-dateless-letter-first-results"></div>
              <pla-initial-section [header]="'Dateless - Letters First'"
                [results]="initialResults.dateless_letter_first" [searching]="dlf_searching"
                [resultChange]="dlf_resultsChange"></pla-initial-section>
            </div>

            <div class="pt-2 pb-5 w-100 px-1 px-md-1 position-relative" id="dateless-number-first-results-content">
              <div class="position-absolute" style="top: -92px" id="section-dateless-number-first-results"></div>
              <pla-initial-section [header]="'Dateless - Numbers First'"
                [results]="initialResults.dateless_number_first" [searching]="dnf_searching"
                [resultChange]="dnf_resultsChange"></pla-initial-section>
            </div>

            <div class="pt-2 pb-5 w-100 px-1 px-md-1 position-relative" id="current-results-content">
              <div class="position-absolute" style="top: -92px" id="section-current-results"></div>
              <pla-initial-section [header]="'Current Style'" [results]="initialResults.current"
                [searching]="current_searching" [resultChange]="c_resultsChange"></pla-initial-section>
            </div>

            <div class="pt-2 pb-5 w-100 px-1 px-md-1 position-relative" id="prefix-results-content">
              <div class="position-absolute" style="top: -92px" id="section-prefix-results"></div>
              <pla-initial-section [header]="'Prefix Style'" [results]="initialResults.prefix"
                [searching]="prefix_searching" [resultChange]="p_resultsChange"></pla-initial-section>
            </div>

            <div class="pt-2 pb-5 w-100 px-1 px-md-1 position-relative" id="suffix-results-content">
              <div class="position-absolute" style="top: -92px" id="section-suffix-results"></div>
              <pla-initial-section [header]="'Suffix Style'" [results]="initialResults.suffix"
                [searching]="suffix_searching" [resultChange]="s_resultsChange"></pla-initial-section>
            </div>
          </div>
        </div>
      </div>

      <ng-template #infoSection>
        <div class="px-0 px-md-2">

          <div class="s-spacing"></div>

          <div class="container-fluid" *ngIf="featuredInitials">
            <div class="row">
              <p class="my-4 fs-5">Featured <strong class="fw-bold">"{{featuredInitials.initial}}"</strong> Initial
                Plates</p>
            </div>
            <div class="row">
              <div class="col-6 col-md-3 mb-4 mb-md-0">
                <div class="py-3 px-2 rounded-2 h-100" style="background-color: #FEECD3;">
                  <p class="text-center fs-md-16 fs-mb-14" style="color: #9A6C08;">CURRENT</p>
                  <div>
                    <ng-container *ngFor="let plate of featuredInitials.current">
                      <div class="mb-2">
                        <pla-plate [rawPlate]="plate.registration" [showIndex]="true" [rawPrice]="plate.price / 100"
                          [customClass]="'flat shaded'" [plateSize]="'smaller'" [smallPlate]="false"
                          [mobileTiny]="true"></pla-plate>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="featuredInitials.current.length == 0">
                      <p class="fst-italic text-center" style="color: #9A6C08;">No Featured Results</p>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-3 mb-4 mb-md-0">
                <div class="py-3 px-2 rounded-2 h-100" style="background-color: #E5F1EE;">
                  <p class="text-center fs-md-16 fs-mb-14" style="color: #214953;">PREFIX</p>
                  <div>
                    <ng-container *ngFor="let plate of featuredInitials.prefix">
                      <div class="mb-2">
                        <pla-plate [rawPlate]="plate.registration" [showIndex]="true" [rawPrice]="plate.price / 100"
                          [customClass]="'flat shaded'" [plateSize]="'smaller'" [smallPlate]="false"
                          [mobileTiny]="true"></pla-plate>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="featuredInitials.prefix.length == 0">
                      <p class="fst-italic text-center" style="color: #214953;">No Featured Results</p>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-3">
                <div class="py-3 px-2 rounded-2 h-100" style="background-color: #FAF1ED;">
                  <p class="text-center fs-md-16 fs-mb-14" style="color: #860202;">SUFFIX</p>
                  <div>
                    <ng-container *ngFor="let plate of featuredInitials.suffix">
                      <div class="mb-2">
                        <pla-plate [rawPlate]="plate.registration" [showIndex]="true" [rawPrice]="plate.price / 100"
                          [customClass]="'flat shaded'" [plateSize]="'smaller'" [smallPlate]="false"
                          [mobileTiny]="true"></pla-plate>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="featuredInitials.suffix.length == 0">
                      <p class="fst-italic text-center" style="color: #860202;">No Featured Results</p>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-3">
                <div class="py-3 px-2 rounded-2 h-100" style="background-color: #FCF6E7;">
                  <p class="text-center fs-md-16 fs-mb-14" style="color: #72560D;">DATELESS</p>
                  <div>
                    <ng-container *ngFor="let plate of featuredInitials.dateless">
                      <div class="mb-2">
                        <pla-plate [rawPlate]="plate.registration" [showIndex]="true" [rawPrice]="plate.price / 100"
                          [customClass]="'flat shaded'" [plateSize]="'smaller'" [smallPlate]="false"
                          [mobileTiny]="true"></pla-plate>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="featuredInitials.dateless.length == 0">
                      <p class="fst-italic text-center" style="color: #72560D;">No Featured Results</p>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="s-spacing"></div>

          <!-- MARKET INFO -->

          <div *ngIf="false" class="pt-5 pb-5 w-100 px-3 px-md-1 position-relative" id="market-information-content">
            <div class="position-absolute" style="top: -92px" id="market-information"></div>
            <p class="my-4 fs-5">Market Insights</p>

            <div class="px-0 mx-auto">
              <div>
                <p class="fw-bold mb-2 fs-md-16">Investing in Initials Registration </p>
                <p class="fs-md-14 mb-3">
                  Investing in new, never previously registered private plates presents a unique opportunity for
                  significant added value. Each year, the DVLA releases a new series of number plates to the public at
                  fixed prices. While the DVLA does hold back several plates for auction—believing these will fetch
                  higher
                  prices through competitive bidding—there remain numerous fixed price opportunities within the
                  general
                  release for astute buyers to find valuable plates.
                </p>
              </div>

              <div class="pt-5 pb-5 w-100 px-3 px-md-1 position-relative border-top">
                <!-- TABLE AND CONTENT -->
                <pla-initials-popularity-data></pla-initials-popularity-data>
              </div>
            </div>
          </div>

          <div class="s-spacing"></div>

          <div *ngIf="faq" class="pt-5 pb-5 w-100 px-3 px-md-1 position-relative bg-white border-top" id="faq-content">
            <div class="position-absolute" style="top: -92px" id="section-faq"></div>
            <div>
              <div class="pb-5 pt-5">
                <p class="text-center text-md-start fs-md-26 fs-mb-22">
                  Frequently Asked Questions
                </p>
              </div>
              <pla-faq [faq]="faq"></pla-faq>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<!-- OVERLAY PANEL -->
<pla-footer-popout [name]="'Search page mobile filters'" [opened]="false" [externalToggle]="mobileFilterToggle"
  [showBorder]="true" [actionText]="'Apply'">
  <div title class="d-flex align-items-center justify-content-start gap-2">
    <h4 class="lh-sm fs-md-20">FILTER</h4>
    <mat-icon data-nosnippet>tune</mat-icon>
  </div>
  <pla-side-bar-filters *ngIf="filterUpdate" [showBorders]="true" [location]="'mobile-popup'"
    [filters]="filterUpdate"></pla-side-bar-filters>
</pla-footer-popout>