import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PlateDetail } from 'src/app/models/plateDetail';
import { RegistrationService } from 'src/app/services/registration-service';
import { Registration } from '../../../app/models/registration';

@Component({
  selector: 'pla-plate-modal',
  templateUrl: './plate-modal.component.html',
  styleUrls: ['./plate-modal.component.css'],
})
export class PlateModalComponent implements OnInit {
  public plate: Registration;
  public plateDetail: PlateDetail;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: Registration,
    private router: Router,
    private registrationService: RegistrationService
  ) {
    this.plate = this.data;
    this.getPlateDetail();
  }

  ngOnInit(): void {}

  private getPlateDetail(): void {
    console.log('plate modal');
    this.registrationService
      .getPlateDetail(this.plate.registration)
      .subscribe((details) => (this.plateDetail = details));
  }

  public openPlateDetailPage(): void {
    this.router.navigate([`/plate-detail/${this.plate.registration}`]);
  }

  public openPlateCompare(): void {
    window.open(
      `https://81ugpzvec7.execute-api.eu-west-2.amazonaws.com/Prod/search?process=score&term=${this.plate.search}&registration=${this.plate.registration}`,
      '_blank'
    );
  }
}
