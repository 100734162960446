export class UserHistory {
  public term: string;
  public subText: string;

  public registration: string;
  public price: string;
  public type: 'search' | 'compare';
  public time: Date;

  public Build(reg: string, price: string, type: 'search' | 'compare'): UserHistory {
    this.registration = reg;
    this.price = price;
    this.type = type;
    this.time = new Date();
    return this;
  }

  public BuildTerm(term: string, subText:string, type: 'search' | 'compare'): UserHistory {
    this.term = term;
    this.subText = subText;
    this.type = type;
    this.time = new Date();
    return this;
  }
}
