<div class="page">
  <div class="content-container px-2">
    <div class="center-content-container">
      <div class="admin-page-container">
        <h1 class="mt-2">Admin page</h1>
        <div class="mb-3">
          <pla-admin-gpt *ngIf="loaded"></pla-admin-gpt>
        </div>
        <div class="mb-3">
          <pla-admin-wordbank *ngIf="loaded"></pla-admin-wordbank>
        </div>
      </div>
    </div>
  </div>
</div>