import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HTTPCONSTANTS } from '../http/http-constants';
import { PlateRating } from '../models/plateRating';
import { Registration } from '../models/registration';
import { UserDetails } from '../models/userDetails';
import { LoggerService } from './logger-service';
import { SessionService } from './session-service';
import { TrackingService } from './tracking-service';
import { PlatexUserAlert } from 'src/app/models/platexUserAlert';
import { UserHistory } from '../models/userHistory';

@Injectable({ providedIn: 'root' })
export class UserService {
  public favouritesUpdated: EventEmitter<Registration[]> = new EventEmitter<
    Registration[]
  >();

  public notifiesUpdated: EventEmitter<Registration[]> = new EventEmitter<
    Registration[]
  >();

  public alertsUpdated: EventEmitter<PlatexUserAlert[]> = new EventEmitter<
    PlatexUserAlert[]
  >();

  public ratingsUpdated: EventEmitter<PlateRating[]> = new EventEmitter<
    PlateRating[]
  >();

  public userDetails: UserDetails = null;
  public userFavourites: Registration[] = [];
  public userNotifies: Registration[] = [];
  public userRatings: PlateRating[] = [];
  public PlatexUserAlerts: PlatexUserAlert[] = [];
  public userHistory: UserHistory[] = [];

  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    private logger: LoggerService,
    private trackingService: TrackingService
  ) { }

  public addCompareToHistory(registration: string, price: string): void {
    var hist = this.getAllHistory();
    var existing = hist.findIndex(x => x.registration == registration && x.type == 'compare');
    if (existing > -1) hist.splice(existing, 1);  // remove existing
    hist = [new UserHistory().Build(registration, price, 'compare'), ...hist]
    localStorage.setItem('u_history', JSON.stringify(hist));
  }

  public addSearchToHistory(term: string, subText: string): void {
    var hist = this.getAllHistory();
    var existing = hist.findIndex(x => x.term == term && x.type == 'search');
    if (existing > -1) hist.splice(existing, 1);  // remove existing
    hist = [new UserHistory().BuildTerm(term, subText, 'search'), ...hist]
    localStorage.setItem('u_history', JSON.stringify(hist));
  }

  public getHistory(type: 'search' | 'compare'): UserHistory[] {
    return this.getAllHistory().filter(h => h.type === type);
  }

  public clearHistory(type: 'search' | 'compare'): void {
    var hist = this.getAllHistory();
    var notOfType = hist.filter(x => x.type != type);
    localStorage.setItem('u_history', JSON.stringify(notOfType));
  }

  public getAllHistory(): UserHistory[] {
    var storageHist = localStorage.getItem('u_history');
    if (storageHist == null) return [];
    var hist: UserHistory[] = [];
    Object.assign(hist, JSON.parse(storageHist));
    return hist
  }

  public getNullableUserDetails(callback: (_: UserDetails) => void): void {
    if (this.userDetails != null) {
      callback(this.userDetails);
      return;
    }
    this.makeGetUserDetailsRequest().subscribe(
      (_: UserDetails) => {
        this.userDetails = _;
        callback(_);
      },
      () => {
        callback(null);
      }
    );
  }

  public getUserDetails(callback: (_: UserDetails) => void): void {
    if (this.userDetails != null) {
      callback(this.userDetails);
      return;
    }
    this.makeGetUserDetailsRequest().subscribe((_: UserDetails) => {
      this.userDetails = _;
      callback(_);
    });
  }

  public updateUserDetails(userDetails: UserDetails): Observable<UserDetails> {
    return this.makeUpdateUserDetailsRequest(userDetails);
  }

  public fetchAllUserData(callback: () => void): void {
    if (!this.sessionService.isLoggedIn()) {
      callback();
      return;
    }
    console.log('get user data');
    this.getPlatexUserAlerts(() => {
      console.log('got alerts');
      this.getUserFavourites(() => {
        console.log('got favs');
        this.getUserNotifies(() => {
          console.log('got notifs');
          this.getUserRatings(() => {
            console.log('got ratings');
            callback();
          });
        });
      });
    });
  }

  public getUserData(callback: () => void): void {
    callback();
    return;
    try {
      // Get favourites
      this.getUserFavourites(() => {
        // Get Notifications
        this.getUserNotifies(() => {
          this.getUserRatings(() => {
            callback();
          });
        });
      });
    } catch (exception: any) {
      this.logger.logException(exception);
      callback();
    }
  }

  public sendFeedback(
    feedback: { email: string; summary: string },
    callback: (status: boolean) => void
  ): void {
    this.http
      .post(
        `${HTTPCONSTANTS.platoProtocol}${HTTPCONSTANTS.platoBaseAddress}/feedback`,
        feedback
      )
      .subscribe(
        () => callback(true),
        () => callback(false)
      );
  }

  public getUserFavourites(
    callback: (favourites: Registration[]) => void
  ): void {
    if (this.userFavourites != null && this.userFavourites.length > 0) {
      callback(this.userFavourites);
      return;
    }
    this.makeGetFavouriteRequest().subscribe(
      (_) => {
        this.userFavourites = _;
        callback(_);
      },
      () => {
        callback(null);
      }
    );
  }

  public getPlatexUserAlerts(callback: (alerts: PlatexUserAlert[]) => void): void {
    if (this.PlatexUserAlerts != null && this.PlatexUserAlerts.length > 0) {
      callback(this.PlatexUserAlerts);
      return;
    }
    this.makeGetAlertsRequest().subscribe(
      (_) => {
        this.PlatexUserAlerts = _;
        callback(_);
      },
      () => {
        callback(null);
      }
    );
  }

  public addAlert(
    alert: PlatexUserAlert,
    callback: (alerts: PlatexUserAlert[]) => void
  ): void {
    this.http
      .post(
        `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/userdetails/alert`,
        alert
      )
      .subscribe((_: PlatexUserAlert[]) => {
        this.trackingService.TrackAlert(alert.term);
        this.PlatexUserAlerts = _;
        this.alertsUpdated.emit(this.PlatexUserAlerts);
        callback(_);
      });
  }

  public hasAlert(term: string): boolean {
    return (
      this.PlatexUserAlerts.filter(
        (ua) => ua.term.toUpperCase() == term.toUpperCase()
      ).length > 0
    );
  }

  public getUserRatings(callback: (ratings: PlateRating[]) => void): void {
    this.makeGeRatingsRequest().subscribe(
      (_) => {
        this.userRatings = _;
        callback(_);
      },
      () => {
        callback(null);
      }
    );
  }

  public localRating(registration: string, term: string): PlateRating {
    if (this.userRatings == null || this.userRatings.length == 0) return null;
    var _userRatings = this.userRatings.filter((rating: PlateRating) => {
      return rating.registration == registration && rating.term == term;
    });
    if (_userRatings.length == 0) return null;
    return _userRatings[0];
  }

  public updateUserRatings(
    registration: string,
    term: string,
    newRating: boolean
  ): void {
    var existingRating = this.localRating(registration, term);
    if (existingRating == null) {
      this.userRatings.push(new PlateRating(registration, newRating, term));
      return;
    }
    this.userRatings = this.userRatings.map((rating: PlateRating) => {
      if (rating.registration == registration && rating.term == term) {
        if (rating.rating == newRating) {
          console.log('remove rating', rating);
          return null; // removed
        }

        console.log('update rating', rating);
        rating.rating = newRating;
      }
      return rating;
    });
    this.userRatings = this.userRatings.filter((r) => r != null);
  }

  public favouriteRegistration(
    registration: Registration,
    callback: (favourites: Registration[]) => void = () => { }
  ) {
    this.makeFavouriteRequest(
      registration.formattedRegistration,
      registration.search || ''
    ).subscribe((_: Registration[]) => {
      this.trackingService.TrackFavourite(registration.registration);
      this.userFavourites = _;
      this.favouritesUpdated.emit(_);
      callback(_);
    });
  }

  public favouriteRegistrationString(
    registration: string,
    callback: (favourites: Registration[]) => void = () => { }
  ) {
    this.makeFavouriteRequest(
      registration, ''
    ).subscribe((_: Registration[]) => {
      this.trackingService.TrackFavourite(registration);
      this.userFavourites = _;
      this.favouritesUpdated.emit(_);
      callback(_);
    });
  }

  public getUserNotifies(callback: (notifies: Registration[]) => void): void {
    if (this.userNotifies != null && this.userNotifies.length > 0) {
      callback(this.userNotifies);
      return;
    }
    this.makeGetNotifiesRequest().subscribe(
      (_) => {
        this.userNotifies = _;
        callback(_);
      },
      (exception) => {
        this.logger.logException(exception);
        callback(null);
      }
    );
  }

  public notifyRegistration(
    registration: Registration,
    callback: (notifies: Registration[]) => void = () => { }
  ) {
    this.makeNotifyRequest(registration.registration).subscribe(
      (_: Registration[]) => {
        this.trackingService.TrackNotify(registration.registration);
        this.userNotifies = _;
        this.notifiesUpdated.emit(_);
        callback(_);
      }
    );
  }

  public notifyRegistrationString(
    registration: string,
    callback: (notifies: Registration[]) => void = () => { }
  ) {
    this.makeNotifyRequest(registration.replace(' ', '')).subscribe((_: Registration[]) => {
      this.userNotifies = _;
      this.notifiesUpdated.emit(_);
      callback(_);
    });
  }

  // Http

  private makeGetUserDetailsRequest(): Observable<UserDetails> {
    return this.http.get<UserDetails>(
      `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/userdetails`
    );
  }

  private makeUpdateUserDetailsRequest(
    userDetails: UserDetails
  ): Observable<UserDetails> {
    return this.http.put<UserDetails>(
      `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/userdetails/update`,
      userDetails
    );
  }

  private makeGetFavouriteRequest(): Observable<Registration[]> {
    return this.http.get<Registration[]>(
      `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/userdetails/favourites`
    );
  }

  private makeGetAlertsRequest(): Observable<PlatexUserAlert[]> {
    return this.http.get<PlatexUserAlert[]>(
      `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/userdetails/alerts`
    );
  }

  private makeGeRatingsRequest(): Observable<PlateRating[]> {
    return this.http.get<PlateRating[]>(
      `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/userdetails/ratings`
    );
  }

  private makeFavouriteRequest(
    registration: string,
    term: string
  ): Observable<Registration[]> {
    return this.http.get<Registration[]>(
      `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/userdetails/favourite/${registration.replace(' ', '')}/${term}`
    );
  }

  private makeGetNotifiesRequest(): Observable<Registration[]> {
    return this.http.get<Registration[]>(
      `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/userdetails/notifies`
    );
  }

  private makeNotifyRequest(registration: string): Observable<Registration[]> {
    return this.http.get<Registration[]>(
      `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/userdetails/notify/${registration}`
    );
  }
}
