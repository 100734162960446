import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin-service';

@Component({
  selector: 'pla-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.css'],
})
export class AdminPageComponent implements OnInit {
  public loaded: boolean = false;

  constructor(
    private adminService: AdminService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.adminService.verify().subscribe((data: { admin: boolean }) => {
      // Redirect to landing for non admin (shouldnt happen)
      if (!data.admin) {
        this.router.navigate(['/landing']);
        return;
      }

      this.loaded = data.admin;

      // lets do this
    });
  }
}
