import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export class TabbedSection {
  constructor(public title: string, public tab_id: string = null) {}
}

@Component({
  selector: 'pla-tabbed-section',
  templateUrl: './tabbed-section.component.html',
  styleUrls: ['./tabbed-section.component.scss'],
})
export class TabbedSectionComponent implements OnInit {
  @Input() public floating: boolean = true;
  @Input() public sections: TabbedSection[] = [];
  @Input() public activeIndex: number = 0;
  @Input() public backgroundColour: string = '#f5f5f5';
  @Input() public showBorder: boolean = false;
  @Output() public sectionChange: EventEmitter<TabbedSection> =
    new EventEmitter<TabbedSection>();

  public activeSection(): TabbedSection {
    if (this.sections == []) return null;
    if (this.activeIndex > this.sections.length) return null;
    return this.sections[this.activeIndex];
  }

  constructor() {}

  ngOnInit(): void {}

  public setActiveTabbedSection(index: number): void {
    this.activeIndex = index;
    this.sectionChange.emit(this.activeSection());
  }
}
