<div class="accordion accordion-flush" id="accordion">
  <ng-container *ngFor="let infoDropdown of faq">
    <div class="accordion-item border-bottom-0 mb-md-4 mb-4">
      <h2 class="accordion-header" id="{{ infoDropdown.id }}">
        <button class="accordion-button d-flex align-items-center justify-content-start gap-2 {{
            infoDropdown.open ? null : 'collapsed'
          }} py-4 shadow-sm" style="background-color: #F1F4F2" type="button" data-bs-toggle="collapse"
          [attr.data-bs-target]="'#' + infoDropdown.id + 'Collapse'" aria-expanded="true"
          [attr.aria-controls]="infoDropdown.id + 'Collapse'">
          <div *ngIf="false"
            class="border rounded-circle p-2 d-flex align-items-center justify-content-center me-2 me-md-3" style="
              border-color: #327B8D !important;
              background: #327B8D;
              width: 33px;
              height: 33px;
            ">
            <mat-icon data-nosnippet class="text-white">question_mark</mat-icon>
          </div>
          <span class="text-dark lh-base"><strong>{{ infoDropdown.title }}</strong></span>
        </button>
      </h2>
      <div id="{{ infoDropdown.id }}Collapse" class="accordion-collapse collapse {{
          infoDropdown.open ? 'show' : null
        }}" [attr.aria-labelledby]="infoDropdown.id">
        <div class="accordion-body px-1">
          <ng-container *ngFor="let content of infoDropdown.content; let last = last">
            <div dt="{{ last }}" class="{{ last ? null : 'mb-3' }}">
              <p class="mb-1 fs-md-18 fs-mb-18 lh-sm" *ngIf="content.title && content.title != ''">
                {{ content.title }}
              </p>
              <div>
                <div class="mb-0 py-2 px-0 rounded fs-md-16 fs-mb-16" [innerHtml]="content.content"></div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</div>