import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'pla-footer-popout',
  templateUrl: './footer-popout.component.html',
  styleUrls: ['./footer-popout.component.scss']
})
export class FooterPopoutComponent implements OnInit {

  @Output() public toggled: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() public name: string;
  @Input() public showBorder: boolean = false;
  @Input() public externalToggle: EventEmitter<boolean> = new EventEmitter<boolean>();;
  @Input() public opened: boolean;
  @Input() public actionText: string;

  constructor() { }

  ngOnInit(): void {
    this.externalToggle.subscribe(_ => this.Toggle(_));
  }

  public Toggle(state: boolean): void {
    this.opened = state;
    this.toggled.emit(state);
    if (state) {
      document.body.classList.add('locked');
    } else document.body.classList.remove('locked');

    this.opened = state;
  }

}
