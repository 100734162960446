<div class="page">
  <div class="content-container">
    <div class="p-2 w-100" style="background-color: #facf15">
      <h3 class="w-100 m-0 text-center text-black fw-bold">
        Get 3 months free listing with the code FREEFOR3
      </h3>
    </div>
  </div>
  <div class="content-container">
    <div class="center-content-container">
      <!-- SEARCH CARD + BANNER -->
      <div class="pla_card mobile spacing no-top no-card no-left no-right position-relative">
        <pla-banner-image [carBanner]="'COUNTACH'" [rawImage]="true" [backgroundImage]="true"
          [backgroundClass]="'desktop-mobile-background'">
          <div class="d-flex align-items-center justify-content-between flex-column"
            style="background-color: rgba(6, 34, 41, 0.49)">
            <!-- HEADER TEXT -->
            <div class="text-white w-100 align-self-start justify-content-start ms-auto ms-md-0 p-4 p-md-4"
              style="max-width: 400px">
              <p class="h1 mb-1 fw-bold">SELL WITH PLATEX</p>
              <div>
                <p class="fw-light fs-6">
                  Sell your private number plate in a simple and cost effective
                  way with the UK's Number Plate Portal
                </p>
              </div>
            </div>

            <div class="align-self-end w-100">
              <pla-page-steps [hideOnMobile]="true" [swiperOnMobile]="true" [steps]="pageSteps"
                [backgroundColor]="'rgba(6,34,41,0.65)'" [headerColor]="'#FACF15'"
                [textColor]="'#FFF'"></pla-page-steps>
              <div class="d-flex align-items-end justify-content-center justify-content-md-end mt-3 p-4 p-md-4">
                <button mat-flat-button class="turquoise-button">
                  List My Plate
                </button>
              </div>
            </div>
          </div>
        </pla-banner-image>
      </div>

      <div class="px-4 py-5">
        <div class="d-block d-md-flex align-items-start justify-content-between">
          <div>
            <h3 class="h2 fw-normal mb-3">Why Platex?</h3>
            <p class="mb-3 fs-6">
              Thousands of buyers take advantage of PlateX portal whole market
              search and price comparison technology to find their perfect
              plate. Now with our classified listing service you to take control
              of the selling process and advertise and sell your private
              registration in the same way you would a car!
            </p>
            <p class="mb-0 fs-6">
              With no commission or dealers fees payable you get 100% of the
              sales price. To list your registration simply choose which plan
              suits you and have your number plate live on PlateX in a matter of
              minutes!
            </p>
          </div>

          <div class="border p-3 w-100 bg-light mt-3 mt-md-0 ms-md-4 ms-auto me-md-0 me-auto shadow-sm flex-shrink-0"
            style="
              border-color: #0a6077 !important;
              min-width: 300px;
              max-width: 350px;
            ">
            <h5 class="h4">Get an instant online valuation</h5>
            <p class="fs-6 mb-4">
              Harness the power of the UK's largest registration database to
              provide you with a FREE and INSTANT valuation!
            </p>

            <div>
              <!-- PLATE -->
              <div class="d-flex align-items-center justify-content-between gap-4 mb-3">
                <div>
                  <small class="text-nowrap">Enter your Registration<br />Number</small>
                </div>
                <div class="input-container plate-background">
                  <input class="plate-font" type="text" spellcheck="false" autocomplete="false" placeholder="112 JAY" />
                </div>
              </div>

              <!-- TERM -->
              <div class="d-flex align-items-center justify-content-between gap-4">
                <div>
                  <small class="text-nowrap">Enter the Word / initials<br />that best represent
                    it.</small>
                </div>
                <div class="input-container plate-background white">
                  <input class="plate-font" type="text" spellcheck="false" autocomplete="false" placeholder="JAY" />
                </div>
              </div>

              <!-- CONFIRMATION -->
              <div class="d-flex align-items-end justify-content-end mt-3">
                <button mat-flat-button class="turquoise-button">
                  Get Valuation
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="py-5">
        <div class="border border-2" style="border-color: #0a6077 !important"></div>

        <div class="position-relative overflow-hidden">
          <pla-banner-image [rawImage]="true" [backgroundImage]="true" [carBanner]="'SELLING_F40'"
            [backgroundClass]="'desktop-mobile-background-left'">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-none d-md-block" style="width: 55%"></div>
              <div style="background-color: rgba(255, 255, 255, 0.95)">
                <div class="px-4 py-5 d-none d-md-block">
                  <ng-container *ngFor="let slide of pageSlides">
                    <div class="d-flex align-items-start justify-content-start mb-4">
                      <div class="me-3 bg-white p-1">
                        <mat-icon data-nosnippet class="x-large">{{ slide.icon }}</mat-icon>
                      </div>
                      <div>
                        <h2>{{ slide.header }}</h2>
                        <p class="fw-light mb-0">{{ slide.body }}</p>
                      </div>
                    </div>
                  </ng-container>
                </div>

                <div class="d-block d-md-none">
                  <p>mobile slider</p>
                </div>

                <div>
                  <!--  OPTIONS -->
                  <div class="d-block w-100 align-items-stretch d-md-flex text-white text-center">
                    <div
                      class="d-flex align-items-stretch flex-column justify-content-between flex-fill px-3 py-3 py-md-3"
                      style="background-color: #3791a8">
                      <h4 class="h4 mt-0 mt-md-2">Monthly</h4>
                      <p class="fs-5">£7.50 / Month</p>
                      <div class="flex-fill"></div>
                      <button mat-raised-button class="turquoise-button">
                        List on a Monthly Plan
                      </button>
                    </div>

                    <div
                      class="d-flex align-items-stretch flex-column justify-content-between flex-fill px-3 py-3 py-md-3 position-relative"
                      style="background-color: #327b8d">
                      <div class="d-none d-md-block position-absolute text-white top-0 start-0 end-0"
                        style="transform: translateY(-50%)">
                        <div class="mx-auto py-1 px-1 border border-white"
                          style="background-color: #facf2f; width: fit-content">
                          <h4 class="h5 m-0 fw-bolder">MOST POPULAR</h4>
                        </div>
                      </div>

                      <div class="d-block d-md-none text-white top-0 start-0 end-0">
                        <div class="mx-auto py-1 px-1 border border-white"
                          style="background-color: #facf2f; width: fit-content">
                          <h4 class="h5 m-0 fw-bolder">MOST POPULAR</h4>
                        </div>
                      </div>

                      <h4 class="h4 mt-0 mt-md-2">3 Month</h4>
                      <p class="fs-5">£18.00</p>
                      <small class="mb-2">£6.00 / Month
                        <span class="fw-bolder" style="color: #ffdd0c">SAVE 20%</span></small>
                      <div class="flex-fill"></div>
                      <button mat-raised-button class="turquoise-button">
                        List on a 3 Month Plan
                      </button>
                    </div>

                    <div
                      class="d-flex align-items-stretch flex-column justify-content-between flex-fill px-3 py-3 py-md-3"
                      style="background-color: #0a6077">
                      <h4 class="h4 mt-0 mt-md-2">12 Month</h4>
                      <p class="fs-5">£54.00</p>
                      <small class="mb-2">£4.50 / Month
                        <span class="fw-bolder" style="color: #ffdd0c">SAVE 40%</span></small>
                      <div class="flex-fill"></div>
                      <button mat-raised-button class="turquoise-button">
                        List on a Yearly Plan
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </pla-banner-image>
        </div>
      </div>

      <!-- CONTENT -->
      <!-- <div class="pla_card no-card mobile spacing padding sell-page">
        <p class="display-4 text-center mb-3">Why Platex?</p>
        <div class="swiper-container">
          <div class="swiper-nav swiper-prev">
            <mat-icon data-nosnippet>chevron_left</mat-icon>
          </div>

          <swiper #swiperRef class="swiper" [config]="swiperConfig">
            <ng-container *ngFor="let row of pageSlides">
              <ng-template swiperSlide>
                <div class="w-100">
                  <ng-container *ngFor="let slide of row">
                    <div
                      style="max-width: 300px; height: 170px"
                      class="m-auto py-0 px-1 d-flex align-items-start justify-content-between"
                    >
                      <div class="me-3">
                        <mat-icon data-nosnippet class="x-large">{{ slide.icon }}</mat-icon>
                      </div>
                      <div>
                        <h2>{{ slide.header }}</h2>
                        <p class="fw-light mb-0">{{ slide.body }}</p>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-template>
            </ng-container>
          </swiper>

          <div class="swiper-nav swiper-next">
            <mat-icon data-nosnippet>chevron_right</mat-icon>
          </div>
        </div>
      </div> -->

      <div class="p-4">
        <h3 class="h2 fw-normal mb-3">Common Questions</h3>
        <div>
          <pla-faq [faq]="faq"></pla-faq>
        </div>
      </div>

      <div>
        <pla-banner-image [rawImage]="true" [backgroundImage]="true" [carBanner]="'AVENTADOR_S'"
          [backgroundClass]="'desktop-mobile-background'">
          <div class="d-flex align-items-center justify-content-end">
            <div
              class="w-75 w-md-100 p-4 ps-5 ps-md-4 d-flex align-items-center justify-content-center ltr_gradient md-no-gradient"
              style="min-height: 450px">
              <div class="d-flex align-items-center justify-content-center flex-column ms-auto me-0"
                style="max-width: 450px">
                <p class="fs-5 text-white">
                  With the entire market of registrations on one single portal,
                  industry leading optimised search facilities and even price
                  comparison it's no wonder buyers are choosing PlateX to find
                  their perfect plate.
                </p>

                <div class="align-self-end mt-4">
                  <button class="ms-auto me-0 turquoise-button" mat-flat-button>
                    Begin Free Trial
                  </button>
                </div>
              </div>
            </div>
          </div>
        </pla-banner-image>
      </div>
    </div>
    <div class="side-container bg-light">
      <div class="">
        <pla-featured-plates [featureType]="rsFeatured" [plateSize]="'featured'" [priceFontSize]="12"
          [priceClass]="'text-muted fst-italic'" [maxCount]="14"></pla-featured-plates>
      </div>
      <div class="border-top">
        <pla-featured-plates [featureType]="[fFeatured, raFeatured]" [plateSize]="'featured'" [priceFontSize]="12"
          [maxCount]="14"></pla-featured-plates>
      </div>
    </div>
  </div>
</div>