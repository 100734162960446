import { Registration } from './registration';

export class InitialSearchResults {
  public dateless_number_first: Registration[];
  public dateless_letter_first: Registration[];
  public current: Registration[];
  public prefix: Registration[];
  public suffix: Registration[];
  public ni: Registration[];
}

export class FeaturedInitials {
  public initial: string;
  public current: { registration: string, price: number }[];
  public prefix: { registration: string, price: number }[];
  public suffix: { registration: string, price: number }[];
  public dateless: { registration: string, price: number }[];
}