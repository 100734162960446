<div class="page-width">
  <div class="mt-3 bootstrap">
    <!-- <pla-faq [faq]="infoContent"></pla-faq> -->
    <div>
      <p class="d-block h2 fw-bold text-start">About Us</p>
      <p class="fw-light">
        PlateX is a UK technology company whose sole endeavour is to simplify
        the cherished registration market.
      </p>
      <p class="fw-light">
        Cutting edge search technologies help our users find their perfect
        registration and our price comparison service locates the best possible
        deal.
      </p>
      <p class="fw-light">
        For sellers we provide a cost-effective, commission-free advertising
        service to an unrivalled marketplace designed specifically to promote
        liquidity.
      </p>
    </div>

    <div>
      <p class="d-block h2 fw-bold text-start">Our Strategy</p>
      <p class="fw-light">
        To simplify the cherished registration market in the UK, our strategy is
        to:
      </p>
      <ul class="fw-light">
        <li class="fw-light">
          be the place consumers turn to first and engage with most
        </li>
        <li class="fw-light">
          provide unrivalled exposure and leads for our customers
        </li>
        <li class="fw-light">
          innovate to create a simpler and more liquid market
        </li>
      </ul>
    </div>

    <div>
      <p class="d-block h2 fw-bold text-start">Our Technology</p>
      <p class="fw-light">
        At the core of all features on the PlateX platform is our proprietary
        search algorithm PLATO, named after another famous deep thinker. PLATO
        allows users to search the entire marketplace of registrations including
        those offered for resale by dealers, private owners, and millions of
        unissued government stock, all in a single search.
      </p>
      <p class="fw-light">
        Within just a few seconds PLATO retrieves and ranks search results by
        numerous datapoints including pattern match, recognisability and
        phonetics while minimising blight caused by additional characters.
      </p>
      <p class="fw-light">
        As an autonomous algorithm PLATO continues to learn and adapt with every
        search it undertakes. In addition our users help PLATO learn to improve
        the quality of ranking through the Vote Up / Down icons beneath each
        search result. To ensure that users are guaranteed to be shown the best
        result we safeguard the integrity of the PLATO search by not offering
        any service to improve the ranking of a registration for fees or
        affiliation.
      </p>
    </div>
  </div>
</div>

<div class="section page-width">
  <pla-glossary [searchTerm]="glossaryUrl"></pla-glossary>
</div>
