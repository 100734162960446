import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ListingStatus } from 'src/app/models/enums/listingStatus';
import { HttpLoadingResp } from 'src/app/models/httpLoadingResp';
import { ListingAnalytic } from 'src/app/models/listingAnalytic';
import { PlatexListingOrder } from 'src/app/models/platexListingOrder';
import { ListingService } from 'src/app/services/listing-service';
import { Listing } from '../../models/listing';

@Component({
  selector: 'pla-sellers-hub-page',
  templateUrl: './sellers-hub-page.component.html',
  styleUrls: ['./sellers-hub-page.component.scss'],
})
export class SellersHubPageComponent implements OnInit {
  public sellerListings: HttpLoadingResp<PlatexListingOrder[]> =
    new HttpLoadingResp();
  // soon come
  // public sellerAnalytics: HttpLoadingResp<ListingAnalytic[]> = new HttpLoadingResp();

  public sellerAnalytics: ListingAnalytic[] = [
    new ListingAnalytic(0, 'Daily Listing Views', 0, 0, 'neutral'),
    new ListingAnalytic(1, 'Daily Listing Clicks', 0, 0.0, 'neutral'),
  ];

  constructor(private listingService: ListingService, private router: Router) {
    this.fetchData();
  }

  ngOnInit(): void {}

  private fetchData(): void {
    // Get users listings
    this.listingService
      .GetUserListings()
      .subscribe((data: PlatexListingOrder[]) => {
        data = data.map((_) => {
          return Object.assign(new Listing(), _);
        });
        this.sellerListings.Update(false, data);
      });
  }

  public handleListingClick(listing: Listing): void {
    console.log(listing);
  }

  public handleNewListingClick(): void {
    this.router.navigate(['/sell']);
  }

  public handleAnalyticClick(analytic: ListingAnalytic): void {
    console.log(analytic);
  }

  public mapStatusToColour(listingStatus: ListingStatus): string {
    if (listingStatus == ListingStatus.Active) return 'positive';
    if (listingStatus == ListingStatus.Suspended) return 'negative';
    if (listingStatus == ListingStatus.Sold) return 'neutral';
  }

  public mapAnalyticToIcon(analytic: ListingAnalytic): string {
    if (analytic.status == 'positive') return 'trending_up';
    if (analytic.status == 'negative') return 'trending_down';
    if (analytic.status == 'neutral') return 'horizontal_rule';
  }
}
