<div class="page">
  <div class="page-description-container"></div>
  <div class="main-content-container content-container" id="results-section">
    <div class="center-content-container">
      <!-- SEARCH CARD + BANNER -->
      <div class="pla_card mobile spacing no-top no-card no-left no-right">
        <pla-banner-image [taller]="true" [carBanner]="'PORSCHE_GT3'">
          <div class="page-title">
            <h1>DVLA AUCTION</h1>
            <span class="sub">Bid on new releases</span>
          </div>
        </pla-banner-image>
        <div class="search-input-container">
          <div class="search-area inner">
            <div class="box-container">
              <pla-option-search
                [optionsEnabled]="false"
                [endpoint]="'dvla-auction'"
                [searchCriteria]="searchCriteria"
                [acceptEmpty]="true"
                [loading]="loading"
                [plateStyle]="true"
              ></pla-option-search>
              <span class="header">Search the latest DVLA auction</span>
            </div>
          </div>
        </div>
      </div>

      <!-- PERFECT CARD -->
      <div class="pla_card no-card mobile spacing padding">
        <!-- MAIN SEARCH CARD -->
        <pla-search-result-table
          [searching]="loading"
          [headerText]="getCurrentAuctionTitle()"
          [results]="results.currentAuction"
          [showRatings]="false"
          [resultChange]="currentResultChange"
          [topResultSet]="true"
          [paginationPageSize]="50"
        ></pla-search-result-table>

        <pla-search-result-table
          [searching]="loading"
          [headerText]="getUpcomingAuctionTitle()"
          [results]="results.upcomingAuction"
          [showRatings]="false"
          [resultChange]="upcomingResultChange"
          [topResultSet]="true"
          [paginationPageSize]="50"
        ></pla-search-result-table>
      </div>
    </div>
  </div>
</div>
