<div class="page" *ngIf="!inlineSearch">
  <pla-main-nav-chips></pla-main-nav-chips>
  <div class="content-container extended">
    <div class="ps-5 side-container mb-3">
      <div class="pt-0 position-relative">
        <pla-side-nav></pla-side-nav>
      </div>

      <div *ngIf="false" class="bg-light py-3">
        <pla-featured-plates [featureType]="raFeatured" [plateSize]="'smaller'" [priceFontSize]="16" [maxCount]="10"
          [sortBy]="'price'" [plateClass]="'flat shaded'" [showIndex]="false"></pla-featured-plates>
      </div>
    </div>
    <div class="center-content-container position-relative">
      <div class="pla_card mobile spacing no-top no-card no-left no-right position-relative">
        <pla-banner-image [carBanner]="'RR_DEFENDER'" [mobileCarBanner]="'RR_DEFENDER_MB'" [rawImage]="true"
          [backgroundImage]="true" [backgroundClass]="'compare-mobile-background'">
          <div class="compare-banner-height">
            <div class="d-flex d-md-flex align-items-start justify-content-between flex-column">
              <div class="p-4 p-md-4">
                <h1 class="lh-sm fw-bold fs-md-28 fs-mb-24 text-white">
                  Build-Your-Own
                </h1>
              </div>
            </div>
          </div>
        </pla-banner-image>

        <div class="mt-4" *ngIf="!inlineSearch && !hasSearchCriteria">
          <!--TEXT CONTENT-->
          <p class="px-4 text-start fw-bolder fs-md-20 fs-mb-18 mb-0 mx-auto lh-sm hs-mb">Make Your Own Number Plate</p>
          <p class="px-4 text-start fs-md-16 fs-mb-14 mb-2 mb-md-3 mx-auto lh-sm">With our Design Your Own Private
            Number Plate tool, personalising your registration has never been easier. Simply select your preferred
            style—Current, Prefix, or Suffix—and enter your desired letters or numbers. Leave any remaining spaces as
            “?” to unlock a variety of options. Our tool will then generate the best available private number plates,
            helping you find a unique combination that fits your style and budget.</p>
        </div>

        <div class="mt-4">
          <pla-byo-search-input [showSwitch]="showSwitch" [defaultStyle]="style" [prefix]="activeBreakdown().prefix"
            [numbers]="activeBreakdown().numbers" [letters]="activeBreakdown().letters"
            (searchOutput)="doInlineSearch($event)" [padLeft]="false" [withPadding]="false"></pla-byo-search-input>
        </div>

        <div class="s-spacing"></div>
      </div>

      <div
        class="pla_card mobile spacing no-top no-card no-left no-right position-relative px-2 px-md-1 py-0 me-0 me-md-2">
        <div class="pla_card no-card mobile spacing px-2">
          <!-- CONTENT -->

          <div *ngIf="!inlineSearch && !hasSearchCriteria">
            <div class="px-2 px-md-0">
              <div class="rounded-3 py-4 px-2 px-md-4" style="background-color: #F2F5F4;">
                <p class="fw-bolder mb-3 fs-md-22 fs-mb-22 text-center text-xl-start mx-auto">B-Y-O Name Private
                  Registrations</p>
                <div class="container-fluid mt-2">
                  <div class="row">

                    <div *ngFor="let featuredName of featuredNames" class="col-6 col-sm-6 col-lg-4 col-xl-3 py-3 px-1">
                      <p class="mb-1 lh-sm fw-normal text-center fs-md-14 fs-mb-13">{{ featuredName.name
                        }}</p>
                      <a [href]="'byo-search/' + featuredName.style + '/' + featuredName.link"
                        class="text-decoration-none">
                        <div class="mx-auto plate-background flat shaded px-2 py-2 py-md-1 rounded-3 feature-plate">
                          <p
                            class="inclusive-plate-font w-100 fs-md-28 fs-mb-20 text-center mx-auto my-0 d-block lh-sm text-nowrap">
                            {{ featuredName.plate }}
                          </p>
                        </div>
                      </a>
                    </div>

                  </div>
                </div>

                <!-- <p class="px-4 text-center fs-md-16 fs-mb-14 mt-3 mx-auto lh-sm" style="max-width: 1000px;">Click a
                  registration to try it out!</p> -->
              </div>

              <div class="s-spacing"></div>

            </div>

            <div class="px-2 px-md-0">
              <div class="container-fluid mt-4">
                <div class="row">

                  <!-- LEARN MORE -->
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="h-100 shadow border border-1 rounded-3 p-4" style="border-color: #0A6077 !important;">

                      <p class="mb-3 lh-sm fw-normal text-center fs-md-20 fs-mb-18">Prefix Registration Guide</p>
                      <pla-plate [rawPlate]="'A42 RON'" [showIndex]="false" [showPrice]="false"
                        [customClass]="'flat shaded'" [plateSize]="'smaller'" [smallPlate]="false"
                        [mobileTiny]="false"></pla-plate>

                      <p class="mt-3 mb-4 lh-sm fw-normal text-center fs-md-16 fs-mb-14">Learn more about the
                        prefix registration format to help build the perfect search...</p>

                      <a href="/articles/prefix-number-plates" style="color: #3791A8;"
                        class="text-decoration-none lh-sm fw-normal text-center d-block mx-auto fs-md-16 fs-mb-14">Read
                        More...</a>
                    </div>
                  </div>
                  <div class="col-12 col-md-6  col-lg-4 mt-3 mt-md-0">
                    <div class="h-100 shadow border border-1 rounded-3 p-4" style="border-color: #0A6077 !important;">

                      <p class="mb-3 lh-sm fw-normal text-center fs-md-20 fs-mb-18">Suffix Registration Guide</p>
                      <pla-plate [rawPlate]="'DAV 1D'" [showIndex]="false" [showPrice]="false"
                        [customClass]="'flat shaded'" [plateSize]="'smaller'" [smallPlate]="false"
                        [mobileTiny]="false"></pla-plate>

                      <p class="mt-3 mb-4 lh-sm fw-normal text-center fs-md-16 fs-mb-14">Learn more about the
                        suffix registration format to help build the perfect search...</p>

                      <a href="/articles/suffix-number-plates" style="color: #3791A8;"
                        class="text-decoration-none lh-sm fw-normal text-center d-block mx-auto fs-md-16 fs-mb-14">Read
                        More...</a>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4 mt-3 mt-md-0">
                    <div class="h-100 shadow border border-1 rounded-3 p-4" style="border-color: #0A6077 !important;">

                      <p class="mb-3 lh-sm fw-normal text-center fs-md-20 fs-mb-18">Current Style Plate Guide</p>
                      <pla-plate [rawPlate]="'AS11 LEY'" [showIndex]="false" [showPrice]="false"
                        [customClass]="'flat shaded'" [plateSize]="'smaller'" [smallPlate]="false"
                        [mobileTiny]="false"></pla-plate>

                      <p class="mt-3 mb-4 lh-sm fw-normal text-center fs-md-16 fs-mb-14">Learn more about the
                        current registration format to help build the perfect search...</p>

                      <a href="/articles/cherished-number-plates" style="color: #3791A8;"
                        class="text-decoration-none lh-sm fw-normal text-center d-block mx-auto fs-md-16 fs-mb-14">Read
                        More...</a>
                    </div>
                  </div>
                  <!-- <div class="col-12 col-md-6 mt-4">
                    <div class="shadow border border-1 rounded-3 p-4" style="border-color: #0A6077 !important;">

                      <p class="mb-3 lh-sm fw-normal text-center fs-md-20 fs-mb-18">Learn about Dateless Style
                        Plates</p>
                      <pla-plate [rawPlate]="'A 1'" [showIndex]="false" [showPrice]="false"
                        [customClass]="'flat shaded'" [plateSize]="'smaller'" [smallPlate]="false"
                        [mobileTiny]="false"></pla-plate>

                      <p class="mt-3 mb-4 lh-sm fw-normal text-center fs-md-16 fs-mb-14">Learn more about the
                        dateless registration format to help build the perfect search...</p>

                      <a href="/articles/dateless-number-plates" style="color: #3791A8;"
                        class="text-decoration-none lh-sm fw-normal text-center d-block mx-auto fs-md-16 fs-mb-14">Read
                        More...</a>
                    </div>
                  </div> -->

                </div>
              </div>

              <div class="s-spacing"></div>
            </div>
          </div>


          <div *ngIf="inlineSearch || hasSearchCriteria">
            <div [ngClass]="{
              'pla_card no-card mobile spacing padding': !inlineSearch
            }">
              <pla-search-result-table [searching]="loading" [searchTitle]="tableHeaderSearchTerm()" [searchType]="''"
                [resultType]="'PLATE'" [pluralResultType]="'PLATES'" [showFilter]="false" [results]="results"
                [resultChange]="resultChange" [paginationPageSize]="30"></pla-search-result-table>
            </div>
          </div>
        </div>

        <div class="s-spacing"></div>

        <div *ngIf="faq && faq.length > 0"
          class="pla_card mobile spacing no-top no-card no-left no-right position-relative px-0 px-md-1 py-0 me-0">
          <div class="px-0 px-md-2">
            <div class="pb-5 pt-5">
              <p class="text-center text-md-start fs-md-26 fs-mb-22">
                Frequently Asked Questions
              </p>
            </div>
            <div class="w-100 px-3 px-md-0 pb-5">
              <pla-faq [faq]="faq"></pla-faq>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="inlineSearch">
  <div class="-search-input-container">
    <div class="search-area inner">
      <div class="box-container">
        <pla-byo-search-input [showSwitch]="showSwitch" [defaultStyle]="style" [prefix]="activeBreakdown().prefix"
          [numbers]="activeBreakdown().numbers" [letters]="activeBreakdown().letters" [breakdowns]="styleBreakdowns"
          (searchOutput)="doInlineSearch($event)" [padLeft]="false" [withPadding]="false"></pla-byo-search-input>
      </div>
    </div>
  </div>
</div>