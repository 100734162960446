import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { MarketShare } from 'src/app/models/market-data';
import { CompareService } from 'src/app/services/compare-service';

@Component({
  selector: 'pla-market-share',
  templateUrl: './market-share.component.html',
  styleUrls: ['./market-share.component.scss'],
})
export class MarketShareComponent implements OnInit {
  @Output() public dataUpdated: EventEmitter<MarketShare> =
    new EventEmitter<MarketShare>();
  public priceData: MarketShare;
  public root!: am5.Root;

  constructor(private compareService: CompareService) {}

  ngOnInit(): void {
    this.GetChartData();
  }

  private GetChartData(): void {
    this.GetPriceData();
  }

  private GetPriceData(): void {
    this.compareService.GetMarketShare().subscribe((data: MarketShare) => {
      this.priceData = data;
      this.dataUpdated.emit(data);
      this.BuildChart();
    });
  }

  private BuildChart(): void {
    console.log('building marketshare chart');
    this.root = am5.Root.new('chartdiv', {
      useSafeResolution: false,
    });
    this.root.setThemes([am5themes_Animated.new(this.root)]);
    let chart = this.root.container.children.push(
      am5percent.PieChart.new(this.root, {
        endAngle: 270,
        paddingTop: 0,
        marginTop: 0,
        layout: this.root.verticalLayout,
        // centerY: 25,
      })
    );

    let series = chart.series.push(
      am5percent.PieSeries.new(this.root, {
        valueField: 'count',
        categoryField: 'category',
        endAngle: 270,
        alignLabels: false,
      })
    );

    series.slices.template.events.on('click', function (ev) {
      series.slices.each(function (slice) {
        slice.set('active', false);
        if (slice != ev.target && slice.get('active')) {
          slice.set('active', false);
        }
      });
    });

    series.labels.template.setAll({
      fontSize: 12,
      text: `[bold]{category}[/]\n{valuePercentTotal.formatNumber('0.00')}%`,
      textType: 'radial',
      radius: 0,
      centerX: am5.percent(110),
      fill: am5.color(0xffffff),
      active: false,
    });
    series.slices.template.set('tooltipText', '');
    //series.slices.template.get('active')

    series.states.create('hidden', {
      endAngle: -90,
    });

    series
      .get('colors')
      .set('colors', [
        am5.color(0x3889d7),
        am5.color(0xf79f01),
        am5.color(0x84194e),
        am5.color(0x77aaa8),
      ]);

    series.slices.template.setAll({
      stroke: am5.color(0xffffff),
      strokeWidth: 2,
      active: false,
      focusable: false,
      interactive: false,
      innerRadius: 0,
    });
    try {
      series.slices.template.events.disableType('click');
      series.template.events.disableType('click');
    } catch {}
    series.data.setAll([
      {
        category: 'Current',
        count: this.priceData.current.count,
        sum: this.priceData.current.sum,
      },
      {
        category: 'Prefix',
        count: this.priceData.prefix.count,
        sum: this.priceData.prefix.sum,
      },
      {
        category: 'Suffix',
        count: this.priceData.suffix.count,
        sum: this.priceData.suffix.sum,
      },
      {
        category: 'Dateless',
        count: this.priceData.dateless.count,
        sum: this.priceData.dateless.sum,
      },
    ]);

    series.appear(1000, 100);
  }
}
