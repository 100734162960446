import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlatexListingOrder } from 'src/app/models/platexListingOrder';
import { OrderService } from 'src/app/services/order-service';

@Component({
  selector: 'pla-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.css'],
})
export class OrderConfirmationComponent implements OnInit {
  private orderId: string;
  public order: PlatexListingOrder;

  constructor(
    private route: ActivatedRoute,
    private orderService: OrderService
  ) {
    this.orderId = this.route.snapshot.params.orderId;
    this.getOrderDetails();
  }

  ngOnInit(): void {}

  public getOrderId(): string {
    if (this.order == null) return '';
    const activeSub = this.order.subscription.filter((s) => s.active)[0];
    return activeSub.id.replace('-', '').substring(0, 8).toUpperCase();
  }

  private getOrderDetails(): void {
    this.orderService.getOrderDetails(this.orderId).subscribe((order) => {
      this.order = order;
    });
  }
}
