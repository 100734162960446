import { isArray } from '@amcharts/amcharts5/.internal/core/util/Type';
import { Component, Input, OnInit } from '@angular/core';

export class InfoDropdownContent {
  constructor(public title: string, public content: string) { }
}

export class InfoDropdown {
  constructor(
    public id: string,
    public title: string,
    public open: boolean = false,
    public content: InfoDropdownContent[] = []
  ) { }
}

export class FaqBuilder {
  static build(id: string, title: string, content: string | string[]): InfoDropdown {
    if (!isArray(content)) content = [content];
    return new InfoDropdown(id, title, false, [
      new InfoDropdownContent(null, content.map(c => `<p>${c}</p>`).join(''))
    ])
  }
}

@Component({
  selector: 'pla-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
  @Input() public faq: InfoDropdown[] = [];

  constructor() { }

  ngOnInit(): void { }
}
