import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { SearchService } from 'src/app/services/search-service';

export class NamePopularity {
  public name: string;
  public registration: string;
  public popularity: number;
  public resalePrice: number;
  public resaleYear: string;
}

@Component({
  selector: 'pla-initials-popularity-data',
  templateUrl: './initials-popularity-data.component.html',
  styleUrls: ['./initials-popularity-data.component.scss']
})
export class InitialsPopularityDataComponent implements OnInit {

  public viewResults: { letterFirst: NamePopularity[], numberFirst: NamePopularity[] };

  constructor(private router: Router, private searchService: SearchService) { }

  ngOnInit(): void {
    this.searchService.getInitialPopularity((results: { letterFirst: NamePopularity[], numberFirst: NamePopularity[] }) => {
      this.viewResults = results;
    });
  }

}
