import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// SENTRY LOGGING
Sentry.init({
  dsn: 'https://d0e309ccc30b45b9b21a7e99c3989aa3@o1235462.ingest.sentry.io/6385429',
  integrations: [
    new BrowserTracing({
      tracingOrigins: [
        'localhost',
        'https://dev.platex.co.uk',
        'https://www.platex.co.uk',
      ],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  tracesSampleRate: 1.0,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((success) => console.log(`Bootstrap success`))
  .catch((err) => console.error(err));
