import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pla-glossary-term-word',
  templateUrl: './glossary-term-word.component.html',
  styleUrls: ['./glossary-term-word.component.scss'],
})
export class GlossaryTermWordComponent implements OnInit {
  @Input('term') public term: string;
  @Input('text') public text: string;

  constructor() {}

  ngOnInit(): void {}
}
