<ng-container *ngIf="!rawImage; else rawTemplate">
  <div [ngClass]="{ 'no-ds': !showShadow }" class="banner-container" [style.min-height]="calcHeight">
    <img [attr.alt]="title" class="desktop-only background-image" [src]="generateBackgroundImageStyle()"
      [style.aspect-ratio]="aspectRatio(false)" />
    <img [attr.alt]="title" class="mobile-only background-image" [src]="generateMobileBackgroundImage()"
      [style.aspect-ratio]="aspectRatio(true)" />
    <div [ngClass]="{ 'outlet-container': overlap, 'regular-content': !overlap, 'banner-tint': useTint }">
      <ng-content></ng-content>
    </div>
  </div>
</ng-container>
<ng-template #rawTemplate>
  <ng-container *ngIf="backgroundImage">
    <div class="{{ backgroundClass }}" [ngClass]="{'banner-tint': useTint}"
      style="background-image: url('{{ generateBackgroundImageStyle() }}')">
      <ng-content></ng-content>
    </div>
  </ng-container>
  <img [attr.alt]="title" *ngIf="!backgroundImage" src="{{ generateBackgroundImageStyle() }}" class="d-block w-100" />
</ng-template>