import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pla-prefix-number-plates',
  templateUrl: './prefix-number-plates.component.html',
  styleUrls: ['./prefix-number-plates.component.scss']
})
export class PrefixNumberPlatesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
