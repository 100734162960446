import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pla-number-plate-types',
  templateUrl: './number-plate-types.component.html',
  styleUrls: ['./number-plate-types.component.scss']
})
export class NumberPlateTypesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
