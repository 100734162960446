import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Registration } from 'src/app/models/registration';
import { RegistrationService } from 'src/app/services/registration-service';
import { SearchService } from 'src/app/services/search-service';

export class DVLAAuctionResp {
  constructor(
    public currentAuction: Registration[],
    public upcomingAuction: Registration[]
  ) {}
}

@Component({
  selector: 'pla-dvla-auction-page',
  templateUrl: './dvla-auction-page.component.html',
  styleUrls: ['./dvla-auction-page.component.scss'],
})
export class DvlaAuctionPageComponent implements OnInit {
  public TITLEMAP = {
    B252: 'Online Auction - (Wed, 11th Jan)',
    B253: 'Cambridge Belfry Hotel Auction - (Wed, 22nd Feb)',
  };

  public loading: boolean = false;
  public searchCriteria: string;
  public results: DVLAAuctionResp = new DVLAAuctionResp([], []);
  public currentResultChange: EventEmitter<Registration[]> = new EventEmitter<
    Registration[]
  >();
  public upcomingResultChange: EventEmitter<Registration[]> = new EventEmitter<
    Registration[]
  >();

  constructor(
    private route: ActivatedRoute,
    private searchService: SearchService,
    private registrationService: RegistrationService
  ) {
    this.searchCriteria = this.route.snapshot.params.searchCriteria;
    // if (this.searchCriteria == null || this.searchCriteria == '') return; // nothing more to do, show content ready for search
    this.search();
  }

  ngOnInit(): void {}

  public search(): void {
    this.loading = true;
    this.searchService.searchAuction(
      this.searchCriteria,
      (res: DVLAAuctionResp) => {
        this.loading = false;
        this.results = res;
        this.results.currentAuction =
          this.registrationService.formatRegistrations(res.currentAuction);
        this.results.upcomingAuction =
          this.registrationService.formatRegistrations(res.upcomingAuction);
        console.log(res);
        this.currentResultChange.emit(this.results.currentAuction);
        this.upcomingResultChange.emit(this.results.upcomingAuction);
      }
    );
  }

  public getUpcomingAuctionTitle(): string {
    if (
      this.results &&
      this.results.upcomingAuction &&
      this.results.upcomingAuction[0]
    ) {
      var auction_id = this.results.upcomingAuction[0].meta_data['auction_id'];
      return this.TITLEMAP[auction_id] || 'Upcoming Auction';
    } else {
      return 'Upcoming Auction';
    }
  }

  public getCurrentAuctionTitle(): string {
    if (
      this.results &&
      this.results.currentAuction &&
      this.results.currentAuction[0]
    ) {
      var auction_id = this.results.currentAuction[0].meta_data['auction_id'];
      return this.TITLEMAP[auction_id] || 'Current Auction';
    } else {
      return 'Current Auction';
    }
  }
}
