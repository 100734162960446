import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HTTPCONSTANTS } from '../http/http-constants';
import { HttpService } from '../http/http-service';
import { DVLAVehicleEnquiryResult } from '../models/dvlaVehicleEnquiryResult';
import { FormattedPlateResponse } from '../models/formattedPlateResponse';
import { PlateDetail } from '../models/plateDetail';
import { PlatePaymentDetails } from '../models/platePaymentDetails';
import { PlateRating } from '../models/plateRating';
import { PlateRatingResponse } from '../models/plateRatingResponse';
import { PriceBreakdown } from '../models/priceBreakdown';
import { Registration } from '../models/registration';
import { SoldPlate } from '../models/soldPlate';
import { PlatopediaPlate } from '../models/platopediaPlate';
import { LoggerService } from './logger-service';
import { UserService } from './user-service';
import { NewReleaseResult } from '../models/newReleaseResult';

export enum TrackType {
  Impression = 0,
  Compared = 1,
  Click = 2,
  AffiliateLink = 3,
}

export class TermRegistration {
  public id: number;
  public added: Date;

  constructor(
    public registration: string,
    public term: string,
    public searchReqId: string,
    public score: number,
    public rank: number
  ) { }
}

export class RegistrationTracking {
  public id: number;
  public time: Date;
  public url: string;

  constructor(
    public registration: string,
    public trackType: TrackType,
    public termRegistration: TermRegistration
  ) { }
}

@Injectable({ providedIn: 'root' })
export class RegistrationService {
  private trackingProcessing: boolean = false;
  private trackingQueue: RegistrationTracking[][] = [];
  private userLoaded: boolean = false;
  private waitingCallbacks: any[] = [];

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private loggerService: LoggerService,
    private httpService: HttpService
  ) {
    this.userService.fetchAllUserData(() => {
      this.userLoaded = true;
      this.waitingCallbacks.forEach(c => c());
    });
  }

  private UserCheckCallback(callback: any): void {
    if (this.userLoaded) callback();
    else this.waitingCallbacks.push(callback);
  }

  public trackRegistrations(
    trackingRequestId: string,
    registrations: Registration[]
  ): void {
    var trackings = registrations.map((r) => {
      const tracking = new RegistrationTracking(
        r.registration,
        TrackType.Impression,
        new TermRegistration(
          r.registration,
          r.search,
          trackingRequestId,
          r.score,
          r.index
        )
      );
      tracking;
      return tracking;
    });

    this.trackingQueue.push(trackings);

    if (this.trackingProcessing) return; // picked up from loop
    else this.makeTrackingRequest();
  }

  private makeTrackingRequest() {
    if (this.trackingQueue.length === 0) {
      this.trackingProcessing = false;
      return;
    }

    this.trackingProcessing = true;

    const trackings = this.trackingQueue.pop();

    this.http
      .post<RegistrationTracking[]>(
        `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/tracking/impress`,
        trackings
      )
      .subscribe(() => {
        this.makeTrackingRequest();
      });
  }

  public getPlatePaymentDetails(
    regPlate: string
  ): Observable<PlatePaymentDetails> {
    return this.http.get<PlatePaymentDetails>(
      `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/platedetails/payment/${regPlate}`
    );
  }

  public formatPaymentDetails(
    details: PlatePaymentDetails
  ): PlatePaymentDetails {
    details.priceBreakdown = this.createPriceBreakdown(details);
    if (
      details != null &&
      details.sellersResp != null &&
      details.sellersResp.sellers != null
    )
      details.sellersResp.sellers = details.sellersResp.sellers
        .sort((a, b) => a.totalPrice - b.totalPrice)
        .reverse();
    return details;
  }

  public getPlateDetail(regPlate: string): Observable<PlateDetail> {
    return this.http.get<PlateDetail>(
      `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/platedetails/${regPlate}`
    );
  }

  public getPlateCount(): Observable<{ totalCount: number }> {
    return this.http.get<{ totalCount: number }>(
      `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/stats/count`
    );
  }

  public getPlateHistory(
    registration: string,
    callback: (data: SoldPlate[]) => void,
    errCallback: (error: any) => void = (error: any) => { }
  ): void {
    this.httpService.RequestWithSignUpRequest<SoldPlate[]>(
      `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/solddata/history/${registration}`,
      callback,
      errCallback
    );
  }

  public getVehicleInfo(
    registration: string
  ): Observable<DVLAVehicleEnquiryResult> {
    return this.http.get<DVLAVehicleEnquiryResult>(
      `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/platedetails/${registration}/vehicle`
    );
  }

  public removePlateRating(
    registration: string,
    term: string
  ): Observable<any> {
    return this.http.delete<any>(
      `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/userdetails/rate/${registration}/${term}`
    );
  }

  public setPlateRating(
    registration: string,
    rating: boolean,
    term: string
  ): Observable<PlateRatingResponse> {
    var ratingRequest = new PlateRating(registration, rating, term);
    return this.http.post<PlateRatingResponse>(
      `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/userdetails/rate/${registration}/${term}`,
      ratingRequest
    );
  }

  public getPlateRating(
    registration: string,
    term: string
  ): Observable<PlateRatingResponse> {
    return this.http.get<PlateRatingResponse>(
      `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/platedetails/rating/${registration}/${term}`
    );
  }

  public FormatRegistration(
    registration: string
  ): Observable<FormattedPlateResponse> {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/platedetails/format/${registration}`;
    return this.http.get<FormattedPlateResponse>(url);
  }

  public LocalFormatRegistration(registration: string): string {
    return this.formatRegistrationString(registration);
  }

  public formatPlatopediaRegistrations(
    registrations: PlatopediaPlate[]
  ): PlatopediaPlate[] {
    registrations.map(
      (r: PlatopediaPlate) =>
        (r.registration.available = r.registration.price >= 0)
    );
    registrations.map(
      (r: PlatopediaPlate) =>
      (r.registration.priceBreakdown = this.createRegPriceBreakdown(
        r.registration
      ))
    );
    return registrations;
  }

  private formatRegistrationString(registration: string): string {
    registration = registration.replace(' ', '');
    try {
      if (registration.length > 7) return registration;
      var pattern = this.createRegistrationPattern(registration);
      if (pattern == '' || pattern == null) return registration;
      var style = this.getStyleFromPattern(pattern);
      var registrationSplit = registration.split('');

      if (style == 'current') registrationSplit.splice(4, 0, ' ');
      if (style == 'prefix') registrationSplit.splice(-3, 0, ' ');
      if (style == 'suffix') registrationSplit.splice(3, 0, ' ');
      if (style == 'dateless') {
        var i = 0;
        if (pattern[0] == '*') i = pattern.indexOf('#');
        else if (pattern[0] == '#') i = pattern.indexOf('*');
        registrationSplit.splice(i, 0, ' ');
      }

      return registrationSplit.join('');
    } catch (ex: any) {
      console.error(ex);
      return registration;
    }
  }

  private getStyleFromPattern(pattern: string): string {
    if (pattern == '**##***') return 'current';

    if (pattern == '*#***') return 'prefix';
    if (pattern == '*##***') return 'prefix';
    if (pattern == '*###***') return 'prefix';

    if (pattern == '***#*') return 'suffix';
    if (pattern == '***##*') return 'suffix';
    if (pattern == '***###*') return 'suffix';

    if (pattern.indexOf('#') < 0) return null;
    var numberCount = pattern.split('').filter((c) => c == '#').length;
    var letterCount = pattern.split('').filter((c) => c == '*').length;

    if (letterCount > 4) return null;
    if (numberCount > 4) return null;

    return 'dateless';
  }

  private createRegistrationPattern(registration: string): string {
    var pattern = '';
    var numbers = '0123456789';
    registration.split('').forEach((char: string, index: number) => {
      if (numbers.indexOf(char) > -1) {
        pattern += '#';
      } else {
        pattern += '*';
      }
    });
    return pattern;
  }

  private isFavourite(registration: string): boolean {
    if (
      this.userService.userFavourites &&
      this.userService.userFavourites.length > 0
    ) {
      var _filteredFavourites = this.userService.userFavourites.filter((f) => {
        return (
          f.registration.replace(' ', '') ==
          registration.replace(' ', '')
        );
      });
      return _filteredFavourites.length > 0;
    } else {
      return false;
    }
  }

  public isRated(registration: Registration): boolean | undefined {
    if (
      this.userService.userRatings &&
      this.userService.userRatings.length > 0
    ) {
      if (registration.registration === '1UKE') {
        console.log('checking rating on 1UKE', this.userService.userRatings);
      }
      var rating = this.userService.userRatings.find(
        (f) => f.registration.replace(' ', '') == registration.registration.replace(' ', '') && (f.term === registration.search || f.term === registration.term)
      );
      if (rating === undefined) return undefined
      return rating.rating;
    } else {
      // console.error('attempt to fetch user info too soon');
      return false;
    }
  }

  public isNotify(registration: string): boolean {
    if (
      this.userService.userNotifies &&
      this.userService.userNotifies.length > 0
    ) {
      var _filteredNotifies = this.userService.userNotifies.filter(
        (f) => f.registration.replace(' ', '') == registration.replace(' ', '')
      );
      return _filteredNotifies.length > 0;
    } else {
      // console.error('attempt to fetch user info too soon');
      return false;
    }
  }

  public formatNewReleaseRegistrations(registrations: NewReleaseResult[]): NewReleaseResult[] {
    try {
      registrations = registrations.filter((r) => r != null);
      if (!registrations || registrations.length == 0) return [];

      registrations.map((r) => {
        try {
          r.favourite = this.isFavourite(r.registration);
          r.notified = this.isNotify(r.registration);
        } catch (ex: any) {
          this.loggerService.logException(ex);
        }

        return r;
      });
      return registrations;
    } catch (ex: any) {
      this.loggerService.logException(ex);
    }
    return registrations;
  }

  public formatRegistrationFromString(registration: string, price: number, available: boolean, callback: (reg: Registration) => void): void {
    this.UserCheckCallback(() => {
      const reg = new Registration(0, registration.replace(' ', ''), registration, price);
      reg.available = available;
      callback(this.formatRegistrations([reg])[0]);
    });
  }

  public formatRegistrations(registrations: Registration[]): Registration[] {
    try {
      registrations = registrations.filter((r) => r != null);
      if (!registrations || registrations.length == 0) return [];
      registrations.map((r: Registration) => {
        if (r == null) return;
        r.available = r.price >= 0;
        r.plateOwner = r.plate_owner || r.plateOwner;
        if (r.plateOwner == 4) {
          r.plateOwner = 0;
          r.seller = 'DVLA';
        }
      });

      registrations.map((r) => {
        try {
          r.favourite = this.isFavourite(r.registration);
          r.notify = this.isNotify(r.registration);
          r.userRating = this.isRated(r);
        } catch (ex: any) {
          this.loggerService.logException(ex);
        }

        return r;
      });

      registrations.map(
        (r) => (r.priceBreakdown = this.createRegPriceBreakdown(r))
      );
      registrations.map((r) => {
        if (!r || !r.stars) return;
        var star_parts = r.stars.toString().split('.');
        r.fullStars = Array(parseInt(star_parts[0])).fill('');
        r.halfStar = star_parts.length > 1;
        var empty_star_parts = 5 - r.fullStars.length - (r.halfStar ? 1 : 0);
        if (empty_star_parts != 0)
          r.emptyStars = Array(empty_star_parts).fill('');
        else r.emptyStars = [];
      });
      return registrations.sort((a, b) => (a.score > b.score ? -1 : 1));
    } catch (ex: any) {
      this.loggerService.logException(ex);
    }
    return registrations;
  }

  public createRegPriceBreakdown(reg: Registration): PriceBreakdown {
    if (reg == null) return;
    if (reg.plateOwner == -1) return;
    if (reg.plateOwner == 3) return;
    if (reg.plateOwner == 1) return;
    if (reg.price < 0) return;
    const priceBreakdown = new PriceBreakdown();
    priceBreakdown.registrationPrice = parseFloat(
      this.calculateRegistrationPrice(reg.price).toFixed(2)
    );
    priceBreakdown.vatIncluded = true;
    priceBreakdown.vat =
      priceBreakdown.registrationPrice * 1.2 - priceBreakdown.registrationPrice;
    priceBreakdown.vat = parseFloat(priceBreakdown.vat.toFixed(2));

    // Create total
    var total = priceBreakdown.registrationPrice;
    total += priceBreakdown.vat;
    // Add transfer fee
    if (reg.plateOwner == 0 || !reg.plateOwner) total += 80;

    priceBreakdown.total = parseFloat(total.toFixed(2));
    priceBreakdown.totalWithExtras = priceBreakdown.total;
    return priceBreakdown;
  }

  public createPriceBreakdown(details: PlatePaymentDetails): PriceBreakdown {
    if (details == null) return;
    if (details.plateOwner == 3) return;
    if (details.price < 0) return;
    const priceBreakdown = new PriceBreakdown();
    priceBreakdown.registrationPrice = parseFloat(
      this.calculateRegistrationPrice(details.price).toFixed(2)
    );
    priceBreakdown.vatIncluded = true;
    priceBreakdown.vat =
      priceBreakdown.registrationPrice * 1.2 - priceBreakdown.registrationPrice;
    priceBreakdown.vat = parseFloat(priceBreakdown.vat.toFixed(2));
    priceBreakdown.addedFees = details.addedFees;

    // Create total
    var total = priceBreakdown.registrationPrice;
    total += priceBreakdown.vat;
    if (priceBreakdown.addedFees) {
      priceBreakdown.addedFees.forEach((fee, _) => {
        total += fee.price / 100;
      });
    } else {
      // Add transfer fee
      if (details.plateOwner == 0 || !details.plateOwner) total += 80;
    }

    priceBreakdown.total = parseFloat(total.toFixed(2));
    priceBreakdown.totalWithExtras = priceBreakdown.total;
    return priceBreakdown;
  }

  private calculateRegistrationPrice(price: number): number {
    var inclusiveRegPrice = price / 100;
    var exclusiveRegPrice = inclusiveRegPrice - 80;
    exclusiveRegPrice /= 1.2;
    return exclusiveRegPrice;
  }
}
