import { EventEmitter } from "@angular/core";

export class HttpLoadingResp<T> {
  public updated: EventEmitter<T> = new EventEmitter<T>();

  constructor(public loading: boolean = true, public data: T = null) {}

  public SetLoading(_loading: boolean): void {
    this.loading = _loading;
  }

  public Update(_loading: boolean, _data: T): void {
    this.loading = _loading;
    this.data = _data;
    this.updated.emit(_data);
  }
}
