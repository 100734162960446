<!-- <div class="logo-container {{ size }}  {{colour}}">
  <p>PLATE<span class="accentLetter">X</span><span *ngIf="direct" class="sub">direct</span></p>
  <div *ngIf="platePortal">
    <span>- The Plate Portal -</span>
  </div>
</div> -->

<div class="logo-container {{ size }}  {{ colour }}" [ngStyle]="{ 'text-align': align }">
  <img alt="PlateX logo" *ngIf="colour == 'white'" src="../../../assets/logo.png" />
  <img alt="PlateX logo" *ngIf="colour == 'default'" src="../../../assets/logo_contrast.png" />
</div>