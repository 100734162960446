import { Component, Input, OnInit } from '@angular/core';
import { Registration } from 'src/app/models/registration';
import { DynamicPlacementService } from 'src/app/services/dynamic-placement-service';
import { RegistrationService } from 'src/app/services/registration-service';
import { SessionService } from 'src/app/services/session-service';

export class FeatureType {
  constructor(
    public title: string,
    public featureType: string,
    public loggedIn: boolean = false
  ) { }
}

@Component({
  selector: 'pla-featured-plates',
  templateUrl: './featured-plates.component.html',
  styleUrls: ['./featured-plates.component.scss'],
})
export class FeaturedPlatesComponent implements OnInit {
  @Input() public flex: boolean = true; // set to false to use columns
  @Input() public featureType: FeatureType | FeatureType[] = new FeatureType(
    '',
    'default'
  );
  @Input() public showIfEmpty: boolean = true;
  @Input() public showTitle: boolean = true;
  @Input() public plateSize: string = '';
  @Input() public priceFontSize: number = undefined;
  @Input() public priceClass: string = undefined;
  @Input() public showPrice: boolean = true;
  @Input() public maxCount: number = 1000;
  @Input() public multipleOf: number = 0;
  @Input() public sortBy: 'date' | 'price' = 'date';
  @Input() public plateClass: string = '';
  @Input() public showIndex: boolean = false;
  @Input() public mobileTiny: boolean = true;

  public sectionFeatureType: FeatureType;

  public registrations: Registration[] = [];
  public loading: boolean = true;

  constructor(
    private sessionService: SessionService,
    private dynamicPlacementService: DynamicPlacementService,
    private registrationService: RegistrationService
  ) { }

  ngOnInit(): void {
    this.configureType();
    this.fetchList();
  }

  private configureType(): void {
    if (Array.isArray(this.featureType)) {
      const isLoggedIn = this.sessionService.isLoggedIn();
      const allowedFeatured = this.featureType.filter((x) => {
        if (!x.loggedIn) return true;
        if (!isLoggedIn && x.loggedIn) return false;
        return x.loggedIn && isLoggedIn;
      });
      const random = Math.floor(Math.random() * allowedFeatured.length);
      this.sectionFeatureType = allowedFeatured[random];
    } else {
      this.sectionFeatureType = this.featureType;
    }
  }

  private fetchList(): void {
    this.loading = true;
    this.dynamicPlacementService
      .FeaturedListings(this.sectionFeatureType.featureType)
      .subscribe((_: Registration[]) => {
        this.registrations = this.registrationService.formatRegistrations(_);

        // SORT
        if (this.sortBy === 'price') {
          this.registrations.sort((a, b) => b.price - a.price);
        }

        // TRIM
        this.registrations = this.registrations.slice(0, this.maxCount);

        this.loading = false;
      });
  }
}
