import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pla-custom-loader',
  templateUrl: './custom-loader.component.html',
  styleUrls: ['./custom-loader.component.scss']
})
export class CustomLoaderComponent implements OnInit {

  @Input() public progress: number;
  @Input() public barColour: string = '#327b8d';
  constructor() { }

  ngOnInit(): void {
  }

  public progressPercent(): string {
    return `${100 - this.progress}%`;
  }

}
