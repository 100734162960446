<div class="page">
  <pla-main-nav-chips></pla-main-nav-chips>
  <div class="content-container extended">
    <div class="ps-5 side-container mb-3">
      <div class="pt-0 position-relative">
        <pla-side-nav></pla-side-nav>
      </div>

      <div *ngIf="false" class="bg-light py-3">
        <pla-featured-plates [featureType]="npFeatured" [plateSize]="'smaller'" [priceFontSize]="16" [maxCount]="10"
          [sortBy]="'price'" [plateClass]="'flat shaded'" [showIndex]="true"></pla-featured-plates>
      </div>
    </div>
    <div class="center-content-container position-relative">
      <div class="pla_card mobile spacing no-top no-card no-left no-right position-relative">
        <div *ngIf="false">
          <!-- BREADCRUMBS -->
          <div class="w-100">
            <div
              class="border-bottom d-flex align-items-center justify-content-start gap-2 w-100 px-3 px-lg-0 pb-2 pt-0">
              <a style="color: #327b8d" class="d-block text-decoration-none" [routerLink]="['/']">Home</a>
              <span>/</span>
              <a style="color: #939393; white-space: pre" class="text-decoration-none d-block"
                [routerLink]="['/names']">Names</a>
            </div>
          </div>
        </div>

        <pla-banner-image [carBanner]="'JAMES_RR'" [mobileCarBanner]="'JAMES_RR_MB'" [rawImage]="true"
          [backgroundImage]="true" [backgroundClass]="'compare-mobile-background'">
          <div class="compare-banner-height">
            <div class="d-flex d-md-flex align-items-start justify-content-between flex-column">
              <div class="p-4 p-md-4">
                <p class="lh-sm fw-bold fs-md-28 fs-mb-24 text-white">Exclusive Name Plates</p>
              </div>
            </div>
          </div>
        </pla-banner-image>

        <div class="mt-4">
          <p class="px-4 text-start fw-bolder fs-md-20 fs-mb-18 mb-0 mx-auto lh-sm hs-mb">A collection of perfect plates
            for names from around the globe</p>
          <p class="px-4 text-start fs-md-16 fs-mb-14 mb-2 mb-md-3 mx-auto lh-sm">The culmination of tens of thousands
            of searches, this collection features only 100% perfect, flawless name
            plates from across the market in one exclusive, curated collection.</p>

          <p class="px-4 text-start fs-md-16 fs-mb-14 mb-0 mb-md-0 mx-auto lh-sm">This unique collection isn’t limited
            to just UK names – you can select names from 28 other countries and
            counting. If your perfect name plate isn’t available right now, simply click the notify button, and we’ll
            message you as soon as it comes to market.</p>


          <div class="s-spacing"></div>
        </div>
      </div>

      <div class="pla_card mobile spacing no-top no-card no-left no-right position-relative px-2 py-0 me-0 me-md-2">
        <!-- <pla-sticky-tabs [config]="stickyLinkConfig"></pla-sticky-tabs> -->

        <div class="pt-3 pb-0 w-100 px-1 px-md-1 position-relative border-top" id="names-content">
          <div class="position-absolute" style="top: -92px" id="section-names"></div>
          <div class="d-flex align-items-center justify-content-between py-2 px-0 gap-2 gap-md-3 overflow-auto">
            <ng-container *ngFor="let flagFilter of flagFilters">
              <div class="flex-shrink-0 pb-3">
                <div class="rounded-pill ps-1 pe-3 py-1 d-flex align-items-center justify-content-start gap-3"
                  style="cursor: pointer; border-style: solid;" (click)="toggleFilter(flagFilter.filter)" [ngStyle]="{
                    'border-width': FilterActive(flagFilter.filter) ? '2px' : '1px',
                  'box-shadow': FilterActive(flagFilter.filter)
                    ? '2px 2px 7px 3px inset rgba(0,0,0,0.02)'
                    : 'none',
                  'borderColor': FilterActive(flagFilter.filter)
                    ? '#3791A8'
                    : '#969696'
                }">
                  <div class="flex-shrink-0 d-block bg-secondary rounded-circle overflow-hidden" style="width: 24px; height: 24px;     background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;"
                    [style.background-image]="'url(https://cdn.platex.co.uk/assets/flags/' + flagFilter['flag'] + '.png)'">
                  </div>
                  <span class="flex-shrink-0 w-100 text-dark text-start lh-sm my-0 d-block fs-md-16 fs-mb-13">{{
                    flagFilter.name
                    }}</span>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="px-0 d-flex align-items-center justify-content-between gap-2">
          <div class="d-flex align-items-center justify-content-start my-3">
            <div>
              <mat-checkbox [(ngModel)]="hideSold" (change)="BuildResultMatrix()">Hide Sold Plates</mat-checkbox>
            </div>
          </div>
        </div>

        <div class="w-100 d-block">
          <div class="mb-3 mx-auto w-100 border-start-0 border-end-0 border-1 border-top border-bottom"
            style="background: #f8f8f8; border-color: #c3c3c3 !important">
            <div class="d-flex align-items-center justify-content-between py-1 px-1 overflow-auto">
              <ng-container *ngFor="let letter of alphabet">
                <button [disabled]="PlateCountForLetter(letter) == 0" [ngClass]="{
                    'opacity-25': PlateCountForLetter(letter) == 0
                  }" class="border border-2 bg-transparent fs-md-20 fs-mb-16" (click)="setAlphabet(letter)" style="aspect-ratio: 1; color: {{
                    currentAlphabet == letter ? '#214953' : '#969696'
                  }} !important;
                      border-color: {{
                    currentAlphabet == letter ? '#214953' : '#F8F8F8'
                  }} !important;">
                  {{ letter }}
                </button>
              </ng-container>
            </div>
          </div>
        </div>

        <ng-container *ngIf="loading">
          <div
            class="d-flex align-items-center justify-content-evenly justify-content-md-between gap-3 flex-wrap px-0 mb-3">
            <ng-template *ngFor="let item of [].constructor(16)" [ngTemplateOutlet]="loadingPlate"></ng-template>
          </div>
        </ng-container>
        <ng-container *ngIf="!loading">
          <div class="mb-5" *ngIf="PlatesForLetter(currentAlphabet).length > 0" id="letter-{{ letter }}">
            <div
              class="d-flex align-items-center justify-content-evenly justify-content-md-between gap-3 flex-wrap px-0 px-md-3">
              <ng-container *ngFor="let plate of PlatesForLetter(currentAlphabet)">
                <ng-template [ngTemplateOutlet]="plateRow" [ngTemplateOutletContext]="{ plate: plate }"></ng-template>
              </ng-container>

              <div class="flex-fill px-2 h-0">
                <pla-plate [blank]="true" [customClass]="'flat fixed'"></pla-plate>
              </div>
              <div class="flex-fill px-2 h-0">
                <pla-plate [blank]="true" [customClass]="'flat fixed'"></pla-plate>
              </div>
              <div class="flex-fill px-2 h-0">
                <pla-plate [blank]="true" [customClass]="'flat fixed'"></pla-plate>
              </div>
              <div class="flex-fill px-2 h-0">
                <pla-plate [blank]="true" [customClass]="'flat fixed'"></pla-plate>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- MARKET INFO -->
        <div class="px-0 px-md-4 mx-auto">
          <div *ngIf="false">
            <p class="fw-bold mb-2 fs-md-16">New Issue Private Registrations</p>
            <p class="fs-md-14 mb-3">
              Investing in new, never previously registered private plates
              presents a unique opportunity for significant added value. Each
              year, the DVLA releases a new series of number plates to the
              public at fixed prices. While the DVLA does hold back several
              plates for auction—believing these will fetch higher prices
              through competitive bidding—there remain numerous opportunities
              within the general release for astute buyers to find valuable
              plates.
            </p>
          </div>

          <div class="pt-5 pb-5 w-100 px-3 px-md-1 position-relative border-top" id="market-information-content">
            <div class="position-absolute" style="top: -92px" id="section-market-information"></div>
            <!-- CONTENT -->
            <pla-name-popularity-data></pla-name-popularity-data>
          </div>
        </div>

        <div class="pt-5 pb-5 w-100 px-3 px-md-1 position-relative bg-white border-top" id="faq-content">
          <div class="position-absolute" style="top: -92px" id="section-faq"></div>
          <pla-faq [faq]="faq"></pla-faq>
        </div>
      </div>
    </div>
  </div>

  <div class="content-container" *ngIf="false">
    <pla-banner-image class="w-100" [rawImage]="true" [backgroundImage]="true" [carBanner]="'AVENTADOR_S_FOOTER'"
      [backgroundClass]="'w-100 desktop-mobile-bottom'">
      <div class="d-flex align-items-stretch justify-content-end new-banner-height">
        <div
          class="w-75 w-md-100 p-4 ps-5 ps-md-4 d-flex align-items-center justify-content-center ltr_gradient md-no-gradient">
          <div class="d-flex align-items-center justify-content-center flex-column ms-auto me-0"
            style="max-width: 450px">
            <p class="fs-5 text-white">
              With the entire market of registrations on one single portal,
              industry leading optimised search facilities and even price
              comparison it's no wonder buyers are choosing PlateX to find their
              perfect plate.
            </p>

            <div class="align-self-end mt-4">
              <button class="ms-auto me-0 turquoise-button" mat-flat-button>
                Begin Free Trial
              </button>
            </div>
          </div>
        </div>
      </div>
    </pla-banner-image>
  </div>
</div>

<div class="page" *ngIf="false">
  <div class="page-description-container"></div>
  <div class="main-content-container content-container">
    <div class="center-content-container">
      <!-- SEARCH CRITERIA -->
      <div class="pla_card no-card no-left no-right mobile spacing no-top">
        <div>
          <pla-banner-image [carBanner]="'FERRARI_812'" [rawImage]="true" [backgroundImage]="true"
            [backgroundClass]="'desktop-mobile-background'">
            <div class="d-flex align-items-center justify-content-between flex-column p-4 p-md-4"
              style="background-color: rgba(5, 71, 88, 0.5); min-height: 200px">
              <!-- HEADER TEXT -->
              <div class="text-white w-100 align-self-start justify-content-start ms-0" style="max-width: 400px">
                <p class="h1 mb-1 fw-bold">Top Name Plates</p>
                <div>
                  <p class="fw-light fs-6">
                    Discover the best 'name' plates available on the UK's Number
                    Plate Portal
                  </p>
                </div>
              </div>
            </div>
          </pla-banner-image>
        </div>
      </div>

      <!-- RESULTS -->
      <div class="pla_card no-card mobile spacing">
        <div class="py-3 px-1">
          <div class="d-block d-md-flex align-items-start justify-content-between">
            <div>
              <h3 class="h2 fw-normal mb-3">UK Name Registration Plates</h3>
              <p class="mb-3 fs-6">
                Thousands of buyers take advantage of PlateX portal whole market
                search and price comparison technology to find their perfect
                plate.
              </p>
              <p class="mb-0 fs-6">
                Introducing the top list of named look-alike registrations
                within the UK format. This curated list reflects common names,
                allowing users to personalize their license plates in a playful
                and memorable manner.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingPlate>
  <div
    class="d-flex align-items-center justify-content-center flex-column flex-fill border rounded p-2 plate-result-container skeleton">
    <div class="w-100 mx-auto" style="max-width: 230px">
      <div class="w-100 d-flex align-items-center justify-content-between mb-2 mx-auto">
        <div style="width: 30px; height: 30px"></div>
        <a class="skeleton-item fw-bolder my-0 text-truncate lh-sm text-decoration-none fs-md-14 lh-sm d-block w-100 text-center mx-auto"
          style="max-width: fit-content; color: transparent">LOADING</a>
        <div class="" style="width: 30px; height: 30px"></div>
      </div>
      <div class="">
        <pla-plate [loading]="true" [showPrice]="false" [changeHover]="false" [mobileSmall]="true"
          [customClass]="'flat shaded'" [plateSize]="'nr-s'" [smallPlate]="false" [showPercentage]="false"
          [noMargin]="true" [white]="false" [termFirst]="false" [showIndex]="false"
          [showSoldRibbon]="false"></pla-plate>
      </div>
      <div class="d-flex mx-auto w-100 mt-2 mb-0 align-items-center justify-content-between">
        <span class="skeleton-item flex-fill rounded-3 p-1 fs-md-12 w-100 lh-sm"
          style="line-height: 30px; color: transparent">£ LOADING</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #plateRow let-plate="plate">
  <div
    class="d-flex align-items-center justify-content-center flex-column flex-fill border rounded p-2 plate-result-container">
    <div class="w-100 mx-auto" style="max-width: 230px">
      <div class="w-100 d-flex align-items-center justify-content-between mb-2 mx-auto">
        <div style="width: 30px; height: 30px"></div>

        <a routerLink="/search-results/{{ plate.term }}" style="max-width: fit-content" class="fw-bolder my-0 text-truncate lh-sm text-decoration-none fs-md-14 lh-sm d-block w-100 text-center {{
            plate.plateOwner == -1 ? 'text-muted' : 'text-dark'
          }}">{{ plate.term }}</a>

        <mat-icon data-nosnippet *ngIf="plate.plateOwner == 0" class="small d-block w-100"
          style="max-width: 30px; color: #007850">new_releases</mat-icon>
        <div *ngIf="plate.plateOwner != 0" class="" style="width: 30px; height: 30px"></div>
      </div>
      <div>
        <pla-plate [plate]="plate" [showPrice]="false" [changeHover]="false" [hoverText]="plate.term"
          [mobileSmall]="true" [customClass]="'flat shaded'" [plateSize]="'nr-s'" [smallPlate]="false"
          [showPercentage]="false" [noMargin]="true" [disabled]="plate.plateOwner == -1" [white]="false"
          [termFirst]="false" [showIndex]="false" [secondaryText]="plate.formattedRegistration"
          [showSoldRibbon]="false"></pla-plate>
      </div>
      <div class="d-flex mx-auto w-100 mt-2 mb-0 align-items-center justify-content-between">
        <span class="flex-fill rounded-3 p-1 fs-md-12 w-100 lh-sm" style="line-height: 30px"
          *ngIf="plate.plateOwner > -2" [ngStyle]="{ color: plate.plateOwner == -1 ? '#860202' : '#214953' }">{{
          plate.plateOwner == -1 ? "SOLD" : "FROM" }}
          {{
          (plate.priceBreakdown
          ? plate.priceBreakdown.total
          : plate.price / 100
          ) | currency : "GBP" : "symbol" : "1.0-0"
          }}</span>

        <!-- ACTIONS -->
        <div *ngIf="plate.plateOwner <= -1"
          class="flex-shrink-0 d-flex align-items-center justify-content-end gap-1 pointer notify-btn px-1 rounded-2"
          [ngClass]="{ set: plate.notify }" (click)="notifyRegistration(plate)">
          <span>{{ plate.notify ? "Notified" : "Notify" }}</span>
        </div>
        <div *ngIf="plate.plateOwner !== -1"
          class="flex-shrink-0 d-flex align-items-center justify-content-end gap-1 pointer favourite-icn px-0 rounded-2"
          [ngClass]="{ set: plate.favourite }" (click)="favouriteRegistration(plate)">
          <!-- <span>{{ plate.favourite ? 'Saved' : 'Save'}}</span> -->
          <mat-icon data-nosnippet class="small icon">{{
            plate.favourite ? "favorite" : "favorite_border"
            }}</mat-icon>
        </div>
      </div>
    </div>
  </div>
</ng-template>