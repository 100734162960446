import * as THREE from 'three';

export class SmartPlateViewer {
  public isAdmin;
  public text;
  public imagePath;
  public fontPath;
  public width;
  public height;
  public savedPositionData;
  public camera;
  public scene;
  public renderer: THREE.WebGLRenderer;
  public cameraPosition = [0, 0, 100];
  public cameraTarget;
  public boxMesh;
  public targetRotation = 0;
  public targetRotationOnPointerDown = 0;
  public pointerXOnPointerDown = 0;
  public windowHalfX;
  public raycaster = new THREE.Raycaster();
  public point = new THREE.Vector2();
  public useDrag = true;
  public container = null;
  public fontColour: string;
  public drawLogo: boolean = false;

  constructor() {
    // try and load font
  }

  public dispose(): void {
    this.renderer.dispose();
  }

  public startWithPosition(params, callback: (src: string) => void): void {
    this.useDrag = false;

    if (params !== null) {
      this.isAdmin = params.isAdmin;
      this.text = params.text;
      this.imagePath = params.imageInfo.path;
      this.fontPath = params.fontPath;
      this.width = params.imageInfo._width;
      this.height = params.imageInfo._height;
      this.fontColour = params.textInfo.color;

      this.savedPositionData = params;
    }

    this.init(() => {
      // complete
      callback(this.getImage());
      this.renderer.domElement.remove();
    });
  }

  public init(callback: () => void): void {
    if (THREE == null) {
      console.error('three.js import is null');
      return;
    }
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    if (this.renderer == null) {
      console.error('three.js is null');
      return;
    }

    //this.container = document.getElementById('smart-plate-viewer-container');
    var canvasContainer = document.createElement('div');
    canvasContainer.style.display = 'none';
    this.container = canvasContainer;
    this.windowHalfX = this.width / 2;

    THREE.ColorManagement.enabled = false;
    this.renderer.outputColorSpace = THREE.LinearSRGBColorSpace;

    this.renderer.setSize(this.width, this.height);
    this.container.appendChild(this.renderer.domElement);

    this.camera = new THREE.PerspectiveCamera();

    this.camera.position.set(...this.cameraPosition);

    this.cameraTarget = new THREE.Vector3(0, 0, 0);
    this.camera.lookAt(this.cameraTarget);

    this.scene = new THREE.Scene();

    const textureBackground = new THREE.TextureLoader().load(
      this.imagePath,
      () => {
        this.scene.background = textureBackground;
        this.createText(callback);

        // EVENTS
        this.container.style.touchAction = 'none';
      }
    );
  }

  public createText(callback: () => void, attempt = 0): void {
    var textCanvas = document.createElement('canvas');
    var context = textCanvas.getContext('2d');

    /*
    Add logo
    */
    const logoTexture = new THREE.TextureLoader().load(
      '/assets/white_logo.png',
      () => {
        textCanvas.width = 600;
        textCanvas.height = 60;

        if (!document.fonts.check('60px UKReg') && attempt < 10) {
          console.warn('font unloaded', attempt);
          setTimeout(() => {
            this.createText(callback, attempt + 1);
          }, 1000);
          return;
        }

        if (attempt >= 10) {
          console.error('Font was not loaded, incorrect image');
        }

        context.textAlign = 'center';
        context.font = '60px UKReg';
        context.fillStyle = this.fontColour;

        var __ = context.measureText('WWW WWWW');
        context.fillText(this.text, 300, (60 + 40) / 2);

        const canvasTexture = new THREE.Texture(textCanvas);
        canvasTexture.needsUpdate = true;
        const canvasGeometry = new THREE.PlaneGeometry(__.width, 40);
        canvasGeometry.computeBoundingBox();
        canvasGeometry.center();

        this.boxMesh = new THREE.Mesh(
          canvasGeometry,
          new THREE.MeshBasicMaterial({
            map: canvasTexture,
            side: THREE.DoubleSide,
            transparent: true,
          })
        );
        this.scene.add(this.boxMesh);

        if (typeof this.savedPositionData !== 'undefined') {
          this.boxMesh.position.set(
            parseFloat(this.savedPositionData.textInfo.position.x),
            parseFloat(this.savedPositionData.textInfo.position.y),
            parseFloat(this.savedPositionData.textInfo.position.z)
          );

          this.boxMesh.scale.set(
            parseFloat(this.savedPositionData.textInfo.scale.x),
            parseFloat(this.savedPositionData.textInfo.scale.y),
            parseFloat(this.savedPositionData.textInfo.scale.z)
          );

          this.boxMesh.rotation.x = parseFloat(
            this.savedPositionData.textInfo.rotation._x
          );
          this.boxMesh.rotation.y = parseFloat(
            this.savedPositionData.textInfo.rotation._y
          );
          this.boxMesh.rotation.z = parseFloat(
            this.savedPositionData.textInfo.rotation._z
          );
        }

        logoTexture.needsUpdate = true;
        const logoGeometry = new THREE.PlaneGeometry(100, 35);
        logoGeometry.computeBoundingBox();
        logoGeometry.center();

        const logoMesh = new THREE.Mesh(
          logoGeometry,
          new THREE.MeshBasicMaterial({
            map: logoTexture,
            side: THREE.DoubleSide,
            transparent: true,
          })
        );

        logoMesh.position.z = 1;
        logoMesh.position.y = -((this.height / 2) * 0.1) + 22;

        logoMesh.scale.setScalar(0.15);

        if (this.drawLogo) {
          this.scene.add(logoMesh);
        }

        this.render(callback);
      }
    );
  }

  public render(callback: () => void = () => {}): void {
    this.renderer.clear();
    this.renderer.render(this.scene, this.camera);
    callback();
  }

  public getImage = function () {
    this.renderer.render(this.scene, this.camera);
    return this.renderer.domElement.toDataURL('image/jpeg', 0.75);
  };
}
