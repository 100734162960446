<div>
  <div *ngIf="loading || results">
    <pla-search-result-table
      [customHeaderContent]="true"
      [headerText]="searchHeader"
      [searching]="loading"
      [results]="results"
      [showFilter]="false"
      [showSort]="true"
      [showToggle]="false"
      [paginationPageSize]="500"
      [showPagination]="false"
      [resultChange]="resultChange"
      [resultRowType]="resultType"
      [allowShowMore]="false"
      [showTerm]="true"
      [whitePlate]="false"
      [headerInFooter]="true"
      [customHeader]="customHeader"
      [scrollToTopTrigger]="scrollToTopTrigger"
      [customButton]="deleteItem"
    >
    </pla-search-result-table>
  </div>
</div>
