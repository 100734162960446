<div class="page">
  <div class="section page-width page-container">
    <div>
      <h1 data-nosnippet>Sign in or create an account</h1>
      <p>
        A Platex account gives you access to all our services. It lets you buy
        and sell registrations, track orders and much more.
      </p>
    </div>

    <div class="details-container">
      <div class="inner-container">
        <h1 data-nosnippet>Sign In</h1>
        <div class="form-container">
          <mat-form-field>
            <input matInput required placeholder="Email" name="email" type="email" [(ngModel)]="loginModel.email" />
          </mat-form-field>

          <mat-form-field>
            <input matInput required placeholder="Password" name="password" type="password"
              [(ngModel)]="loginModel.password" color="warn" />
          </mat-form-field>

          <div *ngIf="!loading">
            <button mat-raised-button class="turquoise-button" (click)="login()">
              Sign In
            </button>
          </div>
        </div>

        <div class="loading-container" *ngIf="loading">
          <mat-progress-spinner class="loading-spinner" color="accent" mode="indeterminate">
          </mat-progress-spinner>
        </div>
      </div>

      <div class="flex-space">
        <div class="spacer"></div>
      </div>

      <div class="inner-container d-none">
        <h1 data-nosnippet>Create an account</h1>
        <p>
          A Platex account gives you access to all our services. It lets you buy
          and sell registrations, track orders and much more.
        </p>
        <div>
          <button mat-raised-button class="turquoise-button" (click)="signUp()">
            Sign Up
          </button>
        </div>
      </div>
    </div>
  </div>
</div>