import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export class DropdownMeta {
  showSelected?: boolean = false;
  selectOnStart?: boolean = true;
  initialValue?: DropdownOption = undefined;
}

export class DropdownConfig {
  constructor(public title: string, public icon: string, public options: DropdownOption[] = [], public meta: DropdownMeta = new DropdownMeta()) { }
}

export class DropdownOption {
  constructor(public value: string, public view: string, public metaData: any = {}) { }
}

@Component({
  selector: 'pla-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss'],
})
export class CustomDropdownComponent implements OnInit {
  public hasChanged: boolean = false;
  @Input() public raw: boolean = false;
  @Input() public showBullet: boolean = false;
  @Input() public justify: string = 'justify-content-center';
  @Input() public containerClass: string;
  @Input() public end: boolean = false;
  @Input() public baseClass: string;
  @Input() public openedClass: string;
  @Input() public closedClass: string;
  @Input() public optionClass: string;
  @Input() public config: DropdownConfig;
  @Input() public remoteOpen: EventEmitter<void> = new EventEmitter<void>();
  @Input() public forceSelect = new EventEmitter<DropdownOption>();
  @Output() public change = new EventEmitter<DropdownOption>();
  public selectedValue: DropdownOption;
  public open: boolean;

  constructor() { }

  ngOnInit(): void {
    this.Init();
  }

  private Init(): void {
    this.remoteOpen.subscribe(() => this.ToggleOpen());
    this.forceSelect.subscribe((option: DropdownOption) => {
      this.SelectValue(option);
    });

    if (this.config == undefined) return;
    if (this.config.options.length == 0) return;
    if (this.config.meta.selectOnStart) {
      if (this.config.meta.initialValue !== undefined) {
        this.selectedValue = this.config.meta.initialValue;
        this.change.emit(this.config.meta.initialValue);
        this.hasChanged = true;
      } else {
        this.selectedValue = this.config.options[0];
        this.change.emit(this.config.options[0]);
      }
    }
    this.hasChanged = false;
  }

  public ToggleOpen(): void {
    this.open = !this.open;
  }

  public SelectValue(option: DropdownOption): void {
    this.open = false;
    this.hasChanged = true;
    if (this.selectedValue == option) return;
    this.selectedValue = option;
    this.change.emit(option);
  }

  public VisibleOptions(options: DropdownOption[]): DropdownOption[] {
    return options;
    // return options.filter(o => !this.hasChanged ? true : this.selectedValue.value != o.value);
  }
}
