import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DropdownConfig, DropdownOption } from 'src/app/components/custom-dropdown/custom-dropdown.component';
import {
  FaqBuilder,
  InfoDropdown,
  InfoDropdownContent,
} from 'src/app/components/faq/faq.component';
import { FeatureType } from 'src/app/components/featured-plates/featured-plates.component';
import {
  PAGE_PLACEMENTS,
  PagePlacement,
  PlacementAction,
  PlacementColour,
  PlacementType,
} from 'src/app/components/page-placement/page-placement.component';
import { SidebarFilterConfig, SidebarFilterGroup, SidebarFilter } from 'src/app/components/side-bar-filters/side-bar-filters.component';
import {
  SECONDARY_STICKY_CONFIG,
  StickyConfig,
  StickyLink,
  StickyLinkGroup,
} from 'src/app/components/sticky-tabs/sticky-tabs.component';
import { BYOTermResponse } from 'src/app/models/byoTermResponse';
import { HttpLoadingResp } from 'src/app/models/httpLoadingResp';
import { NewReleaseResult } from 'src/app/models/newReleaseResult';
import { Registration } from 'src/app/models/registration';
import { QuickSignupService } from 'src/app/services/quick-signup-service';
import { RegistrationService } from 'src/app/services/registration-service';
import { SearchService } from 'src/app/services/search-service';
import { UserService } from 'src/app/services/user-service';
import { SearchFilters } from '../search-results-page/search-results-page.component';

@Component({
  selector: 'pla-new-release-page',
  templateUrl: './new-release-page.component.html',
  styleUrls: ['./new-release-page.component.scss'],
})
export class NewReleasePageComponent implements OnInit {
  public signupTrigger: EventEmitter<void> = new EventEmitter<void>();
  public customSignUpTrigger: EventEmitter<string> = new EventEmitter<string>();
  public showAllSeries: boolean = false;
  public showInlineImage: boolean = false;
  public colourBannerMap: {
    year: string;
    colour: string;
    desktopImage: string;
    mobileImage: string;
    resultImage: string;
    term?: string,
    resultPlate: string;
    result: NewReleaseResult;
  }[] = [
      {
        year: '51',
        colour: '#447E96',
        desktopImage: 'DESIGNS_Z4',
        mobileImage: 'DESIGNS_Z4',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '02',
        colour: '#447E96',
        desktopImage: 'GEORGES_CGT',
        mobileImage: 'GEORGES_CGT',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '52',
        colour: '#447E96',
        desktopImage: 'RUSRELL_RS3',
        mobileImage: 'RUSRELL_RS3',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '03',
        colour: '#447E96',
        desktopImage: 'WHOEVER_CYBERTRUCK',
        mobileImage: 'WHOEVER_CYBERTRUCK',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '53',
        colour: '#447E96',
        desktopImage: 'ARSENAL_ALPINE',
        mobileImage: 'ARSENAL_ALPINE',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '04',
        colour: '#447E96',
        desktopImage: 'SLOANES_BENTLEY',
        mobileImage: 'SLOANES_BENTLEY',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '54',
        colour: '#447E96',
        desktopImage: 'LASANGE_FERRARI',
        mobileImage: 'LASANGE_FERRARI',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '05',
        colour: '#447E96',
        desktopImage: 'GHOSTED_BMW',
        mobileImage: 'GHOSTED_BMW',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '55',
        colour: '#447E96',
        desktopImage: 'MASSAGE_FIAT500',
        mobileImage: 'MASSAGE_FIAT500',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '06',
        colour: '#447E96',
        desktopImage: 'HASGAME_URUS',
        mobileImage: 'HASGAME_URUS',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '56',
        colour: '#447E96',
        desktopImage: 'BLOGGER_MINI',
        mobileImage: 'BLOGGER_MINI',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '07',
        colour: '#447E96',
        desktopImage: 'SHOTGUN_DEFENDER',
        mobileImage: 'SHOTGUN_DEFENDER',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '57',
        colour: '#447E96',
        desktopImage: 'FASTEST_LAFERRARI',
        mobileImage: 'FASTEST_LAFERRARI',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '08',
        colour: '#447E96',
        desktopImage: 'PROBLEM_6X6',
        mobileImage: 'PROBLEM_6X6',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '58',
        colour: '#447E96',
        desktopImage: 'HUSBAND_I8',
        mobileImage: 'HUSBAND_I8',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '09',
        colour: '#447E96',
        desktopImage: 'VLOGGER_DEFENDER',
        mobileImage: 'VLOGGER_DEFENDER',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '59',
        colour: '#447E96',
        desktopImage: 'CURATED_MURCI',
        mobileImage: 'CURATED_MURCI',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '10',
        colour: '#447E96',
        desktopImage: 'BELOVED_R8',
        mobileImage: 'BELOVED_R8',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '60',
        colour: '#447E96',
        desktopImage: 'UNGODLY_AMG',
        mobileImage: 'UNGODLY_AMG',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '11',
        colour: '#447E96',
        desktopImage: 'BALLERS_URUS',
        mobileImage: 'BALLERS_URUS',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '61',
        colour: '#447E96',
        desktopImage: 'CURATED_MURCI',
        mobileImage: 'CURATED_MURCI_MB',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '12',
        colour: '#447E96',
        desktopImage: 'EMIRATE_ROLLS',
        mobileImage: 'EMIRATE_ROLLS',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '62',
        colour: '#447E96',
        desktopImage: 'CURATED_MURCI',
        mobileImage: 'CURATED_MURCI_MB',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '13',
        colour: '#447E96',
        desktopImage: 'FRIENDS_MINI',
        mobileImage: 'FRIENDS_MINI',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '63',
        colour: '#447E96',
        desktopImage: 'CURATED_MURCI',
        mobileImage: 'CURATED_MURCI_MB',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '14',
        colour: '#447E96',
        desktopImage: 'GELATOS_FIAT500',
        mobileImage: 'GELATOS_FIAT500',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '64',
        colour: '#447E96',
        desktopImage: 'CURATED_MURCI',
        mobileImage: 'CURATED_MURCI_MB',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '15',
        colour: '#447E96',
        desktopImage: 'BRISTOL_I8',
        mobileImage: 'BRISTOL_I8',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '65',
        colour: '#447E96',
        desktopImage: 'CURATED_MURCI',
        mobileImage: 'CURATED_MURCI_MB',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '16',
        colour: '#447E96',
        desktopImage: 'FLIGHTS_PORSCHE',
        mobileImage: 'FLIGHTS_PORSCHE',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '66',
        colour: '#447E96',
        desktopImage: 'CURATED_MURCI',
        mobileImage: 'CURATED_MURCI_MB',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '17',
        colour: '#447E96',
        desktopImage: 'CURATED_MURCI',
        mobileImage: 'CURATED_MURCI_MB',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '67',
        colour: '#447E96',
        desktopImage: 'CURATED_MURCI',
        mobileImage: 'CURATED_MURCI_MB',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '18',
        colour: '#447E96',
        desktopImage: 'CURATED_MURCI',
        mobileImage: 'CURATED_MURCI_MB',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '68',
        colour: '#447E96',
        desktopImage: 'CURATED_MURCI',
        mobileImage: 'CURATED_MURCI_MB',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '19',
        colour: '#447E96',
        desktopImage: 'CURATED_MURCI',
        mobileImage: 'CURATED_MURCI_MB',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '69',
        colour: '#447E96',
        desktopImage: 'CURATED_MURCI',
        mobileImage: 'CURATED_MURCI_MB',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '70',
        colour: '#447E96',
        desktopImage: 'CURATED_MURCI',
        mobileImage: 'CURATED_MURCI_MB',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '71',
        colour: '#447E96',
        desktopImage: 'CURATED_MURCI',
        mobileImage: 'CURATED_MURCI_MB',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '22',
        colour: '#447E96',
        desktopImage: 'CURATED_MURCI',
        mobileImage: 'CURATED_MURCI_MB',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '72',
        colour: '#447E96',
        desktopImage: 'CURATED_MURCI',
        mobileImage: 'CURATED_MURCI_MB',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '23',
        colour: '#447E96',
        desktopImage: 'CURATED_MURCI',
        mobileImage: 'CURATED_MURCI_MB',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '73',
        colour: '#447E96',
        desktopImage: 'CURATED_MURCI',
        mobileImage: 'CURATED_MURCI_MB',
        resultImage: null,
        resultPlate: null,
        result: null,
      },
      {
        year: '24',
        colour: '#447E96',
        desktopImage: 'CURATED_MURCI',
        mobileImage: 'CURATED_MURCI_MB',
        resultImage:
          'https://cdn.platex.co.uk/pages/new-release/24-assets/RESULT_ENRAGED_PISTA.png',
        resultPlate: 'EN24GED',
        result: null,
      },
      {
        year: '74',
        colour: '#265243',
        desktopImage: 'ATTACKS_P1',
        mobileImage: 'ATTACKS_P1_MB',
        resultImage:
          'https://cdn.platex.co.uk/pages/new-release/24-assets/RESULT_MUTANTS_CYBER.png',
        resultPlate: 'MU74NTS',
        result: null,
      },
      {
        year: '25',
        colour: '#363F45',
        desktopImage: 'BERSERK_AMG',
        mobileImage: 'BERSERK_AMG_MB',
        resultImage:
          'https://cdn.platex.co.uk/pages/new-release/24-assets/RESULT_MUTANTS_CYBER.png',
        resultPlate: 'BE25ERK',
        term: 'BERSERK',
        result: null,
      },
      {
        year: '75',
        colour: '#483b32',//'#d9a320',
        desktopImage: 'HOTSHOT_URUS',
        mobileImage: 'HOTSHOT_URUS_MB',
        resultImage:
          'https://cdn.platex.co.uk/pages/new-release/HOTSHOT_URUS.png',
        resultPlate: 'BE25ERK',
        term: 'BERSERK',
        result: null,
      },
    ];

  public resultMapMatris: {
    width: number;
    prev: number;
    before: number;
    during: number;
  }[] = [
      { width: 1255, prev: 100000, before: 4, during: 4 },
      { width: 1254, prev: 10000, before: 3, during: 2 },
      { width: 1008, prev: 1254, before: 3, during: 2 },
      { width: 1000, prev: 1008, before: 4, during: 0 },
      { width: 903, prev: 1000, before: 3, during: 2 },
      { width: 768, prev: 903, before: 3, during: 2 },
      { width: 723, prev: 768, before: 2, during: 0 },
      { width: 546, prev: 723, before: 3, during: 0 },
      { width: 0, prev: 723, before: 2, during: 0 },
    ];

  public releaseYear: string;
  public releaseMonth: string;
  public DVLAreleaseMonth: string;
  public breadcrumbRelease: string;
  public saving: boolean = false;
  public notifiying: boolean = false;
  public currentAlphabet = 'A';
  public currentTermAlphabet = 'A';
  public alphabet: string[] = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  public stickyLinkConfig: StickyConfig;
  public rsFeatured = new FeatureType('Recently Sold', 'recently-sold');

  public future_faq: InfoDropdown[] = [
    FaqBuilder.build('faq_1', `When will the DVLA release the new '75' Series Registrations?`, [`The Driver and Vehicle Licensing Agency (DVLA) is anticipated to release the '75' series registrations around May 2025. This release will introduce approximately 8 million new vehicle registration numbers into circulation, providing a vast array of options for those interested in private number plates.`]),
    FaqBuilder.build('faq_2', `What does the '75' relate to in the registration?`, [`In the UK vehicle registration system, the '75' serves as the age identifier within the standard format: two letters (indicating the area code), two numbers (the age identifier), and three random letters. This format has been in use since September 2001 and helps determine the vehicle's registration period.`]),
    FaqBuilder.build('faq_3', `How does the number 75 relate to 2025?`, [`The age identifier in UK registrations changes biannually. For vehicles registered between March and August, the identifier corresponds to the last two digits of the year (e.g., '25' for 2025). For registrations from September onwards, 50 is added to the year's last two digits, resulting in '75' for vehicles registered from September 2025 onwards. This system aids in identifying the vehicle's registration period at a glance.`]),
    FaqBuilder.build('faq_4', `Are all '75' registrations seven characters long?`, [`Yes, all '75' series registrations will consist of seven characters, adhering to the current format introduced in September 2001. This format includes two letters (area code), two numbers (age identifier), and three random letters, distinguishing it from previous formats like prefix, suffix, and dateless registrations.`]),
    FaqBuilder.build('faq_5', `How much will '75' Private Registrations cost?`, [`The cost of '75' private registrations is expected to range from £399 to £3,999, depending on how the DVLA perceives factors such as the desirability and uniqueness of the combination. The DVLA may determine that highly sought-after personalised plate combinations could command higher prices, especially those featuring popular names or words, and choose to hold these back to auction.`]),
    FaqBuilder.build('faq_6', `Is it true that the DVLA holds back all the good plates to go to auction?`, [`The DVLA does reserve certain registrations for auction, particularly those anticipated to attract significant interest and higher bids. However, identifying these valuable combinations among nearly 8 million new registrations per release is a considerable task. Examples of '74' registrations held back for auction include:`, ``, `These selections demonstrate the DVLA's strategy to auction registrations with potential for higher value due to their distinctive combinations.`, `</p><ul><li>OC74 NES</li><li>NA74 LYA</li><li>NA74 SHA</li><li>NO74 BLE</li><li>OC74 GON</li><li>GE74 WAY</li><li>MA74 DOR</li></ul><p>`, `These selections demonstrate the DVLA's strategy to auction registrations with potential for higher value due to their distinctive combinations.`]),
    FaqBuilder.build('faq_7', `Are new release private plates a good investment?`, [
      `Yes, absolutely! At PlateX, we leverage our advanced search algorithm to uncover word plates that may otherwise go unnoticed. Our findings include many one- and two-word perfect plates from the Current series, all of which are added to our <a href="/new-release/75">New Release</a> page for easy browsing. This technology ensures that no opportunity is missed for securing unique and meaningful plates at fixed prices.`,
      `While the DVLA does hold back many notable word plates for auction, some valuable combinations still slip through to general release. For instance, the plate <a href="/plate-detail/KN19HTS">KN19 HTS</a> was sold during general release for only £399, while its sister plate, <a href="/plate-detail/KN16HTS">KN16 HTS</a>, was reserved for auction and fetched an impressive £36,802—nearly 100 times more!`,
      `To ensure you don’t miss out, registered users on PlateX gain exclusive access to potential word plate combinations weeks ahead of the general release.`
    ]),
    FaqBuilder.build('faq_8', `I cannot access the Future 75 Registration page`, [
      `Access to view potential new word registrations on the <a href="/new-release/75">Future 75 Registration</a> page is exclusively available to logged-in users. This ensures that our registered users gain early and exclusive access to potential word plate combinations uncovered by our advanced search technology.`
    ]),
    FaqBuilder.build('faq_9', `Where can I view the future issue “75” word plates?`, [
      `You can see all the word plate results first in our Future Issue - 75 Reg page.`
    ]),
    FaqBuilder.build('faq_10', `Looking for something more personal?`, [
      `The best way to find a personalised plate for your car is by using our advanced <a href="/buy">Super-Search</a> tool. This powerful feature provides a range of results tailored to your search terms, making it easy to discover private number plates that match your preferences.`,
      `If you're looking for the latest issue plates for your new car, these matches are displayed in the <a href="/buy">Newly listed</a> section of each word searched.`
    ]),
  ]

  public faq: InfoDropdown[] = [
    FaqBuilder.build('faq_1', `When will the DVLA release the new '25' Series Registrations?`, [`The Driver and Vehicle Licensing Agency (DVLA) released the '25' series registrations in March 2025. This release introduced nearly 8 million new vehicle registration numbers into circulation, providing an extensive range of private number plates for buyers.`]),
    FaqBuilder.build('faq_2', `What does the '25' relate to in the registration?`, [`In the UK vehicle registration system, the '25' serves as the age identifier in the standard format: two letters (indicating the area code), two numbers (the age identifier), and three random letters. The '25' identifies vehicles registered between March and August 2025.`]),
    FaqBuilder.build('faq_3', `How does the number 25 relate to 2025?`, [`The age identifier in UK vehicle registrations changes biannually. For vehicles registered between March and August, the identifier corresponds to the last two digits of the year ('25' for 2025). For registrations issued from September onwards, the age identifier changes to the year plus 50 (e.g., '75' for September 2025 onwards). This system makes it easy to identify when a vehicle was registered.`]),
    FaqBuilder.build('faq_4', `Are all '25' registrations seven characters long?`, [`Yes, all '25' series registrations consist of seven characters, following the current format introduced in September 2001. This format includes two letters (area code), two numbers (age identifier), and three random letters, distinguishing it from older formats like prefix, suffix, and dateless registrations.`]),
    FaqBuilder.build('faq_5', `How much will '25' Private Registrations cost?`, [`The cost of '25' private registrations typically ranges from £399 to £3,999, depending on the desirability and uniqueness of the combination. The DVLA may reserve highly sought-after plates, such as those featuring popular names or words, for auction. However, many excellent plates are available at fixed prices.`]),
    FaqBuilder.build('faq_6', `Is it true that the DVLA holds back all the good plates to go to auction?`, [`The DVLA does reserve certain registrations for auction, particularly those anticipated to attract significant interest and higher bids. However, identifying these valuable combinations among nearly 8 million new registrations per release is a considerable task. Examples of '74' registrations held back for auction include:`, ``, `These selections demonstrate the DVLA's strategy to auction registrations with potential for higher value due to their distinctive combinations.`, `</p><ul><li>OC74 NES</li><li>NA74 LYA</li><li>NA74 SHA</li><li>NO74 BLE</li><li>OC74 GON</li><li>GE74 WAY</li><li>MA74 DOR</li></ul><p>`, `These selections demonstrate the DVLA's strategy to auction registrations with potential for higher value due to their distinctive combinations.`]),
    FaqBuilder.build('faq_7', `Will there be any potentially valuable word plates available in the fixed-price general release?`, [
      `Yes, absolutely! At PlateX, we use our advanced search algorithm to identify valuable word plates that might otherwise be overlooked. Our findings include numerous one- and two-word plates from the Current '25' series, all of which are showcased on our <a href="/new-release/25">New Release - 25 Reg</a> page for easy access.`,
      `While the DVLA does hold back many notable word plates for auction, some valuable combinations still slip through to general release. For instance, the plate <a href="/plate-detail/KN19HTS">KN19 HTS</a> was sold during general release for only £399, while its sister plate, <a href="/plate-detail/KN16HTS">KN16 HTS</a>, was reserved for auction and fetched an impressive £36,802—nearly 100 times more!`,
      `To ensure you don’t miss out, registered users on PlateX gain exclusive access to potential word plate combinations weeks ahead of the general release.`
    ]),
    FaqBuilder.build('faq_8', `Where can I view the upcoming “75” registration`, [
      `You can see all the word plate results first on our <a href="/new-release/75">Future 25 Registration</a>`
    ]),
    FaqBuilder.build('faq_9', `I cannot access the Future 75 Registration page`, [
      `Access to view potential new word registrations on the <a href="/new-release/75">Future 75 Registration</a> page is exclusively available to logged-in users. This ensures that our registered users gain early and exclusive access to potential word plate combinations uncovered by our advanced search technology.`
    ]),
    FaqBuilder.build('faq_10', `Looking for something more personal?`, [
      `The best way to find a personalised plate for your car is by using our advanced <a href="/buy">Super-Search</a> tool. This powerful feature provides a range of results tailored to your search terms, making it easy to discover private number plates that match your preferences.`,
      `If you're looking for the latest issue plates for your new car, these matches are displayed in the <a href="/buy">Newly listed</a> section of each word searched.`
    ]),
    //     new InfoDropdown('nrp_1', `Can I Buy a New Release Private Number Plate Even If I Don't Have a New Vehicle?`, false, [
    //       new InfoDropdownContent(null, `<p class="lh-sm fs-md-16 fs-mb-16">
    // Yes, you can buy a new release private number plate even if you don't have a new vehicle. Private number plates can be purchased and held on a retention certificate (V778) for up to 10 years, with the option to renew it for another 10 years if needed. This means you can invest in a desirable private number plate from the DVLA's general release at a fixed price and save it for future use or resale. The retention certificate allows you to keep the number plate without assigning it to a vehicle immediately, providing flexibility and making private number plates a great investment option, regardless of whether you currently own a vehicle</p>`)
    //     ]),
    //     new InfoDropdown('nrp_2', `Have the DVLA Banned Any Number Plates from the New Release?`, false, [
    //       new InfoDropdownContent(null, `<p class="lh-sm fs-md-16 fs-mb-16">Yes, the DVLA has banned certain number plates from each new release. Every March and September, the DVLA holds a meeting to identify any permutations of the new digits that could form offensive words or resemble them. This meeting is reportedly one of the more popular ones within the DVLA, as staff creatively identify potentially rude and offensive plates before they are issued to motorists.</p>
    // <p class="lh-sm fs-md-16 fs-mb-16"><a class="link" href="#">Check out the most recent banned plates here.</a></p>
    // <p class="lh-sm fs-md-16 fs-mb-16">Anything related to violence, sex, or discrimination is automatically banned. Plates that slip through the net can also be rescinded at a later date. For example, BA22 ARD was banned when the '22' plate series was released in March 2022. Registrations referring to Covid-19, such as CO22 ONA, were also prohibited.</p>
    // <p class="lh-sm fs-md-16 fs-mb-16">This process ensures that the UK number plate system maintains a standard of decency and respect, preventing offensive or inappropriate messages from appearing on the roads. Investing in private number plates means adhering to these guidelines, ensuring a respectful and acceptable representation on your vehicle.</p>`)
    //     ]),
    //     new InfoDropdown('nrp_4', `Can I Make Money from Investing in New Issue Private Number Plates?`, false, [
    //       new InfoDropdownContent(null, `<p class="lh-sm fs-md-16 fs-mb-16">The short answer is yes!<br />
    //         Our analysis of thousands of private number plate transactions suggests that some of the largest and most predictable returns come from high-quality word and name private registrations first purchased new at a fixed price from the DVLA general release<br />
    //         You can see examples of private plate resales in the Market Insights section.</p>`)
    //     ]),
    //     new InfoDropdown('nrp_5', `What Are the Best Types of New Issue Private Plates to Buy for Investment?`, false, [
    //       new InfoDropdownContent(null, `<p class="lh-sm fs-md-16 fs-mb-16">
    // Our market research shows that new issue private plates that closely resemble popular names or words, tend to be fetch higher resale prices. For instance, the plate RU52ELL resembles the name "Russell" and was bought for £499 in 2002, then resold for £8,040 in 2023, achieving an ROI of 1511%. Another example is PO10CUP, which spells out "Polo Cup," referring to the equestrian sport. This plate was purchased for £499 in 2010 and sold for £5,228 in 2020, yielding a 948% ROI.</p>`)
    //     ]),
    //     new InfoDropdown('nrp_6', `How Does Investing in New Issue Private Number Plates Compare to Traditional Investments?`, false, [
    //       new InfoDropdownContent(null, `<p class="lh-sm fs-md-16 fs-mb-16">
    // While returns vary considerably due to the quality of the new issue registration, the ROI (Return on Investment) for private number plates can be exceptionally high compared to traditional investment options. For example, HE10YOU, bought for £250 in 2010, was resold for £2,700 in 2020, delivering an ROI of 980%. Similarly, NE57EGG, purchased for £399 in 2007, was sold for £3,355 in 2023, yielding an ROI of 741%. In comparison, the FTSE 100 achieved an approximate ROI of 70% from 2010 to 2020, while gold had an ROI of about 50% over the same period. These figures illustrate that with strategic selection and timing, the returns on private number plates can surpass those of stocks, real estate, and other conventional investments, making them a highly attractive investment option.</p>`)
    //     ])
  ];

  public nameReleaseResults: HttpLoadingResp<NewReleaseResult[]> =
    new HttpLoadingResp(true, []);
  public namesFiltered: NewReleaseResult[];
  public termReleaseResults: HttpLoadingResp<NewReleaseResult[]> =
    new HttpLoadingResp(true, []);
  public termsFiltered: NewReleaseResult[];
  public seriesBYO: BYOTermResponse;

  public termSorting: DropdownOption = new DropdownOption('HIGH-TO-LOW', 'Price High to Low');
  public mobileFilterToggle: EventEmitter<boolean> = new EventEmitter<boolean>();;
  public sortTrigger: EventEmitter<void> = new EventEmitter<void>();
  public filterChange: EventEmitter<SearchFilters> = new EventEmitter<SearchFilters>();
  public filterUpdate: EventEmitter<SidebarFilterConfig<any>> =
    new EventEmitter<SidebarFilterConfig<any>>();
  public sortDropdownConfig: DropdownConfig = new DropdownConfig('Sort By', 'swap_vert', [
    new DropdownOption('A-Z', 'A-Z'),
    new DropdownOption('Z-A', 'Z-A'),
    new DropdownOption('LOW-TO-HIGH', 'Price Low to High'),
    new DropdownOption('HIGH-TO-LOW', 'Price High to Low')
  ],
    { initialValue: new DropdownOption('HIGH-TO-LOW', 'Price High to Low') });
  public searchFilters: SidebarFilterConfig<SearchFilters> = new SidebarFilterConfig<SearchFilters>(
    [
      new SidebarFilterGroup('Sort By', [
        new SidebarFilter('sort', 'Sort By', 'sortBy', {
          selectConfig: this.sortDropdownConfig
        })
      ], { visible: true, customClass: 'd-block d-lg-none' }),
    ], this.filterChange);

  public namePlacement: PagePlacement = PAGE_PLACEMENTS.SUPERCHARGE_YOUR_SEARCH(
    this.quickSignupService,
    PAGE_PLACEMENTS.OWN_SIMILAR_FREE_3_MONTHS()
  );
  public termPlacement: PagePlacement = PAGE_PLACEMENTS.DISCOVER_NAME_PLAGES();
  public actualReleaseYear: string;

  constructor(
    private router: Router,
    private registrationService: RegistrationService,
    private activatedroute: ActivatedRoute,
    private searchService: SearchService,
    private quickSignupService: QuickSignupService,
    private userService: UserService
  ) {
    this.releaseYear = this.activatedroute.snapshot.paramMap.get('releaseYear');
    this.actualReleaseYear = this.releaseYear[0].replace('5', '0').replace('6', '1').replace('7', '2') + this.releaseYear[1];
    if (this.releaseYear == null) return;
    this.breadcrumbRelease = `${this.releaseYear} Registration Issue`;

    this.filterChange.subscribe((_: SearchFilters) => {
      this.TermSortChange(this.sortDropdownConfig.options.find(o => o.value === _.sortBy), "");
    });

    if (this.releaseYear.startsWith('0') || this.releaseYear.startsWith('5')) {
      this.DVLAreleaseMonth = this.releaseYear.startsWith('0')
        ? 'November'
        : 'May';
      this.releaseMonth = this.releaseYear.startsWith('0')
        ? 'March'
        : 'September';
    } else {
      this.DVLAreleaseMonth = this.releaseYear.startsWith('2')
        ? 'November'
        : 'May';
      this.releaseMonth = this.releaseYear.startsWith('2')
        ? 'March'
        : 'September';
    }

    this.stickyLinkConfig = new StickyConfig(
      [
        new StickyLinkGroup([
          new StickyLink('Perfect Plates', 'terms', true),
          this.isFutureRelease() ? null : new StickyLink(`Build-Your-${this.releaseYear}`, 'byo', false),
          new StickyLink('Name', 'names', false),
          new StickyLink('Market Insights', 'market-information', false),
          new StickyLink('Q&As', 'faq', false),
        ].filter(x => x != null)),
      ],
      new EventEmitter<void>(),
      { ...SECONDARY_STICKY_CONFIG, selectedStyle: { ...SECONDARY_STICKY_CONFIG.selectedStyle, borderWidth: 2 } }
    );

    this.seriesBYO = new BYOTermResponse().construct();
    this.seriesBYO.current.construct(
      'current',
      '',
      this.releaseYear.toString(),
      ''
    );

    // calc current date and determine future/current
  }

  ngOnInit(): void {
    setTimeout(() => {
      var indexToScroll = this.colourBannerMap.findIndex(
        (y) => y.year === this.releaseYear
      );
      if (indexToScroll === -1) return;
      const el = document.getElementById(`release-year-${this.releaseYear}`)
      if (el == null || el == undefined) return;
      el.scrollIntoView({
        behavior: 'auto',
        inline: 'center',
        block: 'nearest',
      });
    }, 10);
    this.GetNameResults();
  }

  public GotoYear(year: string): void {
    document.getElementById(`release-year-${year}`).scrollIntoView({
      behavior: 'auto',
      inline: 'center',
      block: 'nearest',
    });
    this.router.navigate([`new-release/${year}`]);
  }

  public getReleaseNavOptions(): { year: string; before: boolean }[] {
    var currentRelease = this.colourBannerMap.findIndex(
      (c) => c.year === this.releaseYear
    );
    if (currentRelease == -1) return [];
    if (currentRelease == 0)
      return [
        { year: this.colourBannerMap[currentRelease + 1].year, before: false },
      ];
    if (currentRelease == this.colourBannerMap.length - 1)
      return [
        { year: this.colourBannerMap[currentRelease - 1].year, before: true },
      ];
    return [
      { year: this.colourBannerMap[currentRelease - 1].year, before: true },
      { year: this.colourBannerMap[currentRelease + 1].year, before: false },
    ];
  }

  public signUpWithEmail(email): void {
    this.customSignUpTrigger.emit(email);
  }

  public isFutureRelease(): boolean {
    return ['75'].findIndex((x) => x === this.releaseYear.toString()) > -1;
  }

  public getBannerInfo(): {
    year: string;
    colour: string;
    desktopImage: string;
    mobileImage: string;
    resultImage: string;
    resultPlate: string;
    term?: string;
    result: NewReleaseResult;
  } {
    var bi = this.colourBannerMap.find((y) => y.year == this.releaseYear);
    return bi;
  }

  public currentMatrix(): { width: number; before: number; during: number } {
    if (!this.showInlineImage) return { width: 0, before: 0, during: 0 };
    var curWidth = window.innerWidth;
    var cr = this.resultMapMatris.find((r) => curWidth >= r.width);
    return cr;
  }

  public resultsBefore(): NewReleaseResult[] {
    return this.termReleaseResults.data.slice(0, this.currentMatrix().before);
  }

  public resultsDuring(): NewReleaseResult[] {
    return this.termReleaseResults.data.slice(
      this.currentMatrix().before,
      this.currentMatrix().before + this.currentMatrix().during
    );
  }

  public resultsAfter(): NewReleaseResult[] {
    if (this.getBannerInfo().result)
      return this.termReleaseResults.data.slice(
        this.currentMatrix().before + this.currentMatrix().during
      );
    else return this.termReleaseResults.data; // show all if we dont have a result image
  }

  private GetNameResults(): void {
    if (this.releaseYear == null) return;
    this.nameReleaseResults.SetLoading(true);
    this.searchService.getNewReleaseName(
      this.releaseYear,
      (res: NewReleaseResult[]) => {
        res = this.registrationService.formatNewReleaseRegistrations(res);
        this.nameReleaseResults.Update(
          false,
          res.sort((a, b) => a.registration.localeCompare(b.registration))
        );
        this.setAlphabet(this.currentAlphabet);
        this.GetTermResults();
      }
    );
  }

  private GetTermResults(): void {
    if (this.releaseYear == null) return;
    this.termReleaseResults.SetLoading(true);
    this.searchService.getNewRelease(
      this.releaseYear,
      'english',
      (res: NewReleaseResult[]) => {
        res = this.registrationService.formatNewReleaseRegistrations(res);
        const pageCustomBanner = this.getBannerInfo();
        if (pageCustomBanner) {
          //console.log('try and get data for result', pageCustomBanner)

          var featured = res.find(
            (r) => r.registration === this.getBannerInfo().resultPlate
          );

          if (featured) {
            this.getBannerInfo().result = featured;
            res = res.filter((r) => r.registration !== featured.registration);
          } else {
            this.getBannerInfo().result = {
              registration: pageCustomBanner.resultPlate,
              owner: -1,
              term: pageCustomBanner.term,
              index: -1,
              price: -1,
              notified: false,
              favourite: false
            };
          }
        }
        this.termReleaseResults.Update(false, res);
        this.TermSortChange(this.termSorting, "");

        this.filterUpdate.emit(this.searchFilters);
      }
    );
  }

  public TermSortChange(e: DropdownOption, key: string): void {
    if (e === undefined) return;
    this.termSorting = e;
    if (this.termSorting.value === 'A-Z')
      this.termReleaseResults.Update(
        false,
        this.termReleaseResults.data.sort((a, b) =>
          a.registration.localeCompare(b.registration)
        )
      );
    else if (this.termSorting.value === 'Z-A')
      this.termReleaseResults.Update(
        false,
        this.termReleaseResults.data.sort((a, b) =>
          b.registration.localeCompare(a.registration)
        )
      );
    else if (this.termSorting.value === 'HIGH-TO-LOW')
      this.termReleaseResults.Update(
        false,
        this.termReleaseResults.data.sort((a, b) => b.price - a.price)
      );
    else if (this.termSorting.value === 'LOW-TO-HIGH')
      this.termReleaseResults.Update(
        false,
        this.termReleaseResults.data.sort((a, b) => a.price - b.price)
      );
  }

  public setAlphabet(letter: string): void {
    this.currentAlphabet = letter;
    this.namesFiltered = this.nameReleaseResults.data.filter((p) =>
      p.registration.toUpperCase().startsWith(letter.toUpperCase())
    );
  }

  public notifyRegistration(reg: NewReleaseResult): void {
    if (this.notifiying) return;
    this.notifiying = true;
    reg.notified = !reg.notified; // UI update
    this.userService.notifyRegistrationString(
      reg.registration,
      (notifs: Registration[]) => {
        var _filteredNotifies = notifs.filter(
          (f) =>
            f.registration.replace(' ', '') == reg.registration.replace(' ', '')
        );
        reg.notified = _filteredNotifies && _filteredNotifies.length > 0;
        this.notifiying = false;
      }
    );
  }

  public favouriteRegistration(reg: NewReleaseResult): void {
    if (this.saving) return;
    this.saving = true;
    reg.favourite = !reg.favourite; // UI update
    this.userService.favouriteRegistrationString(
      reg.registration,
      (favs: Registration[]) => {
        var _filteredFavourites = favs.filter(
          (f) =>
            f.registration.replace(' ', '') == reg.registration.replace(' ', '')
        );
        reg.favourite = _filteredFavourites && _filteredFavourites.length > 0;
        this.saving = false;
      }
    );
  }
}
