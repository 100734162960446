<div class="content-container extended overflow-hidden" style="max-width: 100vw;">
    <div class="w-100" [ngClass]="{'ms-0 me-0 ms-lg-5 me-lg-5' : useSpacing}">
        <div class="content-container extended mx-auto w-100 d-flex align-items-stretch justify-content-start overflow-hidden"
            [ngClass]="{'mb-0 mb-lg-3' : useSpacing}">
            <ng-container *ngIf="backLink">
                <div [attr.data-test]="'backlink-' + backLink"
                    class="border-bottom border-1 py-2 d-flex flex-column justify-content-center align-items-center">
                    <div class="border-end border-1 pe-3" style="border-color: #969696 !important">
                        <a class="d-flex align-items-center justify-content-start gap-1 text-decoration-none text-dark my-0"
                            [routerLink]="backLink">
                            <mat-icon data-nosnippet class="text-dark mid">chevron_left</mat-icon>
                            <span class="lh-sm text-dark">Back</span>
                        </a>
                    </div>
                </div>
            </ng-container>
            <div class="overflow-auto flex-fill mx-auto ms-0 me-0">
                <pla-sticky-tabs [config]="stickyLinkConfig"></pla-sticky-tabs>
            </div>
        </div>
    </div>
</div>