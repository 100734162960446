import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { PlatexMessage } from 'src/app/models/platexMessage';
import { PlatexSendMessageRequest } from 'src/app/models/platexSendMessageRequest';
import { UserConversation } from 'src/app/models/userConversation';
import { MessagingService } from 'src/app/services/messaging-service';

@Component({
  selector: 'pla-messenger-client',
  templateUrl: './messenger-client.component.html',
  styleUrls: ['./messenger-client.component.scss'],
})
export class MessengerClientComponent implements OnInit {
  public windowHeight: number = window.document.body.clientHeight;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowHeight = event.target.innerHeight;
    this.scrollToTop();
  }
  @ViewChild('scrollContainer')
  private scrollContainer: ElementRef;

  public loading: boolean = true;
  public newMessage: PlatexSendMessageRequest = new PlatexSendMessageRequest(
    null,
    ''
  );

  @Input() public conversation: UserConversation = null;
  @Input() public conversationChange: EventEmitter<UserConversation> =
    new EventEmitter<UserConversation>();
  @Output() public conversationClose: EventEmitter<void> =
    new EventEmitter<void>();
  public conversationMessages: PlatexMessage[] = [];

  constructor(private messageService: MessagingService) {}

  ngOnInit(): void {
    if (this.conversationChange) this.subscribeToChanges();
    this.GetMessagesInConversation();
  }

  public isValidMessage(): boolean {
    if (
      this.conversation == null ||
      this.newMessage.contextId == null ||
      this.newMessage == null ||
      this.newMessage.message == ''
    )
      return false; // invalid input
    return true;
  }

  public handleCloseConvoClick(): void {
    this.conversation = null;
    this.conversationMessages = [];
    this.conversationClose.emit();
  }

  public handleSendMessageClick(): void {
    if (!this.isValidMessage()) return;
    this.messageService.sendMessageToConversation(
      this.conversation,
      this.newMessage.message,
      (_: boolean) => {
        if (!_) return; // something went wrong
        var newSentMessage = new PlatexMessage();
        newSentMessage.message = this.newMessage.message;
        newSentMessage.seen = false;
        newSentMessage.timeStamp = new Date();
        newSentMessage.userSent = true;
        this.conversationMessages.push(newSentMessage);
        this.newMessage.message = '';
        this.scrollToBottom();
      }
    );
  }

  private subscribeToChanges(): void {
    this.conversationChange.subscribe((_) => {
      this.conversation = _;
      this.GetMessagesInConversation();
    });
  }

  private GetMessagesInConversation(): void {
    if (this.conversation == null) return;
    this.loading = true;
    this.messageService.getMessagesInConversations(
      this.conversation,
      (_: PlatexMessage[]) => {
        this.loading = false;

        this.conversationMessages = _.map((m) => {
          // m.message = this.wrapURL(m.message);
          return m;
        });
        this.newMessage = new PlatexSendMessageRequest(
          this.conversation.contextId,
          ''
        );
        this.scrollToBottom();
      }
    );
  }

  private wrapURL(text): string {
    // Create your regex pattern for urls
    let urlPattern =
      /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\z`!()\[\]{};:'".,<>?«»“”‘’]))/gi;

    // Check string for any url patterns and wrap them in anchor tags
    let result = text.replace(urlPattern, function (url) {
      return ` <a href="${url.trim()}">${url.trim()}</a> `;
    });

    return result;
  }

  scrollToTop(): void {
    try {
      document.getElementsByTagName('pla-root')[0].scrollTop = 0;
    } catch (err) {}
  }

  scrollToBottom(): void {
    try {
      this.scrollContainer.nativeElement.scrollTop =
        this.scrollContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }
}
