import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { HTTPCONSTANTS } from './http-constants';
import { environment } from 'src/environments/environment';
import { environment as _environemnt } from 'src/app/environment';

@Injectable()
export class UrlInterceptor implements HttpInterceptor {
  private useLocalAPI: boolean = false;
  private useLocalPlato: boolean = false;

  constructor() { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.indexOf(HTTPCONSTANTS.apiBaseAddress) > -1) {
      // PLATEX API REQUEST
      var protocol = environment.apiProtocol;
      var baseAddress = environment.apiBaseAddress;
      if (!environment.production && this.useLocalAPI) {
        protocol = environment.local_apiProtocol;
        baseAddress = environment.local_apiBaseAddress;
      }
      request = request.clone({
        url: request.url
          .replace(HTTPCONSTANTS.protocol, protocol)
          .replace(HTTPCONSTANTS.apiBaseAddress, baseAddress),
      });
    } else if (request.url.indexOf(HTTPCONSTANTS.platoBaseAddress) > -1) {
      // PLATEX PLATO REQUEST
      var protocol = environment.platoProtocol;
      var baseAddress = environment.platoBaseAddress;
      if (!environment.production && this.useLocalPlato) {
        protocol = environment.local_platoProtocol;
        baseAddress = environment.local_platoBaseAddress;
      }
      request = request.clone({
        url: request.url
          .replace(HTTPCONSTANTS.platoProtocol, protocol)
          .replace(HTTPCONSTANTS.platoBaseAddress, baseAddress),
      });
    } else {
      // PLATEX PLATO SEARCH REQUEST
      var protocol = environment.platoSearchProtocol;
      var baseAddress = environment.platoSearchBaseAddress;
      var env = _environemnt.dev() ? '&environment=dev' : '';
      request = request.clone({
        url:
          request.url
            .replace(HTTPCONSTANTS.plato_search_protocol, protocol)
            .replace(HTTPCONSTANTS.plato_search_base, baseAddress) + env,
      });
    }
    return next.handle(request);
  }
}
