<div class="page">
  <div class="order-page-container vertical-section">
    <h2>Thank you for your order!</h2>

    <div class="order-confirmation-container" *ngIf="order">
      <div class="order-confirmation-content">
        <h1>ORDER CONFIRMATION</h1>
        <div class="order-details">
          <h2>Thank you for listing with us!</h2>
          <p class="fw-bold">Order ID: {{ getOrderId() }}</p>
        </div>

        <h2 class="fw-bold">What happens next?</h2>
        <p style="max-width: 500px" class="m-auto">
          We will send you an email to confirm your order, along with regular
          updates on how your advert is performing.
        </p>
      </div>
    </div>

    <div class="mt-4">
      <p>
        We're finalising everything with your listing and it will be live in a
        few moments.
      </p>
      <p>
        You can check your listings performance from your account page, which
        includes views and clicks.
      </p>
    </div>

    <div class="order-information-container mt-5">
      <h2>Got a question about your order?</h2>
      <p>
        Contact us
        <a href="https://www.instagram.com/plates/" target="_blank">here</a>
      </p>
    </div>
  </div>
</div>
