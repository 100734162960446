import { Component, OnInit } from '@angular/core';
import { FeatureType } from 'src/app/components/featured-plates/featured-plates.component';
import SwiperCore, { Navigation, Thumbs, Pagination, SwiperOptions } from 'swiper';

SwiperCore.use([Pagination, Thumbs, Navigation]);

@Component({
  selector: 'pla-sell-landing',
  templateUrl: './sell-landing.component.html',
  styleUrls: ['./sell-landing.component.scss']
})
export class SellLandingComponent implements OnInit {
  public rsFeatured = new FeatureType('Recently Sold', 'recently-sold');

  public cards: any[] = [
    { image: 'https://cdn.platex.co.uk/pages/landing/value_plate.png', number: '01', title: 'Value Your Plate', header: ``, body: `Choose the perfect marketing price with with help from verified market data of similar registrations for sale and recently sold.`, link: '', url: '/buy' },
    { image: 'https://cdn.platex.co.uk/pages/landing/create_advert.png', number: '02', title: 'Create Your Advert', header: ``, body: 'Complete a sellers form, choose a plan and your listing will be live in minutes!', link: '', url: '/compare' },
    { image: 'https://cdn.platex.co.uk/pages/landing/respond_to_enquiries.png', number: '03', title: 'Respond To Enquiries', header: ``, body: 'Your live advert includes a contact form to allow buyers to message you directly.', link: '', url: '/buy' },
    { image: 'https://cdn.platex.co.uk/pages/landing/plate_sold.png', number: '04', title: 'Arrange Your Sale', header: ``, body: 'Finalise the sale with your buyer and keep 100% of the selling price with no commission charged!', link: '', url: '/buy' },
  ]

  public whyListWith: any[] = [
    { icon: 'https://cdn.platex.co.uk/pages/landing/deal_direct_v2.png', title: 'Deal Direct', body: 'Save hundreds - No hidden commission or buyers premiums.  Our most popular listing is just £17.50 for 3 months.' },
    { icon: 'https://cdn.platex.co.uk/pages/landing/be_seen_v2.png', title: 'Be Seen', body: 'Smart-Search puts your registration in front of the right buyers, every time. Guaranteed to appear for keyword searches!' },
    { icon: 'https://cdn.platex.co.uk/pages/landing/tech_driven_v2.png', title: 'Technology Driven', body: 'We don\'t offer opinions—we provide real market data on similar plates, helping you choose the optimal price.' },
    { icon: 'https://cdn.platex.co.uk/pages/landing/buyer_alert_v2.png', title: 'Buyer Alert', body: 'We alert our database of register of buyers when new plates are added for sale, giving our private sellers a jump start!' },
    { icon: 'https://cdn.platex.co.uk/pages/landing/impartial_v2.png', title: 'Impartial', body: 'Our business is being the top private plate portal, not selling them. We don\'t own plates, so we\'re never competing for buyers.' },
    // { icon: 'https://cdn.platex.co.uk/pages/landing/commision_free.png', title: 'Commission Free', body: 'No commission, dealer margin, buyers premium or any sellers fee! The only cost is a listing fee for your advert and you can cancel at any time.' },
  ]

  public wlop_options: SwiperOptions = {
    navigation: false,
    spaceBetween: 20,
    slidesPerView: 1,
    centeredSlides: true,
    pagination: {
      clickable: true,
      el: '.wlop-pagination-swiper-dots'
    },
    breakpoints: {
      768: {
        enabled: true,
        noSwiping: false,
        slidesPerView: 1,
        loop: true,
        centeredSlides: true,
        loopedSlides: 1,
        navigation: false,
      },
      769: {
        enabled: false,
        noSwiping: true,
        slidesPerView: 3,
        loop: false,
        loopedSlides: 0,
        navigation: false,
      }
    }
  }

  public sp_options: SwiperOptions = {
    navigation: false,
    spaceBetween: 20,
    slidesPerView: 1.2,
    initialSlide: 1,
    centeredSlides: true,
    pagination: {
      clickable: true,
      el: '.sp-pagination-swiper-dots'
    },
    breakpoints: {
      450: {
        enabled: true,
        noSwiping: false,
        slidesPerView: 1.2,
        loop: false,
        loopedSlides: 1,
        navigation: false,
        centeredSlides: true,
      },
      500: {
        enabled: true,
        noSwiping: false,
        slidesPerView: 2.5,
        loop: false,
        loopedSlides: 1,
        navigation: false,
        centeredSlides: true,
      },
      769: {
        enabled: true,
        noSwiping: true,
        slidesPerView: 3,
        loop: false,
        loopedSlides: 1,
        navigation: false,
        centeredSlides: true,
      },
      960: {
        enabled: true,
        noSwiping: true,
        slidesPerView: 3,
        loop: false,
        loopedSlides: 0,
        navigation: false,
        centeredSlides: true,
      },
      1300: {
        enabled: false,
        noSwiping: true,
        slidesPerView: 4,
        loop: false,
        loopedSlides: 0,
        navigation: false,
        centeredSlides: false,
      },
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
