import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BYOTermBreakdown } from 'src/app/models/byoTermResponse';
import { FeaturedInitials, InitialSearchResults } from 'src/app/models/initialsSearchResults';
import { Registration } from 'src/app/models/registration';
import {
  SearchReqResponse,
  SearchService,
} from 'src/app/services/search-service';
import { Location } from '@angular/common';
import { PageStep } from 'src/app/components/page-steps/page-steps.component';
import {
  TrackingService,
  TRACKING_SEARCH_TYPE,
} from 'src/app/services/tracking-service';
import { FeatureType } from 'src/app/components/featured-plates/featured-plates.component';
import { FaqBuilder, InfoDropdown } from 'src/app/components/faq/faq.component';
import { DropdownOption, DropdownConfig } from 'src/app/components/custom-dropdown/custom-dropdown.component';
import { SidebarFilterConfig, SidebarFilterGroup, SidebarFilter } from 'src/app/components/side-bar-filters/side-bar-filters.component';
import { StickyConfig, StickyLink, StickyLinkGroup } from 'src/app/components/sticky-tabs/sticky-tabs.component';
import { SearchFilters } from '../search-results-page/search-results-page.component';

@Component({
  selector: 'pla-initials-search-page',
  templateUrl: './initials-search-page.component.html',
  styleUrls: ['./initials-search-page.component.scss'],
})
export class InitialsSearchPageComponent implements OnInit {
  public initialFeatured = new FeatureType('Number 1 Plates', 'initial');
  public initials: string;
  public dnf_searching: boolean = false;
  public dlf_searching: boolean = false;
  public current_searching: boolean = false;
  public prefix_searching: boolean = false;
  public suffix_searching: boolean = false;

  public initialResults: InitialSearchResults;
  public dnf_resultsChange: EventEmitter<SearchReqResponse> =
    new EventEmitter<SearchReqResponse>();
  public dlf_resultsChange: EventEmitter<SearchReqResponse> =
    new EventEmitter<SearchReqResponse>();
  public c_resultsChange: EventEmitter<SearchReqResponse> =
    new EventEmitter<SearchReqResponse>();
  public p_resultsChange: EventEmitter<SearchReqResponse> =
    new EventEmitter<SearchReqResponse>();
  public s_resultsChange: EventEmitter<SearchReqResponse> =
    new EventEmitter<SearchReqResponse>();

  public featuredInitials: FeaturedInitials;


  public stickyLinkConfig: StickyConfig = new StickyConfig(
    [
      new StickyLinkGroup([
        new StickyLink('Dateless Letter First', 'dateless-letter-first-results', true),
        new StickyLink('Dateless Number First', 'dateless-number-first-results', false),
        new StickyLink('Current Style', 'current-results', false),
        new StickyLink('Prefix Style', 'prefix-results', false),
        new StickyLink('Suffix Style', 'suffix-results', false),
        // new StickyLink('Q&As', 'faq', false),
      ].filter(x => x != null)),
    ],
    new EventEmitter<void>()
  );

  public termSorting: DropdownOption;
  public mobileFilterToggle: EventEmitter<boolean> = new EventEmitter<boolean>();;
  public sortTrigger: EventEmitter<void> = new EventEmitter<void>();
  public filterChange: EventEmitter<SearchFilters> = new EventEmitter<SearchFilters>();
  public filterUpdate: EventEmitter<SidebarFilterConfig<any>> =
    new EventEmitter<SidebarFilterConfig<any>>();
  public sortDropdownConfig: DropdownConfig = new DropdownConfig('Sort By', 'swap_vert', [
    new DropdownOption('A-Z', 'A-Z'),
    new DropdownOption('Z-A', 'Z-A'),
    new DropdownOption('LOW-TO-HIGH', 'Price Low to High'),
    new DropdownOption('HIGH-TO-LOW', 'Price High to Low')
  ]);
  public searchFilters: SidebarFilterConfig<SearchFilters> = new SidebarFilterConfig<SearchFilters>(
    [
      new SidebarFilterGroup('Sort By', [
        new SidebarFilter('sort', 'Sort By', 'sortBy', {
          selectConfig: this.sortDropdownConfig
        })
      ], { visible: true, customClass: 'd-block d-lg-none' }),
    ], this.filterChange);

  public pageSteps: PageStep[] = [
    new PageStep().AddBody('<b>Search</b> up to 3 initials'),
    new PageStep().AddBody(
      '<b>View</b> your initials on Current, Prefix, Suffix and Dateless Registrations'
    ),
    new PageStep().AddBody(
      '<b>Compare</b> deals across the whole market to get the best possible price'
    ),
    new PageStep().AddBody(
      '<b>Buy</b> direct with us or be transferred to the dealer to checkout with cash or finance'
    ),
  ]

  public faq: InfoDropdown[] = [
    FaqBuilder.build(
      'faq_2',
      'What are the benefits of choosing initials for a personalised number plate?',
      [
        `Choosing initials for a personalised plate adds timeless appeal and versatility, offering a discreet yet meaningful way to make your vehicle uniquely yours. Whether you choose a single letter like "S," a two-letter combination such as "AB," or a three-letter sequence like "JSM," initials provide an elegant and professional touch. Single-letter plates are particularly prestigious, while two- and three-letter combinations offer a personal option with wider affordability.`,
        `Alongside the highly sought-after but often expensive <a href="/articles/dateless-number-plates">dateless registrations</a> like "A 1" or "AB 23," <a href="/articles/private-number-plates">current-style registrations</a> beginning with "OO" are gaining popularity. Plates such as "OO11 ABC" mimic the look of dateless formats like "0011 ABC" but at a significantly lower cost, offering premium style on a budget.`,
        `Initials plates are not only stylish but also have broad resale appeal, making them a smart choice for personalisation and future investment.`
      ]
    ), FaqBuilder.build(
      'faq_3',
      'Can I find initials combined with specific numbers for a personalised plate?',
      [
        `Yes, personalised plates can pair initials with meaningful numbers, creating a unique and personal combination. For example, "JS23" might represent John Smith born in 2023, while "AB75" could signify a milestone year. This mix of letters and numbers allows for endless customisation.`,
        `Using the PlateX Initials Search tool, you can find plates that combine your desired initials with significant numbers, such as birth years, anniversaries, or lucky digits. Formats like "LM19" or "CH64" are popular choices for adding a personal touch.`,
        `While dateless plates offer a timeless appeal, current-style plates starting with "OO" are a rising trend. Combinations like "OO23 AB" mimic the premium look of dateless styles such as "0023 AB" but at a more affordable price, making them an excellent option for stylish personalisation.`,
        `Combining initials with numbers is a great way to create a distinctive and memorable personalised plate that reflects your identity or celebrates a special moment.`
      ]
    ), FaqBuilder.build(
      'faq_4',
      'How do initials plates compare in cost to full name plates?',
      [
        `Initials plates are generally more affordable than full name plates, largely due to their greater availability and flexibility. Full name plates, such as those featured on our <a href="/names">Perfect Name Plates</a> page, are rare and often command premium prices due to their uniqueness. In contrast, initials plates offer numerous combinations, such as "AB 1-99" or "JS 23," making them more accessible to a wider audience.`,
        `With our Initials Search, you can explore a variety of options tailored to your desired initials, including plates from the <a href="/dateless-search">Dateless Search</a> for a timeless look. For those seeking something truly exclusive, our <a href="/number-1">Number 1</a> page highlights the most coveted dateless plates featuring initials paired with the prestigious single-digit "1."`
      ]
    ), FaqBuilder.build(
      'faq_5',
      'Can I purchase a personalised number plate without currently owning a vehicle?',
      [
        `Yes, you can purchase a personalised plate featuring your initials even if you don’t own a vehicle yet. The DVLA allows you to hold the registration on a V750 Certificate of Entitlement for up to 10 years, ensuring your desired plate is reserved until you’re ready to use it.`,
        `This option is particularly useful for initials plates, as these combinations are often highly sought after and may sell out quickly. By securing your plate in advance, you avoid the risk of losing the perfect match.`,
        `When you’re ready to assign the plate to a vehicle, the process is simple and can be done online or by post through the DVLA. This flexibility allows you to personalise your car at your convenience, making initials plates a smart and future-proof choice.`
      ]
    ), FaqBuilder.build(
      'faq_6',
      'Can I finance initials plates?',
      [
        `Yes, many cherished registration dealers offer flexible payment options, including services like Klarna for splitting payments into instalments and bespoke finance packages for higher-value plates. These options make it easier to spread the cost of your perfect initials plate.`,
        `At PlateX, you can compare prices across multiple dealers and filter results to show those offering finance. Whether you’re seeking a premium dateless plate or a more budget-friendly option, you can find a deal that suits your budget and payment preferences.`,
        `Financing initials plates is a convenient way to secure a personalised registration without paying the full amount upfront, ensuring you don’t miss out on the perfect plate for your vehicle.`
      ]
    ),
    FaqBuilder.build(
      'faq_7',
      'Are there any vehicles that cannot display a personalised number plate?',
      [
        `Yes, there are certain vehicles that cannot display personalised number plates, including plates featuring initials. Personalised registrations cannot be assigned to vehicles with a 'Q' registration, as these indicate the vehicle’s age or identity is uncertain. Similarly, vehicles that do not require an MOT or HGV certificate, such as tractors, agricultural machinery, or some specialist vehicles, are also ineligible.`,
        `For those considering initials plates, it’s important to note that the same restrictions apply. If your vehicle falls under these categories, you won’t be able to assign an initials plate or any other personalised registration. Additionally, the plate must meet all DVLA regulations and be assigned to a qualifying vehicle before it can be legally displayed.`
      ]
    )
  ]

  constructor(
    private searchService: SearchService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private trackingService: TrackingService
  ) {
    this.initials = this.activatedRoute.snapshot.paramMap.get('initials');

    this.filterChange.subscribe((_: SearchFilters) => {
      this.SortChange(this.sortDropdownConfig.options.find(o => o.value === _.sortBy), "");
    });

    if (this.initials == null) return;
    this.trackingService.TrackSearch(
      TRACKING_SEARCH_TYPE.INITALS,
      this.initials
    );
  }

  ngOnInit(): void {
    // if (this.initials == null) this.initials = 'AAA'; // default
    if (this.initials == null) {
      this.getFeatured();
      return;
    }
    this.getSearchResults();
  }

  public SortChange(e: DropdownOption, key: string): void {
    if (this.initialResults === undefined ||
      this.initialResults.dateless_number_first === undefined ||
      this.initialResults.dateless_letter_first === undefined ||
      this.initialResults.current === undefined ||
      this.initialResults.prefix === undefined ||
      this.initialResults.suffix === undefined
    ) {
      console.log('cant sort nothing')
      return;
    }

    if (e.value === 'A-Z') {
      this.initialResults.dateless_number_first = this.initialResults.dateless_number_first.sort((a, b) => a.registration.localeCompare(b.registration));
      this.initialResults.dateless_letter_first = this.initialResults.dateless_letter_first.sort((a, b) => a.registration.localeCompare(b.registration));
      this.initialResults.current = this.initialResults.current.sort((a, b) => a.registration.localeCompare(b.registration));
      this.initialResults.prefix = this.initialResults.prefix.sort((a, b) => a.registration.localeCompare(b.registration));
      this.initialResults.suffix = this.initialResults.suffix.sort((a, b) => a.registration.localeCompare(b.registration));
    } else if (e.value === 'Z-A') {
      this.initialResults.dateless_number_first = this.initialResults.dateless_number_first.sort((a, b) => b.registration.localeCompare(a.registration));
      this.initialResults.dateless_letter_first = this.initialResults.dateless_letter_first.sort((a, b) => b.registration.localeCompare(a.registration));
      this.initialResults.current = this.initialResults.current.sort((a, b) => b.registration.localeCompare(a.registration));
      this.initialResults.prefix = this.initialResults.prefix.sort((a, b) => b.registration.localeCompare(a.registration));
      this.initialResults.suffix = this.initialResults.suffix.sort((a, b) => b.registration.localeCompare(a.registration));
    } else if (e.value === 'HIGH-TO-LOW') {
      this.initialResults.dateless_number_first = this.initialResults.dateless_number_first.sort((a, b) => b.price - a.price);
      this.initialResults.dateless_letter_first = this.initialResults.dateless_letter_first.sort((a, b) => b.price - a.price);
      this.initialResults.current = this.initialResults.current.sort((a, b) => b.price - a.price);
      this.initialResults.prefix = this.initialResults.prefix.sort((a, b) => b.price - a.price);
      this.initialResults.suffix = this.initialResults.suffix.sort((a, b) => b.price - a.price);
    } else if (e.value === 'LOW-TO-HIGH') {
      this.initialResults.dateless_number_first = this.initialResults.dateless_number_first.sort((a, b) => a.price - b.price);
      this.initialResults.dateless_letter_first = this.initialResults.dateless_letter_first.sort((a, b) => a.price - b.price);
      this.initialResults.current = this.initialResults.current.sort((a, b) => a.price - b.price);
      this.initialResults.prefix = this.initialResults.prefix.sort((a, b) => a.price - b.price);
      this.initialResults.suffix = this.initialResults.suffix.sort((a, b) => a.price - b.price);
    }

    this.dnf_resultsChange.emit(new SearchReqResponse('dnf_id', this.initialResults.dateless_number_first));
    this.dlf_resultsChange.emit(new SearchReqResponse('dlf_id', this.initialResults.dateless_letter_first));
    this.c_resultsChange.emit(new SearchReqResponse('c_id', this.initialResults.current));
    this.p_resultsChange.emit(new SearchReqResponse('p_id', this.initialResults.prefix));
    this.s_resultsChange.emit(new SearchReqResponse('s_id', this.initialResults.suffix));
  }

  public getFeatured(): void {
    this.searchService.featuredInitials((res) => {
      this.featuredInitials = res;
    });
  }

  public UpdateInitialsInput($event: string): void {
    this.initials = $event.toUpperCase();
    if (this.initials == null || this.initials == '') return;
    this.location.go(`/initials-search/${this.initials}`);
  }

  public doInlineSearch(): void {
    this.getSearchResults();
  }

  private getSearchResults(): void {
    this.dnf_searching = true;
    this.dlf_searching = true;
    this.current_searching = true;
    this.prefix_searching = true;
    this.suffix_searching = true;
    this.initialResults = new InitialSearchResults();

    this.searchService.searchInitials(
      this.initials,
      'current',
      (res: InitialSearchResults) => {
        this.current_searching = false;
        res.current.map((r: Registration) => (r.available = r.price >= 0));
        this.initialResults.current = res.current;
        this.c_resultsChange.emit(new SearchReqResponse('c_id', res.current));

        this.filterUpdate.emit(this.searchFilters);
      }
    );
    this.searchService.searchInitials(
      this.initials,
      'prefix',
      (res: InitialSearchResults) => {
        this.prefix_searching = false;
        res.prefix.map((r: Registration) => (r.available = r.price >= 0));
        this.initialResults.prefix = res.prefix;
        this.p_resultsChange.emit(new SearchReqResponse('p_id', res.prefix));
      }
    );
    this.searchService.searchInitials(
      this.initials,
      'dateless_number_first',
      (res: InitialSearchResults) => {
        this.dnf_searching = false;
        res.dateless_number_first.map(
          (r: Registration) => (r.available = r.price >= 0)
        );
        this.initialResults.dateless_number_first = res.dateless_number_first;
        this.dnf_resultsChange.emit(
          new SearchReqResponse('dnf_id', res.dateless_number_first)
        );
      }
    );
    this.searchService.searchInitials(
      this.initials,
      'dateless_letter_first',
      (res: InitialSearchResults) => {
        this.dlf_searching = false;
        res.dateless_letter_first.map(
          (r: Registration) => (r.available = r.price >= 0)
        );
        this.initialResults.dateless_letter_first = res.dateless_letter_first;
        this.dlf_resultsChange.emit(
          new SearchReqResponse('dlf_id', res.dateless_letter_first)
        );
      }
    );
    this.searchService.searchInitials(
      this.initials,
      'suffix',
      (res: InitialSearchResults) => {
        this.suffix_searching = false;
        res.suffix.map((r: Registration) => (r.available = r.price >= 0));
        this.initialResults.suffix = res.suffix;
        this.s_resultsChange.emit(new SearchReqResponse('s_id', res.suffix));
      }
    );
  }
}
