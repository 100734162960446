<div class="px-3"
    [ngClass]="{'focused': focused || (searchInput !== '' && searchInput != null), 'nsi-nottall': !fixedHeight, 'nsi': fixedHeight, 'opacity-50': disabled }"
    (click)="FocusElement()">
    <div *ngIf="!changingPlaceholder" for="#search-input" class="changing-text">
        <pla-text-changer [texts]="searchTexts" [customClasses]="'{{ customClasses }} fs-mb-11 fs-md-14 fw-light'"
            [loopText]="true" [duration]="1.5"></pla-text-changer>
    </div>
    <div class="custom-input position-relative w-100">
        <input id="search-input" style="color: transparent !important"
            class="bg-transparent rounded-0 flex-fill border-0 py-0 px-0 m-0 w-100 {{ weight }} text-start"
            [maxlength]="maxLength" [disabled]="disabled" (ngModelChange)="searchInput = $event.toUpperCase()"
            [ngModel]="searchInput" (focus)="SetFocus(true)" (blur)="SetFocus(false)" (keydown)="KeyDown($event)">
        <span *ngIf="searchInput !== '' && searchInput != null" style="pointer-events: none; text-wrap: nowrap;"
            class="custom-value position-absolute top-50 start-0 end-0 w-100 translate-middle-y {{ weight }}">{{searchInput}}</span>

        <span *ngIf="!changingPlaceholder && (searchInput === '' || searchInput == null)"
            style="pointer-events: none; text-wrap: nowrap;"
            class="custom-placeholder position-absolute top-50 start-0 end-0 w-100 translate-middle-y {{ weight }}">{{placeholder}}</span>

        <span *ngIf="changingPlaceholder && (searchInput === '' || searchInput == null)"
            style="pointer-events: none; text-wrap: nowrap;"
            class="custom-placeholder position-absolute top-50 start-0 end-0 w-100 translate-middle-y {{ weight }}">
            <pla-text-changer [texts]="searchTexts"
                [customClasses]="customChangerClasses + ' ' + weight + ' ' + (focused || (searchInput !== '' && searchInput != null) ? 'opacity-50' : '')"
                [loopText]="true" [duration]="1.5"></pla-text-changer>
        </span>
    </div>
</div>