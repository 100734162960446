import { Location } from '@angular/common';
import { Component, Injectable, Input, OnInit } from '@angular/core';
import GlossaryJson from '../../../assets/data/glossary.json';

interface GlossaryLetter {
  letter: string;
  terms: GlossaryTerm[];
  hasTerms: boolean;
}

interface GlossaryTerm {
  term: string;
  definition: string;
}

@Injectable({ providedIn: 'root' })
export class GlossaryService {
  private glossaryJSON: any = GlossaryJson; // { a: [{ term: str, definition: str }], b: []...}

  public getGlossaryData(): GlossaryLetter[] {
    var letters = Object.keys(this.glossaryJSON);
    var _data: GlossaryLetter[] = [];
    letters.forEach((letter: string, i: number) => {
      var glosTermList = this.glossaryJSON[letter] as GlossaryTerm[];
      var glosLetter = {} as GlossaryLetter;
      glosLetter.letter = letter;
      glosLetter.hasTerms = glosTermList != null && glosTermList.length > 0;
      glosLetter.terms = glosTermList;
      _data.push(glosLetter);
    });

    return _data;
  }

  public getGlossaryTerm(term: string): GlossaryTerm {
    var _ = this.getGlossaryData();
    var termDef = null;
    _.forEach((__) => {
      if (termDef !== null) return;
      var x = __.terms.filter(
        (___) => ___.term.toLowerCase() == term.toLowerCase()
      );
      if (x.length == 0) return;
      termDef = x[0];
    })[0];
    return termDef;
  }
}

@Component({
  selector: 'pla-glossary',
  templateUrl: './glossary.component.html',
  styleUrls: ['./glossary.component.scss'],
})
export class GlossaryComponent implements OnInit {
  public glossaryData: GlossaryLetter[] = [];
  private fullGlossaryData: GlossaryLetter[] = [];

  @Input() public searchTerm: string = '';

  constructor(
    private location: Location,
    private glossaryService: GlossaryService
  ) {
    this.configureData();
  }

  ngOnInit(): void {
    this.filter();
  }

  public setSearch(glosLetter: GlossaryLetter): void {
    if (!glosLetter.hasTerms) return;
    this.searchTerm = glosLetter.letter.toUpperCase();
    this.filter();
  }

  public clearSearch(): void {
    this.searchTerm = '';
    this.filter();
  }

  public filter(): void {
    this.location.go(`/info/glossary/${this.searchTerm}`);
    this.glossaryData = JSON.parse(JSON.stringify(this.fullGlossaryData));
    if (this.searchTerm == null || this.searchTerm == '') return;

    if (this.searchTerm.length > 1) {
      this.glossaryData = this.glossaryData.filter((gd) => {
        var matchedDefinitions = gd.terms.filter((term: GlossaryTerm) => {
          return (
            term.term.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
          );
        });
        return gd.letter == this.searchTerm || matchedDefinitions.length > 0;
      });

      this.glossaryData = [...this.glossaryData].map((gd) => {
        gd.terms = [...gd.terms].filter(
          (t) =>
            t.term.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
        );

        return { ...gd };
      });
    } else {
      this.glossaryData = this.glossaryData.filter((gd) => {
        return gd.letter.toLowerCase() == this.searchTerm.toLowerCase();
      });
    }

    if (this.glossaryData.length == 0)
      this.glossaryData = JSON.parse(JSON.stringify(this.fullGlossaryData));
  }

  private configureData(): void {
    var _data = this.glossaryService.getGlossaryData();
    this.fullGlossaryData = _data;
    this.glossaryData = _data;
  }
}
