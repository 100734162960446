<div>
  <ng-container *ngIf="!loading; else loadingSelling">
    <ng-container *ngIf="userListings != null && userListings.length > 0; else noListings">
      <ng-container *ngFor="let listing of userListings; let last = last">
        <div class="py-2 {{ !last ? 'border-bottom' : null }}">
          <div class="d-flex d-md-none justify-content-between align-items-center px-1 gap-2 mb-1">
            <p class="d-block mb-0">
              {{ getSubPriceLine(listing) }}
            </p>
            <p class="d-block mb-0">
              Listed for {{ listing.price / 100 | currency : "GBP" }}
            </p>
          </div>
          <div class="d-flex justify-content-between align-items-center px-1 gap-2">
            <pla-plate [rawPlate]="listing.registration" [disabled]="!listing.active"></pla-plate>

            <div class="d-none d-md-flex flex-fill gap-2 justify-content-between align-items-center px-3">
              <p class="d-block mb-0">
                {{ getSubPriceLine(listing) }}
              </p>
              <p class="d-block mb-0">
                Listed for {{ listing.price / 100 | currency : "GBP" }}
              </p>
            </div>

            <div class="d-flex justify-content-between align-items-center gap-2">
              <button class="cyan-button" mat-flat-button (click)="editListing(listing)">
                Details
              </button>
              <button *ngIf="listing.listingType != 0" class="cyan-border-button" style="min-width: 90px; height: 38px"
                mat-flat-button [disabled]="
                  !listing.active || listingLoadings[listing.advertId]
                " (click)="billingListing(listing)">
                <ng-container *ngIf="!listingLoadings[listing.advertId]">{{
                  listing.active
                  ? listing.listingType == 0
                  ? "N/A"
                  : "Billing"
                  : "Inactive"
                  }}</ng-container>
                <ng-container *ngIf="listingLoadings[listing.advertId]">
                  <div class="spinner-loader d-flex align-items-center justify-content-center">
                    <mat-progress-spinner class="spinner cyan" mode="indeterminate" [color]="'#327b8d'"
                      [diameter]="22"></mat-progress-spinner>
                  </div>
                </ng-container>
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #loadingSelling>
  <div class="full spinner-loader">
    <div class="py-3">
      <mat-progress-spinner class="spinner cyan" mode="indeterminate" [color]="'#327b8d'"
        [diameter]="60"></mat-progress-spinner>
    </div>
    <span>Loading Listings...</span>
  </div>
</ng-template>

<ng-template #noListings>
  <div class="full spinner-loader">
    <span>No Listings</span>
  </div>
</ng-template>