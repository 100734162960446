<div class="w-100">
    <div class="w-100">
        <!-- SWIPER -->
        <swiper #swiperRef class="swiper px-4 py-4" [config]="swiperConfig">
            <ng-template swiperSlide *ngIf="showSellSlide">
                <div class="w-100 shadow rounded-3 mx-auto bg-white listing-card">
                    <a href="/create-auction"
                        class="d-flex flex-column align-items-stretch justify-content-center gap-2 text-dark text-decoration-none justify-content-md-start p-3">
                        <!-- BLOG POST ROW -->
                        <div class="d-block w-100 mx-0 flex-shrink-0 listing-container" style="min-width: 100px">
                            <img src="{{ 'https://cdn.platex.co.uk/listing-images/sell_listing.png' | noCache }}"
                                class="d-block w-100 mx-0 listing-image" style="object-fit: cover;" />
                        </div>
                        <div
                            class="d-flex flex-fill flex-row align-items-center justify-content-center mx-auto listing-container">
                            <p class="mb-0 lh-sm fs-mb-12 fs-md-12 text-decoration-none text-center w-100"
                                style="color: #0A6077;">
                                See Your Registration Here!
                            </p>
                        </div>
                    </a>
                </div>
            </ng-template>

            <ng-container *ngFor="let listing of listings">
                <ng-template swiperSlide>
                    <div class="w-100 rounded-3 mx-auto listing-card">
                        <a href="/plate-detail/{{ listing.registration }}"
                            class="d-flex flex-column align-items-stretch justify-content-center gap-2 text-dark text-decoration-none justify-content-md-start p-3">
                            <!-- BLOG POST ROW -->
                            <div class="d-block w-100 mx-0 flex-shrink-0 position-relative listing-container"
                                style="min-width: 100px">
                                <img src="{{ listing.image | noCache }}" class="d-block w-100 mx-0 listing-image"
                                    style="object-fit: cover;" />

                                <ng-container *ngIf="listing.tag != ''">
                                    <div class="position-absolute top-0 end-0">
                                        <div class="px-3 pt-1 pb-1" [style.backgroundColor]="tagColour(listing)"
                                            style="border-radius: 0 0 0 12px">
                                            <span class="lh-1 text-white fs-mb-12">{{listing.tag}}</span>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            <div
                                class="d-flex flex-fill flex-row align-items-center justify-content-between listing-container">
                                <p class="d-block mb-0 lh-sm fs-6 text-decoration-none text-start w-100">
                                    {{ listing.registration }}
                                </p>
                                <p class="text-end lh-sm w-100 d-block mb-0">
                                    {{ listing.price / 100 | currency: 'GBP' }}
                                </p>
                            </div>
                        </a>
                    </div>
                </ng-template>
            </ng-container>
        </swiper>
    </div>
</div>