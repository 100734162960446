<div class="form-group _{{ tabs.length }}_items" [style.--chip-colour]="chipColour"
  [style.--selected-chip-colour]="selectedChipColour" [style.--chip-text-colour]="chipTextColour"
  [style.--selected-chip-text-colour]="selectedChipTextColour" [style.--pill-radius]="pillRadius">
  <div class="switch-toggle-pip">
    <ng-container *ngFor="let option of tabs; let i = index">
      <div class="toggle_option_slider" [ngClass]="{
          selected: selectedTabId == option.id,
          hidden: i > selectedTabIndex
        }"></div>
    </ng-container>
    <div class="toggle_option_slider_pip"></div>
    <ng-container *ngFor="let option of tabs; let i = index">
      <div class="toggle_option_slider" [ngClass]="{
          selected: selectedTabId == option.id,
          hidden: i < selectedTabIndex
        }"></div>
    </ng-container>
  </div>
  <div class="switch-toggle">
    <ng-container *ngFor="let option of tabs; let i = index">
      <input type="radio" [name]="comp_id + '_state-d'" [id]="comp_id + '_state-d' + option.id"
        [checked]="selectedTabId == option.id" [value]="option.id" />
      <label (click)="onSelectionChange(option, i)" for="{{ option.name }}" class="fs-md-15 fs-mb-17 fw-normal">{{
        option.name }}
      </label>
    </ng-container>
  </div>
</div>