<div>
  <div *ngIf="!registrationHistory.loading">
    <div class="chart-container" *ngIf="hasSoldData(2, true)">
      <ng-content select="[aboveChart]"></ng-content>
      <pla-chart-price-history [data]="registrationHistoryPoints"></pla-chart-price-history>
      <ng-content select="[belowChart]"></ng-content>
    </div>
    <div *ngIf="!hasSoldData(2, true)">
      <ng-content select="[noChart]"></ng-content>
    </div>
    <pla-sold-data-table *ngIf="this.hasSoldData(1, false)" [data]="registrationHistory.data"></pla-sold-data-table>
    <div *ngIf="!hasSoldData(1, false)">
      <ng-content select="[noTable]"></ng-content>
    </div>
  </div>
</div>