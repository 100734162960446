import { Component, Input, OnInit } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { DataPoint } from '../components/chart/chart.component';

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'pla-chart-price-history',
  templateUrl: './chart-price-history.component.html',
  styleUrls: ['./chart-price-history.component.scss']
})
export class ChartPriceHistoryComponent implements OnInit {

  @Input() public data: DataPoint[];

  constructor() {
    //console.log('', am4themes_animated);
  }

  ngOnInit(): void {
    console.log(this.data);
    // Create chart instance
    var chart = am4core.create("chart-price-history", am4charts.XYChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    // Add data
    chart.data = this.data.map((dp) => {
      return {
        "date": new Date(dp.x),
        "price": dp.y
      };
    });

    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    // Create series
    var lineSeries = chart.series.push(new am4charts.LineSeries());
    lineSeries.dataFields.valueY = "price";
    lineSeries.dataFields.dateX = "date";
    valueAxis.numberFormatter.numberFormat = '£#,###.##';
    lineSeries.name = "Sales";
    lineSeries.strokeWidth = 2;
    lineSeries.strokeDasharray = '5,4'; //5,4
    valueAxis.renderer.minGridDistance = 30;
    // var y_minMax = this.Y_MinMax(this.data);
    // valueAxis.max = y_minMax[1];
    chart.padding(15, 0, 0, 0);
    var x_minMax = this.X_MinMax(this.data);
    dateAxis.min = x_minMax[0].getTime();
    dateAxis.max = x_minMax[1].getTime();
    dateAxis.strictMinMax = true;
    chart.fontSize = 11;

    chart.cursor = new am4charts.XYCursor();
    chart.dateFormatter.dateFormat = "dt MMM yyyy";
    lineSeries.tooltipText = "[/]{date.formatDate()}\n[/]{price}";
    lineSeries.tooltip.pointerOrientation = "vertical";

    // Add simple bullet
    var bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
    bullet.disabled = false;
    bullet.propertyFields.disabled = "disabled";
    bullet.fill = am4core.color('#0a6077');

    // var secondCircle = bullet.createChild(am4core.Circle);
    // secondCircle.radius = 4;
    // secondCircle.fill = am4core.color('#0a6077');

    var that = this;
    bullet.events.on("inited", (event) => {
      console.log(event);
      // that.animateBullet(that, event.target.circle);
    });
  }

  private animateBullet(that, bullet): void {
    var animation = bullet.animate([{ property: "scale", from: 1, to: 5 }, { property: "opacity", from: 1, to: 0 }], 1000, am4core.ease.circleOut);
    animation.events.on("animationended", function (event) {
      that.animateBullet(that, event.target.object);
    })
  }

  private Y_MinMax(pricePoints: DataPoint[]): Date[] {
    var minMax = [];
    
    // get min price
    // get max price

    // add 10%
    // minus 10%;

    return minMax
  }

  private X_MinMax(pricePoints: DataPoint[]): Date[] {
    var minMax = [];
    var xDiff = new Date(pricePoints[pricePoints.length - 1].x).getTime() - new Date(pricePoints[0].x).getTime();

    minMax = [pricePoints[0].x, pricePoints[pricePoints.length - 1].x]; // just in case

    var xDiffMonths = xDiff / 2629746000;
    if (xDiff == 0) {
      var todayDate = new Date(pricePoints[0].x);
      var lastMonthDate = new Date(pricePoints[0].x);

      todayDate.setMonth(todayDate.getMonth() + 1);
      var today = todayDate.getTime();

      lastMonthDate.setMonth(lastMonthDate.getMonth() - 2);
      var lastMonth = lastMonthDate.getTime();

      xDiff = today - lastMonth;
      minMax = [this.calcZeroX(lastMonth, xDiff, false), this.calcZeroX(today, xDiff, true)]
    } else if (xDiffMonths < 6) {
      var todayDate = new Date(pricePoints[pricePoints.length - 1].x);
      var lastMonthDate = new Date(pricePoints[0].x);

      todayDate.setDate(todayDate.getDate() + 7);
      var today = todayDate.getTime();

      lastMonthDate.setDate(lastMonthDate.getDate() - 7);
      var lastMonth = lastMonthDate.getTime();

      xDiff = today - lastMonth;
      minMax = [this.calcZeroX(lastMonth, xDiff, false), this.calcZeroX(today, xDiff, true)]
    } else {
      minMax = [this.calcMinX(pricePoints, xDiff), this.calcMaxX(pricePoints, xDiff)]
    }

    return minMax
  }

  private calcZeroX(time: number, diff: number, max: boolean): any {
    var x = 0;
    var perc_diff = diff * 0.15;
    if (max) x = time + perc_diff;
    else x = time - perc_diff;
    var y = new Date(x);
    return y;
  }

  private calcMinX(sortedPricePoints: DataPoint[], diff: number): any {
    var firstDataPoint = new Date(sortedPricePoints[0].x);
    var perc_diff = diff * 0.15;
    var x = firstDataPoint.getTime() - perc_diff;
    return new Date(x);
  }

  private calcMaxX(sortedPricePoints: DataPoint[], diff: number): any {
    var lastDataPoint = new Date(
      sortedPricePoints[sortedPricePoints.length - 1].x
    );
    lastDataPoint.setMonth(lastDataPoint.getMonth() + 1); // add padding to ensure current month is filled
    var perc_diff = diff * 0.15;
    var x = lastDataPoint.getTime() + perc_diff;
    return new Date(x);
  }
}
