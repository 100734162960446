import { Component, EventEmitter, OnInit } from '@angular/core';
import { UserConversation } from 'src/app/models/userConversation';
import { MessagingService } from 'src/app/services/messaging-service';

@Component({
  selector: 'pla-messenger-page',
  templateUrl: './messenger-page.component.html',
  styleUrls: ['./messenger-page.component.scss'],
})
export class MessengerPageComponent implements OnInit {
  public conversations: UserConversation[] = [];
  public selectedConversation: UserConversation = null;
  public conversationChange: EventEmitter<UserConversation> =
    new EventEmitter<UserConversation>();

  public loading: boolean = true;
  public conversationActive: boolean = false;

  constructor(private messagingService: MessagingService) {
    this.GetActiveConversations();
  }

  ngOnInit(): void {}

  public conversationSelectionHandler($event: UserConversation): void {
    this.selectedConversation = $event;
    this.conversationActive = true;
    this.conversationChange.emit(this.selectedConversation);
  }

  public conversationClosedHandler($event: any): void {
    this.conversationActive = false;
    this.selectedConversation = null;
  }

  private GetActiveConversations(): void {
    this.loading = true;
    this.messagingService.getConversations((convos: UserConversation[]) => {
      this.loading = false;
      this.conversations = convos;
      if (convos != null && convos.length > 0)
        this.conversationSelectionHandler(this.conversations[0]);
    });
  }
}
