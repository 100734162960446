<div *ngIf="chartType == 'price-data'" class="w-100">
  <canvas
    height="250"
    baseChart
    [plugins]="barChartPlugins"
    [data]="barChartData"
    [options]="barChartOptions"
    [type]="barChartType"
  >
  </canvas>
</div>
<div *ngIf="chartType == 'name-data'" class="w-100">
  <canvas
    height="400"
    baseChart
    [data]="singleBarChartData"
    [options]="singleBarChartOptions"
    [type]="singleBarChartType"
  >
  </canvas>
</div>
<div *ngIf="chartType == 'style-pie'" class="w-100">
  <canvas
    height="180"
    baseChart
    [plugins]="pieChartPlugins"
    [data]="pieChartData"
    [options]="pieChartOptions"
    [type]="pieChartType"
  >
  </canvas>
</div>
<div *ngIf="chartType == 'price-index'" class="w-100">
  <canvas
    height="400"
    id="strBarChart"
    baseChart
    [plugins]="lineChartPlugins"
    [data]="lineChartData"
    [options]="lineChartOptions"
    [type]="lineChartType"
  >
  </canvas>
</div>
