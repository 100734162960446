<div>
    <div class="d-block d-md-flex position-relative justify-content-between align-items-stretch gap-5"
        *ngIf="viewResults">
        <div class="d-md-block mb-3 mb-md-0 pt-2 start-0 end-0 position-sticky nr-info"
            style="top: 92px; height: fit-content;">
            <div class="py-3 px-4 rounded-3" style="background-color: #2D515A;">
                <ng-content></ng-content>
            </div>
        </div>
        <div class="flex-fill" style="max-width: 1000px;">
            <table class="table text-center">
                <thead>
                    <tr>
                        <th style="max-width: 200px;" class="text-start text-md-start">
                            <div class="d-flex align-items-center justify-content-between pe-2 fs-md-12 pointer"
                                (click)="sortChange('registration')">
                                <span>Initials</span>
                                <mat-icon data-nosnippet
                                    class="sort-{{isSorting('registration')}}">{{sortingIcon('registration')}}</mat-icon>
                            </div>
                        </th>
                        <th class="text-center" style="max-width: 120px;">
                            <div style="max-width: 120px;"
                                class="d-flex align-items-center justify-content-between pe-2 fs-md-12 pointer mx-auto"
                                (click)="sortChange('popularity')">
                                <div></div>
                                <span>Popularity</span>
                                <mat-icon data-nosnippet
                                    class="sort-{{isSorting('popularity')}}">{{sortingIcon('popularity')}}</mat-icon>
                            </div>
                        </th>
                        <th class="text-center" style="max-width: 120px;">
                            <div style="max-width: 120px;"
                                class="d-flex align-items-center justify-content-between pe-2 fs-md-12 pointer mx-auto"
                                (click)="sortChange('resalePrice')">
                                <div></div>
                                <span>Average Sale</span>
                                <mat-icon data-nosnippet
                                    class="sort-{{isSorting('resalePrice')}}">{{sortingIcon('resalePrice')}}</mat-icon>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of displayResults" (click)="GotoPlate(row.registration)" style="cursor: pointer"
                        class="hover-interact">
                        <td>
                            <div class="py-2 d-flex align-items-center justify-content-start">
                                <pla-plate [rawPlate]="row.registration" [customClass]="'flat shaded'"
                                    [plateSize]="'ns-s'" [smallPlate]="false" [mobileTiny]="true"></pla-plate>
                            </div>
                        </td>
                        <td>
                            <div style="max-width: 120px;" class="mx-auto">{{row.popularity | ordinalNumber}}</div>
                        </td>
                        <td>
                            <div style="max-width: 120px;" class="mx-auto">{{row.resalePrice | currency: 'GBP' :
                                "symbol" :
                                "1.0-0"}}</div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <mat-paginator [length]="viewResults.length" [pageSize]="paginationPageSize" [hidePageSize]="true"
                (page)="paginate($event)">
            </mat-paginator>
        </div>
    </div>
</div>